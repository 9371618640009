import {defineStore} from "pinia";
import {reactive, ref, toRaw} from "vue";
import {useServicesStore} from "@/stores/services";
import {useClaimLeadsStore} from "@/stores/claim-leads.store";

const slideComponents = [
    'RecurringStatus',
    'ServiceAreas',
    'Budget',
    'DeliveryMethod',
    'DeliveryContacts'
];

export const usePurchaseMissedProductsStore = defineStore('purchase-missed-products',
    () => {
        const loading = ref(false);
        const api = useServicesStore().apiService;
        const currentSlide = ref(slideComponents[0])
        const slides = ref(slideComponents)
        const showPurchaseFlow = ref(false);
        const showCheckout = ref(false);
        const servicesStore = useServicesStore();
        const claimLeadsStore = useClaimLeadsStore();
        const missedLeadData = reactive({
            serviceArea: [],
            totalLeadsSelected: 0,
        });
        const legacyCampaign = ref(null);
        const displayErrorModal = ref(false);
        const errorMessage = ref('');
        const isInitializingCheckout = ref(false);

        const editingCampaign = reactive({
            id: null,
            product: 'Lead',
            name: '',
            status: false, // must always be false until the checkout is successful, this status is updated in Admin2
            active_after_payment: true,
            daily_limit_type: null,
            max_daily_spend: 0,
            max_daily_leads: 0,
            contact_deliveries: [],
            crm_deliveries: {},
            crm_providers: [],
            service_areas: null,
            delivery_methods: [],
            property_types: [1],
            allow_non_budget_premium_leads: null,
            optional_leads_types: {},
            zip_codes: []
        });

        const editingCrmDelivery = reactive({
            id: null,
            name: null,
            enabled: false,
            crm: {
                id: null,
            },
            integration_fields: {
                system_fields: {},
                fields: {},
                custom_fields: {},
            },
        });

        const nextSlide = function (slideIndex = null) {
            if (!slideIndex) {
                currentSlide.value = slides.value[slides.value.indexOf(currentSlide.value) + 1];
            } else {
                currentSlide.value = slides.value[slideIndex];
            }
        }

        const getMinimumSpend = function() {
            const selectProducts = claimLeadsStore.unsoldProducts.filter(product => claimLeadsStore.customerSelectedLeads.includes(product.id));

            return selectProducts.reduce((total, item) => {
                if (item.hasOwnProperty('price')) {
                    return total + item.price;
                }
                return total;
            }, 0);
        }

        const formatDate = function(date) {
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();

            return `${month}/${day}/${year}`;
        }

        const getMissedProductZipCodes = function() {
            let codes = missedLeadData.items.map((selectedProduct) => selectedProduct.zipcode)
            return codes.filter((code, index) => codes.indexOf(code) === index)
        }

        /**
         * Get all zip codes of each county the selected products live in,
         * otherwise return just the selected product zip codes
        **/
        const getCountyZipCodes = async function() {
            try {
                const { data } = await api.getCountyZipCodes(getMissedProductZipCodes());

                if (data?.data?.zip_codes.length) {
                    return data?.data?.zip_codes;
                }

                return getMissedProductZipCodes()
            } catch (error) {
                displayErrorModal.value = true;
                errorMessage.value = genericErrorMessage()
            }
        }

        const initializeCheckout = async function () {
            isInitializingCheckout.value = true
            
            const formattedDate = formatDate((new Date()));
            editingCampaign.name = 'Missed opportunities purchased on ' + formattedDate;

            missedLeadData.serviceArea = getServiceAreaOfSelectedLeads();
            missedLeadData.totalLeadsSelected = claimLeadsStore.customerSelectedLeads.length;
            missedLeadData.items = claimLeadsStore.unsoldProducts.filter(product => claimLeadsStore.customerSelectedLeads.includes(product.id));

            if (editingCampaign.service_areas === 'counties') {
                editingCampaign.zip_codes = await getCountyZipCodes();
            } else {
                editingCampaign.zip_codes = getMissedProductZipCodes()
            }
            
            if (!editingCampaign.max_daily_spend) {
                editingCampaign.max_daily_spend = getMinimumSpend()
            }

            if (!editingCampaign.max_daily_leads) {
                editingCampaign.max_daily_leads = claimLeadsStore.customerSelectedLeads.length
            }

            if (editingCampaign.delivery_methods.includes('email')) {
                editingCampaign.contact_deliveries.map(function(contact) {
                    contact.email_active = true
                });
            }

            if (editingCampaign.delivery_methods.includes('sms')) {
                editingCampaign.contact_deliveries.map(function(contact) {
                    contact.sms_active = true
                });
            }

            createCampaign().then((resp) => {
                legacyCampaign.value = resp.data?.data?.campaign
                showPurchaseFlow.value = false;
                showCheckout.value = true;
            }).catch(() => {
                displayErrorModal.value = true;
                errorMessage.value = genericErrorMessage()
            }).finally(() => {
                isInitializingCheckout.value = false
            })
        }

        const createCampaign = function ()  {
            const payload = { ...toRaw(editingCampaign) };
            return servicesStore.apiService.saveCampaign(editingCampaign.id, payload);
        }

        const genericErrorMessage = function() {
            return `An unknown error occurred fetching data, please try again or contact your account manager.`;
        }

        const resetCheckoutFlow = function() {
            claimLeadsStore.clearCustomerSelectedLeads();
            showPurchaseFlow.value = false;
            currentSlide.value = slideComponents[0];
            legacyCampaign.value = null;
            Object.assign(editingCampaign, {
                id: null,
                name: '',
                status: true,
                daily_limit_type: null,
                max_daily_spend: 0,
                max_daily_leads: 0,
                contact_deliveries: [],
                crm_deliveries: {},
                crm_providers: [],
                service_areas: null,
                delivery_methods: [],
                property_types: [1],
                allow_non_budget_premium_leads: null,
                optional_leads_types: {},
                zip_codes: []
            })
        }

        const getServiceAreaOfSelectedLeads = () => {
            let servicedStates = [];

            for (const product in claimLeadsStore.unsoldProducts) {
                if (claimLeadsStore.customerSelectedLeads.includes(claimLeadsStore.unsoldProducts[product].id)) {
                    servicedStates.push(claimLeadsStore.unsoldProducts[product].state);
                }
            }

            return servicedStates;
        }

        const dismissError = function () {
            displayErrorModal.value = false;
            errorMessage.value = '';
        }

        return {
            showPurchaseFlow,
            showCheckout,
            loading,
            editingCampaign,
            editingCrmDelivery,
            currentSlide,
            slides,
            missedLeadData,
            legacyCampaign,
            errorMessage,
            displayErrorModal,
            isInitializingCheckout,
            nextSlide,
            initializeCheckout,
            resetCheckoutFlow,
            getMinimumSpend,
            formatDate,
            dismissError
        }
    },
    {
        persist: false,
    }
)
