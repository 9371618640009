<template>
    <div>
        <div class="grid grid-cols-1 gap-y-12"
            :class="[saving ? 'pointer-events-none grayscale-[50%] opacity-50' : '']"
        >
            <div class="grid md:grid-cols-2 max-w-2xl items-center pt-4 gap-2">
                <OutlineButton @click="createContactDelivery">New Contact Delivery</OutlineButton>
                <OutlineButton v-if="showIntegrationContent"
                               @click="createCrmDelivery"
                >
                    New CRM Delivery
                </OutlineButton>
            </div>
            <div v-if="productScope === 'appointments'" class="py-4">
                <div>
                    <h4 class="font-semibold pb-1">
                        Schedules
                    </h4>
                    <div class="hidden md:sticky md:top-20 md:grid md:grid-cols-4 text-xs text-gray-700 uppercase px-4 bg-cyan-50 font-bold py-3 items-center border-b border-gray-200" >
                        <div>Active</div>
                        <div>Schedule Name</div>
                        <div>Calendar Type</div>
                        <div>Actions</div>
                    </div>
                    <div class="relative min-h-[10rem]">
                        <div v-if="loadingSchedules" >
                            <LoadingSpinner :small="true" />
                        </div>
                        <div v-else-if="!scheduleStore.schedules.length">
                            No Schedules found.
                        </div>
                        <div v-else
                             class="relative border-b odd:bg-gray-50 text-gray-600 text-sm items-start md:items-center grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-0 px-4 py-6 md:py-4 md:px-4"
                            v-for="schedule in scheduleStore.schedules"
                        >
                            <div>
                                <CustomCheckbox v-model="activeSchedules[schedule.id]"
                                    @change="handleScheduleChange()"
                                />
                            </div>
                            <div>{{ schedule.name }}</div>
                            <div>{{ getPlatformName(schedule.platform) }}</div>
                            <div class="w-min ml-4 relative">
                                <ButtonDropdown class="">
                                    <template v-slot:trigger>
                                        <div class="cursor-pointer flex justify-center items-center text-cyan-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                            </svg>
                                        </div>
                                    </template>

                                    <div class="translate-y-7 bg-white border border-gray-300 shadow rounded absolute top-0 right-0 z-50 transform p-2 w-36">
                                        <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                             @click="viewSchedule(schedule.id)"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                            <p class="ml-2 pb-0 text-cyan-500">View</p>
                                        </div>
                                    </div>
                                </ButtonDropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="py-4">
                <h4 class="font-semibold pb-1">
                    Company Contacts
                </h4>
                <p class="text-sm text-gray-500 pb-4">Decide who receives emails or texts for this campaign.</p>
                <div class="hidden md:sticky md:top-20 md:grid md:grid-cols-4 text-xs text-gray-700 uppercase px-4 bg-cyan-50 font-bold py-3 items-center border-b border-gray-200" >
                    <div>Name</div>
                    <div>Email</div>
                    <div>Cell (SMS)</div>
                    <div>Actions</div>
                </div>
                <div v-for="userDelivery in campaignStore.editingCampaign.contact_deliveries"
                     :key="userDelivery.legacy_id"
                     class="relative border-b odd:bg-gray-50 text-gray-600 text-sm items-start md:items-center grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-0 px-4 py-6 md:py-4 md:px-4" >
                    <div>
                        <p class="font-bold text-sm md:hidden text-gray-900">Name</p>
                        <p>{{ userDelivery.name }}</p>
                    </div>
                    <div
                        :class="[ userDelivery.email ? '' : 'pointer-events-none grayscale-[50%] opacity-50' ]"
                    >
                        <p class="font-bold text-sm md:hidden text-gray-900">Email</p>
                        <div class="flex gap-y-4 gap-x-2">
                            <CustomCheckbox v-model="userDelivery.email_active" />
                            <p class="truncate" :class="[ userDelivery.email_active ? 'text-slate-900' : 'text-slate-400' ]">{{ userDelivery.email ?? "N/A" }}</p>
                        </div>
                    </div>
                    <div
                        :class="[ userDelivery.cell_phone ? '' : 'pointer-events-none grayscale-[50%] opacity-50' ]"
                    >
                        <p class="font-bold text-sm md:hidden text-gray-900">Cell (SMS)</p>
                        <div class="flex gap-y-4 gap-x-2">
                            <CustomCheckbox v-model="userDelivery.sms_active" />
                            <p class="truncate" :class="[ userDelivery.sms_active ? 'text-slate-900' : 'text-slate-400' ]">{{ userDelivery.cell_phone ?? "N/A" }}</p>
                        </div>
                    </div>
                    <div class="">
                        <div>
                            <div class="w-min ml-4 relative">
                                <ButtonDropdown class="">
                                    <template v-slot:trigger>
                                        <div class="cursor-pointer flex justify-center items-center text-cyan-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                            </svg>
                                        </div>
                                    </template>

                                    <div class="transform translate-y-7 bg-white border border-gray-300 shadow rounded absolute top-0 right-0 z-50 transform p-2 w-36">
                                        <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                             @click="editContact(userDelivery.id)"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                            <p class="ml-2 pb-0 text-cyan-500">Edit</p>
                                        </div>
                                        <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                             @click="deleteContactDeliveryConfirm(userDelivery.id)"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                            <p class="ml-2 pb-0 text-cyan-500">Delete</p>
                                        </div>
                                    </div>
                                </ButtonDropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="errorMessage" class="text-red-900 text-sm pt-8 py-6 text-center">
                {{ errorMessage }}
            </div>

            <div v-if="showIntegrationContent"
                 class="py-4 border-t border-slate-300"
            >
                <h4 class="font-semibold pb-4">
                    CRM Integrations
                </h4>
                <div class="hidden md:sticky md:top-20 md:grid md:grid-cols-4 text-xs text-gray-700 uppercase px-4 bg-cyan-50 font-bold py-3 items-center border-b border-gray-200" >
                    <div>Active</div>
                    <div>Name</div>
                    <div>CRM</div>
                    <div>Actions</div>
                </div>
                <div v-for="crmDelivery in campaignStore.campaignConfigData.crm_deliveries"
                     :key="crmDelivery.id"
                     class="relative border-b odd:bg-gray-50 text-gray-600 text-sm items-start md:items-center grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-0 px-4 py-6 md:py-4 md:px-4" >
                    <div>
                        <CustomCheckbox v-model="campaignStore.editingCampaign.crm_deliveries[crmDelivery.id]" />
                    </div>
                    <div>
                        <p class="truncate" :class="[ crmDelivery.active ? 'text-slate-900' : 'text-slate-400' ]">{{ crmDelivery.name }}</p>
                    </div>
                    <div>
                        <p class="truncate" :class="[ crmDelivery.active ? 'text-slate-900' : 'text-slate-400' ]">{{ crmDelivery.crm.name }}</p>
                    </div>
                    <div>
                        <div class="relative w-min ml-4">
                            <ButtonDropdown>
                                <template v-slot:trigger>
                                    <div class="cursor-pointer flex justify-center items-center text-cyan-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                        </svg>
                                    </div>
                                </template>

                                <div class="transform translate-y-7 bg-white border border-gray-300 shadow rounded absolute top-0 right-0 z-50 transform p-2 w-36">
                                    <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                         @click="editCrmDelivery(crmDelivery.id)"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                        </svg>
                                        <p class="ml-2 pb-0 text-cyan-500">Edit</p>
                                    </div>
                                    <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                         @click="deleteCrmDeliveryConfirm(crmDelivery.id)"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                        <p class="ml-2 pb-0 text-cyan-500">Delete</p>
                                    </div>
                                </div>
                            </ButtonDropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--    Add Contact Delivery Modal  -->
        <ContactDeliveryModal
          v-if="showContactDeliveryModal"
          @hideContactDeliveryModal="showContactDeliveryModal = false"
        />
        <!--    Add CRM Delivery Modal      -->
        <CrmDeliveryModal
            v-if="showCrmDeliveryModal"
            @hideCrmDeliveryModal="showCrmDeliveryModal = false"
        />
        <!--    Add contact modal    -->
        <CreateUpdateCompanyUserModal
            :deliveries-slide="true"
            @hideEditUserModal="closeEditUserModal"
            v-if="showEditContactModal"
        />
        <!--    Delete confirm modal    -->
        <Modal
            v-if="showDeleteConfirmModal"
            @clicked:confirm="deleteConfirm"
            @clicked:cancel="cancelConfirm"
            confirm-label="Delete"
            :small="true"
        >
            <template v-slot:header>
                <p>Delete {{ deletingType === 'crm' ? `CRM` : 'Contact'}} delivery</p>
            </template>
            <template v-slot:body>
                <p class="whitespace-pre-wrap">
                    {{ deletingType === 'crm'
                        ? `Are you sure you wish to delete the CRM Delivery method "${getCrmName}"?\n\nThis will completely remove the CRM and its settings. If the CRM is attached to other Campaigns, it will not be deleted.`
                        : `Are you sure you wish to delete the Contact Delivery method for "${getContactName}"?\nThis will remove them as a lead delivery option for this Campaign only - it will not delete the contact.`
                    }}
                </p>
            </template>
        </Modal>

        <!--    View Schedule Modal    -->
        <CreateOrEditScheduleModal
            v-if="showViewScheduleModal"
            :view-mode="true"
            :edit-schedule="viewingSchedule"
            @close-modal="closeScheduleViewer"
        />

    </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import ContactDeliveryModal from "@/components/campaigns/EditorSlides/Modals/ContactDeliveryModal.vue";
import { useCampaignStore } from "@/stores/campaigns.js";
import CrmDeliveryModal from "@/components/campaigns/EditorSlides/Modals/CrmDeliveryModal.vue";
import CreateUpdateCompanyUserModal from "@/components/company/modals/CreateUpdateCompanyUserModal.vue";
import Modal from "@/components/Modal.vue";
import ButtonDropdown from "@/components/ButtonDropdown.vue";
import { useCompanyUsersStore } from "@/stores/company-users.js";
import { useSchedulesStore } from "@/stores/schedules.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import CreateOrEditScheduleModal from "@/views/appointments/modals/CreateOrEditScheduleModal/CreateOrEditScheduleModal.vue";

const props = defineProps({
    productScope: {
        type: String,
        default: 'leads',
    },
    showIntegrationContent: {
        type: Boolean,
        default: true,
    },
    purchaseMissedLead: {
        type: Boolean,
        default: false,
    },
});

const campaignStore = useCampaignStore();
const companyUserStore = useCompanyUsersStore();
const scheduleStore = useSchedulesStore();

const loadingSchedules = ref(false);
const saving = ref(false);
const errorMessage = ref(null);
const activeSchedules = ref({});

const showContactDeliveryModal = ref(false);
const showCrmDeliveryModal = ref(false);
const showDeleteConfirmModal = ref(false);
const showEditContactModal = ref(false);

const showViewScheduleModal = ref(false);
const viewingSchedule = ref(null);

const deletingType = ref(null);
const deletingContactId = ref(null);
const deletingCrmId = ref(null);

const platformLabels = {
    'internal': 'Availability Timeframe',
};

onMounted(async () => {
    await companyUserStore.initialize(); // should already be loaded by campaign editor, but make sure
    if (props.productScope === 'appointments') {
        loadingSchedules.value = true;
        scheduleStore.initialize().then(() => {
            setActiveSchedules();
            loadingSchedules.value = false;
        });
    }

    // If purchasing missed lead(s), Auto-populate the existing contact(s) of a company to speed up campaign creation
    if (props.purchaseMissedLead
    && !campaignStore.editingCampaign.contact_deliveries.length) {
        createContactDelivery();
    }
});

const getCrmName = computed(() => {
    const target = campaignStore.campaignConfigData.crm_deliveries?.find(crm => crm.id === deletingCrmId.value);
    return target ? target.name : 'Unknown CRM';
});
const getContactName = computed(() => {
    const target = companyUserStore.companyUsers.find(user => user.id === deletingContactId.value);
    return target ? target.first_name : 'Unknown Contact';
});

const editCrmDelivery = (crmDeliveryId) => {
    campaignStore.editingCrmDelivery.id = crmDeliveryId;
    showCrmDeliveryModal.value = true;
}

const createContactDelivery = () => {
    showContactDeliveryModal.value = true;
}

const createCrmDelivery = () => {
    showCrmDeliveryModal.value = true;
}

const deleteCrmDelivery = async () => {
    errorMessage.value = null;
    const crmDeliveryId = deletingCrmId.value;
    saving.value = true;
    const { status, message } = await campaignStore.deleteCrmDelivery(crmDeliveryId);
    if (!status) {
        errorMessage.value = message;
    }
    cancelConfirm();
    saving.value = false;
}

const editContact = (contactId) => {
    companyUserStore.editCompanyUser(contactId);
    showEditContactModal.value = true;
}

const deleteContactDelivery = async () => {
    saving.value = true;
    const { status, message } = await campaignStore.deleteContactDelivery(deletingContactId.value);
    if (!status) {
        errorMessage.value = message;
    }
    cancelConfirm();
    saving.value = false;
}

const deleteContactDeliveryConfirm = (contactId) => {
    deletingType.value = 'contact';
    deletingContactId.value = contactId;
    showDeleteConfirmModal.value = true;
}

const deleteCrmDeliveryConfirm = (crmDeliveryId) => {
    deletingType.value = 'crm';
    deletingCrmId.value = crmDeliveryId;
    showDeleteConfirmModal.value = true;
}

const cancelConfirm = () => {
    deletingType.value = null;
    deletingCrmId.value = null;
    deletingContactId.value = null;
    showDeleteConfirmModal.value = false;
}

const deleteConfirm = () => {
    if (deletingType.value === 'crm') {
        deleteCrmDelivery();
    }
    else if (deletingType.value === 'contact') {
        deleteContactDelivery();
    }
    showDeleteConfirmModal.value = false;
}

const closeEditUserModal = () => {
    showEditContactModal.value = false;
}

const setActiveSchedules = () => {
    scheduleStore.schedules.forEach((schedule) => {
        activeSchedules.value[schedule.id] = (campaignStore.editingCampaign.schedules || []).includes(schedule.id);
    });
}

const viewSchedule = (scheduleId) => {
    viewingSchedule.value = scheduleStore.schedules.find(schedule => schedule.id === scheduleId) ?? null;
    if (viewingSchedule.value) showViewScheduleModal.value = true;
}

const closeScheduleViewer = () => {
    viewingSchedule.value = null;
    showViewScheduleModal.value = false;
}

const getPlatformName = (platformId) => {
    return platformLabels[platformId] || platformId;
}

const handleScheduleChange = () => {
    campaignStore.editingCampaign.schedules = Object.entries(activeSchedules.value).reduce((output, [id, active]) => {
        return active ? [id, ...output] : output;
    }, []);
}

const validateSlide = () => {
    return (campaignStore.editingCampaign.contact_deliveries.length + Object.values(campaignStore.editingCampaign.crm_deliveries).filter(v=>v).length) > 0
        ? { status: true }
        : { status: false, message: `At least one delivery method must be active.` }
}
defineExpose({ validateSlide });

</script>