<template>
    <div v-if="accountManager && accountManager.name">
        <div class="pt-4 mt-6 border-t">
            <p class="mx-4 font-semibold text-xs mb-3 text-gray-500 uppercase">Your Account Manager</p>
        </div>
        <div class="flex flex-row items-center">
            <avatar
                :name="accountManager.name"
            />
            <div class="ml-1 my-0 lg:w-40 w-full">
                <p v-if="accountManager.name"
                   class="ml-2 lg:truncate font-bold text-sm text-base-2 pb-0"
                >
                    {{ prepareName(accountManager.name) }}
                </p>
                <p v-if="accountManager.phone"
                   class="ml-2 lg:truncate text-xs text-base-2 pb-0 cursor-pointer"
                   @click="callUser"
                >
                    {{ accountManager.phone }}
                </p>
                <p v-if="accountManager.email"
                   class="ml-2 lg:truncate text-xs text-base-2 pb-2 cursor-pointer"
                   @click="emailUser"
                >
                    {{ accountManager.email }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { useProperCase } from "@/composables/useProperCase";
    import Avatar from "./Avatar.vue";

    const props = defineProps({
        accountManager: {
            type: Object,
            default: {}
        },
    });

    const prepareName = (name) => {
        return useProperCase(name);
    }

    const emailUser = (email) => {
        window.open(`mailto:${email}`, '_self');
    }

    const callUser = (phone) => {
        window.open(`tel:${phone}`, '_self');
    }

</script>

<style scoped>

</style>