<template>
    <div class="bg-white p-20 py-10 rounded-xl flex flex-col gap-6">
        <LoadingSpinner v-if="loading" :small="true"/>
        <div class="cursor-pointer flex items-center">
            <span class="mr-2">
                <svg width="7" height="12" viewBox="0 0 7 12" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.70536 11.2946C7.09463 10.9053 7.09497 10.2743 6.70612 9.88462L2.82997 6L6.70612 2.11539C7.09497 1.72569 7.09463 1.09466 6.70536 0.705388V0.705388C6.31578 0.315815 5.68416 0.315815 5.29459 0.705388L0.707078 5.2929C0.316554 5.68342 0.316554 6.31659 0.707078 6.70711L5.29459 11.2946C5.68416 11.6842 6.31578 11.6842 6.70536 11.2946V11.2946Z"
                        fill="#50AFE2"/>
                </svg>
            </span>
            <span>
                <a @click="returnToLogin" class="text-cyan-500">Back to Login</a>
            </span>
        </div>

        <div v-if="migratingRoofingUser">
            <h1 class="text-2xl mb-2 text-center">Reset Password</h1>
            <p v-if="attemptedLegacyLogin" class="mb-2">
                It looks like you might be trying to log in using old Roofing Calculator Dashboard credentials.
                If you are a Roofing Calculator customer, please use this form to reset your password.
            </p>
            <p>
                Enter the email address associated with your old Roofing Calculator Dashboard account, then follow the instructions sent in the email.
                You may re-use your old password if you wish.
            </p>
        </div>

        <div v-else>
            <h1 class="text-2xl mb-2">Forgot password</h1>
            <p>Enter the email address associated with your FIXR account.</p>
        </div>

        <div v-if="errors[0]" class="text-red-500 text-center">
            {{errors[0]}}
        </div>

        <div v-if="sentConfirmation" class="p-3 bg-cyan-100 border border-blue-200 rounded">
            <p class="pb-1">
                If the provided address exists in our system, an email has been sent.
            </p>
            <p class="pb-1">
                Please click the link in the email to reset your password.
            </p>
            <p v-if="resendTimer > 0">
                If you do not receive the email, you can send another one in {{ resendTimer }} seconds.
            </p>
        </div>

        <TextInput
            label="Email"
            v-model="email"
            :disabled="resendTimer > 0"
        />
        <CustomButton
            @click="requestPasswordReset"
            :disabled="resendTimer > 0"
            :loading="props.loading"
        >
            {{
                sentConfirmation ? 'Resend Email' : 'Send Reset Email'
            }}
        </CustomButton>

        <div class="flex justify-end items-center gap-2">
            <span class="mr-2 text-gray-400 text-sm">Powered by </span>
            <fixr-roofing-calculator-logo/>
        </div>
    </div>
</template>

<script setup lang="ts">
import {onMounted, Ref, ref, watch} from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import FixrRoofingCalculatorLogo from "@/components/shared/FixrRoofingCalculatorLogo.vue";
import CustomButton from "@/components/authentication/components/CustomButton.vue";
import TextInput from "@/components/authentication/components/TextInput.vue";

interface Props {
    initialEmail: string,
    loading: boolean,
    errors: String[],
    migratingRoofingUser: boolean,
    attemptedLegacyLogin: boolean,
}

const props = defineProps<Props>();

const emit = defineEmits(["reset-password", "cancel"]);

const sentConfirmation: Ref<boolean> = ref(false);
const email: Ref<string> = ref('');
const throttle = 60;
const resendTimer: Ref<number> = ref(0);
const resendInterval: Ref<number | undefined> = ref();

onMounted(() => {
    email.value = props.initialEmail;
});

const returnToLogin = () => emit('cancel');
const requestPasswordReset = () => {
    if (resendTimer.value > 0) return;
    emit('reset-password', email.value);
}

const confirmationSent = () => {
    sentConfirmation.value = true;
    const sentAt = Date.now();
    resendTimer.value = throttle;

    resendInterval.value = setInterval(() => {
        const elapsed = Math.round((Date.now() - sentAt) / 1000);
        resendTimer.value = throttle - elapsed;

        if (resendTimer.value <= 0) {
            clearInterval(resendInterval.value);
            resendTimer.value = 0;
        }
    }, 1000);
}

defineExpose({
    confirmationSent,
});

watch(() => props.initialEmail, () => {
    email.value = props.initialEmail;
});

</script>