<template>
    <ButtonDropdown class="relative w-8 h-8 transition duration-300 rounded-full hover:bg-cyan-100 flex items-center justify-center text-cyan-500">
        <template v-slot:trigger>
            <div class="cursor-pointer flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
            </div>
        </template>

        <div class="transform translate-y-7">
            <div class="bg-white border-l border-t border-gray-300 absolute top-0 right-0 transform rotate-45 -translate-x-1 -translate-y-2 z-75">
                <div class="w-4 h-4"></div>
            </div>
            <div class="bg-white border border-gray-300 shadow rounded absolute top-0 right-0 z-60 transform lg:translate-x-4 translate-x-12">
                <div class="w-auto whitespace-no-wrap h-full text-sm">
                    <div class="block border-b border-gray-300">
                        <div class="block py-4 px-6 items-center">
                            <p class="font-medium text-gray-800 pb-1">{{ user.fullName }}</p>
                            <p class="text-gray-700 pb-1">{{ user.email }}</p>
                            <RouterLink :to="'/user-settings'" class="text-cyan-500 cursor-pointer">User Settings</RouterLink>
                        </div>
                    </div>
                    <div class="block py-4 px-6">
                        <div class="inline-flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 mr-2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                            </svg>
                            <p @click="logout" class="text-cyan-500 cursor-pointer">Log out</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ButtonDropdown>
</template>

<script setup>
import {useAuthStore} from "@/stores/auth";
import {useCompanyStore} from "@/stores/company";
import {useUserStore} from "@/stores/user";
import {useRouter, RouterLink} from "vue-router";
import ButtonDropdown from "@/components/ButtonDropdown.vue";
import * as Sentry from '@/sentry'
import { useAppointmentsStore } from "@/stores/appointments.js";
import { useBillingStore } from "@/stores/billing.js";
import { useCampaignStore } from "@/stores/campaigns.js";
import { useCompanyLicenseStore } from "@/stores/company-licenses.js";
import { useCompanyMediaStore } from "@/stores/company-media.js";
import { useCompanyUsersStore } from "@/stores/company-users.js";
import { useLeadsStore } from "@/stores/leads.js";
import { useErrorStore } from "@/stores/errors.js";
import { useLocalityDataStore } from "@/stores/locality-data.js";
import { useLocationsStore } from "@/stores/locations.js";
import { useNotificationSettingsStore } from "@/stores/notification-settings.js";
import { usePricingStore } from "@/stores/pricing.js";
import { useProfitabilityAssumptionsStore } from "@/stores/profitability-assumptions.js";
import { useProfitabilityFilterStore } from "@/stores/profitability-filters.js";
import { useReferenceDataStore } from "@/stores/reference-data.js";

const auth = useAuthStore();
const company = useCompanyStore();
const user = useUserStore();
const appointments = useAppointmentsStore();
const billing = useBillingStore();
const campaigns = useCampaignStore();
const companyLicenses = useCompanyLicenseStore();
const companyMedia = useCompanyMediaStore();
const companyUsers = useCompanyUsersStore();
const errors = useErrorStore();
const leads = useLeadsStore();
const localityData = useLocalityDataStore();
const locations = useLocationsStore();
const notificationSettings = useNotificationSettingsStore();
const pricing = usePricingStore();
const profitabilityAssumptions = useProfitabilityAssumptionsStore();
const profitabilityFilters = useProfitabilityFilterStore();
const referenceData = useReferenceDataStore();

const router = useRouter();

const logout = () => {
    auth.$reset();
    company.$reset();
    user.$reset();
    appointments.$reset();
    billing.$reset();
    campaigns.$reset();
    companyLicenses.$reset();
    companyMedia.$reset();
    companyUsers.$reset();
    errors.$reset();
    leads.$reset();
    localityData.$reset();
    locations.$reset();
    notificationSettings.$reset();
    pricing.$reset();
    profitabilityAssumptions.$reset();
    profitabilityFilters.$reset();
    referenceData.$reset();

    Sentry.removeUserFromContext()

    router.push({name: 'login'});
}

</script>