<template>
    <div>
        <span class="font-medium py-1 px-2 rounded" :class="[styleClasses, sizeClasses]">{{ text }}</span>
    </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    text:{
        type: String,
        required: true
    },

    color: {
        type: String,
        default: 'blue',
    },

    size: {
        type: String,
        default: 'base'
    }
})

const styleClasses = computed(() => {
    const classes = []

    switch (props.type) {
        case 'blue':
        default:
            classes.push('bg-blue-400 text-white')
            break;
    }

    return classes;
})


const sizeClasses = computed(() => {
    const classes = []

    switch (props.size) {
        case 'xs':
            classes.push('text-xs')
            break;
        case 'lg':
            classes.push('text-lg')
            break;
        case 'xl':
            classes.push('text-xl')
            break;
        case '2xl':
            classes.push('text-2xl')
            break;
        case 'base':
        default:
            classes.push('text-base')
            break;
    }

    return classes;
})
</script>