import { defineStore } from "pinia";
import { ProductType, useServicesStore } from "@/stores/services";
import { computed, ComputedRef, reactive, ref, Ref } from "vue";
import { BaseApiServiceV4 } from "@/services/api/v4/BaseApiServiceV4";
import { useSnakeCase } from "@/composables/useSnakeCase";
import { useProperCase } from "@/composables/useProperCase";

export enum BudgetType {
    NO_LIMIT = 0,
    TYPE_DAILY_UNITS = 1,
    TYPE_DAILY_SPEND = 2,
}

export type BudgetConfiguration = {
    display_name: string,
    key: string,
    status: number,
    type: number,
    value: number,
    required?: boolean,
    product_configuration: GenericObject,
}

export type BudgetInput = {
    display_name: string,
    key: string,
    status: number,
    type: number,
    value: number,
    required: boolean,
}

export type BudgetConfigurationCollection = {
    required: BudgetConfiguration[],
    optional: BudgetConfiguration[],
}

export type ProductConfiguration = {
    property_types: string[],
    budget_types: string[],
    budgets: BudgetConfigurationCollection,
    budget_options: GenericObject,
    can_reject: boolean,
    rejection_reasons: {
        [key: string]: string,
    },
}

type ProductConfigurationCollection = {
    [key in ProductType]: ProductConfiguration
}

type CampaignStatusConfiguration = {
    statuses: string[],
    reasons: { [key: string]: string },
}

type BudgetSummary = {
    [key: string]: string[];
}

type BudgetSummaryCollection = {
    [key in string]: BudgetSummary
}

const getDefaultProductConfiguration = () => {
    return {
        property_types: [],
        budget_types: [],
        budget_options: {},
        budgets: {
            required: [],
            optional: []
        },
        can_reject: true,
        rejection_reasons: {},
    }
}

export const useProductConfigurationStore = defineStore('productConfiguration', () => {
    const services = useServicesStore();

    const productStore: ProductConfigurationCollection = reactive({
        [ProductType.Lead]: getDefaultProductConfiguration(),
        [ProductType.Appointment]: getDefaultProductConfiguration(),
        [ProductType.DirectLeads]: getDefaultProductConfiguration(),
    });

    const budgetSummaryStore: Ref<BudgetSummaryCollection> = ref({[ProductType.Lead]: {}, [ProductType.Appointment]: {}});

    const currentConfiguration: ComputedRef<ProductConfiguration> = computed(() => {
        return productStore[services.apiServiceV4.getProductKey()] ?? {};
    });

    const currentBudgetSummary: ComputedRef<BudgetSummary> = computed(() =>
        budgetSummaryStore.value[productScope.value]);

    const campaignStatusConfiguration: Ref<CampaignStatusConfiguration> = ref({
        statuses: [],
        reasons: {},
    });

    const overBudgetOptions: CustomSelectOption[] = [
        { label: 'All', value: '' },
        { label: 'Qualified Over Budget', value: 1 },
        { label: 'Within Budget', value: 0 },
    ];

    const productStatuses: CustomSelectOption[] = [
        { label: 'All', value: '' },
        { label: 'Purchased', value: 'purchased' },
        { label: 'Non Chargeable', value: 'non_chargeable' },
        { label: 'Rejected', value: 'rejected' },
    ];

    const initialized: Ref<boolean> = ref(false);

    const defaultBudgetKey: ComputedRef<string> = computed(() => getRequiredBudgetKeys()[0] ?? getOptionalBudgetKeys()[0] ?? '');
    const usesLinkedBudgets: ComputedRef<boolean> = computed(() => currentConfiguration.value.budget_options?.link_optional_budget_types ?? false);
    const showPricing: ComputedRef<boolean> = computed(() => currentConfiguration.value.budget_options?.show_pricing ?? false);
    const minimumDailySpend: ComputedRef<number> = computed(() => currentConfiguration.value.budget_options.minimum_daily_spend ?? 0);
    const minimumDailyProducts: ComputedRef<number> = computed(() => currentConfiguration.value.budget_options.minimum_daily_products ?? 0);
    const emailOnlyBudgetEnabled: ComputedRef<boolean> = computed(() => {
        for (const qualityTier in currentBudgetSummary.value) {
            if (currentBudgetSummary.value[qualityTier].includes('email_only'))
                return true;
        }
        return false;
    })

    const initialize = async (): Promise<StatusResponse> => {
        if (initialized.value) return { status: true }
        const resp = await services.apiServiceV4.getProductConfigurations();
        if (resp.data?.data?.status) {
            for (const product in resp.data.data.product_configurations) {
                productStore[product as ProductType] = resp.data.data.product_configurations[product];
                campaignStatusConfiguration.value = resp.data.data.status_configuration;
            }
            setBudgetSummaries();

            return { status: true }
        }
        else return BaseApiServiceV4.transformErrorResponse(resp);
    }

    /**
     * Set budget summaries for bidding module filtering
     * Bidding uses this to set the available quality tiers and sale types on the bidding table for /v4
     * Current pricing has no config per-industry, and will send through ALL quality tiers/saletypes to the bidding store
     */
    const setBudgetSummaries = () => {
        for (const product in productStore) {
            budgetSummaryStore.value[product] = Object.entries(productStore[product as ProductType].budgets).reduce((output, [_budgetType, budgetArray]) => {
                budgetArray.forEach(budget => {
                    Object.entries(budget.product_configuration ?? {}).forEach(([qualityTier, saleTypeArray]) => {
                        output[qualityTier] = output[qualityTier] ?? [];
                        (saleTypeArray as string[]).forEach((saleTypeName) => {
                            const saleTypeKey = useSnakeCase(saleTypeName);
                            if (!output[qualityTier].includes(saleTypeKey)) output[qualityTier].push(saleTypeKey);
                        });
                    });
                });

                return output;
            }, {} as BudgetSummary);
        }
    }

    const getBudgetTypeOptions = () => {
        return Object.entries(currentConfiguration.value.budget_types ?? {}).map(([budgetKey, budgetLabel]) => ({ value: parseInt(budgetKey), label: budgetLabel }));
    }

    const getBudgetTypeLabel = (type: number): string => {
        return currentConfiguration.value?.budget_types[type] ?? '';
    }

    const getDefaultBudgetType = () => {
        return 0; // Implement properly if a product needs something other than 'No Limit' as default budget type
    }

    const getRequiredBudgets = () => {
        return currentConfiguration.value?.budgets.required ?? [];
    }

    const getRequiredBudgetKeys = () => {
        return getRequiredBudgets().map(budget => budget.key);
    }

    const getOptionalBudgets = () => {
        return currentConfiguration.value?.budgets.optional ?? [];
    }

    const getOptionalBudgetKeys = () => {
        return getOptionalBudgets().map(budget => budget.key);
    }

    const getSalesTypes = (budgetKey: string, requiredBudget?: boolean, qualityTier?: string): { key: string, name: string }[] => {
        const requiredKey = requiredBudget ? 'required' : 'optional';
        const targetBudgetConfig = currentConfiguration.value?.budgets[requiredKey]?.find(budget => budget.key === budgetKey)?.product_configuration;

        if (targetBudgetConfig) {
            const tiers = qualityTier
                ? targetBudgetConfig[qualityTier] ?? []
                : Object.values(targetBudgetConfig)[0];

            return tiers.reduce((output: { key: string, name: string }[], enumName: string) => {
                return [...output, { key: useSnakeCase(enumName), name: enumName }];
            }, []);

        }
        else
            return [];
    }

    const getPropertyTypes = () => {
        return currentConfiguration.value?.property_types ?? [];
    }

    const getDefaultPropertyType = () => {
        return currentConfiguration.value?.property_types[0] ?? 'Residential';
    }

    const getBudgetDefault = (budgetKey: string, requiredBudget?: boolean): BudgetInput|null => {
        const requiredKey = requiredBudget ? 'required' : 'optional';
        const targetBudget = currentConfiguration.value?.budgets[requiredKey]?.find(budget => budget.key === budgetKey);

        return targetBudget ?
            {
                display_name: targetBudget.display_name,
                key: targetBudget.key,
                type: targetBudget.type,
                value: targetBudget.value,
                status: targetBudget.status,
                required: targetBudget.required ?? false,
            }
            : null;
    }

    const getBudgetName = (budgetKey: string, required?: boolean): string => {
        const requiredKey = required ? 'required' : 'optional';
        const targetBudget = currentConfiguration.value.budgets[requiredKey].find(budget => budget.key === budgetKey);

        return targetBudget?.display_name ?? useProperCase(targetBudget?.key ?? '');
    }

    const productScope: ComputedRef<ProductType> = computed(() => services.apiServiceV4.getProductKey());

    const getProductLabel = (plural?: boolean) => {
        const label = useProperCase(productScope.value);
        return plural
            ? `${label}s`
            : label;
    }

    const canRejectCurrentProduct = (): boolean => {
        return currentConfiguration.value.can_reject;
    }

    const updateRejectionStatus = (newStatus: boolean|null): void => {
        if (newStatus === null) return;
        else
            currentConfiguration.value.can_reject = newStatus;
    }

    return {
        configuration: currentConfiguration,
        defaultBudgetKey,
        usesLinkedBudgets,
        showPricing,
        minimumDailySpend,
        minimumDailyProducts,
        productScope,
        campaignStatusConfiguration,
        productStatuses,
        overBudgetOptions,
        currentBudgetSummary,
        emailOnlyBudgetEnabled,

        initialize,
        getBudgetTypeOptions,
        getBudgetTypeLabel,
        getDefaultBudgetType,
        getRequiredBudgets,
        getOptionalBudgets,
        getRequiredBudgetKeys,
        getOptionalBudgetKeys,
        getSalesTypes,
        getPropertyTypes,
        getDefaultPropertyType,
        getBudgetDefault,
        getBudgetName,
        getProductLabel,
        canRejectCurrentProduct,
        updateRejectionStatus,
    }
});