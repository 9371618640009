<template>
    <div>
        <Modal
            @clicked:confirm="submitRejection"
            @clicked:cancel="cancelModal"
            :disable-buttons="saving"
            confirm-label="Ok"
        >
            <template v-slot:header>
                <div class="flex items-center">
                    <h5 class="text-md">Do you want to reject this appointment?</h5>
                </div>
            </template>
            <template v-slot:body>
                <div v-if="loading">
                    <p class="text-gray-500 text-center">Loading...</p>
                </div>
                <div v-else>
                    <div class="mb-4 border-b border-slate-300 px-6">
                        <div class="grid xl:grid-cols-3 items-center gap-4 gap-y-8 pb-6">
                            <div>
                                <p>Appointment: {{ appointment.id }}</p>
                            </div>
                            <div class="xl:col-span-2">
                                <p>Date Delivered: {{ useFormatTimestamp(appointment.date_sent) }}</p>
                            </div>
                            <div>
                                <div class="flex items-center mb-1 text-slate-500">
                                    <svg  class="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                    </svg>
                                    <p class="ml-2">Name</p>
                                </div>
                                <p class="ml-2">{{ appointment.name }}</p>
                            </div>
                            <div class="xl:col-span-2">
                                <div class="flex items-center mb-1 text-slate-500">
                                    <svg  class="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                    </svg>
                                    <p class="ml-2">Address</p>
                                </div>
                                <p class="ml-2">{{ appointment.address }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col gap-y-6 px-4 py-4">
                        <CustomSelect
                            :options="rejectionOptions"
                            v-model="selectedRejection"
                            label="Reason to reject:"
                        />
                        <div>
                            <p class="block mb-1 text-sm font-medium">Notes:</p>
                            <textarea
                                class="w-full border border-slate-300 rounded-lg resize-none h-[8rem] py-2 px-3 overflow-y-auto"
                                v-model="rejectionDescription"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="errorMessage" class="py-4">
                   <p class="text-red-900 text-sm text-center">{{ errorMessage }}</p>
                </div>
            </template>

        </Modal>
        <Modal
            v-if="exceedingRejectionQuota"
            :show-cancel-button="false"
            confirm-label="Ok"
            :small="true"
            @clicked:confirm="exceedingRejectionQuota = false"
        >
            <template v-slot:header>
                About to exceed rejection quota.
            </template>
            <template v-slot:body>
                <p class="text-red-700 text-center">Warning: rejecting this appointment may prevent you from receiving appointments for up to 30 days.</p>
            </template>
        </Modal>
    </div>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import { useFormatTimestamp } from "@/composables/useFormatTimestamp.js";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { useLeadsStore } from "@/stores/leads.js";
import { onMounted, ref } from "vue";
import {useAppointmentsStore} from "@/stores/appointments";

const props = defineProps({
    appointment: {
        type: Object,
        default: {},
    }
});

const appointmentStore = useAppointmentsStore();

const saving = ref(false);
const loading = ref(false);

const rejectionOptions = [
    { label: 'No Show', value: 'ns' },
    { label: 'Other', value: 'ot' }
];
const selectedRejection = ref(null);
const rejectionDescription = ref('');

const errorMessage = ref(null);
const exceedingRejectionQuota = ref(false);

const emit = defineEmits(['hideAppointmentRejectionModal']);

onMounted(() => {
    selectedRejection.value = rejectionOptions[0].value;
});

const submitRejection = async () => {
    if (!validateRejection()) return;
    errorMessage.value = null;
    saving.value = true;
    const payload = {
        rejection_reason: selectedRejection.value,
        rejection_notes: rejectionDescription.value,
    }
    const { status, message } = await appointmentStore.rejectAppointment(props.appointment.id, payload);
    if (!status) {
        errorMessage.value = message;
    }
    else {
        await appointmentStore.search();
        cancelModal();
    }
    saving.value = false;
}

const validateRejection = () => {
    const error = !selectedRejection.value
        ? 'Please select a rejection reason'
        : rejectionDescription.value.length < 10
            ? `Please provide a brief description of the reason for rejection`
            : null;
    if (error) errorMessage.value = error;
    return !error;
}

const cancelModal = () => {
    emit('hideAppointmentRejectionModal');
}

</script>