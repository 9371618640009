<template>
    <div v-click-outside="hide">
        <div v-if="selectingIndustry" class="fixed lg:absolute top-[8.5rem] md:top-[9.5rem] lg:top-0 left-0 inset-y-0 w-full overflow-y-auto z-50 text-black shadow" :class="{'bg-cyan-25': selectingIndustry}">
            <div class="flex items-center justify-between px-5 my-4">
                <button class="flex items-center text-sm text-cyan-500 hover:text-cyan-600 cursor-pointer"
                        @click="launchIndustryServiceEditor"
                >
                    <svg class="fill-current mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.55556 14H12.4444C12.857 14 13.2527 13.8361 13.5444 13.5444C13.8361 13.2527 14 12.857 14 12.4444V1.55556C14 1.143 13.8361 0.747335 13.5444 0.455612C13.2527 0.163888 12.857 0 12.4444 0H1.55556C1.143 0 0.747335 0.163888 0.455612 0.455612C0.163888 0.747335 0 1.143 0 1.55556V12.4444C0 12.857 0.163888 13.2527 0.455612 13.5444C0.747335 13.8361 1.143 14 1.55556 14ZM3.11111 6.22222H6.22222V3.11111H7.77778V6.22222H10.8889V7.77778H7.77778V10.8889H6.22222V7.77778H3.11111V6.22222Z"/>
                    </svg>
                    <p class="">Add New Service</p>
                </button>
                <svg @click="selectIndustryService" class="cursor-pointer w-3.5 fill-current text-cyan-500 hover:text-cyan-600" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292492 0.305288C0.479831 0.117817 0.733884 0.0125018 0.998781 0.0125018C1.26368 0.0125018 1.51773 0.117817 1.70507 0.305288L5.99375 4.59829L10.2824 0.305288C10.3746 0.209778 10.4848 0.133596 10.6067 0.0811869C10.7286 0.0287779 10.8597 0.00119157 10.9923 3.77571e-05C11.125 -0.00111606 11.2565 0.0241854 11.3793 0.0744663C11.5021 0.124747 11.6136 0.199 11.7074 0.292893C11.8012 0.386786 11.8754 0.498438 11.9256 0.621334C11.9758 0.744231 12.0011 0.87591 12 1.00869C11.9988 1.14147 11.9713 1.27269 11.9189 1.39469C11.8665 1.5167 11.7904 1.62704 11.695 1.71929L7.40633 6.01229L11.695 10.3053C11.877 10.4939 11.9777 10.7465 11.9754 11.0087C11.9731 11.2709 11.8681 11.5217 11.6828 11.7071C11.4976 11.8925 11.2471 11.9977 10.9851 12C10.7232 12.0022 10.4709 11.9014 10.2824 11.7193L5.99375 7.42629L1.70507 11.7193C1.51666 11.9014 1.26431 12.0022 1.00238 12C0.740443 11.9977 0.489883 11.8925 0.304661 11.7071C0.11944 11.5217 0.0143761 11.2709 0.0121 11.0087C0.00982384 10.7465 0.110517 10.4939 0.292492 10.3053L4.58118 6.01229L0.292492 1.71929C0.10521 1.53176 0 1.27745 0 1.01229C0 0.747124 0.10521 0.492816 0.292492 0.305288Z"/>
                </svg>
            </div>
            <p class="font-semibold text-xs px-5 pb-2 text-gray-500 uppercase">Your Services</p>

            <div class="bg-white overflow-y-auto">
                <div v-for="(industry, idx) in company.services" class="capitalize">
                    <p class="px-5 py-1 bg-gray-100 border-y font-semibold text-black">
                        {{ useProperCase(idx) }}
                    </p>
                    <div class="">
                        <div v-for="service in industry" @click="select(service)" class="px-8 py-2 bg-white text-gray-800 hover:text-cyan-500 cursor-pointer capitalize">
                            <p>
                                {{ service.name }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useCompanyStore } from "@/stores/company";
import { ref } from "vue";
import { useProperCase } from "@/composables/useProperCase.js";
import { useRouter} from "vue-router";

const props = defineProps({
    selectingIndustry: {
        type: Boolean,
        default: false
    }
})

const company = useCompanyStore();
const router = useRouter();
const services = company.services;
const industries = company.industries;

const active = ref(false);

const emit = defineEmits(['launchIndustryServiceEditor', 'selectIndustryService']);

const toggle = () => active.value = !active.value;
const hide = () => active.value = false;

const select = async val => {
    await company.$patch({selectedIndustry: val.industry, selectedService: val.slug});
    hide();
    if (company.selectedService) {
        location.reload();
    }
}

const launchIndustryServiceEditor = () => {
    emit('launchIndustryServiceEditor');
}

const selectIndustryService = () => {
    emit('selectIndustryService');
}

</script>