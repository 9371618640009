<template>
    <label class="relative block"
        :class="[ inputDisabled ? 'grayscale-[50%] opacity-50' : '' ]"
    >
        <span v-if="label" class="block mb-1 text-sm font-medium">{{ label }}</span>
        <slot v-else class="mb-1"></slot>
        <template class="flex relative items-center">
            <slot name="drop-down" /> <!-- Slot for visually integrating a drop-down with the input field -->
            <span v-if="prefixText"
                class="absolute left-0 inset-y-0 bg-gray-100 flex items-center justify-center px-2 border-t border-b border-l rounded-l text-sm"
            >{{ prefixText }}</span>
            <input ref="inputElement"
                   :value="modelValue"
                   :type="inputType"
                   @input="updateValue($event.target.value)"
                   @change="handleChange($event.target.value)"
                   @focus="($event) => $event.target.select()"
                   :name="name"
                   :placeholder="placeholder"
                   :minlength="minLength"
                   :maxlength="maxLength || ''"
                   :required="isRequired()"
                   :pattern="inputPattern"
                   :disabled="inputDisabled"
                   :autocomplete="newPassword ? 'new-password' : ''"
                   class="w-full block placeholder:font-light font-normal placeholder:text-slate-400 focus:outline-none focus:border-cyan-500 bg-white border border-slate-300 rounded-md py-2 px-4 h-11"
                   :class="[
                       classes,
                       inputDisabled ? 'bg-slate-100 text-slate-400' : 'bg-none',
                       prefixText ? 'pl-8' : '',
                       suffixText ? 'pr-8' : '',
                   ]"
            />
            <span v-if="suffixText"
                  class="absolute right-0 inset-y-0 bg-gray-100 flex items-center justify-center px-2 border-t border-b border-r rounded-r text-sm"
            >{{ suffixText }}</span>
        </template>
        <p v-if="validationMessage" class="text-xs text-red-900 absolute w-full py-1 px-2">
            {{ validationMessage }}
        </p>
    </label>
</template>

<script setup>

    import { ref } from "vue";

    const inputElement = ref(null);
    const isValid = ref(true);
    const validationMessage = ref('');

    const props = defineProps({
        name: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '',
        },
        classes: {
            type: String,
            default: '',
        },
        modelValue: {
            type: [String, Number],
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        inputType: {
            type: String,
            validator: (val) => [ 'text', 'number', 'email', 'password' ].includes(val),
            default: 'text',
        },
        requiredField: {
            type: [ Boolean, null ],
            default: null,
        },
        minLength: {
            type: Number,
            default: 0,
        },
        maxLength: {
            type: Number,
            default: null
        },
        inputPattern: {
            type: [ String, null ],
            default: null,
        },
        inputDisabled: {
            type: Boolean,
            default: false,
        },
        prefixText: {
            type: String,
            default: null,
        },
        suffixText: {
            type: String,
            default: null,
        },
        inputKey: {
            type: String,
            default: '',
        },
        newPassword: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits([
        'update:modelValue',
        'change:modelValue',
    ]);

    const updateValue = (value) => {
        value = props.inputType === 'number'
            ? parseFloat(value) ?? 0
            : value;
        emit('update:modelValue', value, props.inputKey);
    }

    const handleChange = (value) => {
        validationMessage.value = '';
        if (value !== undefined) {
            updateValidity(value);
            emit('change:modelValue');
        }
    }

    /**
     * Uses native HTML validation on the input element, but custom validation could also be added here
     */
    const updateValidity = () => {
        isValid.value = inputElement.value?.validity.valid;
        validationMessage.value = inputElement.value?.validationMessage || '';
    }

    /**
     * Fields with labels appended with a * are auto-required
     * @returns {boolean}
     */
    const isRequired = () => props.requiredField || /\*$/.test(props.label);

</script>

<style scoped>

</style>
