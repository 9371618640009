<template>
    <Module class="mt-4 mx-4 pb-4 relative min-h-[24rem]">
        <template v-slot:title>
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <svg class="w-6 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <h2 class="text-lg font-medium">Leads</h2>
                </div>
                <div class="flex items-center">
                    <div class="flex items-center cursor-pointer" @click="showTermsAndConditions">
                        <svg class="w-5 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19 8H5C3.34 8 2 9.34 2 11V17H6V21H18V17H22V11C22 9.34 20.66 8 19 8ZM16 19H8V14H16V19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM18 3H6V7H18V3Z"
                                fill="#14B1E7"/>
                        </svg>
                        <p class="text-right mr-5 pb-0 text-cyan-500 hidden md:block">View Terms and Conditions</p>
                    </div>
                    <div v-if="downloading">
                        <loading-spinner></loading-spinner>
                    </div>
                    <div class="flex items-center cursor-pointer" @click="downloadLeadsAsCsv">
                        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1666 15.8333L19.1666 17.5L3.83329 17.5L3.83329 15.8333L19.1666 15.8333ZM12.4583 10.8333L15.3333 10.8333L11.5 14.1667L7.66663 10.8333L10.5416 10.8333L10.5416 2.5L12.4583 2.5L12.4583 10.8333Z" fill="#20A5DC"/>
                        </svg>
                        <p class="text-right text-cyan-500 pb-0 block">Download</p>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:default>
            <div class="py-5 px-3 md:px-6 gap-y-3 flex flex-col justify-center relative min-h-[12rem]">
                <LoadingSpinner v-if="loading || saving || !storesInitialized" :small="true" />
                <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3 mb-6 items-end">
                    <div>
                        <p class="block mb-1 text-sm font-medium">Date Range</p>
                        <DatePicker
                            v-model="leadStore.searchOptions.date_range"
                            range
                            input-class-name="h-11 rounded-md"
                            model-type="timestamp"
                            :enable-time-picker="false"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="leadStore.searchOptions.contact_name"
                            label="Name or Email"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="leadStore.searchOptions.lead_id"
                            label="Lead ID"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="leadStore.searchOptions.address"
                            label="State or Zipcode"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="leadStore.searchOptions.invoice_id"
                            label="Invoice No."
                        />
                    </div>
                    <div v-if="companyStore.missedLeadsEnabled">
                        <CustomSelect
                            v-model="leadStore.searchOptions.missed_only"
                            :options="onlyMissedLeadOptions"
                            label="Only Missed leads?"
                        />
                    </div>
                    <div>
                        <CustomSelect
                            v-model="leadStore.searchOptions.campaign_id"
                            :options="campaignOptions"
                            label="Campaign"
                        />
                    </div>
                    <div>
                        <CustomSelect
                          v-model="leadStore.searchOptions.status"
                          label="Status"
                          :options="leadStatusOptions"
                        />
                    </div>
                    <div v-if="leadStore.leadConfigData.show_over_budget">
                        <CustomSelect
                          v-model="leadStore.searchOptions.over_budget"
                          label="Qualified Over Budget"
                          :options="overBudgetOptions"
                        />
                    </div>
                    <div class="flex gap-2">
                        <SolidButton @click="submitLeadsSearch" classes="w-full h-11 font-semibold">
                            Search
                        </SolidButton>
                        <OutlineButton @click="clearSearch" color="gray" classes="w-full h-11 font-semibold">
                            Reset
                        </OutlineButton>
                    </div>
                </div>
                <div v-if="errorMessage">
                    <p class="text-red-900 text-sm text-center">{{ errorMessage }}</p>
                </div>
                <div class="mt-8 min-h-[4rem]">
                    <div v-if="!(leadStore.leads.length > 0)" class="mt-8 text-center">
                        <p>No Leads were found.</p>
                    </div>
                    <div v-else>
                        <Pagination
                            :limit="leadStore.paginationLimit"
                            :total="leadStore.paginationTotal"
                            :offset="leadStore.paginationOffset"
                            @change-page="handlePageChange"
                        >
                            <div class="hidden md:sticky md:top-20 md:grid  text-xs text-gray-700 uppercase bg-cyan-50 text-center font-bold py-3 items-center border-b border-gray-200" :class="[leadStore.leadConfigData.show_over_budget ? 'md:grid-cols-8' : 'md:grid-cols-7']">
                                <div>ID</div>
                                <div>Status</div>
                                <div>Date Sent / Source</div>
                                <div>Contact</div>
                                <div>Address</div>
                                <div>Cost</div>
                                <div v-if="leadStore.leadConfigData.show_over_budget">Qualified Over Budget</div>
                                <div>Reject</div>
                            </div>
                            <div v-for="lead in leadStore.leads"
                                 :key="lead.id"
                                 class="border-b odd:bg-gray-50 text-gray-600 text-sm items-start md:items-center grid grid-cols-2 gap-4 md:gap-0 px-4 py-6 md:py-4 md:px-0" :class="[leadStore.leadConfigData.show_over_budget ? 'md:grid-cols-8' : 'md:grid-cols-7']">
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">ID</p>
                                    <p @click="showLeadDetail(lead.id)" class="text-cyan-500 cursor-pointer md:text-center">
                                        {{ lead.id }}
                                    </p>
                                </div>
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">Status</p>
                                    <p class="md:text-center">{{ useProperCase(lead.status) }}</p>
                                </div>
                                <div class="flex flex-col gap-1 md:text-center">
                                    <p class="font-bold text-sm md:hidden text-gray-900">Date Sent / Source</p>
                                    <p>{{ useFormatTimestamp(lead.date_sent) }}</p>
                                    <p>{{lead.source}}</p>
                                    <Badge v-if="lead.single_product_sale && companyStore.missedLeadsEnabled" text="Missed Lead" size="xs" />
                                </div>
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">Contact</p>
                                    <p class="md:text-center truncate" :title="lead.name">{{ `${lead.name}` }}</p>
                                    <p class="md:text-center truncate" :title="lead.email">{{ lead.email }}</p>
                                    <p class="md:text-center truncate" :title="lead.phone">{{ lead.phone }}</p>
                                </div>
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">Address</p>
                                    <p class="md:text-center">{{ lead.address }}</p>
                                </div>
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">Cost</p>
                                    <p class="md:text-center">${{ lead.cost }}</p>
                                </div>
                                <div class="col-span-2 md:col-span-1" v-if="leadStore.leadConfigData.show_over_budget">
                                    <p class="font-bold text-sm md:hidden text-gray-900">Qualified Over Budget</p>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         class="flex flex-row h-6 w-full justify-center text-cyan-500" fill="none" viewBox="0 0 12 24" stroke="currentColor"
                                         :class="[lead.exclude_budget ? 'text-cyan-600' : 'text-transparent']"
                                         :stroke-width="[lead.exclude_budget ?  '3' : '1.5']">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <div class="col-span-2 md:col-span-1 md:flex md:justify-center">
                                    <div v-if="canRejectLead(lead)" class="flex flex-col items-center">
                                        <p class="text-sm md:justify-center text-red-500 cursor-pointer"
                                           @click="showLeadRejection(lead.id)">
                                            Reject
                                        </p>
                                        <p class="text-gray-700 text-sm pb-0">Time remaining:</p>
                                        <p class="text-gray-900 text-sm font-bold text-center">{{useRelativeTimeRemaining(lead.rejection_timer/1000)}}</p>
                                    </div>
                                    <div v-else-if="lead.rejected" class="flex flex-col items-center">
                                        <p class="font-bold">Lead was rejected</p>
                                        <p class="text-green-500 cursor-pointer p-2 inline-block" @click="attemptLeadUnrejection(lead)">
                                            Unreject
                                        </p>
                                    </div>
                                    <div v-else-if="lead.single_product_sale" class="flex flex-col items-center">
                                        <p class="text-green-600 p-2 inline-block text-center">
                                            This lead cannot be rejected
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <template v-slot:summary>
                                <p class="text-gray-800 text-sm" v-if="leadStore.leads.length > 0 && pageCost > 0 && leadStore.totalSpend > 0">
                                    Total: <span class="text-green-500">
                                    ${{ pageCost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                    (${{ leadStore.totalSpend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} total)
                                </span>
                                </p>
                            </template>
                        </Pagination>
                    </div>
                </div>
            </div>
            <!--    Lead Details modal  -->
            <LeadDetailsModal
                v-if="showLeadDetailsModal"
                :lead="leadStore.getLeadById(leadDetailId)"
                @hideLeadDetailsModal="hideLeadDetailsModal"
                :small="true"
            />
            <!--    Lead Rejection modal    -->
            <LeadRejectionModal
                v-if="showLeadRejectionModal"
                :lead="leadStore.getLeadById(leadRejectionId)"
                @hideLeadRejectionModal="hideLeadRejectionModal"
                :small="true"
            />
            <Modal
                v-if="showContractModal"
                @clicked:confirm="hideContractModal"
                confirm-label="Ok"
                :show-cancel-button="false"
            >
                <template v-slot:header>
                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                        </svg>
                        <p class="ml-4 text-lg">Terms & Conditions</p>
                    </div>
                </template>
                <template v-slot:body>
                    <div class="overflow-auto h-full p-4 md:px-5 md:py-3 border-slate-300 border bg-slate-50">
                        <p v-html="companyContract" />
                    </div>
                </template>
            </Modal>
        </template>
    </Module>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import CustomInput from '@/components/inputs/CustomInput.vue';
import SolidButton from "@/components/inputs/SolidButton.vue";
import Module from "@/components/Module.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { useCampaignStore } from "@/stores/campaigns.js";
import { useLeadsStore } from "@/stores/leads.js";
import { useFormatTimestamp } from "@/composables/useFormatTimestamp.js";
import { useProperCase } from "@/composables/useProperCase.js";
import { useRelativeTimeRemaining } from "@/composables/useRelativeTimeRemaining.js";
import LeadDetailsModal from "@/components/leads/Modals/LeadDetailsModal.vue";
import LeadRejectionModal from "@/components/leads/Modals/LeadRejectionModal.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import Modal from "@/components/Modal.vue";
import { useCompanyStore } from "@/stores/company.js";
import { useErrorStore } from "@/stores/errors.js";
import { useCreateCsvString } from "@/composables/useCreateCsvString.js";
import { useDownloadFile } from "@/composables/useDownloadFile.js";
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Alert from "@/components/Alert.vue";
import Badge from "@/components/Badge.vue";
import Products from "@/Utilities/Products";

const props = defineProps({
    storesInitialized: {
        type: Boolean,
        default: false,
    },
});

onMounted(async () => {
    await Promise.all([
        campaignStore.setProduct(Products.LEAD).then(() => campaignStore.initialize()),
        leadStore.initialize()
    ]);
    updateCampaignOptions();
    updateLeadStatusOptions();
    updateOverBudgetOptions();
});

const campaignStore = useCampaignStore();
const leadStore = useLeadsStore();
const companyStore = useCompanyStore();
const errors = useErrorStore();

const loading = ref(false);
const saving = ref(false);
const downloading = ref(false);
const errorMessage = ref(null);

const showLeadDetailsModal = ref(false);
const showLeadRejectionModal = ref(false);
const showContractModal = ref(false);
const leadDetailId = ref(null);
const leadRejectionId = ref(null);

const campaignOptions = ref([]);
const leadStatusOptions = ref([]);
const overBudgetOptions = ref([]);

const pageCost = computed(() => leadStore.leads.filter(lead => lead.status === 'purchased').map(lead => lead.cost).reduce((sum, cost) => sum + cost, 0));

const onlyMissedLeadOptions = ref([
    {
        label: 'All', value: ''
    },
    {
        label: 'Only missed leads', value: 1,
    },
    {
        label: 'Only non-missed leads', value: 0
    }
]);


const companyContract = ref("");

const updateCampaignOptions = () => {
    campaignOptions.value = [{ value: '', label: '-' }];
    campaignOptions.value.push(...campaignStore.campaigns.leads.map(campaign => ({ value: campaign.id, label: campaign.name })));
}

const updateLeadStatusOptions = () => {
    leadStatusOptions.value = leadStore.leadConfigData.lead_statuses.map(status => ({ value: status.id, label: status.name }));
}

const updateOverBudgetOptions = () => {
    overBudgetOptions.value = leadStore.leadConfigData.over_budget_options.map(option => ({ value: option.id, label: option.name }));
}

const handlePageChange = (payload) => {
    submitLeadsSearch(payload.page);
}

const submitLeadsSearch = async (page = 1) => {
    loading.value = true;
    errorMessage.value = null;
    const { status, message } = await leadStore.search(page);
    if (!status) {
        errors.report(message);
        errorMessage.value = message;
    }
    loading.value = false;
}

const clearSearch = () => {
    leadStore.clearSearchOptions();
    submitLeadsSearch();
}

const canRejectLead = (lead) => {
    return lead.rejection_timer > 0 && !lead.rejected && !lead.single_product_sale;
};

const showTermsAndConditions = async () => {
    loading.value = true;
    const { status, contract, message } = await companyStore.getCompanyContractForUser();
    if (status) {
        companyContract.value = contract;
        showContractModal.value = true;
    }
    else {
        errors.report(message);
        errorMessage.value = message;
    }
    loading.value = false;
}

const hideContractModal = () => {
    showContractModal.value = false;
}

const attemptLeadUnrejection = async (lead) => {
    if (lead.legacy_id) {
        saving.value = true;
        const { status, message } = await leadStore.unrejectLead(lead.legacy_id);
        if (!status) {
            errors.report(message);
            errorMessage.value = message;
        }
        saving.value = false;
    }
}

const downloadLeadsAsCsv = async () => {
    downloading.value = true;
    await leadStore.download();
    downloading.value = false;
}

const showLeadDetail = (leadId) => {
    if (leadId > 0) {
        leadDetailId.value = leadId;
        showLeadDetailsModal.value = true;
    }
}

const hideLeadDetailsModal = () => {
    leadDetailId.value = null;
    showLeadDetailsModal.value = false;
}

const showLeadRejection = (leadId) => {
    if (leadId > 0) {
        leadRejectionId.value = leadId;
        showLeadRejectionModal.value = true;
    }
}

const hideLeadRejectionModal = () => {
    leadRejectionId.value = null;
    showLeadRejectionModal.value = false;
}

</script>