/**
 * Create an anchor tag to start a download
 * @param encodedDownloadString
 * @param filename
 */
export const useDownloadFile = (encodedDownloadString, filename = 'file.txt') => {
    const link = document.createElement('a');
    link.href = encodedDownloadString;
    link.download = filename;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}