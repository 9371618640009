<template>
    <div v-if="wizardStore.headerComponent">
        <component
            :is="wizardStore.headerComponent"
            :initial-header-data="wizardStore.getInitialHeaderData()"
            @update:header-input="handleInputUpdate"
        />
    </div>
</template>

<script setup lang="ts">
import { useWizardStore } from "@/stores/v4/wizard";

const wizardStore = useWizardStore();

const handleInputUpdate = (newValue: any, inputKey: string) => {
    wizardStore.updateWizardHeader(newValue, inputKey);
}
</script>