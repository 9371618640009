<template>
    <div>
        <div v-if="campaignStore.zipCodeCampaign">
            <!--    HEADER BUTTONS    -->
            <div class="flex items-center gap-x-6 pb-6">
                <OutlineButton
                    @click="toggleZipCodeUploadModal(true)"
                >
                    Upload File
                </OutlineButton>
                <OutlineButton
                    @click="toggleZipCodeRadiusModal(true)"
                >
                    Add Zip Codes By Radius
                </OutlineButton>
            </div>
        </div>

        <!--    MAIN LOCATION NAVIGATION    -->
        <LoadingSpinner v-if="loading" :small="true" />
        <LocationCheckboxSelect
            v-if="!loading && slideInputs.zip_codes"
            :stores-initialized="storesInitialized"
            :model-value="slideInputs.zip_codes"
            :imported-zip-codes="importedCodes"
            input-key="zip_codes"
            @update:model-value="handleInputUpdate"
        />

            <!--    Zip Radius modal    -->
            <ZipCodeRadiusModal
                v-if="showZipCodeRadiusModal"
                @hideZipRadiusModal="toggleZipCodeRadiusModal"
                @addZipCodesFromModal="addZipCodesFromModal"
                :saving="saving"
            />
            <!--    Zip Upload modal    -->
            <ZipCodeUploadModal
                v-if="showZipCodeUploadModal"
                @hideZipUploadModal="toggleZipCodeUploadModal(false)"
                @addZipCodesFromModal="addZipCodesFromModal"
                :saving="saving"
            />
            <!--   Zip error modal   -->
            <Modal
                v-if="zipCodeErrorMessage"
                :small="true"
                :show-cancel-button="false"
                confirm-label="Ok"
                @clicked:confirm="() => zipCodeErrorMessage = null"
            >
                <template v-slot:header>
                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                        </svg>
                        <h5 class="text-md ml-2">Zip Code alert</h5>
                    </div>
                </template>
                <template v-slot:body>
                    <div class="px-5 p-3">
                        {{ zipCodeErrorMessage }}
                    </div>
                </template>
            </Modal>
    </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, Ref, toRaw } from "vue";
import { SlideInputs } from "@/stores/v4/wizard";
import { useAlertStore } from "@/stores/v4/alerts";
import LocationCheckboxSelect, { ZipCodeLocation } from "@/components/v4/wizard/slides/components/LocationCheckboxSelect.vue";
import { useLocalityDataStore } from "@/stores/locality-data";
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import ZipCodeRadiusModal from "@/components/campaigns/EditorSlides/Modals/ZipCodeRadiusModal.vue";
import ZipCodeUploadModal from "@/components/campaigns/EditorSlides/Modals/ZipCodeUploadModal.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useFutureCampaignStore } from "@/stores/v4/future-campaigns";
import Modal from "@/components/Modal.vue";

interface Props {
    initialSlideData: SlideInputs,
}
const props = defineProps<Props>();

const emit = defineEmits(['update:slideInput']);

const alertStore = useAlertStore();
const localityStore = useLocalityDataStore();
const campaignStore = useFutureCampaignStore();

const storesInitialized: Ref<boolean> = ref(false);

const slideInputs = reactive({
    zip_codes: {},
});

const importedCodes: Ref<{ append: boolean, zipCodes: ZipCodeLocation[] }> = ref({ append: true, zipCodes: [] });

const loading: Ref<boolean> = ref(true);
const saving: Ref<boolean> = ref(false);

const showZipCodeRadiusModal: Ref<boolean> = ref(false);
const showZipCodeUploadModal: Ref<boolean> = ref(false);
const zipCodeErrorMessage: Ref<string|null> = ref(null);

const initialize = async () => {
    Object.assign(slideInputs, props.initialSlideData);

    await localityStore.initialize();
    if (!localityStore.storeInitialized) {
        alertStore.showError('Failed to fetch location data.');
    }
    storesInitialized.value = true;
    loading.value = false;
}
onMounted(() => { initialize() });

const toggleZipCodeUploadModal = (show?: boolean) => {
    if (show === undefined)
        showZipCodeUploadModal.value = !showZipCodeUploadModal.value;
    else
        showZipCodeUploadModal.value = show;
}

const toggleZipCodeRadiusModal = (show?: boolean) => {
    if (show === undefined)
        showZipCodeRadiusModal.value = !showZipCodeRadiusModal.value;
    else
        showZipCodeRadiusModal.value = show;
}

const addZipCodesFromModal = async (payload: ZipCodeLocation[], appendZipCodes?: boolean) => {
    if (saving.value) return;
    if (!campaignStore.zipCodeCampaign) {
        zipCodeErrorMessage.value = "Zip codes can only be added to zip-targeted campaigns. You monster."
    }
    saving.value = true;

    const zipCodeIds = payload.map(zipCode => zipCode.id);
    const { status, data, message } = await localityStore.validateImportedZipCodes(zipCodeIds) as DataResponse;
    if (!status || !data) {
        zipCodeErrorMessage.value = message ?? "An error occurred adding zip codes.";
    }
    else {
        const { valid_zip_codes, invalid_zip_codes} = data;
        const validLocations = payload.filter(location => valid_zip_codes.includes(location.id));
        const validLocationCount = validLocations.length;
        const invalidLocationCount = invalid_zip_codes.length;

        if (validLocationCount) {
            importedCodes.value = {
                append: !!appendZipCodes,
                zipCodes: validLocations,
            };

            if (invalidLocationCount) {
                zipCodeErrorMessage.value = `${validLocationCount} zip code(s) successfully added. ${invalidLocationCount} zip code(s) ignored (invalid or outside the approved counties for this campaign).`;
            }
        }
        else {
            zipCodeErrorMessage.value = "The supplied zip codes were invalid, or not in an approved county for this campaign.";
        }

        saving.value = false;
    }

    toggleZipCodeUploadModal(false);
    toggleZipCodeRadiusModal(false);
}

const handleInputUpdate = (newValue: any, inputKey: string) => {
    campaignStore.updateActiveZipCodes(toRaw({ ...newValue }));
    emit('update:slideInput', newValue, inputKey);
}

</script>