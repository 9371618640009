<template>
    <Module class="mt-4 mx-4 relative">
        <template v-slot:title>
            <div class="flex items-center justify-between py-2">
                <div class="flex items-center">
                    <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                    </svg>
                    <h2 class="text-lg">
                        Bidding
                    </h2>
                </div>
            </div>
        </template>
        <template v-slot:default>
            <div>
                <div class="flex items-center justify-between mb-6 pb-6 border-gray-300 border-b">
                    <div class="flex items-center">
                        <h4 class="font-semibold py-4 mr-4">Select a Campaign: </h4>
                        <CustomSelect
                            :options="campaignSelectOptions"
                            v-model="selectedCampaign"
                            label=""
                        />
                    </div>
                    <div class="flex items-center gap-x-3"
                        v-if="selectedCampaign"
                    >
                        <SolidButton @click="cancelBidding"
                             label="Cancel"
                             color="gray"
                        />
                        <SolidButton @click="saveBidding"
                             color="orange"
                             label="Save"
                        />
                    </div>
                </div>
                <div class="relative min-h-[48rem]">
                    <LoadingSpinner v-if="loading || saving" :small="true" />
                    <BiddingTable
                        v-if="!loading && selectedCampaign"
                        :initial-data="biddingPayload"
                        @update:bidding-input="(newValue) => handlePayloadUpdate(newValue)"
                        :class="[saving && 'grayscale-[60%] opacity-50 pointer-events-none']"
                    />
                </div>
            </div>
        </template>
    </Module>
</template>

<script setup lang="ts">
import Module from "@/components/Module.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { useFutureCampaignStore } from "@/stores/v4/future-campaigns";
import { computed, ComputedRef, onMounted, ref, Ref, watch } from "vue";
import { useAlertStore } from "@/stores/v4/alerts";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import BiddingTable from "@/components/v4/campaigns/BiddingTable.vue";
import { LocationBidCollection, useBiddingStore } from "@/stores/v4/bidding";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { useRouter } from "vue-router";

const campaignStore = useFutureCampaignStore();
const alertStore = useAlertStore();
const biddingStore = useBiddingStore();
const router = useRouter();

const biddingPayload: Ref<LocationBidCollection> = ref({});

const campaignSelectOptions: ComputedRef<CustomSelectOption[]> = computed(() => {
    return [
        { label: '-', value: '' },
        ...campaignStore.scopedCampaignList.map(campaign => ({ label: campaign.name, value: campaign.reference })),
    ];
});
const selectedCampaign: Ref<string> = ref(campaignStore.editingCampaign?.reference ?? '');

const loading: Ref<boolean> = ref(false);
const saving: Ref<boolean> = ref(false);

const initialize = async () => {
    loading.value = true;
    const { status, message } = await campaignStore.initialize();
    if (!status) {
        alertStore.showError(message ?? "An error occurred fetching the Campaign list");
    }
    else {
        if (!selectedCampaign.value && campaignSelectOptions.value[1]?.value) {
            selectedCampaign.value = campaignSelectOptions.value[1].value;
            await loadCampaign(selectedCampaign.value);
        }
    }
    loading.value = false;
}
onMounted(() => initialize());

const loadCampaign = async (campaignReference: string) => {
    loading.value = true;
    const { status, message } = await campaignStore.getCampaignDetailByUuid(campaignReference);
    if (!status) {
        alertStore.showError(message ?? "An error occurred fetching the requested Campaign");
    }
    else {
        biddingStore.$reset();
        biddingPayload.value = campaignStore.editingCampaign.bidding ?? biddingPayload.value;
    }
    loading.value = false;
}

const handlePayloadUpdate = (newValue: LocationBidCollection) => {
    campaignStore.updateModulePayload('bidding', {
        location_bids: newValue
    });
}

const saveBidding = async () => {
    saving.value = true;
    const { status, message } = await campaignStore.saveCampaignModule('bidding');
    if (!status) {
        alertStore.showError(message ?? "There was a problem saving new Bids to the Campaign");
    }
    else {
        alertStore.showInfo(`Bids have been updated for your "${campaignStore.editingCampaign.name}" Campaign`);
        cancelBidding();
    }

    saving.value = false;
}

const cancelBidding = () => {
    //TODO: confirmation modal
    switch(campaignStore.productScope) {
        case 'lead':
            router.push('/leads-campaigns');
            break;
        case 'appointment':
            router.push('/appointments-campaigns');
            break;
        case 'direct leads':
            router.push('/direct-leads');
            break;
    }
}

watch(() => selectedCampaign.value, (newValue: any) => {
    if (newValue) loadCampaign(newValue);
});

</script>