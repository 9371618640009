<template>
    <Modal
        @clicked:confirm="saveLicense"
        @clicked:cancel="closeModal"
    >
        <template v-slot:header>
            <div class="flex items-center">
                <svg  class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                </svg>
                <h5 class="text-md ml-2">{{ newLicense ? 'Add' : 'Edit'}} License</h5>
            </div>
        </template>
        <template v-slot:body>
            <LoadingSpinner v-if="loading || saving || !storesInitialized" />
            <div v-if="!loading && storesInitialized" class="grid md:grid-cols-2 gap-x-6 gap-y-6 grid-flow-row-dense pb-12"
                :class="[saving ? 'pointer-events-none grayscale-[50%] opacity-50' : '']"
            >
                <CustomInput
                    label="Name"
                    v-model="licenseStore.editingLicense.name"
                />
                <CustomInput
                    label="Classification/Type"
                    v-model="licenseStore.editingLicense.type"
                />
                <div>
                    <p class="block mb-1 text-sm font-medium">Date Issued</p>
                    <DatePicker
                        input-class-name="h-11 rounded-md"
                        v-model="licenseStore.editingLicense.issued_at"
                        model-type="timestamp"
                        :enable-time-picker="false"
                    />
                </div>
                <div>
                    <p class="block mb-1 text-sm font-medium">Expiry</p>
                    <DatePicker
                        input-class-name="h-11 rounded-md"
                        v-model="licenseStore.editingLicense.expires_at"
                        model-type="timestamp"
                        :enable-time-picker="false"
                    />
                </div>
                <CustomInput
                    label="License Number"
                    v-model="licenseStore.editingLicense.license_number"
                />
                <CustomInput
                    label="Link"
                    v-model="licenseStore.editingLicense.url"
                />
                <CustomSelect
                    label="Country"
                    :options="countryOptions"
                    v-model="licenseStore.editingLicense.country"
                    selected-option="US"
                    :dropdown-disabled="true"
                />
                <CustomSelect
                    label="State"
                    :options="stateOptions"
                    v-model="licenseStore.editingLicense.state"
                />
                <CustomSelect
                    label="Industry"
                    :options="industryOptions"
                    v-model="licenseStore.editingLicense.industry"
                />
                <div v-if="errorMessage" class="py-4 md:col-span-2">
                    <p class="text-red-900 text-center whitespace-pre">{{ errorMessage }}</p>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import Modal from "@/components/Modal.vue";
import { useCompanyLicenseStore } from "@/stores/company-licenses.js";
import { useLocalityDataStore } from "@/stores/locality-data.js";
import CustomInput from "@/components/inputs/CustomInput.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { useCompanyStore } from "@/stores/company.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const licenseStore = useCompanyLicenseStore();
const localityDataStore = useLocalityDataStore();
const companyStore = useCompanyStore();

const newLicense = computed(() => !licenseStore.editingLicense.id);

const storesInitialized = ref(false);

const countryOptions = ref([ { label: 'US', value: 'US' }]);
const stateOptions = ref([]);
const industryOptions = ref([]);

onMounted(async () => {
    await Promise.all([
        licenseStore.initialize(),
        localityDataStore.initialize()
    ]);
    updateStateOptions();
    updateIndustryOptions();
    storesInitialized.value = true;
});

const loading = ref(false);
const saving = ref(false);
const errorMessage = ref(null);

const emit = defineEmits(['hideLicenseModal']);

const updateStateOptions = () => {
    stateOptions.value = [
        { label: '-', value: '' },
        ...localityDataStore.states.map(state => ({ label: state.state_name, value: state.state_key }))
    ];
}

const updateIndustryOptions = () => {
    industryOptions.value = [
        { label: '-', value: '' },
        ...companyStore.industries.map(industry => ({ label: industry, value: industry })),
    ];
}

const saveLicense = async () => {
    if (saving.value) return;
    saving.value = true;
    errorMessage.value = null;
    const { status, message } = await licenseStore.save();
    if (!status) {
        errorMessage.value = message;
    }
    else {
        closeModal();
    }
    saving.value = false;
}

const closeModal = () => {
    licenseStore.clearEditing();
    emit('hideLicenseModal');
}

</script>