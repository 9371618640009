<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>

                <h5 class="text-md">Lead Types Purchased</h5>
            </div>
        </template>

        <div class="relative min-h-[12rem]">
            <LoadingSpinner v-if="loading" :small="true" />
            <div v-else-if="error !== null"><p class="text-center text-red-500">{{ error }}</p></div>
            <div v-else>
                <p class="pb-3">
                    Change the mix of lead types you purchase by increasing your bids on the types you prefer and reducing
                    your bids for your least preferred lead types (provided you are above the minimum bid).
                </p>

                <div class="grid grid-cols-2 md:grid-cols-6 p-2 md:p-0">
                    <div class="border-r border-b pb-4 md:border-none md:p-0 text-center">
                        <div class="md:border-r py-4 my-4">
                            <div>
                                <Tooltip :title-classes="['justify-center']">
                                    <template v-slot:title>
                                        <p class="mr-1 uppercase text-sm font-medium text-gray-400">Exclusive</p>
                                    </template>
                                    Exclusive phone verified lead sold only to you
                                </Tooltip>
                            </div>
                            <p class="font-light text-2xl font-display" v-if="exclusive.enabled">{{ exclusive.percent }}%</p>
                            <p class="font-light text-2xl font-display uppercase" v-if="!exclusive.enabled">-</p>
                        </div>
                        <div class="my-4 px-2">
                            <RouterLink to="/leads-bidding" class="" v-if="exclusive.enabled">
                                <OutlineButton class="w-full" :is-button="false">Adjust Bids</OutlineButton>
                            </RouterLink>

                            <SolidButton class="w-full" @click="router.push({name: 'campaigns'})" v-if="!exclusive.enabled">Turn On</SolidButton>
                        </div>
                    </div>

                    <div class="border-r border-b pb-4 md:border-none md:p-0 text-center">
                        <div class="md:border-r py-4 my-4">
                            <div>
                                <Tooltip :title-classes="['justify-center']">
                                    <template v-slot:title>
                                        <p class="mr-1 uppercase text-sm font-medium text-gray-400">Duo</p>
                                    </template>
                                    Phone verified lead sold to you and only one other company
                                </Tooltip>
                            </div>
                            <p class="font-light text-2xl font-display" v-if="duo.enabled">{{ duo.percent }}%</p>
                            <p class="font-light text-2xl font-display uppercase" v-if="!duo.enabled">-</p>
                        </div>
                        <div class="my-4 px-2">
                            <RouterLink to="/leads-bidding" class="" v-if="duo.enabled">
                                <OutlineButton class="w-full" :is-button="false">Adjust Bids</OutlineButton>
                            </RouterLink>

                            <SolidButton class="w-full" @click="router.push({name: 'campaigns'})" v-if="!duo.enabled">Turn On</SolidButton>
                        </div>
                    </div>

                    <div class="border-r border-b pb-4 md:border-none md:p-0 text-center">
                        <div class="md:border-r py-4 my-4">
                            <div>
                                <Tooltip :title-classes="['justify-center']">
                                    <template v-slot:title>
                                        <p class="mr-1 uppercase text-sm font-medium text-gray-400">Trio</p>
                                    </template>
                                    Phone verified lead sold to you and two other companies
                                </Tooltip>
                            </div>
                            <p class="font-light text-2xl font-display" v-if="trio.enabled">{{ trio.percent }}%</p>
                            <p class="font-light text-2xl font-display uppercase" v-if="!trio.enabled">-</p>
                        </div>
                        <div class="my-4 px-2">
                            <RouterLink to="/leads-bidding" class="" v-if="trio.enabled">
                                <OutlineButton class="w-full" :is-button="false">Adjust Bids</OutlineButton>
                            </RouterLink>

                            <SolidButton class="w-full" @click="router.push({name: 'campaigns'})" v-if="!trio.enabled">Turn On</SolidButton>
                        </div>
                    </div>

                    <div class="border-r border-b pb-4 md:border-none md:p-0 text-center">
                        <div class="md:border-r py-4 my-4">
                            <div>
                                <Tooltip :title-classes="['justify-center']">
                                    <template v-slot:title>
                                        <p class="mr-1 uppercase text-sm font-medium text-gray-400">Quad</p>
                                    </template>
                                    Phone verified lead sold to you and three other companies
                                </Tooltip>
                            </div>
                            <p class="font-light text-2xl font-display" v-if="quad.enabled">{{ quad.percent }}%</p>
                            <p class="font-light text-2xl font-display uppercase" v-if="!quad.enabled">-</p>
                        </div>
                        <div class="my-4 px-2">
                            <RouterLink to="/leads-bidding" class="" v-if="quad.enabled">
                                <OutlineButton class="w-full" :is-button="false">Adjust Bids</OutlineButton>
                            </RouterLink>

                            <SolidButton class="w-full" @click="router.push({name: 'campaigns'})" v-if="!quad.enabled">Turn On</SolidButton>
                        </div>
                    </div>

                    <div class="border-r pb-4 md:border-none md:p-0 text-center">
                        <div class="md:border-r py-4 my-4">
                            <div>
                                <Tooltip :title-classes="['justify-center']" :align-left="false">
                                    <template v-slot:title>
                                        <p class="mr-1 uppercase text-sm font-medium text-gray-400">Unverified</p>
                                    </template>
                                    Same lead with phone number but our QA team has been unable to get a response after two
                                    attempts. Sold at lower cost.
                                </Tooltip>
                            </div>
                            <p class="font-light text-2xl font-display" v-if="unverified.enabled">{{ unverified.percent }}%</p>
                            <p class="font-light text-2xl font-display uppercase" v-if="!unverified.enabled">-</p>
                        </div>
                        <div class="my-4 px-2">
                            <RouterLink to="/leads-bidding" class="" v-if="unverified.enabled">
                                <OutlineButton class="w-full" :is-button="false">Adjust Bids</OutlineButton>
                            </RouterLink>

                            <SolidButton class="w-full" @click="router.push({name: 'leads-campaigns'})" v-if="!unverified.enabled">Turn On</SolidButton>
                        </div>
                    </div>

                    <div class="pb-4 md:p-0 text-center"
                        v-if="email.allowed"
                    >
                        <div class="py-4 my-4">
                            <div>
                                <Tooltip :title-classes="['justify-center']" :align-left="false">
                                    <template v-slot:title>
                                        <p class="mr-1 uppercase text-sm font-medium text-gray-400">Email Only</p>
                                    </template>
                                    The consumer has indicated a preference to deal via email only. Sold at very low cost.
                                </Tooltip>
                            </div>
                            <p class="font-light text-2xl font-display" v-if="email.enabled">{{ email.percent }}%</p>
                            <p class="font-light text-2xl font-display uppercase" v-if="!email.enabled">-</p>
                        </div>
                        <div class="my-4 px-2">
                            <RouterLink to="/leads-bidding" class="" v-if="email.enabled">
                                <OutlineButton class="w-full" :is-button="false">Adjust Bids</OutlineButton>
                            </RouterLink>

                            <SolidButton class="w-full" @click="router.push({name: 'leads-campaigns'})" v-if="!email.enabled">Turn On</SolidButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import { ref, watch } from "vue";
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import Tooltip from "@/components/Tooltip.vue";
import {useServicesStore} from "@/stores/services";
import SolidButton from "@/components/inputs/SolidButton.vue";
import {useRouter} from "vue-router";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useProductConfigurationStore } from "@/stores/v4/product-configuration";

const services = useServicesStore();
const router = useRouter();
const productConfiguration = useProductConfigurationStore();

const props = defineProps({
    global: {
        type: Boolean,
        default: false
    },
    services: {
        type: Array,
        default: null
    }
});

const loading = ref(true);
const error = ref(null);
const exclusive = ref({enabled: false, percent: 0});
const duo = ref({enabled: false, percent: 0});
const trio = ref({enabled: false, percent: 0});
const quad = ref({enabled: false, percent: 0});
const unverified = ref({enabled: false, percent: 0});
const email = ref({enabled: false, percent: 0, allowed: false});

const thirtyDaysAgo = (new Date()).getTime() / 1000 - (60 * 60 * 24) * 30;

const getLeadsPurchasedBreakdown = async () => {
    services.apiService.getLeadTypesPurchasedBreakdown(thirtyDaysAgo, (new Date()).getTime() / 1000, {
        global: props.global,
        services_filter: props.services,
    }).then(resp => {
        loading.value = false;

        if (resp.data.data.status) {
            exclusive.value = resp.data.data.exclusive;
            duo.value = resp.data.data.duo;
            trio.value = resp.data.data.trio;
            quad.value = resp.data.data.quad ?? quad.value;
            unverified.value = {
                percent: resp.data.data.unverified.percent,
                enabled: resp.data.data.unverified.enabled,
            };
            email.value = {
                percent: resp.data.data.email.percent,
                enabled: resp.data.data.email.enabled,
                allowed: productConfiguration.emailOnlyBudgetEnabled,
            };

        } else {
            error.value = "There was an issue loading your lead breakdown."
        }
    }).catch(e => error.value = "There was an issue loading your lead breakdown.");
}
getLeadsPurchasedBreakdown();

watch(() => props.services, () => {
    getLeadsPurchasedBreakdown(props.services);
});

</script>