<template>
    <Module class="mt-4 mx-4">
        <template v-slot:title>
            <div class="flex items-center justify-between py-2">
                <div class="flex items-center">
                    <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                    </svg>
                    <h2 class="text-lg font-medium">Campaigns</h2>
                </div>
                <div class="flex items-center cursor-pointer text-cyan-500" @click="launchCampaignEditor(null)">
                    <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <p class="text-right text-cyan-500 pb-0 block">New Campaign</p>
                </div>
            </div>
        </template>
        <template v-slot:default>
            <div class="gap-y-4 flex flex-col justify-center relative min-h-[12rem]">
                <div class="pb-4 flex flex-wrap gap-4 justify-between items-center"
                     :class="[ saving ? 'grayscale-[60%] opacity-50 pointer-events-none' : '' ]"
                >
                    <div class="w-64">
                        <CustomSelect
                          v-model="campaignFilter"
                          :options="campaignFilterOptions"
                        />
                    </div>

                    <div class="flex items-center justify-end cursor-pointer"
                        @click="downloadCampaignZip('all')"
                    >
                        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1666 15.8333L19.1666 17.5L3.83329 17.5L3.83329 15.8333L19.1666 15.8333ZM12.4583 10.8333L15.3333 10.8333L11.5 14.1667L7.66663 10.8333L10.5416 10.8333L10.5416 2.5L12.4583 2.5L12.4583 10.8333Z" fill="#20A5DC"/>
                        </svg>
                        <p class="text-right text-cyan-500 pb-0 block">Download All Zips</p>
                    </div>
                </div>
                <LoadingSpinner v-if="loading || !storeInitialized" :small="true" />
                <div v-else-if="!campaignStore.campaigns[productScope]?.length" class="py-4">
                    No Campaigns found.
                </div>
                <div v-else class="pb-12 pt-8 relative"
                     :class="[ saving ? 'grayscale-[60%] opacity-50 pointer-events-none' : '' ]"
                >
                    <div class="absolute left-0 top-0 inline-flex items-center gap-x-8 z-[60]">
                        <div v-if="activeSelection" class="flex items-center gap-3 text-sm">
                            <p class="text-gray-600 text-sm">Selection: </p>
                            <p @click="bulkToggleCampaignStatus(true, false)" class="text-cyan-500 font-medium cursor-pointer hover:text-cyan-600">Activate</p>
                            <p class="text-gray-400 text-sm">/</p>
                            <p @click="bulkToggleCampaignStatus(false, false)" class="text-red-500 font-medium cursor-pointer hover:text-red-600">Pause</p>
                        </div>
                    </div>
                    <div class="relative hidden z-50 md:sticky md:top-20 md:grid md:grid-cols-7 bg-cyan-50 text-center font-bold py-2 items-center border-b border-gray-200 text-xs text-gray-700 uppercase">
                        <div class="absolute left-2 mt-1">
                            <div>
                                <CustomCheckbox
                                  @update:modelValue="(checked) => handleCampaignSelect(checked, 'all')"
                                />
                            </div>
                        </div>
                        <div>Status</div>
                        <div>Name</div>
                        <div>Region</div>
                        <div>
                            <div class="flex flex-col items-center gap-y-2">
                                <Tooltip>{{ tooltips.averageDailySpend }}</Tooltip>
                                <p>Avg Daily Spend <br/>/ Daily Budget</p>
                            </div>
                        </div>
                        <div>
                            <div class="flex flex-col items-center gap-y-2">
                                <Tooltip>{{ tooltips.averageDailyWon }}</Tooltip>
                                <p>Avg Daily <br/>Leads Won</p>
                            </div>
                        </div>
                        <div>
                            <div class="flex flex-col items-center gap-y-2">
                                <Tooltip :align-left="false">{{ tooltips.averageDailyAvailable }}</Tooltip>
                                <p>Avg Daily <br/>Leads Available</p>
                            </div>
                        </div>
                        <div>Actions</div>
                    </div>
                    <div v-for="campaign in campaignStore.campaigns[productScope]"
                        :key="campaign.id"
                        class="relative border-b odd:bg-gray-50 text-gray-600 text-sm md:text-center items-center grid md:grid-cols-7 gap-4 md:gap-0 p-4 md:py-4 md:px-0"
                        :class="[campaignFilter === 'all' || !!parseInt(campaignFilter) === !!campaign.status ? '' : 'hidden']"
                    >

                            <div class="mt-2 md:mt-0 md:absolute md:left-2">
                                <CustomCheckbox v-model="selectedCampaigns[campaign.id]" />
                            </div>
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">Status</p>
                                <ToggleSwitch
                                  :model-value="!!campaign.status"
                                  :small="true"
                                  color="green"
                                  @change="() => toggleCampaign(campaign)"
                                />
                                <p v-if=!campaign.status class="text-center">Paused Until: {{ campaign.reactivate_date }}</p>
                            </div>
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">Name</p>
                                <p class="text-cyan-500 cursor-pointer"
                                    @click="launchCampaignEditor(campaign.id)"
                                >
                                    {{ campaign.name }}
                                </p>
                                <p>Property Types: {{ getPropertyTypeNames(campaign) }}</p>
                            </div>
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">Region</p>
                                <p>{{ campaign.zip_locations_count }} Zip Codes</p>
                                <p>{{ campaign.state_locations_count }} States</p>
                            </div>
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">
                                    Avg Daily Spend / Daily Budget
                                </p>
                                <p>{{ getAverageDailyStats(campaign, 'spend') }}</p>
                            </div>
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">
                                    Avg Daily Leads Won
                                </p>
                                <p>{{ getAverageDailyStats(campaign, 'won') }}</p>
                            </div>
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">
                                    Avg Daily Leads Available
                                </p>
                                <p>{{ getAverageDailyStats(campaign, 'available') }}</p>
                            </div>
                        <div class="relative w-max mx-auto">
                            <ButtonDropdown>
                                <template v-slot:trigger>
                                    <div class="cursor-pointer flex justify-center items-center text-cyan-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                        </svg>
                                    </div>
                                </template>

                                <div class="translate-y-7 bg-white border border-gray-300 shadow rounded absolute top-0 right-0 z-50 transform p-2 w-48">
                                    <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                         @click="launchCampaignEditor(campaign.id)"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                        </svg>
                                        <p class="ml-2 pb-0 text-cyan-500">Edit</p>
                                    </div>
                                    <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                         @click="downloadCampaignZip(campaign.id)"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                        </svg>
                                        <p class="ml-2 pb-0 text-cyan-500">Download Zip Codes</p>
                                    </div>
                                    <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                         @click="confirmDelete(campaign.id)"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                        <p class="ml-2 pb-0 text-cyan-500">Delete</p>
                                    </div>
                                </div>
                            </ButtonDropdown>
                        </div>
                    </div>
                </div>
                <!--    Delete campaign confirmation    -->
                <Modal
                    v-if="showConfirmDeleteModal"
                    :class="[ saving ? 'grayscale-[60%] pointer-events-none' : '' ]"
                    confirm-label="Yes"
                    @clicked:confirm="deleteCampaign"
                    @clicked:cancel="cancelDelete"
                    :small="true"
                >
                    <template v-slot:header>
                        Delete Campaign
                    </template>
                    <template v-slot:body>
                        <p class="text-center">Are you sure you wish to delete Campaign "{{ campaignStore.getCampaignNameByUuid(deletingCampaignId, productScope) }}"? The data will be permantently lost.</p>
                    </template>
                </Modal>
                <!--    Pause campaign confirmation    -->
                <PauseCampaignModal
                    v-if="showConfirmPauseModal"
                    :campaign-name="getCampaignPauseName"
                    :saving="saving"
                    @confirm-pause="continuePause"
                    @cancel-pause="cancelPause"
                />
            </div>
        </template>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { computed, nextTick, ref } from "vue";
import ToggleSwitch from "@/components/inputs/ToggleSwitch.vue";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import Tooltip from "@/components/Tooltip.vue";
import { useCampaignStore } from "@/stores/campaigns.js";
import { useErrorStore } from "@/stores/errors.js";
import Modal from "@/components/Modal.vue";
import { useCreateCsvString } from "@/composables/useCreateCsvString.js";
import { useDownloadFile } from "@/composables/useDownloadFile.js";
import ButtonDropdown from "@/components/ButtonDropdown.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import PauseCampaignModal from "@/components/leads/Modals/PauseCampaignModal.vue";

const props = defineProps({
    storeInitialized: {
        type: Boolean,
        default: false,
    },
    productScope: {
        type: String,
        default: 'leads',
    }
})

const campaignStore = useCampaignStore();
const errors = useErrorStore();

const loading = ref(false);
const saving = ref(false);

const errorMessage = ref('');

const deletingCampaignId = ref(null);
const showConfirmDeleteModal = ref(false);
const showConfirmPauseModal = ref(false);
const pausingCampaignId = ref(null);

const campaignFilter = ref('all');
const campaignFilterOptions = [
  {label:'All', value: 'all'},
  {label:'Active', value: 1},
  {label:'Inactive', value: 0}
];

const selectedCampaigns = ref({});

const emit = defineEmits([ 'campaignEditOpen']);

const getCampaignPauseName = computed(() => {
    return pausingCampaignId.value === 'selected' ? 'the selected campaigns' : `the campaign "${campaignStore.getCampaignNameByUuid(pausingCampaignId.value, props.productScope)}"`;
});

const activeSelection = computed(() => {
    for (const id in selectedCampaigns.value) {
        if (selectedCampaigns.value[id]) return true;
    }
    return false;
});

const getPropertyTypeNames = (campaign) => {
    return campaign.property_types.map(type => type.name).join(', ');
}

const tooltips = {
    averageDailySpend: `Average daily spend over last 30 days (or since last budget change). Daily Budget is set within your campaign.`,
    averageDailyWon: `Average daily leads purchased last 30 days (or since last budget change).`,
    averageDailyAvailable: `Average daily leads available in your zip codes last 30 days (or since last budget change).`
}

const handleCampaignSelect = (isChecked, id) => {
    if (id === 'all') {
        campaignStore.campaigns[props.productScope].forEach(campaign => selectedCampaigns.value[campaign.id] = !!isChecked);
    }
    else {
        selectedCampaigns.value[id] = !!isChecked;
    }
}

const confirmDelete = (id) => {
    deletingCampaignId.value = id;
    showConfirmDeleteModal.value = true;
}

const cancelDelete = () => {
    deletingCampaignId.value = null;
    showConfirmDeleteModal.value = false;
}

const deleteCampaign = async () => {
    if (saving.value) return;
    saving.value = true;
    const { status, message } = await campaignStore.deleteCampaign(deletingCampaignId.value, props.productScope);
    if (status) {
        deletingCampaignId.value = null;
    }
    else {
        errors.report(message);
        errorMessage.value = message;
    }
    showConfirmDeleteModal.value = false;
    saving.value = false;
}

const downloadCampaignZip = async (id) => {
    if (saving.value) return;
    
    try {
        saving.value = true;
        const payload = id === 'all'
            ? campaignStore.campaigns[props.productScope].map(campaign => campaign.id)
            : [id];
        const { status, zipCodes, message } = await campaignStore.getZipCodesForCampaignList(payload);
        if (!status) {
            errors.report(message);
            errorMessage.value = message;
        }
        else {
            const filename = id === 'all'
                ? 'All_Campaigns_Zip_Codes'
                : `Campaign_Zip_Codes_${campaignStore.getCampaignNameByUuid(id) || id}`;
            const encodedCsv = useCreateCsvString(['Zip Code'], zipCodes);
            useDownloadFile(encodedCsv, filename);
        }
        saving.value = false;
    } catch (e) {
        errors.report(e);
    }
}

const bulkToggleCampaignStatus = (activate, confirmed, payload = {}) => {
    if (!activate && !confirmed) {
        errorMessage.value = null;
        showConfirmPauseModal.value = true;
        pausingCampaignId.value = 'selected';
    }
    else {
        const changedCampaigns = [];

        campaignStore.campaigns[props.productScope].forEach(campaign => {
            if (selectedCampaigns.value[campaign.id]) {
                changedCampaigns.push(campaign.id);
            }
        });

        updateCampaignStatus(changedCampaigns, payload);
    }
}

const continuePause = (payload) => {
    if (pausingCampaignId.value === 'selected') {
        bulkToggleCampaignStatus(false, true, payload);
    }
    else if (pausingCampaignId.value) {
        const campaign = campaignStore.campaigns[props.productScope].find(campaign => campaign.id === pausingCampaignId.value);
        toggleCampaign(campaign, true, payload);
    }
}

const cancelPause = () => {
    pausingCampaignId.value = null;
    showConfirmPauseModal.value = false;
}

const toggleCampaign = (campaign, confirmed, payload) => {
    if (campaign.status && !confirmed) {
        errorMessage.value = null;
        showConfirmPauseModal.value = true;
        pausingCampaignId.value = campaign.id;
    }
    else {
        updateCampaignStatus([ campaign.id ], payload);
    }
}

const updateCampaignStatus = async (idArray, payload) => {
    if (!idArray.length) return;
    saving.value = true;
    await nextTick();

    const { status, message } = await campaignStore.setCampaignStatus(idArray, props.productScope, payload);

    if (!status) {
        errors.report(message);
        errorMessage.value = message;
    }
    else {
        idArray.forEach(id => {
             const targetCampaign = campaignStore.campaigns[props.productScope].find(campaign => campaign.id === id);
             if (targetCampaign) targetCampaign.status = !targetCampaign.status;
        });
        showConfirmPauseModal.value = false;
    }

    saving.value = false;
    return !!status;
}

const launchCampaignEditor = async (campaignId) => {
    if (campaignId) {
        try {
            loading.value = true;
            const { status, message } = await campaignStore.editCampaign(campaignId);
            loading.value = false;
            if (!status) {
                errors.report(message);
                errorMessage.value = message;
                return;
            }
        } catch(e) {
            errors.report(e, 'There was an issue creating a new campaign, please contact support.');
        }        
    }
    emit('campaignEditOpen', campaignId);
}

const getAverageDailyStats = (campaign, type) => {
    const daysOfLeads = Math.max(campaign.statistics?.days_of_stats ?? 30, 1);
    return type === 'spend'
        ? campaign.budgets.max_daily_lead > 0
            ? getAverageDailyLeadSpend(campaign, daysOfLeads)
            : getAverageDailyCurrencySpend(campaign, daysOfLeads)
        : type === 'won'
            ? ((campaign.statistics?.received ?? 0)/ daysOfLeads).toFixed(2)
            : type === 'available'
                ? ((campaign.statistics?.available ?? 0) / daysOfLeads).toFixed(2)
                : 0;
}

const getAverageDailyCurrencySpend = (campaign, daysOfLeads) => {
    const limitString = campaign.budgets.max_daily_spend > 0
        ? `$${campaign.budgets.max_daily_spend.toFixed(2)}`
        : `No Limit`
    return `$${((campaign.statistics?.cost || 0) / daysOfLeads).toFixed(2)} / ${limitString}`;
}

const getAverageDailyLeadSpend = (campaign, daysOfLeads) => {
    return `${((campaign.statistics?.purchased || 0)/ daysOfLeads).toFixed(2)} / ${campaign.budgets.max_daily_lead} Leads`
}

</script>