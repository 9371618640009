import { Ref, ref } from 'vue';
import { defineStore } from 'pinia';
import { makeApiServiceV3, makeApiServiceV4 } from "@/services/api/factory";
import { useConfigStore } from "@/stores/config";
import { ApiServiceV4 } from "@/services/api/v4/ApiServiceV4";
import { DummyApiServiceV4 } from "@/services/api/v4/DummyApiServiceV4";

export enum Environments {
    Production = 'production',
    Development = 'development',
}

export enum ProductType {
    Lead = 'lead',
    Appointment = 'appointment',
    DirectLeads = 'direct leads'
}

export enum ProductTypeName {
    Lead = 'Lead',
    Appointment = 'Appointment',
}

export const useServicesStore = defineStore('services', () => {
    const config = useConfigStore();

    const apiServiceV3 = ref(makeApiServiceV3(config));
    const apiServiceV4: Ref<ApiServiceV4 | DummyApiServiceV4> = ref(makeApiServiceV4(config));

    return {
        apiService: apiServiceV3,
        apiServiceV4,
    };
});