<template>
    <div class="h-16 md:h-20">
        <div class="fixed z-40 w-full h-16 md:h-20 bg-white flex justify-between border-b border-gray-200">
            <div class="flex items-center relative z-60">
                <RouterLink to="/leads">
                    <div class="pl-5">
                        <fixr-roofing-calculator-logo />
                    </div>
                </RouterLink>
                <p class="hidden md:flex text-sm text-gray-600 pb-0 mx-8">
                    Welcome {{ user.fullName }} to the dashboard for {{ company.name }}
                </p>
            </div>

            <div class="inline-flex justify-around md:justify-end z-50">
                <div class="flex flex-row items-center">
                    <div class="mr-4 lg:mr-8">
                    </div>
                    <div class="mr-4 lg:mr-8">
                    </div>
                    <div class="mr-4 lg:mr-8 inline-flex items-center">
                        <p class="text-gray-600 text-sm mr-2">{{ user.fullName }}</p>
                        <ProfileDropdown />
                    </div>
                    <div @click="toggleMobileNavMenu" class="lg:hidden mr-4 lg:mr-8 cursor-pointer text-cyan-500 hover:text-cyan-600">
                        <svg class="fill-current w-5 h-5" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1C14 1.26522 13.8946 1.51957 13.7071 1.70711C13.5196 1.89464 13.2652 2 13 2H1C0.734784 2 0.48043 1.89464 0.292893 1.70711C0.105357 1.51957 0 1.26522 0 1ZM0 6C0 5.73478 0.105357 5.48043 0.292893 5.29289C0.48043 5.10536 0.734784 5 1 5H13C13.2652 5 13.5196 5.10536 13.7071 5.29289C13.8946 5.48043 14 5.73478 14 6C14 6.26522 13.8946 6.51957 13.7071 6.70711C13.5196 6.89464 13.2652 7 13 7H1C0.734784 7 0.48043 6.89464 0.292893 6.70711C0.105357 6.51957 0 6.26522 0 6ZM0 11C0 10.7348 0.105357 10.4804 0.292893 10.2929C0.48043 10.1054 0.734784 10 1 10H13C13.2652 10 13.5196 10.1054 13.7071 10.2929C13.8946 10.4804 14 10.7348 14 11C14 11.2652 13.8946 11.5196 13.7071 11.7071C13.5196 11.8946 13.2652 12 13 12H1C0.734784 12 0.48043 11.8946 0.292893 11.7071C0.105357 11.5196 0 11.2652 0 11Z"/>
                        </svg>

                    </div>
                </div>
            </div>
            <div v-if="mobileNav" class="absolute inset-0 top-16">
                <MenuDropdown @launchIndustryServiceEditor="launchIndustryServiceEditor"></MenuDropdown>
            </div>
        </div>
    </div>
</template>

<script setup>
import {RouterLink, useRoute} from 'vue-router'
import {useUserStore} from "@/stores/user";
import {useCompanyStore} from "@/stores/company";
import ProfileDropdown from "@/components/navigation/Dropdowns/ProfileDropdown.vue";
import {useServicesStore} from "@/stores/services";
import {ref, watch} from "vue";
import MenuDropdown from "@/components/navigation/Dropdowns/MenuDropdown.vue";
import FixrRoofingCalculatorLogo from "@/components/shared/FixrRoofingCalculatorLogo.vue";

const user = useUserStore();
const company = useCompanyStore();
const services = useServicesStore();
const mobileNav = ref(false);

const route = useRoute();

watch(() => route.path, () => {
    if(mobileNav.value)
        mobileNav.value = false;
});

const toggleMobileNavMenu = () => {
    mobileNav.value = ! mobileNav.value;
}

const emit = defineEmits(['launchIndustryServiceEditor']);

const launchIndustryServiceEditor = () => {
    emit('launchIndustryServiceEditor');
}

services.apiService.getCompany().then(resp => {
    company.set({
        accountManager: {
            name: resp.data.data.account_manager?.name,
            email: resp.data.data.account_manager?.email,
            phone: resp.data.data.account_manager?.phone,
            meetingUrl: resp.data.data.account_manager?.meeting_url
        },
        contractAccepted: resp.data.data.contractAccepted,
        id: resp.data.data.id,
        leadPurchasing: {
            status: resp.data.data.leadPurchasing
        },
        logoUrl: resp.data.data.logo_url,
        description: resp.data.data.description,
        name: resp.data.data.name,
        websiteUrl: resp.data.data.website_url,
        industries: resp.data.data.industries,
        industryConfigurations: resp.data.data.industry_configurations,
        services: resp.data.data.services,
        status: resp.data.data.status,
        hasValidPaymentMethod: resp.data.data.has_valid_payment_method,
        activeCustomer: resp.data.data.active_customer,
        adminLocked: resp.data.data.admin_locked,
        missedLeadsEnabled: resp.data.data.missed_leads_enabled ?? false,
        reviewsEnabled: resp.data.data.reviews_enabled ?? false,
        appointmentsActive: !!(resp.data.data.appointments_active ?? false),
        futureCampaignsActive: !!resp.data.data.future_campaigns_active,
    });

    // selectedIndustry has changed to Slug instead of Name, reset the selected industry if the localStorage value is invalid
    company.autoSelectIndustryService();
});
</script>