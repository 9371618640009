export class LocalStorageStorePersistence {
    subscribe(store, key) {
        store.$subscribe((mutation, state) => {
            localStorage.setItem(key, JSON.stringify(state));
        });
    }

    load(store, key) {
        const item = localStorage.getItem(key);

        if(item !== null)
            store.set(JSON.parse(item));
    }
}

export const storePersistenceService = new LocalStorageStorePersistence();