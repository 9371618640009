<template>
    <div class="flex flex-col items-center justify-center min-h-[25vh]">
        <p class="mb-10 w-full">
            We have created a campaign in the FIXR Dashboard for you to buy these opportunities.
            Do you want to buy opportunities in these areas on an ongoing basis?
        </p>
        <div class="flex flex-col gap-5 w-full md:w-2/3">
            <SolidButton @click="activateOngoing">Yes, activate Ongoing</SolidButton>

            <SolidButton @click="buySelectedOnly">No, buy Selected Only</SolidButton>
        </div>
    </div>
</template>

<script setup>
import SolidButton from "@/components/inputs/SolidButton.vue";
import {usePurchaseMissedProductsStore} from "@/stores/purchase-missed-products";

const store = usePurchaseMissedProductsStore();

const activateOngoing = function () {
    store.editingCampaign.active_after_payment = true
    store.nextSlide()
}

const buySelectedOnly = function () {
    store.editingCampaign.active_after_payment = false
    store.nextSlide(3)
}

</script>