<template>
    <Module class="mt-4 mx-4 relative"
            :class="[ saving || loading ? 'grayscale-[60%] opacity-50 pointer-events-none' : '']"
    >
        <template v-slot:title>
            <div class="flex items-center justify-between py-2">
                <div class="flex items-center">
                    <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                    </svg>
                    <h2 class="text-lg"
                        :class="[purchaseMissedLead ? 'font-medium' : 'font-semibold']"
                    >
                      {{ getEditorHeading() }}
                    </h2>
                </div>
            </div>
        </template>
        <template v-slot:default>
            <div>
                <div>
                    <LoadingSpinner v-if="!storeInitialized || saving" />
                    <div v-if="storeInitialized">
                        <div v-if="!campaignStore.editingCampaign.id"
                             class="w-full flex rounded-lg bg-cyan-50 p-4 mb-3 text-gray-700"
                        >
                            <InformationCircleIcon class="h-5 w-5"/>
                            <p class="ml-4 text-sm font-medium">
                                {{ getCampaignIndustryServiceInfo() }}
                            </p>
                        </div>
                        <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-12">
                            <CustomInput
                              label="Campaign Name"
                              v-model="campaignStore.editingCampaign.name"
                              placeholder="My Campaign..."
                            />
                            <div class="xl:col-span-2">
                                <p class="block mb-3 text-sm font-medium">Property Types</p>
                                <div class="flex gap-3 flex-wrap">
                                    <label v-for="propertyType in Object.values(campaignStore.campaignConfigData[`${productScope}_property_types`])"
                                           class="inline-flex items-center mr-4"
                                    >
                                        <CustomCheckbox
                                          :modelValue="getPropertyTypeValue(propertyType.id)"
                                          @update:modelValue="(checked) => updatePropertyTypes(propertyType.id, checked)"
                                        />
                                        <span class="ml-2">{{ propertyType.name }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex gap-x-3 py-1 my-4 overflow-x-auto w-72 sm:w-88 md:w-auto">
                        <div v-for="([ slideKey, slide ]) in Object.entries(slideMap).filter(([ _, slideObj ]) => !slideObj.disabled?.())"
                             :key="slideKey"
                             class="inline-flex items-center"
                        >
                            <button @click="saveAndProgressSlide(slideKey)">
                            <span class="font-medium text-md"
                                  :class="[activeSlide === slideKey ? 'text-cyan-500' : campaignStore.editingCampaign.saved_slides.includes(slideKey) ? 'text-gray-500' : 'text-slate-300' ]"
                            >
                                {{ slide.name }}
                            </span>
                            </button>
                            <svg class="inline w-2 mx-4" v-if="slide.next !== false" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.417527 0.677659C0.808051 0.287135 1.44122 0.287135 1.83174 0.677659L8.83174 7.67766C9.22227 8.06818 9.22227 8.70135 8.83174 9.09187L1.83174 16.0919C1.44122 16.4824 0.808051 16.4824 0.417527 16.0919C0.0270027 15.7013 0.0270027 15.0682 0.417527 14.6777L6.71042 8.38477L0.417527 2.09187C0.0270027 1.70135 0.0270027 1.06818 0.417527 0.677659Z" fill="#64748B"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <!--    SLIDES    -->
                <div>
                    <Transition name="slide" mode="out-in">
                        <component ref="currentSlideInstance"
                                   :is="slideMap[activeSlide]?.slide ?? slideMap.geography.slide"
                                   :show-zip-code-radius-button="!purchaseMissedLead"
                                   :show-zipCode-upload-button="!purchaseMissedLead"
                                   :auto-select-states="getServiceAreasToAutoSelect()"
                                   :show-optional-leads="!purchaseMissedLead"
                                   :show-required-leads="!purchaseMissedLead"
                                   :purchase-missed-lead="purchaseMissedLead"
                                   :show-integration-content="!purchaseMissedLead"
                                   :minimum-daily-leads="getMinimumDailyLeads()"
                                   :productScope="productScope"
                        />
                    </Transition>
                </div>
                <!--    FOOTER    -->
                <div class="sticky bottom-0 bg-white border-t flex justify-between px-4 py-6 mt-6 z-10">
                    <SolidButton @click="confirmCancelCampaign"
                                 label="Cancel"
                                 color="gray"
                                 :disabled="saving"
                    />
                    <SolidButton @click="saveAndProgressSlide"
                                 :label="getEditorProgressButtonLabel()"
                                 :disabled="saving"
                    />
                </div>
                <!--    MODAL   -->
                <Modal v-if="campaignAlert"
                       @clicked:confirm="closeAlertModal"
                       :show-cancel-button="false"
                       :small="true"
                       confirm-label="Ok"
                >
                    <template v-slot:header>
                        Campaign Editor
                    </template>
                    <template v-slot:body>
                        <p class="text-center whitespace-pre">{{ campaignAlert }}</p>
                    </template>
                </Modal>
                <Modal
                  v-if="showCancelConfirm"
                  confirm-label="Yes"
                  @clicked:confirm="cancelCampaignEditor"
                  @clicked:cancel="showCancelConfirm = false"
                  :small="true"
                >
                    <template v-slot:header>
                        Cancel Campaign
                    </template>
                    <template v-slot:body>
                        <p class="text-center">Are you sure you wish to cancel? All changes will be lost.</p>
                    </template>
                </Modal>
            </div>
        </template>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import { onBeforeMount, ref } from "vue";
import CustomInput from "@/components/inputs/CustomInput.vue";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import Geography from "@/components/campaigns/EditorSlides/Geography.vue";
import LeadBudget from "@/components/leads/EditorSlides/LeadBudget.vue";
import Delivery from "@/components/campaigns/EditorSlides/Delivery.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { useCampaignStore } from "@/stores/campaigns.js";
import Modal from "@/components/Modal.vue";
import { useCompanyUsersStore } from "@/stores/company-users.js";
import LeadBidding from "@/components/leads/EditorSlides/LeadBidding.vue";
import AppointmentBidding from "@/components/appointments/EditorSlides/AppointmentBidding.vue";
import { useCompanyStore } from "@/stores/company.js";
import { useProperCase } from "@/composables/useProperCase.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {usePurchaseMissedProductsStore} from "@/stores/purchase-missed-products";
import AppointmentBudget from "@/components/appointments/EditorSlides/AppointmentBudget.vue";
import {InformationCircleIcon} from "@heroicons/vue/solid";

const props = defineProps({
    storeInitialized: {
        type: Boolean,
        default: false,
    },
    productScope: {
        type: String,
        default: 'leads',
    },
    purchaseMissedLead: {
        type: Boolean,
        default: false,
    },
    missedLeadData: {
        type: Object,
        default: {}
    },
});

const campaignStore = useCampaignStore();
const companyUserStore = useCompanyUsersStore();
const companyStore = useCompanyStore();
companyUserStore.initialize();

const currentSlideInstance = ref(null);
const newCampaign = ref(false);

const campaignAlert = ref(null);

const showCancelConfirm = ref(false);

const slideMappings = {
    leads: {
        geography: {
            name: 'Geography',
            slide: Geography,
            next: 'budget',
        },
        budget: {
            name: 'Budget',
            slide: LeadBudget,
            next: 'bidding',
        },
        bidding: {
            name: 'Bidding',
            slide: LeadBidding,
            next: 'delivery',
            disabled: () => newCampaign.value,
        },
        delivery: {
            name: 'Delivery',
            slide: Delivery,
            next: false,
        },
    },
    appointments: {
        geography: {
            name: 'Geography',
            slide: Geography,
            next: 'budget',
        },
        budget: {
            name: 'Budget',
            slide: AppointmentBudget,
            next: 'bidding'
        },
        bidding: {
            name: 'Bidding',
            slide: AppointmentBidding,
            next: 'delivery',
            disabled: () => newCampaign.value,
        },
        delivery: {
            name: 'Delivery',
            slide: Delivery,
            next: false,
        },
    }
};
const slideMap = slideMappings[props.productScope];

const activeSlide = ref('geography');

const saving = ref(false);
const loading = ref(false);

const defaultPropertyTypeId = ref(1);

const emit = defineEmits([
    'campaignEditClose',
    'campaignCreated',
]);

onBeforeMount(() => {
    newCampaign.value = !campaignStore.editingCampaign.id;
    if (!newCampaign.value) campaignStore.editingCampaign.saved_slides = Object.keys(slideMap);
    campaignStore.editingCampaign.product_name = props.productScope;

    // Pre-populate the campaign's basic details for purchasing missed lead(s)
    if (props.purchaseMissedLead) {
        const formattedDate = usePurchaseMissedProductsStore().formatDate((new Date()));
        campaignStore.editingCampaign.name = 'Missed leads purchased on ' + formattedDate;

        updatePropertyTypes(defaultPropertyTypeId.value, true);
    }
});

const setSlide = (slideKey) => {
    if (slideKey in slideMap) {
        activeSlide.value = slideKey;
    }
    else console.warn('Bad slide name');
}

const getPropertyTypeValue = (propertyTypeId) => campaignStore.editingCampaign.property_types.includes(propertyTypeId);

const updatePropertyTypes = (propertyTypeId, checked) => {
    if (checked && !(campaignStore.editingCampaign.property_types.includes(propertyTypeId))) {
        campaignStore.editingCampaign.property_types.push(propertyTypeId);
    }
    else {
        campaignStore.editingCampaign.property_types = campaignStore.editingCampaign.property_types.filter(id => id !== propertyTypeId);
    }
}

const confirmCancelCampaign = () => {
    showCancelConfirm.value = true;
}

const cancelCampaignEditor = () => {
    campaignStore.clearEditing();
    emit('campaignEditClose');
}

const saveAndProgressSlide = (nextSlide) => {
    const validator = currentSlideInstance.value?.validateSlide;
    if (!validator) {
        console.warn(`Slide ${activeSlide.value} must have a validator function.`);
    }
    else {
        const { status, message } = validator();
        if (!status) {
            campaignAlert.value = message ?? 'The slide failed validation.';
            return;
        }
    }
    if (!nextSlide || !(nextSlide in slideMap)) {
        nextSlide = slideMap[activeSlide.value].next;
        let slideDisabled = slideMap[nextSlide]?.disabled?.();
        while (slideDisabled && nextSlide !== false) {
            nextSlide = slideMap[nextSlide].next;
            slideDisabled = slideMap[nextSlide].disabled && slideMap[nextSlide].disabled();
        }
    }
    if (nextSlide != null) {
        currentSlideComplete();
        if (nextSlide === false) {
            saveCampaign();
        } else {
            activeSlide.value = nextSlide;
        }
    }
}

const currentSlideComplete = () => {
    if (!campaignStore.editingCampaign.saved_slides.includes(activeSlide.value)) campaignStore.editingCampaign.saved_slides.push(activeSlide.value);
}

//TODO: outerValidation for appointments
const outerValidation = () => {
    let errors = [];
    if (campaignStore.editingCampaign.property_types.length < 1) {
        errors.push(`At least one lead category must be selected.`);
    }
    if (!campaignStore.editingCampaign.name) {
        errors.push(`The campaign must have a name.`);
    }
    return errors.length
        ? { status: false, message: errors.join('\n') }
        : { status: true }
}

const finalValidation = () => {
    const errors = [];
    for (const slide in slideMap) {
        if (campaignStore.editingCampaign.id || !slideMap[slide].disabled) {
            if (!campaignStore.editingCampaign.saved_slides.includes(slide)) {
                errors.push(`Slide ${slideMap[slide].name} must be completed.`);
            }
        }
    }
    const { status, message } = outerValidation();
    if (!status) errors.push(message);
    return { status: !errors.length, message: errors.join('\n') }
}

const saveCampaign = async () => {
    const { status, message } = finalValidation();
    if (!status) {
         campaignAlert.value = `We found a few issues to resolve:\n\n${message}`;
    }
    else {
        saving.value = true;
        const { status, message, campaignId } = await campaignStore.saveCampaign();
        if (status) {
            await campaignStore.refresh();

            if (!props.purchaseMissedLead) {
                campaignStore.clearEditing();
            }
            emit('campaignCreated', {
                campaignId: campaignId
            });
        }
        else {
            const errorMessage = message || `An unknown error occurred saving the campaign`;
            campaignAlert.value = (errorMessage);
        }
        saving.value = false;
    }
}

const closeAlertModal = () => {
    campaignAlert.value = null;
}

const getEditorHeading = () => {
    return props.purchaseMissedLead && props.missedLeadData?.totalLeadsSelected
        ? `Purchasing ${props.missedLeadData.totalLeadsSelected} Lead${props.missedLeadData.totalLeadsSelected > 1 ? 's' : '' }`
        : `Campaign Editor - ${campaignStore.editingCampaign.id ? 'Edit Campaign' : 'New Campaign' }`;
}

const getServiceAreasToAutoSelect = () => {
    return props.purchaseMissedLead && props.missedLeadData?.serviceArea
        ? activeSlide.value.toLowerCase() === slideMappings.leads.geography.name.toLowerCase()
            ? props.missedLeadData.serviceArea
            : []
        : [];
}

const getMinimumDailyLeads = () => {
    return props.purchaseMissedLead && props.missedLeadData?.totalLeadsSelected
        ? activeSlide.value.toLowerCase() === slideMappings.leads.budget.name.toLowerCase()
            ? props.missedLeadData.totalLeadsSelected
            : 0
        : 0;
}

const getEditorProgressButtonLabel = () => {
    return slideMap[activeSlide.value].next === false
        ? !props.purchaseMissedLead
            ? 'Save Campaign'
            : 'Proceed To Checkout'
        : 'Next Slide';
}

const getCampaignIndustryServiceInfo = () => {
    return `The campaign is being set up for the '${useProperCase(companyStore.selectedService)}' service. In case you want to switch a service, refer to the 'Service Management' panel on the top left.`;
}

</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
    opacity: 1;
    transition: opacity 0.25s ease-in 0s;
}

.slide-enter-from,
.slide-leave-to {
    opacity: 0;
    transition: opacity 0.25s 0s;
}

</style>