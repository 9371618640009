<template>
    <MainGrid>
        <UploadMediaModule />
        <UploadYoutubeModule />
        <UploadedMediaModule :store-initialized="storeInitialized"/>
    </MainGrid>
</template>

<script setup>
import MainGrid from "@/components/layouts/MainGrid.vue";
import UploadMediaModule from "@/components/company/UploadMediaModule.vue";
import UploadYoutubeModule from "@/components/company/UploadYoutubeModule.vue";
import UploadedMediaModule from "@/components/company/UploadedMediaModule.vue";
import { useCompanyMediaStore } from "@/stores/company-media.js";
import { ref } from "vue";

const companyMediaStore = useCompanyMediaStore();

const storeInitialized = ref(false);

companyMediaStore.initialize().then(() => storeInitialized.value = true);
</script>