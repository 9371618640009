<template>
    <div class="relative hidden z-20 md:sticky md:top-20 md:grid text-xs text-gray-700 uppercase bg-cyan-50 font-bold py-2 px-6 items-center border-b border-gray-200"
        :class="[`md:${getGridColumns}`]"
    >
        <p class=""
            v-for="(column, index) in childTableMap"
           :key="column.key ?? index"
        >
            {{ column.header ?? '' }}
        </p>
    </div>
    <div v-for="(item, index) in childTableData"
         :key="item.key ?? index"
         class="relative border-b odd:bg-gray-50 text-gray-600 items-center grid gap-4 md:gap-0 py-4 px-4 md:px-6"
         :class="[`md:${getGridColumns}`]"
    >
        <div v-for="(column, index) in childTableMap"
            :key="column.key ?? index"
         >
            <div v-if="column.type">
                <div v-if="showColumn(PresetType.Checkbox, column, item)">
                    <CustomCheckbox
                        :model-value="!!item[column.key]"
                        @update:modelValue="(newValue) => handleCheckboxClick(item[column.emitKey ?? column.key], newValue)"
                    />
                </div>
                <div v-else-if="showColumn(PresetType.SelectAll, column, item)"
                     class="flex gap-x-6 text-sm"
                >
                    <OutlineButton
                        @click="toggleAll(item[column.key], true)"
                    >
                        Add All
                    </OutlineButton>
                    <OutlineButton
                        @click="toggleAll(item[column.key], false)"
                        color="red"
                    >
                        Remove All
                    </OutlineButton>
                </div>
            </div>
            <div v-else>
                <div v-if="column.emitClick"
                    @click="selectItem(item[column.emitKey || column.key])"
                     class="cursor-pointer"
                >
                    {{ item[column.key] ?? 'N/A' }}
                </div>
                <p v-else class="">
                    {{ item[column.key] ?? 'N/A' }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import { computed, ComputedRef } from "vue";

enum PresetType {
    SelectAll = 'selectAll',
    Checkbox = 'checkbox',
}

export type ChildTableHeaderItem = {
    header: string,
    type?: PresetType|string,
    key: string,
    emitClick?: boolean|string,
    emitKey?: string|null
    show?: boolean|string,
}

interface Props {
    childTableMap: ChildTableHeaderItem[],
    childTableData: GenericObject[],
    gridColumns?: string
}
const props = withDefaults(defineProps<Props>(), {
    childTableMap: () => [],
    childTableData: () => [],
});

const emit = defineEmits([
    'update:selection',
    'toggleAll',
    'selectItem',
    'toggleCheckbox',
]);

const getGridColumns: ComputedRef<string> = computed(() => props.gridColumns ?? `grid-cols-${props.childTableMap.length ?? 4}`);

const toggleAll = (itemKey: string, addItems: boolean) => {
    emit('toggleAll', itemKey, addItems);
}

const selectItem = (itemKey: string) => {
    emit('selectItem', itemKey);
}

const handleCheckboxClick = (itemKey: any, addItem: boolean) => {
    emit('toggleCheckbox', itemKey, addItem);
}

const showColumn = (columnType: PresetType, column: ChildTableHeaderItem, item: GenericObject): boolean => {
    return columnType === column.type
        ? typeof(column.show) === 'string'
            ? handleStringConditional(column.show, item)
            : !!column.show
        : false;
}

const handleStringConditional = (keyString: string, item: GenericObject): boolean => {
    const [_, negation, key] = (keyString.match(/^\s*(!)?(.*)/) ?? []);
    const truthyKey = !!item[key];

    return negation ? !truthyKey : truthyKey;
}

</script>