import { defineStore } from "pinia";
import { useServicesStore } from "@/stores/services";
import { reactive, ref, toRaw } from "vue";
import { usePricingStore } from "@/stores/pricing.js";
import Products from "@/Utilities/Products";
import {AppointmentTypes, BudgetCategory, BudgetValueTypes} from "@/Utilities/Appointment";


export const useCampaignStore = defineStore('campaigns', () => {
    const servicesStore = useServicesStore();
    const pricingStore = usePricingStore();

    const product = ref(Products.LEAD);

    const campaigns = reactive({
        leads: [],
        appointments: [],
    });

    const productStatistics = reactive({
        leads: {},
        appointments: {},
    });

    const setProduct = async (productName) => {
        const previousProduct = product.value;

        if(/appointment/i.test(productName)) product.value = Products.APPOINTMENT;
        else if(/lead/i.test(productName)) product.value = Products.LEAD;
        else {
            console.error('Invalid product');
            product.value = null;
        }

        if (storeInitialized.value && previousProduct !== product.value) {
            storeInitialized.value = false;
            await initialize();
        }
    }

    const campaignConfigData = reactive({
        minimumDailySpend: 50,
        minimumDailyLeads: 1,
        minimumDailyAppointmentSpend: 50,
        minimumDailyAppointments: 1,
        //TODO: replace with backend pricing data when available
        leads_quality_tiers: [
            { label: 'Standard', value: 1 },
            // { label: 'Premium', value: 2 }
        ],
        //TODO: replace with backend Appointments data when available
        appointments_sales_types: [
            {
                id: 1,
                name: "Exclusive",
                key: "exclusive",
                description: "Matched only to you",
                default_type: 1,
                ordinal_position: 1,
                sale_limit: 1,
                status: 1
            },
            {
                id: 2,
                name: "Duo",
                key: "duo",
                description: "Matched w/2 installers",
                default_type: 1,
                ordinal_position: 2,
                sale_limit: 2,
                status: 1
            },
            {
                id: 3,
                name: "Trio",
                key: "trio",
                description: "Matched w/3 installers",
                default_type: 1,
                ordinal_position: 3,
                sale_limit: 3,
                status: 1
            }
        ],
        appointments_quality_tiers: [
            {label: AppointmentTypes.IN_HOME, value: 3},
            {label: AppointmentTypes.ONLINE, value: 4}
        ],
        appointments_property_types: [
            {
                id: 1,
                key_value: 'RESIDENTIAL',
                name: 'Residential',
                status: 1
            },
            {
                id: 2,
                key_value: 'COMMERCIAL',
                name: 'Commercial',
                status: 0
            }
        ],
    });

    const editingCampaign = reactive({
        id: null,
        name: '',
        status: true,
        daily_limit_type: null,
        max_daily_spend: 0,
        max_daily_leads: 0,
        allow_non_budget_premium_leads: null,
        property_types: [],
        contact_deliveries: [],
        crm_deliveries: {},
        optional_leads_types: {},
        zip_codes: {},
        saved_slides: [],
        product_name: null,
        schedules: [],
        appointment_budgets: [
            {
                quality_tier: AppointmentTypes.IN_HOME,
                category: BudgetCategory.VERIFIED,
                budget_type: BudgetValueTypes[0].value,
                budget_value: 0,
                status: true
            },
            {
                quality_tier: AppointmentTypes.ONLINE,
                category: BudgetCategory.VERIFIED,
                budget_type: BudgetValueTypes[0].value,
                budget_value: 0,
                status: true
            }
        ]
    });

    const editingCrmDelivery = reactive({
        id: null,
        name: null,
        enabled: !!editingCampaign.id,
        crm: {
            id: null,
        },
        integration_fields: {
            system_fields: {},
            fields: {},
            custom_fields: {},
        },
    });

    const storeInitialized = ref(false);

    const getPropertyTypeById = (propertyTypeId, productName = 'leads') => {
        return campaignConfigData[`${productName}_property_types`]?.find(type => type.id === propertyTypeId);
    }

    const getCrmFieldsById = (crmProviderId) => {
        const targetProvider = campaignConfigData.crm_providers.find(provider => provider.id === parseInt(crmProviderId));
        return targetProvider
            ? { fields: targetProvider.fields, systemFields: targetProvider.system_fields }
            : { fields: [], systemFields: [] }
    }

    async function initialize() {
        if (storeInitialized.value) return { status: true }
        if(product.value === null)
            product.value = Products.LEAD;

        const resp = await servicesStore.apiService.getCampaigns(product.value).catch(e=>e);
        if (resp.data?.data?.status) {
            if (resp.data.data.product === Products.LEAD) campaigns.leads = resp.data.data.campaigns;
            else if (resp.data.data.product === Products.APPOINTMENT) campaigns.appointments = resp.data.data.campaigns;

            //TODO: update to handle multi-product campaigns when Appointments are ready
            Object.assign(campaignConfigData,resp.data.data.config_data ?? {});
            storeInitialized.value = true;
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function refresh() {
        if(product.value === null)
            product.value = Products.LEAD;

        const resp = await servicesStore.apiService.getCampaigns(product.value).catch(e=>e);
        if (resp.data?.data?.status) {
            if (resp.data.data.product === Products.LEAD) campaigns.leads = resp.data.data.campaigns;
            else if (resp.data.data.product === Products.APPOINTMENT) campaigns.appointments = resp.data.data.campaigns;
        }
        else { return { status: false, message: resp.response?.data?.message || resp.err || `An unknown error occurred.`} }
    }

    function newCampaign() {
        clearEditing();
        editingCampaign.contact_deliveries = campaignConfigData.contact_deliveries;
        if (campaignConfigData.crm_deliveries) {
            campaignConfigData.crm_deliveries.forEach(crmDelivery => {
                editingCampaign.crm_deliveries[crmDelivery.id] = false;
            });
        }
    }

    async function editCampaign(campaignId) {
        if (editingCampaign.id === campaignId) return { status: true };
        const resp = await servicesStore.apiService.getCampaignDetail(campaignId).catch(e=>e);
        if (resp.data?.data?.status) {
            Object.assign(editingCampaign, resp.data.data.campaign);
            editingCampaign.zip_codes = editingCampaign.zip_codes.reduce((output, zipCode) => {
                return { ...output, [zipCode.id]: { ...zipCode } };
            }, {});
            editingCampaign.contact_deliveries = resp.data.data.contact_deliveries;
            campaignConfigData.crm_deliveries.forEach(crmDelivery => {
                editingCampaign.crm_deliveries[crmDelivery.id] = resp.data.data.crm_deliveries.includes(crmDelivery.id);
            });
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function saveCampaign() {
        const payload = prepareCampaignDataForRequest();
        const resp = await servicesStore.apiService.saveCampaign(editingCampaign.id, payload).catch(e=>e);
        if (resp.data?.data?.status) {
            // Remove pricing data as it may be out of date after updating the Campaign
            pricingStore.removePriceRangeForCampaign(editingCampaign.id, editingCampaign.product_name);
            return { status: true, campaignId: resp.data?.data?.campaign }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function bulkUpdateCampaigns(payload) {
        const resp = await servicesStore.apiService.bulkUpdateCampaigns(payload).catch(e => e);
        if (resp.data?.data?.status) {
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function setCampaignStatus(idArray, scope = 'leads', pauseOptions = { reactivate_at: null }) {
        const campaignPayload = campaigns[scope].map(campaign => {
            return idArray.includes(campaign.id)
                ? { id: campaign.id, status: !campaign.status }
                : null
        }).filter(v => v);

        const payload = {
            campaigns: campaignPayload,
            options: pauseOptions
        };

        return await bulkUpdateCampaigns(payload);
    }

    function getCampaignNameByUuid(uuid, scope = 'leads') {
        const target = campaigns[scope].find(campaign => campaign.id === uuid);
        return target?.name ?? 'Unknown Campaign';
    }

    async function deleteCampaign(uuid, scope = 'leads') {
        const resp = await servicesStore.apiService.deleteCampaign(uuid).catch(e=>e);
        if (resp.data?.data?.status) {
            campaigns[scope] = campaigns[scope].filter(campaign => campaign.id !== uuid);
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function saveCrmDeliveryMethod() {
        const payload = { ...toRaw(editingCrmDelivery) };
        if (editingCampaign.id) payload['lead_campaign_id'] = editingCampaign.id;
        const resp = editingCrmDelivery.id
            ? await servicesStore.apiService.updateCrmDeliveryIntegration(editingCrmDelivery.id, payload).catch(e=>e)
            : await servicesStore.apiService.addCrmDeliveryIntegration(payload).catch(e=>e);
        if (resp.data?.data?.status) {
            return { status: true, id: resp.data.data.id }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function getCrmDeliveryDetail() {
        const resp = await servicesStore.apiService.getCrmDeliveryDetail(editingCrmDelivery.id).catch(e=>e);
        if (resp.data?.data?.status) {
            Object.assign(editingCrmDelivery, resp.data.data.crm_delivery);
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function deleteCrmDelivery(crmDeliveryId) {
        const resp = await servicesStore.apiService.deleteCrmDelivery(editingCampaign.id, crmDeliveryId).catch(e=>e);
        if (resp.data?.data?.status) {
            campaignConfigData.crm_deliveries = campaignConfigData.crm_deliveries.filter(delivery => delivery.id !== crmDeliveryId);
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    /**
     * If a company user is updated by the store, check if they're attached to a Contact Delivery and update if required
     * Short circuit if the store isn't initialized - Delivery data hasn't been fetched and won't need updating
     * @param companyUser
     */
    function updateContactDeliveries(companyUser) {
        if (!storeInitialized.value) return;
        const targetContactDelivery = editingCampaign.contact_deliveries?.find(contact => contact.id === companyUser.id);
        if (targetContactDelivery) {
            Object.assign(targetContactDelivery, {
                cell_phone: companyUser.cell_phone,
                email: companyUser.email,
                name: `${companyUser.first_name} ${companyUser.last_name ?? ''}`
            });
        }
    }

    /**
     * Deletes a contact delivery (LeadCampaignDeliveryMethod legacy models) from a campaign only
     * @param contactId
     * @returns {Promise<{message: (*|string), status: boolean}|{status: boolean}>}
     */
    async function deleteContactDelivery(contactId) {
        const targetDelivery = editingCampaign.contact_deliveries.find(contact => contact.id === contactId);
        if (targetDelivery?.delivery_method_id && editingCampaign.id) {
            const resp = await servicesStore.apiService.deleteContactDelivery(editingCampaign.id, targetDelivery.delivery_method_id).catch(e=>e);
            if (resp.data?.data?.status) {
                editingCampaign.contact_deliveries = editingCampaign.contact_deliveries.filter(contact => contact.id !== contactId);
                campaignConfigData.contact_deliveries = campaignConfigData.contact_deliveries.filter(contact => contact.id !== contactId);
                return { status: true }
            }
            else {
                return genericErrorMessage(resp);
            }
        }
        else return { status: false, message: `The requested Contact Delivery could not be deleted.` }
    }

    /**
     * Function may need to be updated if campaigns get pagination
     */
    async function getZipCodesForCampaignList(campaignUuidArray) {
        const resp = await servicesStore.apiService.getZipCodesForCampaignList(campaignUuidArray).catch(e=>e);
        if (resp.data?.data?.status) {
            return { status: true, zipCodes: resp.data.data.zip_codes }
        }
        else {
            return { status: false, message: resp.data.data.message ?? resp.err ?? 'An unknown error occurred' }
        }
    }

    /**
     * Strip out unneeded data before post/patch
     */
    function prepareCampaignDataForRequest() {
        const output = JSON.parse(JSON.stringify(toRaw(editingCampaign)));
        output.zip_codes = Object.keys(output.zip_codes);
        output.property_types = Object.values(output.property_types);
        output.product =  product.value;
        return output;
    }

    async function getBiddingLocationStatistics(stateAbbreviation, countyKey, propertyTypeId, productName = 'leads') {
        const propertyTypeKey = getPropertyTypeNameById(productName, propertyTypeId);
        const countyStorageKey = countyKey || 'state';
        if (productStatistics[productName][propertyTypeKey]?.[stateAbbreviation]?.[countyKey]) return { status: true };

        const dateNow = new Date();
        const params = {
            state_abbr: stateAbbreviation,
            county_key: countyKey || null,
            property_type_id: propertyTypeId,
            grouping: 'location',
            start_timestamp: (dateNow.setMonth(dateNow.getMonth() - 1))/1000,
            end_timestamp: dateNow/1000
        };

        const resp = await servicesStore.apiService.getProductStatisticsByLocation(params).catch(e=>e);
        if (resp.data?.data?.status) {
            productStatistics[productName][propertyTypeKey] = productStatistics[productName][propertyTypeKey] ?? {};
            productStatistics[productName][propertyTypeKey][stateAbbreviation] = productStatistics[productName][propertyTypeKey][stateAbbreviation] ?? {}
            Object.assign(productStatistics[productName][propertyTypeKey][stateAbbreviation], {
                [countyStorageKey]: resp.data.data.statistics,
            });
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    function getPropertyTypeNameById(productName, categoryId) {
        return campaignConfigData[`${productName}_property_types`]?.find(category => `${category.id}` === `${categoryId}`)?.name;
    }

    function clearEditing() {
        Object.assign(editingCampaign, {
            id: null,
            name: '',
            status: true,
            daily_limit_type: null,
            max_daily_spend: 0,
            max_daily_leads: 0,
            allow_non_budget_premium_leads: null,
            property_types: [],
            contact_deliveries: [],
            crm_deliveries: [],
            crm_providers: [],
            optional_leads_types: {},
            zip_codes: {},
            saved_slides: [],
            product_name: null,
            schedules: [],
            appointment_budgets: [
                {
                    quality_tier: AppointmentTypes.IN_HOME,
                    category: BudgetCategory.VERIFIED,
                    budget_type: BudgetValueTypes[0].value,
                    budget_value: 0,
                    status: true
                },
                {
                    quality_tier: AppointmentTypes.ONLINE,
                    category: BudgetCategory.VERIFIED,
                    budget_type: BudgetValueTypes[0].value,
                    budget_value: 0,
                    status: true
                }
            ]
        });
    }

    function clearCrmEditing() {
        Object.assign(editingCrmDelivery, {
            id: null,
            name: null,
            enabled: !!editingCampaign.id,
            crm: {
                id: null,
            },
            integration_fields: {
                system_fields: {},
                fields: {},
                custom_fields: {},
            },
        })
    }

    function clearStore() {
        Object.assign(campaigns,{
            leads: [],
            appointments: [],
        });
    }

    function genericErrorMessage(resp) {
        return { status: false, message: resp.data?.data?.message || resp.response?.data?.message || resp.err || `An unknown error occurred fetching data.` };
    }

    function $reset() {
        clearEditing();
        clearCrmEditing();
        clearStore();
    }

    return {
        initialize,
        refresh,
        campaigns,
        productStatistics,
        editingCampaign,
        editingCrmDelivery,
        campaignConfigData,
        deleteCampaign,
        saveCampaign,
        newCampaign,
        editCampaign,
        setCampaignStatus,
        clearEditing,
        clearCrmEditing,
        getCampaignNameByUuid,
        getPropertyTypeById,
        getCrmFieldsById,
        saveCrmDeliveryMethod,
        getCrmDeliveryDetail,
        deleteCrmDelivery,
        updateContactDeliveries,
        deleteContactDelivery,
        getZipCodesForCampaignList,
        getBiddingLocationStatistics,
        getPropertyTypeNameById,
        setProduct,
        $reset,
    }
});