<template>
    <button
        class="my-5 w-full rounded-md btn text-white flex items-center justify-center p-2"
        :type="type"
        :disabled="isDisabled"
        :class="[
            styles
        ]"
    >
        <loading-indicator v-if="loading" class="mr-1"/>
        <slot></slot>
    </button>
</template>
<script setup>
import LoadingIndicator from "@/components/authentication/components/LoadingIndicator.vue";
import {computed} from "vue";

const props = defineProps({
    loading: {
        type: Boolean,
        default: false
    },

    type: {
        type: String,
        default: 'button'
    },

    disabled: {
        type: Boolean,
        default: false
    }
})

const isDisabled = computed(() => props.disabled || props.loading)

const styles = computed(() => {
    return {
        'bg-blue-fixr hover:bg-blue-fixr/95': !isDisabled.value,
        'bg-gray-300': isDisabled.value,
        'cursor-wait': props.loading
    }
})

</script>