<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>

                <h5 class="text-md">Description</h5>
            </div>
        </template>

        <div class="relative">
            <LoadingSpinner v-if="saving" :small="true" />
            <p class="text-cyan-500">Description cannot contain contact details, e.g. phone number, email address.</p>

            <div class="flex items-center mt-4">
                <div class="w-1/4">
                    <p>Website</p>
                    <p class="text-gray-500 text-sm">(Must include 'https://')</p>
                </div>

                <input class="ml-16 w-full pl-4 rounded border border-grey-400 py-2" type="text" placeholder="https://example.com"
                    v-model="website"
                />
            </div>

            <div class="flex items-start mt-4">
                <div class="w-1/4">
                    <p>Description</p>
                </div>

                <textarea class="ml-16 w-full pl-4 rounded border border-grey-400 py-2" type="text" placeholder="Enter your company description here"
                    v-model="description"
                />
            </div>

            <div class="flex items-center mt-4">
                <div class="w-1/4">
                    <p v-if="errorMessage" class="text-red-900 text-sm text-center">{{ errorMessage }}</p>
                </div>

                <SolidButton
                    class="ml-auto"
                    :disabled="saving"
                    @click="updateCompanyProfile"
                >Save</SolidButton>
            </div>
        </div>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { onMounted, ref } from "vue";
import { useCompanyStore } from "@/stores/company.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const company = useCompanyStore();

const website = ref(null);
const description = ref(null);

const saving = ref(false);
const errorMessage = ref(null);

onMounted(() => {
    website.value = company.websiteUrl;
    description.value = company.description;
});

const updateCompanyProfile = async () => {
    if (saving.value) return;
    errorMessage.value = null;
    saving.value = true;
    const { status, message } = await company.updateCompanyProfile({
        'website': website.value,
        'description': description.value,
    });
    if (!status) {
        errorMessage.value = message
    }
    saving.value = false;
}

</script>