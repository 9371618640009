import { AxiosResponse } from "axios";
import { DummyResponse } from "@/services/api/v4/DummyApiServiceV4";
import { ProductType } from "@/stores/services";
import { CrmDeliverer } from "@/stores/v4/delivery";
import { CampaignPausePayload } from "@/stores/v4/future-campaigns";
import { ProductAssignmentSearchOptions, RejectionPayload } from "@/stores/v4/products";


export type ApiResponse =
    | AxiosResponse
    | DummyResponse;

export interface ApiServiceV4Contract {
    getModuleConfigurations(): Promise<ApiResponse>;
    getCrmConfigurations(): Promise<ApiResponse>;
    saveCrmConfiguration(payload: CrmDeliverer): Promise<ApiResponse>

    saveNewCampaign(payload: GenericObject): Promise<ApiResponse>;

    getStateFloorPrices(stateLocationKey: string, zipCodeTargeted: boolean): Promise<ApiResponse>;
    getCountyFloorPrices(stateLocationKey: string, countyLocationKey: string, zipCodeTargeted: boolean): Promise<ApiResponse>;
    getStateBidPrices(stateLocationKey: string, campaignReference: string): Promise<ApiResponse>;
    getCountyBidPrices(stateLocationKey: string, countyLocationKey: string, campaignReference: string, propertyType: string): Promise<ApiResponse>;
    getPriceRangeForZipCodes(zipCodes: number[], propertyTypes: string[]): Promise<ApiResponse>;

    getCampaigns(allProducts: boolean): Promise<ApiResponse>;
    getCampaignDetail(campaignReference: string): Promise<ApiResponse>;
    updateCampaign(payload: GenericObject, campaignReference: string): Promise<ApiResponse>;
    getProductConfigurations(): Promise<ApiResponse>;
    pauseCampaigns(payload: CampaignPausePayload): Promise<ApiResponse>;
    unpauseCampaigns(payload: GenericObject): Promise<ApiResponse>;
    deleteCampaign(campaignReference: string) : Promise<ApiResponse>;

    executeCrmInteractable(crmId: number, methodName: string, payload: CrmDeliverer): Promise<ApiResponse>;
    getCrmImportOptions(campaignReference: string): Promise<ApiResponse>;

    searchProductAssignments(options: ProductAssignmentSearchOptions): Promise<ApiResponse>;
    rejectProductAssignment(payload: RejectionPayload): Promise<ApiResponse>;
    unrejectProductAssignment(productAssignmentId: number): Promise<ApiResponse>;
    checkRejectionWillExceedQuota(productAssignmentId: number): Promise<ApiResponse>;
    getLeadVolume(params: Object): Promise<ApiResponse>
    getStatisticsByLocation(params: Object): Promise<ApiResponse>
    getZipCodes(params: Object): Promise<ApiResponse>;
    validateTargetedZipCodes(zipCodeArray: number[]): Promise<ApiResponse>;
}

export class BaseApiServiceV4 {

    bearer: string|null = null;
    companyId: string|null = null;
    userId: string|null = null;
    industryKey: string|null = null;
    serviceKey: string = '';
    productKey: ProductType = ProductType.Lead;
    baseUrl: string|null = null;

    setBearer(bearer: string): void {
        this.bearer = bearer;
    }

    setCompanyId(companyId: string): void {
        this.companyId = companyId;
    }

    setUserId(userId: string): void {
        this.userId = userId;
    }

    getIndustryKey(): string|null {
        return this.industryKey;
    }

    setIndustryKey(industryKey: string): void {
        this.industryKey = industryKey?.toLowerCase() ?? null;
    }

    getServiceKey(): string {
        return this.serviceKey;
    }

    setServiceKey(serviceKey: string): void {
        this.serviceKey = `${serviceKey}`?.toLowerCase() ?? null;
    }

    getProductKey(): ProductType {
        return this.productKey;
    }

    setProductKey(productKey: ProductType): void {
        this.productKey = productKey;
    }

    static transformErrorResponse (response: ApiResponse): StatusResponse {
        //@ts-ignore
        return ({ status: false, message: response?.response?.data?.message || response.data?.data?.message || response?.data?.message || 'An unknown error occurred.' });
    }
}