<template>
    <div class="w-full bg-cyan-50 border border-cyan-100 p-4 rounded cursor-pointer" @dragover="dragOver" @drop="drop">
        <div
            class="flex flex-col items-center justify-center w-full text-center relative"
            :class="{'py-6': fileList.length === 0, 'py-2': fileList.length > 2}"
        >
            <label class="block cursor-pointer">
                <input
                    type="file"
                    multiple
                    class="w-px h-px opacity-0 overflow-hidden absolute"
                    ref="fileInput"
                    @change="onChange"
                    :accept="accept"
                >

                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1 text-cyan-500">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                    </svg>

                    <p>
                        <span class="text-cyan-500">Add {{ multi ? "files" : "file" }}</span> or drop files here
                    </p>
                </div>
            </label>

            <ul class="mt-4" v-if="fileList.length > 0">
                <li class="text-sm p-1" v-for="(file, i) in fileList">
                    {{ file.name }} <button @click="remove(i)" class="pl-2 text-red-500" type="button" title="Remove file">x</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";

const props = defineProps({
    accept: {
        type: String,
        default: ".pdf,.jpg,.jpeg,.png"
    },
    multi: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(["file-uploaded"]);

const fileList = ref([]);
const error = ref(null);
const fileInput = ref(null);

const validateMime = type => props.accept.split(',').includes("." + type.split("/").pop())
const remove = i => fileList.value.splice(i, 1);
const dragOver = e => e.preventDefault();
const drop = (e) => {
    e.preventDefault();
    fileInput.value.files = e.dataTransfer.files;
    onChange();
}

const onChange = () => {
    error.value = null;
    fileList.value = !props.multi ? [fileInput.value.files[0]] : [...fileList.value, ...fileInput.value.files];

    for(const file of fileList.value) {
        if(!validateMime(file.type)) {
            error.value = "Please upload a file of type: " + props.accept
        }
    }

    if(error.value === null)
        emit('file-uploaded', fileList.value);
}

const clearFileList = () => {
    fileList.value = [];
}
defineExpose({ clearFileList });

</script>