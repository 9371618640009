<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                </svg>

                <h5 class="text-md">Contacts & Users</h5>

                <SolidButton class="ml-auto text-sm"
                    @click="toggleCompanyUserModal(true)"
                >
                    Add Contact/User
                </SolidButton>
            </div>
        </template>

        <div class="bg-cyan-50 border border-cyan-100 p-4 rounded text-sm">
            You can add as many people (or generic group addresses) as you like, then choose which contacts will receive
            invoices, reviews and outbid notifications on the
            <RouterLink to="/" class="text-cyan-500">notifications</RouterLink> page.
        </div>
        <div class="mt-2 bg-cyan-50 border border-cyan-100 p-4 rounded text-sm">
            <strong>Users</strong> can log in to this Dashboard to make changes.
            <br><strong>Contacts</strong> can receive SMS and Email notifications regarding leads.
        </div>
        <div class="relative min-h-[24rem]">
            <LoadingSpinner v-if="loading || saving || !companyUserStoreInitialized" :small="true" />
            <div v-if="companyUserStoreInitialized && !loading" class="grid grid-cols-1 gap-y-3 mt-4 pt-2">
                <div class="border-b border-gray-200 pb-3 flex last:border-none" v-for="user in companyUsersStore.companyUsers"
                    :key="user.id"
                >
                    <div class="flex w-full flex-col">
                        <div class="flex items-end">
                            <h3 class="text-lg font-light pr-2">{{ user.first_name }} {{user.last_name}}</h3>
                            <div class="flex items-center">
                                <h4 class="text-sm  text-slate-400">({{ user.is_contact  ? `Contact` : `User`}})</h4>
                                <Tooltip>
                                    {{ user.is_contact ? tooltips.contact : tooltips.user }}
                                </Tooltip>
                            </div>
                        </div>

                        <p class="text-gray-500 text-sm font-medium">{{ user.title }}</p>

                        <div class="grid grid-cols-1 md:grid-cols-4 mt-2">
                            <div class="md:col-span-2">
                                <p class="text-gray-500 text-sm">Email</p>
                                <p class="break-words pr-4 pl-2">{{ user.email }}</p>
                            </div>

                            <div class="mt-2 md:mt-0">
                                <p class="text-gray-500 text-sm">Office Phone</p>
                                <p>{{ user.office_phone }}</p>
                            </div>

                            <div class="mt-2 md:mt-0">
                                <p class="text-gray-500 text-sm">Cell</p>
                                <p>{{ user.cell_phone }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="ml-auto relative">
                        <ButtonDropdown class="">
                            <template v-slot:trigger>
                                <div class="cursor-pointer flex justify-center items-center text-cyan-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                    </svg>
                                </div>
                            </template>

                            <div class="transform translate-y-7 bg-white border border-gray-300 shadow rounded absolute top-0 right-0 z-50 transform p-2 w-36">
                                <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                    @click="editCompanyUser(user.id)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                    <p class="ml-2 pb-0 text-cyan-500">Edit</p>
                                </div>
                                <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                    @click="deleteConfirm(user.id)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                    <p class="ml-2 pb-0 text-cyan-500">Delete</p>
                                </div>
                            </div>
                        </ButtonDropdown>
                    </div>
                </div>
            </div>
        </div>
        <!--    Create/Edit User Modal    -->
        <CreateUpdateCompanyUserModal
            v-if="showEditUserModal"
            @hideEditUserModal="toggleCompanyUserModal(false)"
        />
        <!--    Delete confirmation Modal    -->
        <Modal
            v-if="showDeleteConfirmModal"
            @clicked:confirm="deleteCompanyUser"
            @clicked:cancel="deleteCancel"
            confirm-label="Delete"
            :small="true"
        >
            <template v-slot:header>
                Delete this user?
            </template>
            <template v-slot:body>
                Are you sure you wish to delete Company user "{{ companyUsersStore.getCompanyUserNameById(deletingCompanyUserId) }}"?
            </template>
        </Modal>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import ButtonDropdown from "@/components/ButtonDropdown.vue";
import { ref } from "vue";
import { useCompanyUsersStore } from "@/stores/company-users.js";
import Tooltip from "@/components/Tooltip.vue";
import CreateUpdateCompanyUserModal from "@/components/company/modals/CreateUpdateCompanyUserModal.vue";
import Modal from "@/components/Modal.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const props = defineProps({
    companyUserStoreInitialized: {
        type: Boolean,
        default: false
    },
});

const companyUsersStore = useCompanyUsersStore();

const loading = ref(false);
const saving = ref(false);

const showEditUserModal = ref(false);
const showDeleteConfirmModal = ref(false);

const deletingCompanyUserId = ref(null);
const errorMessage = ref(null);

const tooltips = {
    contact: `Contacts can receive SMS and Email notifications regarding leads`,
    user: `Users can log in to this Dashboard to make changes.`
};

const editCompanyUser = (companyUserId) => {
    companyUsersStore.editCompanyUser(companyUserId);
    toggleCompanyUserModal(true);
}

const deleteConfirm = (companyUserId) => {
    deletingCompanyUserId.value = companyUserId;
    showDeleteConfirmModal.value = true;
}
const deleteCancel = () => {
    deletingCompanyUserId.value = null;
    showDeleteConfirmModal.value = false;
}

const deleteCompanyUser = async () => {
    if (saving.value) return;
    saving.value = true;
    const { status, message } = await companyUsersStore.deleteCompanyUser(deletingCompanyUserId.value);
    if (!status) {
        errorMessage.value = message;
    }
    saving.value = false;
    showDeleteConfirmModal.value = false;
}

const toggleCompanyUserModal = (showModal) => {
    showEditUserModal.value = !!showModal;
}


</script>