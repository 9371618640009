<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                </svg>

                <h5 class="text-md">Payment Details</h5>

                <SolidButton @click="addPayment" class="text-sm ml-auto">Add Payment Method</SolidButton>
            </div>
        </template>

        <div class="relative min-h-[12rem]">
            <LoadingSpinner v-if="loading" :small="true" />
            <div v-if="!loading" class="grid grid-cols-1 gap-y-3 mt-4 pt-2">
                <div class="text-gray-500 text-center mb-5" v-if="paymentMethods.length === 0">
                    No Payment Methods
                </div>
                <div class="border-b border-gray-200 pb-4 pt-1 first:pt-0 flex last:border-none" v-for="method in paymentMethods">
                    <div class="w-full grid grid-cols-7 gap-4">
                        <div class="col-span-2 rounded bg-cyan-50 border border-cyan-100 p-2">
                            <div class="grid grid-cols-2 gap-y-2">
                                <div class="text-gray-500">Method:</div>
                                <div class="font-medium">{{ method.method }}</div>

                                <div class="text-gray-500">Brand:</div>
                                <div class="font-medium">{{ method.brand }}</div>

                                <div class="text-gray-500">Ends With:</div>
                                <div class="font-medium">{{ method.finalDigits }}</div>

                                <div class="text-gray-500">Expires:</div>
                                <div class="font-medium">{{ method.expiryDate }}</div>
                            </div>
                        </div>

                        <div class="col-span-5">
                            <div class="flex items-center">
                                <h3 class="text-sm font-bold">Billing Info</h3>
                                <span class="ml-2 text-white bg-cyan-500 rounded-full px-2 text-sm" v-if="method.isDefault">Primary</span>
                            </div>

                            <div class="grid grid-cols-3 gap-4 mt-2 gap-y-2">
                                <div>
                                    <p class="uppercase text-xs text-gray-500">Name</p>
                                    <p class="text-base">{{ method.name }}</p>
                                </div>

                                <div>
                                    <p class="uppercase text-xs text-gray-500">Address</p>
                                    <p class="text-base">{{ method.address }}</p>
                                </div>

                                <div>
                                    <p class="uppercase text-xs text-gray-500">City</p>
                                    <p class="text-base">{{ method.city }}</p>
                                </div>

                                <div>
                                    <p class="uppercase text-xs text-gray-500">State</p>
                                    <p class="text-base">{{ method.state }}</p>
                                </div>

                                <div>
                                    <p class="uppercase text-xs text-gray-500">Zip Code</p>
                                    <p class="text-base">{{ method.zipCode }}</p>
                                </div>

                                <div>
                                    <p class="uppercase text-xs text-gray-500">Country</p>
                                    <p class="text-base">{{ method.country }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="ml-auto relative">
                        <ButtonDropdown class="" v-if="!method.isDefault">
                            <template v-slot:trigger>
                                <div class="cursor-pointer flex justify-center items-center text-cyan-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                    </svg>
                                </div>
                            </template>

                            <div class="transform translate-y-7 bg-white border border-gray-300 shadow rounded absolute top-0 right-0 z-50 p-2 w-36">
                                <div @click="makePaymentMethodPrimary(method.id)" class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500" v-if="!method.isDefault">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                    <p class="ml-2 pb-0 text-cyan-500">Make Primary</p>
                                </div>
                                <div @click="deletePaymentMethod(method.id)" class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500" v-if="!method.isDefault">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                    <p class="ml-2 pb-0 text-cyan-500">Delete</p>
                                </div>
                            </div>
                        </ButtonDropdown>
                    </div>
                </div>
            </div>
        </div>
    </Module>

    <Modal v-if="showAddPayment" @clicked:cancel="closeAddPayment" @clicked:confirm="saveAddPayment" :small="true">
        <template v-slot:header>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                </svg>

                <h5 class="text-md">Add New Payment Method</h5>
            </div>
        </template>

        <template v-slot:body>
            <AddPaymentModule ref="addPaymentModule" @add="completeAddPayment" />
        </template>
    </Modal>
</template>

<script setup>
import Module from "@/components/Module.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import ButtonDropdown from "@/components/ButtonDropdown.vue";
import { computed, ref } from "vue";
import { useServicesStore } from "@/stores/services";
import { useErrorStore } from "@/stores/errors.js";
import Modal from "@/components/Modal.vue";
import AddPaymentModule from "@/components/billing/AddPaymentModule.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const services = useServicesStore();
const errors = useErrorStore();

const loading = ref(false);
const paymentMethods = ref([]);
const showAddPayment = ref(false);
const addPaymentModule = ref(null);


const hasPayments = computed(() => paymentMethods.value.length > 0);

const addPayment = () => showAddPayment.value = true;
const closeAddPayment = () => showAddPayment.value = false;
const saveAddPayment = () => addPaymentModule.value.handleStripeForm();

const completeAddPayment = () => {
    closeAddPayment();
    load();
}

const deletePaymentMethod = async (id) => {
    await services.apiService.deletePaymentMethod(id);
    await load();
}

const makePaymentMethodPrimary = async (id) => {
    await services.apiService.makePaymentMethodPrimary(id);
    await load();
}

const load = async () => {
    loading.value = true;

    await services.apiService.getPaymentMethods().then(resp => {
        paymentMethods.value = resp.data.data.methods.map((item, i) => ({
            id: item.id,
            method: item.method,
            status: item.status,
            brand: item.brand,
            finalDigits: item.last4,
            expiryDate: item.expiry,
            expiryMonth: item.expiry_month,
            expiryYear: item.expiry_year,
            name: item.name,
            address: item.address_1 + ' ' + item.address_2,
            line1: item.address_1,
            line2: item.address_2,
            city: item.city,
            state: item.state,
            zipCode: item.zipcode,
            country: item.country,
            isDefault: i === 0
        }));
    }).catch((e) => {
        errors.report(e, "Error fetching payment methods, please contact support.");
    }).finally(() => loading.value = false);
}

load();
</script>