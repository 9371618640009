import { ApiServiceV3 } from "@/services/api/api.js";
import { DummyApiServiceV4 } from "@/services/api/v4/DummyApiServiceV4";
import { DummyApiServiceV3 } from "@/services/api/dummy.js";
import { ApiServiceV4 } from "@/services/api/v4/ApiServiceV4";


export const makeApiServiceV3 = (config) => {
    switch(config.apiDriver) {
        case "api":
            return new ApiServiceV3(config.apiBaseUrl);
        case "dummy":
        default:
            return new DummyApiServiceV3(config.apiDummyDelay);
    }
}

export const makeApiServiceV4 = (config) => {
    switch(config.apiDriver) {
        case "api":
            return new ApiServiceV4(config.apiBaseUrl);
        case "dummy":
        default:
            return new DummyApiServiceV4(config.apiDummyDelay);
    }
}