import { useServicesStore } from "@/stores/services";
import { ref, toRaw } from "vue";
import { defineStore } from "pinia";
import { useCompanyUsersStore } from "@/stores/company-users.js";


export const useNotificationSettingsStore = defineStore('notification-settings', () => {
    const servicesStore = useServicesStore();
    const companyUsersStore = useCompanyUsersStore();

    const notificationsArray = ref([]);
    const editingNotificationsArray = ref([]);

    // Update the logic for notifiableUsers when alerts are moved over to A2
    const notifiableUsers = ref([]);

    const storeInitialized = ref(false);

    const notificationTypes = [ 'reviews', 'invoices', 'outbid' ];
    const notificationMethods = [ 'sms', 'email' ];

    async function initialize(forceRefresh = false) {
        if (!forceRefresh && storeInitialized.value) return { status: true };
        const [ resp, userStoreReady ] = await Promise.all([
            servicesStore.apiService.getCompanyNotificationSettings().catch(e=>e),
            companyUsersStore.initialize(true),
        ]);
        if (resp.data?.data?.status && userStoreReady.status) {
            notificationsArray.value = resp.data.data.notification_settings;
            filterNotifiableUsers();
            createEditableArray();
            storeInitialized.value = true;
            return { status: true }
        }
        else {
            return { status: false, message: resp.response?.data?.message || resp.err || `An unknown error occurred.`}
        }
    }

    function filterNotifiableUsers() {
        notifiableUsers.value = [];
        companyUsersStore.companyUsers.forEach(user => {
            if (user.legacy_id && user.is_contact && (user.cell_phone || user.email)) notifiableUsers.value.push(user);
        });
    }

    function createEditableArray() {
        editingNotificationsArray.value = [];
        notifiableUsers.value.forEach(user => {
            const userAlerts = getAlertsByLegacyId(user.legacy_id);
            if (!userAlerts) return; // Alerts service is currently on legacy - if a contact is not returned from legacy there's no point displaying them
            const userData = {
                id          : user.id,
                legacy_id   : user.legacy_id,
                name        : userAlerts.name,
                cell_phone  : user.cell_phone,
                email       : user.email,
                alerts      : notificationTypes.reduce((output, type) => {
                    return { ...output, [type]: notificationMethods.reduce((innerOutput, method) => {
                            return { ...innerOutput, [method]: false }
                        }, {})
                    }
                }, {}),
            };
            userAlerts.alerts.forEach(alert => {
                if ([alert.method] in userData.alerts[alert.type]) userData.alerts[alert.type][alert.method] = true;
            });
            editingNotificationsArray.value.push(userData);
        });
    }

    function getAlertsByLegacyId(legacyId) {
        return legacyId > 0
            ? notificationsArray.value.find(user => user.legacy_id === legacyId)
            : null;
    }

    async function set() {
        const resp = await servicesStore.apiService.updateCompanyNotificationSettings(toRaw(editingNotificationsArray.value))
        if (resp.data?.data?.status) {
            return { status: true }
        }
        else {
            return { status: false, message: resp.response?.data?.message || resp.message || resp.err || resp.data?.data?.message || `An unknown error occurred updating contact notifications. `}
        }
    }

    function clearEditing() {
        createEditableArray();
    }

    function clearStore() {
        notificationsArray.value = [];
        notifiableUsers.value = [];
        clearEditing();
        storeInitialized.value = false;
    }

    function $reset() {
        clearStore();
    }

    return {
        initialize,
        editingNotificationsArray,
        notifiableUsers,
        set,
        clearEditing,
        $reset
    }

});