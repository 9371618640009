<template>
    <MainGrid>
        <LeadsToBeInvoiced :stores-initialized="storesInitialized" />
        <Invoices :stores-initialized="storesInitialized" />
    </MainGrid>
</template>

<script setup>
import MainGrid from "@/components/layouts/MainGrid.vue";
import LeadsToBeInvoiced from "@/components/billing/LeadsToBeInvoiced.vue";
import Invoices from "@/components/billing/Invoices.vue";
import { useBillingStore } from "@/stores/billing.js";
import { ref } from "vue";

const storesInitialized = ref(false);

const billingStore = useBillingStore();
billingStore.initialize().then(() => storesInitialized.value = true);


</script>