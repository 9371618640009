import { defineStore } from "pinia";
import { useServicesStore } from "@/stores/services";
import { Component, computed, ComputedRef, Ref, ref } from "vue";
import CampaignLocationsSlide from "@/components/v4/wizard/slides/CampaignLocationsSlide.vue";
import { AxiosResponse } from "axios";
import { ModuleConfiguration, ReservedComponent, WizardComponentMap } from "@/stores/v4/wizard";
import { ApiResponse } from "@/services/api/v4/BaseApiServiceV4";
import CampaignWizardHeader from "@/components/v4/wizard/CampaignWizardHeader.vue";
import CampaignBudgetSlide from "@/components/v4/wizard/slides/CampaignBudgetSlide.vue";
import LeadCampaigns from "@/views/v4/leads/LeadCampaigns.vue";
import CampaignDeliverySlide from "@/components/v4/wizard/slides/CampaignDeliverySlide.vue";
import BiddingModule from "@/components/v4/campaigns/modules/BiddingModule.vue";
import CampaignBiddingSlide from "@/components/v4/wizard/slides/CampaignBiddingSlide.vue";
import LeadOverview from "@/views/v4/leads/LeadOverview.vue";
import AppointmentOverview from "@/views/v4/appointments/AppointmentOverview.vue";
import { useCompanyStore } from "@/stores/company";
import { useNavigationStore } from "@/stores/v4/navigation";
import { CampaignType, useFutureCampaignStore } from "@/stores/v4/future-campaigns";
import DirectLeadsOverview from "@/components/v4/direct-leads/DirectLeadsOverview.vue";
import DirectLeadsCampaigns from "@/components/v4/direct-leads/DirectLeadsCampaigns.vue";

export enum CampaignSlide {
    Location = 'location',
    Budget = 'budget',
    Bidding = 'bidding',
    Delivery = 'delivery',
}

export type ModuleComponentNavigation = {
    id: string,
    component: Component,
    meta: GenericObject,
    enabled: boolean,
    parentRoute?: string,
    name: string,
    image?: string,
    child?: boolean,
    global?: boolean,
};

type ConfigurationCollection = {
    [key in CampaignType]: {
        moduleConfiguration: ModuleConfiguration|null,
        wizardConfiguration: ModuleConfiguration[],
    }
}

const getDefaultConfiguration = () => ({
    moduleConfiguration: null,
    wizardConfiguration: [],
});

export type ModuleComponentNavigationMap = ModuleComponentNavigation[];

export const useCampaignModulesStore = defineStore('modules', () => {
    const servicesStore = useServicesStore();
    const company = useCompanyStore();
    const navigationStore = useNavigationStore();
    const campaignStore = useFutureCampaignStore();

    const initialized: Ref<{ [key in CampaignType]: boolean }> = ref({
        [CampaignType.SOLAR_LEAD_CAMPAIGN]: false,
        [CampaignType.SOLAR_APPOINTMENT_CAMPAIGN]: false,
        [CampaignType.LEAD_CAMPAIGN]: false,
        [CampaignType.APPOINTMENT_CAMPAIGN]: false,
    });

    const configurations: Ref<ConfigurationCollection> = ref({
        [CampaignType.LEAD_CAMPAIGN]: getDefaultConfiguration(),
        [CampaignType.APPOINTMENT_CAMPAIGN]: getDefaultConfiguration(),
        [CampaignType.SOLAR_APPOINTMENT_CAMPAIGN]: getDefaultConfiguration(),
        [CampaignType.SOLAR_LEAD_CAMPAIGN]: getDefaultConfiguration(),
    });

    const moduleConfiguration: ComputedRef<ModuleConfiguration|null> = computed(() => {
        return configurations.value[campaignStore.currentCampaignType].moduleConfiguration;
    });
    const wizardConfiguration: ComputedRef<ModuleConfiguration[]> = computed(() => {
        return configurations.value[campaignStore.currentCampaignType].wizardConfiguration;
    });

    const wizardComponentMap: WizardComponentMap = {
        [ReservedComponent.Header]: CampaignWizardHeader,
        [CampaignSlide.Location]: CampaignLocationsSlide,
        [CampaignSlide.Budget]: CampaignBudgetSlide,
        [CampaignSlide.Bidding]: CampaignBiddingSlide,
        [CampaignSlide.Delivery]: CampaignDeliverySlide,
    }

    /**
     * This object maps NavigationItem components which have been updated to v4
     * These will replace the v3 Views on dashboard load if the Company has future campaigns enabled
     */
    const moduleComponentNavigationMap: ModuleComponentNavigationMap = [
        {
            id: 'leads-campaigns',
            child: false,
            component: LeadCampaigns,
            meta: { requiresAuth: true, requiresIndustryService: true },
            parentRoute: 'leads',
            name: 'Campaigns',
            enabled: true,
        },
        {
            id: 'leads-bidding',
            child: false,
            component: BiddingModule,
            meta: { requiresAuth: true, requiresIndustryService: true },
            parentRoute: 'leads',
            name: 'Bidding ',
            enabled: true,
        },
        {
            id: 'leads',
            child: false,
            component: LeadOverview,
            meta: { requiresAuth: true, requiresIndustryService: true },
            parentRoute: 'leads',
            name: 'Leads',
            enabled: true,
        },
        {
            id: 'appointments',
            child: true,
            component: AppointmentOverview,
            meta: { requiresAuth: true, requiresIndustryService: true },
            parentRoute: 'appointments',
            name: 'Overview',
            enabled: company.appointmentsActive && navigationStore.selectedServiceSupportsAppointments(),
        },
        {
            id: 'direct-leads-campaigns',
            child: true,
            component: DirectLeadsCampaigns,
            meta: { requiresAuth: true, requiresIndustryService: true },
            parentRoute: 'direct-leads',
            name: 'Campaigns',
            enabled: true,
        },
        {
            id: 'direct-leads-bidding',
            child: true,
            component: BiddingModule,
            meta: { requiresAuth: true, requiresIndustryService: true },
            parentRoute: 'direct-leads',
            name: 'Bidding ',
            enabled: true,
        },
        {
            id: 'direct-leads',
            child: true,
            component: DirectLeadsOverview,
            meta: { requiresAuth: true, requiresIndustryService: true },
            parentRoute: 'direct-leads',
            name: 'Overview',
            enabled: true,
        }
    ];

    const initialize = async (): Promise<StatusResponse> => {
        const campaignType = campaignStore.currentCampaignType;
        if (initialized.value[campaignType]) return { status: true };

        return await getWizardConfiguration().catch(e => transformErrorResponse(e));
    }

    const getWizardConfiguration = async (): Promise<StatusResponse> => {
        const resp = await servicesStore.apiServiceV4.getModuleConfigurations().catch((e: AxiosResponse) => e);
        if (resp.data?.data?.status) {
            const campaignType: CampaignType = resp.data.data.campaign_type;

            if (!resp.data.data.modules?.length && !resp.data.data.wizard?.length) {
                return { status: false, message: `The requested configuration contains no Wizard or Module data.` }
            }
            configurations.value[campaignType] = {
                moduleConfiguration: resp.data.data.modules,
                wizardConfiguration: resp.data.data.wizard,
            }

            initialized.value[campaignType] = true;

            return { status: true }
        }
        else {
            return transformErrorResponse(resp);
        }
    }

    const transformErrorResponse = (response: ApiResponse|Error) => {
        return (response instanceof Error)
            ? ({ status: false, message: response.message || 'An unknown error occurred.' })
            : ({ status: false, message: response?.data?.data?.message || 'An unknown error occurred.' });
    }

    return {
        moduleConfiguration,
        wizardConfiguration,
        wizardComponentMap,
        moduleComponentNavigationMap,
        configurations,
        initialized,

        initialize,
        getWizardConfiguration
    }
});