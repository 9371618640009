<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>

                <h5 class="text-md">Addresses</h5>
            </div>
        </template>
        <div class="relative min-h-[8rem]">
            <LoadingSpinner v-if="!storeInitialized" :small="true" />

            <div v-else class="grid grid-cols-1 gap-y-3">
                <div v-for="location in locationStore.locations"
                    class="border-b border-gray-200 pb-3">
                    <strong>{{ location.name }}</strong>
                    <p>{{ location.address.address_1 }}</p>
                    <p v-if="location.address.address_2">{{ location.address.address_2 }}</p>
                    <p>{{ location.address.city }}, {{ location.address.state }} {{ location.address.zip_code }}</p>
                </div>
            </div>

            <div class="mt-3">
                <RouterLink to="/addresses">
                    <SolidButton>
                        Manage Addresses
                    </SolidButton>
                </RouterLink>
            </div>
        </div>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { useLocationsStore } from "@/stores/locations.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const locationStore = useLocationsStore();

const props = defineProps({
    storeInitialized: {
        type: Boolean,
        default: false
    }
});

</script>