<template>
    <div class="relative inline-flex items-center justify-center" :class="containerClasses">
        <input class="h-5 w-5 flex-shrink-0 rounded-full appearance-none bg-white border border-cyan-500 checked:bg-cyan-500 cursor-pointer hover:bg-cyan-100 checked:hover:bg-cyan-500"
               :class="[inputDisabled ? 'grayscale-[80%] pointer-events-none' : '']"
               type="checkbox"
               :value="modelValue"
               :checked="!!modelValue"
               :disabled="inputDisabled"
               @change="handleChange"
        />
        <svg class="absolute w-3 pointer-events-none" viewBox="0 0 41 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M40.1097 0.864402C41.2852 2.02831 41.2983 3.92853 40.139 5.10867L14.1643 31.5478C13.3805 32.3456 12.0948 32.3456 11.311 31.5478L0.861051 20.911C-0.298347 19.7309 -0.285248 17.8306 0.890309 16.6667C2.06587 15.5028 3.95872 15.516 5.11812 16.6961L11.311 22.9997C12.0948 23.7975 13.3805 23.7975 14.1643 22.9997L35.8819 0.893774C37.0413 -0.286358 38.9341 -0.299508 40.1097 0.864402Z" fill="white"/>
        </svg>
    </div>

</template>

<script setup>

    const props = defineProps({
        containerClasses: {
            default: null,
            required: false
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
        value: {
            type: null,
            default: null,
        },
        inputDisabled: {
            type: Boolean,
            default: false,
        },
        inputKey: {
            type: String,
            default: false,
        }
    });

    const emit = defineEmits(["update:modelValue"]);

    /**
     * If a value is supplied, checkbox returns <value> / null
     * Otherwise, returns true / false
     * @param target
     */
    const handleChange = ({ target }) => {
        const result = target.checked
            ? props.value != null
                ? props.value
                : true
            : props.value != null
                ? null
                : false;
        emit('update:modelValue', result, props.inputKey);
    }

</script>
