import { defineStore } from "pinia";
import { ref } from "vue";

export const useBannerStore = defineStore('banner', () => {
    const banner = ref(null);

    const showBanner = (
        {
            id = '',
            title,
            text = '',
            color = 'blue',
            callback = null,
            button_text = ''
        }
    ) => {
        banner.value = {
            id,
            title,
            text,
            color,
            callback,
            button_text,
            show: true
        }
    }

    const hideBanner = (id = '') => {
        if (banner.value?.id === id) banner.value = null;
    }


    return {
        banner,
        showBanner,
        hideBanner,
    };
});
