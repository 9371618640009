<template>
    <div class="flex items-end gap-x-4 py-5 px-5 mx-auto ">
        <div class="md:min-w-[16rem]">
            <label>
                <span>Industries:</span>
                <ButtonDropdown class="relative min-w-[12rem]">
                    <template v-slot:trigger>
                        <div class="flex items-center justify-between border hover:border-cyan-500 border-slate-300 bg-white rounded-md py-2 px-4 h-11">
                            <p>{{ getIndustryDisplay }} Selected</p>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 text-cyan-500">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                    </template>
                    <div class="transform translate-y-7 bg-white border border-gray-300 shadow rounded absolute top-4 right-0 z-50 transform p-2 w-full shadow-lg hover:border-cyan-500">
                        <div v-for="industry in profitabilityFilterStore.availableIndustries"
                            class="flex items-center gap-x-2 pb-1"
                         >
                            <CustomCheckbox
                                v-model="selectedIndustries[industry.id]"
                                @update:modelValue="(selected) => updateFilteredAvailableServices(industry.id, selected)"
                            />
                            <p>{{ industry.name }}</p>
                        </div>
                    </div>
                </ButtonDropdown>
            </label>
        </div>
        <div class="md:min-w-[16rem]">
            <label>
                <span>Services:</span>
                <ButtonDropdown class="relative">
                    <template v-slot:trigger>
                        <div class="flex items-center justify-between border border-slate-300 hover:border-cyan-500 bg-white rounded-md py-2 px-4 h-11">
                            <p>{{ getServiceDisplay }} Selected</p>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 text-cyan-500">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                    </template>
                    <div class="transform translate-y-7 bg-white border border-gray-300 shadow rounded absolute top-4 right-0 z-50 transform p-2 w-full hover:border-cyan-500"
                    >
                        <div v-for="service in filteredAvailableServices"
                             class="flex items-center gap-x-2 pb-1"
                        >
                            <CustomCheckbox v-model="selectedServices[service.id]" />
                            <p @click.prevent="">{{ service.name }}</p>
                        </div>
                    </div>
                </ButtonDropdown>
            </label>
        </div>
        <div>
            <SolidButton
                @click="submitFilters"
                label="Update Filters"
                class="h-11"
            />
        </div>
        <div>
            <OutlineButton @click="resetFilters" class="h-11">
                Reset Filters
            </OutlineButton>
        </div>
    </div>
</template>

<script setup>
import ButtonDropdown from "@/components/ButtonDropdown.vue";
import { computed, onMounted, ref } from "vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import { useProfitabilityFilterStore } from "@/stores/profitability-filters.js";

const profitabilityFilterStore = useProfitabilityFilterStore();

const loading = ref(false);

const filteredAvailableServices = ref([]);

const selectedServices = ref({});
const selectedIndustries = ref({});

onMounted(() => {
    profitabilityFilterStore.initialize();
    selectAll();
});

const getIndustryDisplay = computed(() => {
    const numberSelectedIndustries = Object.values(selectedIndustries.value).filter(v=>v).length,
        totalIndustries = profitabilityFilterStore.availableIndustries.length;
    return numberSelectedIndustries === totalIndustries
        ? 'All'
        : !(numberSelectedIndustries > 0)
            ? 'None'
            : numberSelectedIndustries;
});

const getServiceDisplay = computed(() => {
    const numberSelectedServices = Object.values(selectedServices.value).filter(v=>v).length,
        totalServices = filteredAvailableServices.value.length;
    return numberSelectedServices === totalServices
        ? 'All'
        : !(numberSelectedServices > 0)
            ? 'None'
            : numberSelectedServices;
});

const selectAll = () => {
    profitabilityFilterStore.availableIndustries.forEach(industry => selectedIndustries.value[industry.id] = true);
    updateFilteredAvailableServices();
    filteredAvailableServices.value.forEach(service => selectedServices.value[service.id] = true);
}

const updateFilteredAvailableServices = (industryId, selected) => {
    const activeIndustries = Object.entries(selectedIndustries.value).reduce((output, [industryId, selected]) => {
        return selected ? [...output, industryId] : output;
    }, []);
    filteredAvailableServices.value = activeIndustries.reduce((output, industryId) => {
        return [...output, ...(profitabilityFilterStore.availableServices[industryId] ?? []) ]
    }, []);
    // If an industry was just added, pass the id to the next function so the services can be auto-selected
    const autoSelectIndustry = selected && industryId;
    updateSelectedServices(autoSelectIndustry);
}

// Remove selected services if their industry has been de-selected
const updateSelectedServices = (autoSelectByIndustry) => {
    const activeFilteredServices = filteredAvailableServices.value.map(service => service.id);
    for (const serviceId in selectedServices.value) {
        if (!activeFilteredServices.includes(parseInt(serviceId))) selectedServices.value[serviceId] = false;
    }
    if (autoSelectByIndustry) {
        profitabilityFilterStore.availableServices[autoSelectByIndustry].forEach(service => selectedServices.value[service.id] = true);
    }
}

const submitFilters = () => {
    updateStoreSelectedServices();
}

const resetFilters = () => {
    const numberSelectedServices = Object.values(selectedServices.value).filter(v=>v).length;
    const numberSelectedIndustries = Object.values(selectedIndustries.value).filter(v=>v).length;
    if (profitabilityFilterStore.availableIndustries.length === numberSelectedIndustries && filteredAvailableServices.value.length === numberSelectedServices) {
        return;
    }
    selectAll();
    submitFilters();
}

// Updates store only - components must watch for changes if they need to update anything
const updateStoreSelectedServices = () => {
    const payload = Object.entries(selectedServices.value).reduce((output, [serviceId, selected]) => {
        return selected ? [...output, parseInt(serviceId)] : output;
    }, []);
    profitabilityFilterStore.setSelectedServices(payload);
}

</script>