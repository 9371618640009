<template>
    <div
        :class="[
                ...(styleClasses?.wrapper ?? []),
            ]"
    >
        <div v-for="field in fields">
            <div
                v-if="field.category ==='text'"
                :class="[
                    ...(styleClasses?.default?.wrapper ?? []),
                    ...(styleClasses[field.key]?.wrapper ?? [])
                    ]"
            >
                <span
                    :class="[
                    ...(styleClasses?.default?.name ?? []),
                        ...(styleClasses[field.key]?.name ?? [])
                    ]"
                >
                    {{field.name}}:
                </span>
                <span
                    :class="[
                        ...(styleClasses?.default?.value ?? []),
                        ...(styleClasses[field.key]?.value ?? [])
                    ]"
                >
                    {{field.value}}
                </span>
            </div>
            <div v-if="field.category ==='image'">
                <img
                    @click="handleClickImage(field)"
                    :class="[
                        ...(styleClasses?.default?.value ?? []),
                        ...(styleClasses[field.key]?.value ?? [])
                    ]"
                    :src="field.value"
                    :alt="field.name"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    fields: {
        type: Array,
        default: false,
    },
    styleClasses: {
        type: Object,
        default: {}
    },
})


const emits = defineEmits(['click:field']);

const handleClickImage = (field) => {
    emits('click:field', field);
}
</script>
