<template>
    <div class="flex items-center py-6">
        <!--  Top Level   -->
        <div @click="setNavigationLevel(0)"
             :key="navigationData[0].key"
            :class="[currentNestedIndex > 0 && 'cursor-pointer']"
        >
            {{ navigationData[0].name }}
        </div>
        <!-- Navigation -->
        <div v-for="(navigationLevel, index) in navigationData"
            :key="navigationLevel.key"
        >
            <div v-if="index !== 0">
                <div v-if="currentNestedIndex >= index" class="flex items-center">
                    <svg class="h-4 mx-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    <div
                        :class="[ currentNestedIndex > index ? 'text-slate-800 cursor-pointer' : 'text-cyan-500 cursor-default pointer-events-none' ]"
                        @click="setNavigationLevel(index)"
                    >
                        {{ navigationLevel.name.value ?? navigationLevel.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Ref } from "vue";

interface Props {
    navigationData: { key: string, name: string|Ref<string> }[],
    currentNestedIndex: number,
}
defineProps<Props>();

const emit = defineEmits([
    'nav:click'
]);

const setNavigationLevel = (level: number) => {
    emit('nav:click', level);
}

</script>