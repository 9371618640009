<template>
    <ProductSearch :stores-initialized="storesInitialized" />
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useFutureCampaignStore } from "@/stores/v4/future-campaigns.js";
import { useProductAssignmentStore } from "@/stores/v4/products.js";
import { ProductType, useServicesStore } from "@/stores/services";
import ProductSearch from "@/components/v4/products/ProductSearch.vue";

const campaignStore = useFutureCampaignStore();
const productStore = useProductAssignmentStore();
const services = useServicesStore();
const storesInitialized = ref(false);

onBeforeMount(async () => {
    const productScope = productStore.productScope;

    services.apiServiceV4.setProductKey(ProductType.Lead);
    await Promise.all([
        campaignStore.initialize(productScope !== ProductType.Lead),
        productStore.initialize(),
    ]);
    storesInitialized.value = true;
});

</script>