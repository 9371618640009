<template>
    <div class="relative">
        <div
            class="mt-16 md:mt-20 fixed z-60 inset-y-0 lg:bg-white border-r border-gray-200 lg:overflow-y-auto lg:pt-0 w-full lg:w-64 lg:block hidden">
            <ServiceSelector
                class="hidden lg:block"
                @launchIndustryServiceEditor="launchIndustryServiceEditor"
                @selectIndustryService="selectIndustryService"
                :selecting-industry="selectingIndustry"
            />
            <NavigationItems></NavigationItems>
        </div>
    </div>
</template>

<script setup>
import ServiceSelector from "@/components/navigation/ServiceSelector.vue";
import NavigationItems from "@/components/navigation/NavigationItems.vue";

const emit = defineEmits(['launchIndustryServiceEditor', 'selectService']);

const launchIndustryServiceEditor = () => {
    emit('launchIndustryServiceEditor');
}

const selectIndustryService = () => {
    emit('selectIndustryService');
}

const props = defineProps({
    selectingIndustry: {
        type: Boolean,
        default: false
    }
})
</script>