<template>
    <MainGrid>
        <ContactsModule :company-user-store-initialized="companyUsersStoreInitialized" />
    </MainGrid>
</template>

<script setup>
import MainGrid from "@/components/layouts/MainGrid.vue";
import ContactsModule from "@/components/company/ContactsModule.vue";
import { ref } from "vue";
import { useCompanyUsersStore } from "@/stores/company-users.js";

const companyUsersStore = useCompanyUsersStore();
const companyUsersStoreInitialized = ref(false);
companyUsersStore.initialize().then(() => { companyUsersStoreInitialized.value = true });

</script>