<template>
    <div class="bg-yellow-100 border border-gray-200 rounded">
        <div class="py-5 px-3 md:px-6 flex flex-wrap items-center gap-y-3">
            <h5 class="inline pb-0 mr-3 font-bold text-lg">Account Status</h5>
            <p>
                Your account is currently pending approval. A FIXR account manager has been assigned to review
                your registration. If you believe you're receiving this message by mistake call (844) 442-5029.
            </p>
        </div>
    </div>
</template>

<script setup>
</script>