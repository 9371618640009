<template>
    <Modal
        @clicked:confirm="confirmPause"
        @clicked:cancel="cancelPause"
        :class="[ saving ? 'grayscale-[60%] pointer-events-none' : '' ]"
        confirm-label="Yes"
    >
        <template v-slot:header>
            Pause Campaign
        </template>
        <template v-slot:body>
            <div class="relative">
                <div class="text-center w-[97%]">
                    <p>Pausing {{ campaignName }} will <span class="text-red-700">deactivate all {{ productScope }}</span>. If you wish to proceed, select an option below.</p>
                </div>
                <hr class="my-4">
                <div class="min-h-[24rem]">
                    <div class="grid grid-cols-2 gap-4 w-[97%] mx-auto items-end pb-4">
                        <CustomSelect
                            label="Pause Campaign:"
                            :options="pauseOptions"
                            v-model="pauseType"
                        />
                        <div class="mb-2">
                            <DatePicker
                                v-if="pauseType === 'temp'"
                                v-model="selectedDate"
                                :enable-time-picker="false"
                                auto-apply
                                :close-on-auto-apply="true"
                                model-type="timestamp"
                                :min-date="tomorrow"
                            />
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4 mx-auto w-[97%] items-start pb-2">
                        <CustomSelect
                            label="Reason for pausing Campaign:"
                            :options="reasonsOptions"
                            v-model="selectedReason"
                            :selected-option=null
                        />
                        <textarea
                            class="border-slate-300 border rounded-md p-2"
                            v-if="selectedReason === 'other'"
                            placeholder="Please enter a reason..."
                            v-model="otherReason"
                        />
                    </div>
                </div>
                <div v-if="errorMessage" class="pb-2 text-center">
                    <p class="text-red-900 test-sm whitespace-pre">{{ errorMessage }}</p>
                </div>
                <LoadingSpinner v-if="saving" class="absolute" :small="true" />
            </div>
        </template>
    </Modal>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { ref } from "vue";
import { useCampaignStore } from "@/stores/campaigns.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const props = defineProps({
    campaignName: {
        type: String,
        default: "unknown campaign"
    },
    productScope: {
        type: String,
        default: "leads",
    },
    saving: {
        type: Boolean,
        default: null,
    },
});

const campaignStore = useCampaignStore();

const emit = defineEmits('confirmPause', 'cancelPause');

const pauseOptions = [
    { label: 'Temporarily until start of...', value: 'temp' },
    { label: 'Permanently', value: 'perm' },
];
const reasonsOptions = [
    { label: 'Select a reason', value: null },
    ...Object.entries(campaignStore.campaignConfigData.pause_reasons ?? {}).map(([value, label]) => ({ label, value })),
];

const tomorrow = Date.now() + (1000*3600*24);

const selectedReason = ref(null);
const selectedDate = ref(tomorrow);
const pauseType = ref('temp');
const otherReason = ref(null);

const errorMessage = ref(null);

const validate = () => {
    const errors = [];
    if (pauseType.value === 'temp' && !selectedDate.value) {
        errors.push('Please select a date.');
    }
    if (!selectedReason.value) {
        errors.push('Please select a reason for pausing your Campaign.');
    }
    if (selectedReason.value === 'other' && !otherReason.value) {
        errors.push('Please type a reason for pausing your Campaign.');
    }

    if (errors.length) {
        errorMessage.value = errors.join("\n");
        return false;
    }

    return true;
}
const confirmPause = () => {
    errorMessage.value = null;
    if (!validate()) return;

    const payload = {
        reactivate_at: (pauseType.value === 'temp') ? selectedDate.value/1000 : null,
        reason: selectedReason.value,
        other: otherReason.value,
    }
    emit('confirmPause', payload);
}

const cancelPause = () => {
    emit('cancelPause');
}

</script>