import { defineStore } from "pinia";
import { useCompanyStore } from "@/stores/company.js";
import { ref } from "vue";
import { useKebabCase } from "@/composables/useKebabCase.js";

export const useProfitabilityFilterStore = defineStore('profitabilityFilters', () => {
    const companyStore = useCompanyStore();

    const availableIndustries = ref([]);
    const availableServices = ref({});

    const selectedProfitabilityServices = ref([]);

    const storeInitialized = ref(false);

    const servicesSelectedDisplay = ref('All');

    function initialize() {
        if (storeInitialized.value) return;
        availableIndustries.value = companyStore.industries.map(industry => ({ name: industry, id: useKebabCase(industry) }));
        availableServices.value = companyStore.services;
        selectedProfitabilityServices.value = getAllServiceIds();
    }

    // Updates variable only - components must watch for changes if they need to update anything
    function setSelectedServices(serviceIdArray) {
        if (!Array.isArray(serviceIdArray)) return;
        selectedProfitabilityServices.value = serviceIdArray;
        servicesSelectedDisplay.value = getAllServiceIds().length === selectedProfitabilityServices.value.length ? 'All' : 'Selected';
    }

    function getAllServiceIds() {
        return Object.values(companyStore.services).reduce((output, industry) => {
            return [ ...output, ...industry.map(service => service.id) ];
        }, []);
    }

    function clearStore() {
        availableIndustries.value = [];
        availableServices.value = [];
        selectedProfitabilityServices.value = [];
        storeInitialized.value = false;
    }

    function $reset() {
        clearStore();
    }

    return {
        initialize,
        setSelectedServices,
        availableServices,
        availableIndustries,
        selectedProfitabilityServices,
        servicesSelectedDisplay,
        $reset,
    }

});