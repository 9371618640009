import { defineStore } from "pinia";
import { useServicesStore } from "@/stores/services";
import { reactive } from "vue";
import { useCampaignStore } from "@/stores/campaigns.js";


export const usePricingStore = defineStore('pricing', () => {
   const servicesStore = useServicesStore();
   const campaignStore = useCampaignStore();

   // Storage of price ranges for campaign budget display
   const priceRanges = reactive({
       leads: {},
       appointments: {},
       'direct leads': {}
   });

   // Storage of bidding and floor prices for Bidding Control
   const prices = reactive({
       leads: {},
       appointments: {},
       'direct leads': {}
   });

   const transformProductNameForRequest = (productName) => {
       if (/direct leads/i.test(productName)) {
           return 'direct leads'
       }

       if (/appointment/i.test(productName)) {
           return 'appointment'
       }

       if (/lead/i.test(productName)) {
           return 'lead'
       }

       console.error(`Invalid product: ${productName}`);
       return null;
   }

    /**
     * Fetch min/max price ranges for campaign budget display
     */
   async function getPriceRangeForCampaign(campaignUuid, productName) {
       if (priceRanges[productName]?.[campaignUuid]) return { status: true }
       const productParam = /lead/i.test(productName) ? 'lead' : 'appointment';
       const resp = await servicesStore.apiService.getPriceRangeForCampaign(campaignUuid, productParam);
       if (resp.data?.data?.status) {
           priceRanges[productName][campaignUuid] = resp.data.data.prices;
           return { status: true }
       }
       else {
           return genericErrorMessage(resp);
       }
   }

    /**
     * Get min/max prices ranges by zipcodes
     */
   async function getPriceRangeForZipCodes(zipCodeIds, productName) {
       const resp = await servicesStore.apiService.getPriceRangeForZipCodes(zipCodeIds, transformProductNameForRequest(productName));
       if (resp.data?.data?.status) {
           priceRanges[productName].new = resp.data.data.prices;
           return { status: true }
       }
       else {
           return genericErrorMessage(resp);
       }
   }

    /**
     * Remove stored data if the Campaign's locations are changed
     */
   function removePriceRangeForCampaign(campaignUuid, productName) {
       if (priceRanges[productName]?.[campaignUuid]) {
           delete priceRanges[productName][campaignUuid];
       }
   }

   async function getPricingForLocation(productName, campaignUuid, stateKey, countyKey, productCategory) {
       const countyStorageKey = countyKey || 'state';
       const categoryKey = campaignStore.getPropertyTypeNameById(productName, productCategory);
       if (prices[productName]?.[categoryKey]?.[stateKey]?.[countyStorageKey]) return { status: true }
       const dateNow = new Date();

       const resp = await servicesStore.apiService.getPricesForLocation(campaignUuid, {
           campaign_uuid: campaignUuid,
           state_abbr: stateKey,
           county_key: countyKey || null,
           property_type_id: productCategory,
           grouping: 'location',
           start_timestamp: (dateNow.setMonth(dateNow.getMonth() - 1))/1000,
           end_timestamp: dateNow/1000
       }).catch(e => e);

       if (resp.data?.data?.status) {
           prices[productName][categoryKey] = prices[productName][categoryKey] ?? {};
           prices[productName][categoryKey][stateKey] = prices[productName][categoryKey][stateKey] ?? {};
           Object.assign(prices[productName][categoryKey][stateKey], {
               [countyStorageKey]: transformLeadsPricingObject(resp.data.data.prices),
           });
           return { status: true }
       }
       else {
           return genericErrorMessage(resp);
       }
   }

   async function updateBidPrice(productName, campaignUuid, stateKey, countyKey, qualityTier, productCategory, salesTypeKey, newPrice) {
       const qualityTierId = campaignStore.campaignConfigData[`${productName}_quality_tiers`]?.find(tier => tier.label === qualityTier)?.value;
       const saleTypeId = campaignStore.campaignConfigData[`${productName}_sales_types`]?.find(salesType => salesType.key === salesTypeKey)?.id;
       const countyStorageKey = countyKey || 'state';
       const categoryKey = campaignStore.getPropertyTypeNameById(productName, productCategory);
       const payload = {
           campaign_uuid: campaignUuid,
           state_abbr: stateKey,
           county_key: countyKey || null,
           property_type_id: productCategory,
           quality_tier_id: qualityTierId,
           sale_type_id: saleTypeId,
           price: newPrice,
       };
       const resp = await servicesStore.apiService.updateBidPriceForLocation(campaignUuid, payload).catch(e=>e);
       if (resp.data?.data?.status) {
           Object.assign(prices[productName][categoryKey][stateKey][countyStorageKey][qualityTier][salesTypeKey], {
               bid_price: newPrice,
           });
           if (countyStorageKey === 'state') {
               for (const county in prices[productName][categoryKey][stateKey]) {
                   if (county !== 'state') delete prices[productName][categoryKey][stateKey][county];
               }
           }
           return { status: true }
       }
       else {
           return genericErrorMessage(resp);
       }
   }

   function transformLeadsPricingObject(responseData) {
       const salesTypes = campaignStore.campaignConfigData?.leads_sales_types;
       const output = {};
       for (const qualityTier in responseData) {
        output[qualityTier] = {};
        responseData[qualityTier].forEach(saleTypeGroup => {
            const saleTypeKey = salesTypes.find(type => type.id === saleTypeGroup.sale_type_id)?.key ?? 'unknown';
            output[qualityTier][saleTypeKey] = {
                floor_price: saleTypeGroup.floor_price,
                bid_price: saleTypeGroup.bid_price || saleTypeGroup.floor_price,
            }
        });
       }
       return output;
   }

   function getFetchedPriceRangesForProductV4() {
       let product = servicesStore.apiServiceV4.getProductKey();
       product = /s$/.test(product) ? product : `${product}s`;

       return priceRanges[product].new;
   }

   function genericErrorMessage(resp) {
       return { status: false, message: resp.data?.data?.message || resp.response?.data?.message || resp.err || `An unknown error occurred fetching data.` };
   }

   function clearStore() {
       Object.assign(prices, {
           leads: {},
           appointments: {},
       });
       Object.assign(priceRanges, {
           leads: {},
           appointments: {},
       });
   }

   function $reset() {
      clearStore();
   }

   return {
       priceRanges,
       prices,
       getPriceRangeForCampaign,
       getPriceRangeForZipCodes,
       removePriceRangeForCampaign,
       getPricingForLocation,
       getFetchedPriceRangesForProductV4,
       updateBidPrice,
       $reset,
   }

});