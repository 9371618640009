<template>
    <Modal
        @clicked:confirm="saveCompanyUser"
        @clicked:cancel="toggleConfirmModal(true)"
    >
        <template v-slot:header>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>

                <h5 class="text-md">{{ newUser ? 'Add' : 'Edit'}} Company User</h5>
            </div>
        </template>
        <template v-slot:body>
            <div class="relative min-h-[16rem]">
                <LoadingSpinner v-if="saving" :small="true" />
                <div v-else class="grid xl:grid-cols-3 gap-x-6 gap-y-6 grid-flow-row-dense">
                    <div class="flex items-center xl:col-span-3">
                        <CustomSelect
                            :dropdown-disabled="deliveriesSlide"
                            label="*User Type"
                            :options="userTypeOptions"
                            v-model="companyUsersStore.editingUser.user_type"
                        />
                        <Tooltip v-if="companyUsersStore.editingUser.user_type !== ''"
                            class="pt-4"
                            :large="true"
                        >
                            {{ tooltips[companyUsersStore.editingUser.user_type] ?? '' }}
                        </Tooltip>
                    </div>
                    <CustomInput
                        label="Title"
                        placeholder="Mrs..."
                        v-model="companyUsersStore.editingUser.title"
                    />
                    <CustomInput
                        placeholder="Jane..."
                        label="*First Name"
                        v-model="companyUsersStore.editingUser.first_name"
                    />
                    <CustomInput
                        placeholder="Doe..."
                        label="Last Name"
                        v-model="companyUsersStore.editingUser.last_name"
                    />
                    <div v-if="companyUsersStore.editingUser.user_type === 'user'"
                        class="col-span-3"
                    >
                        <p v-if="!newUser" class="pb-4 text-cyan-600">
                            Leave password fields blank unless you wish to change this User's password.
                        </p>
                        <PasswordInput
                            :new-password="true"
                            placeholder="password..."
                            v-model:password-value="companyUsersStore.editingUser.password"
                            v-model:confirm-password-value="companyUsersStore.editingUser.password_confirm"
                        />
                    </div>
                    <CustomInput
                        class="xl:col-span-3"
                        :label="companyUsersStore.editingUser.user_type === 'contact' ? 'Email' : '*Email'"
                        placeholder="companyuser@example.com..."
                        v-model="companyUsersStore.editingUser.email"
                    />
                    <CustomInput
                        placeholder="555 555 5555..."
                        label="Office Phone"
                        v-model="companyUsersStore.editingUser.office_phone"
                    />
                    <CustomInput
                        placeholder="555 555 5555..."
                        label="Cell Phone"
                        v-model="companyUsersStore.editingUser.cell_phone"
                    />
                </div>
                <div v-if="errorMessage"
                     class="text-red-900 text-sm pt-8 pb-4 text-center"
                >
                    {{ errorMessage }}
                </div>
            </div>
        </template>
    </Modal>
    <Modal v-if="showConfirmModal"
           @clicked:confirm="closeEditUserModal"
           @clicked:cancel="toggleConfirmModal(false)"
           confirm-label="Confirm"
           :small="true"
    >
        <template v-slot:header>
            Cancel Edit User
        </template>
        <template v-slot:body>
            Are you sure you wish to cancel updating this User?
        </template>
    </Modal>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import { ref } from "vue";
import CustomInput from "@/components/inputs/CustomInput.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { useProperCase } from "@/composables/useProperCase.js";
import { useCompanyUsersStore } from "@/stores/company-users.js";
import { useErrorStore } from "@/stores/errors.js";
import Tooltip from "@/components/Tooltip.vue";
import PasswordInput from "@/components/navigation/PasswordInput.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const props = defineProps({
    deliveriesSlide: {
        type: Boolean,
        default: false,
    },
});


const companyUsersStore = useCompanyUsersStore();
const errors = useErrorStore();

const saving = ref(false);
const showConfirmModal = ref(false);
const errorMessage = ref(null);
const newUser = ref(!companyUsersStore.editingUser.id);

const requiredFields = {
    contact: [ 'first_name' ],
    contactMethods: [ 'email', 'office_phone', 'cell_phone' ],
    user: [ 'first_name','email' ],
    password: [ 'password', 'password_confirm' ]
};

const emit = defineEmits([ 'hideEditUserModal' ]);

const tooltips = {
    contact: `Contacts can receive notifications.`,
    user: `Users can log in to this Dashboard to make changes.`
};

const userTypeOptions = [
    { label: 'Select Type', value: '' },
    { label: 'User', value: 'user' },
    { label: 'Contact', value: 'contact' }
]

const validateInput = () => {
    const missingFields = [];
    if (!companyUsersStore.editingUser.user_type) {
        errorMessage.value = `The User type must be selected.`;
        return false;
    }
    const targetFields = [ ...requiredFields[companyUsersStore.editingUser.user_type] ];
    if (companyUsersStore.editingUser.user_type === 'user' && (newUser.value || companyUsersStore.editingUser.password)) {
        targetFields.push(...requiredFields.password);
    }
    targetFields.forEach(fieldName => {
        if (!companyUsersStore.editingUser[fieldName]) missingFields.push(useProperCase(fieldName));
    });
    const contactFieldsFilled = requiredFields.contactMethods.reduce((output, fieldName) => companyUsersStore.editingUser[fieldName] ? output + 1 : output, 0);
    if (missingFields.length) {
        errorMessage.value = `The following fields are required: ${missingFields.join(', ')}.`;
        return false;
    }
    if (contactFieldsFilled < 1) {
        errorMessage.value = `One of the following fields must be filled: ${requiredFields.contactMethods.map(v => useProperCase(v)).join(', ')}.`;
        return false;
    }
    return true;
}

const saveCompanyUser = async () => {
    if (saving.value) return;

    errorMessage.value = null;
    if (!validateInput()) return;
    saving.value = true;

    try {
        const saveSynchronously = !!props.deliveriesSlide;
        const { status, message } = await companyUsersStore.saveCompanyUser(saveSynchronously);

        if (!status) {
            errors.report(message);
            errorMessage.value = message;
        }
        else {
            closeEditUserModal();
        }
    } catch (e) {
        // e.message is displayed to the user here and is appropriate as 
        // server-side validation messages will be returned.
        errors.report(e); 
    }

    saving.value = false;
}

const toggleConfirmModal = (showModal) => {
    showConfirmModal.value = !!showModal;
}

const closeEditUserModal = () => {
    const user = {...companyUsersStore.editingUser};
    companyUsersStore.clearEditing();
    toggleConfirmModal(false);
    emit('hideEditUserModal', user);
}

</script>