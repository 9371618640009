<template>
    <MainGrid>
        <CompanyLogoModule />
        <CompanyDescriptionModule />
        <CompanyStartedModule />
    </MainGrid>
</template>

<script setup>
import MainGrid from "@/components/layouts/MainGrid.vue";
import {useCompanyStore} from "@/stores/company";
import CompanyLogoModule from "@/components/company/CompanyLogoModule.vue";
import CompanyDescriptionModule from "@/components/company/CompanyDescriptionModule.vue";
import CompanyStartedModule from "@/components/company/CompanyStartedModule.vue";

const company = useCompanyStore();
</script>