<template>
    <div>
        <Modal
            v-if="modalActive"
            @clicked:confirm="modalActive = false"
            :showCancelButton="false"
            confirm-label="Dismiss"
            :small="true"
        >
            <template v-slot:header>
                <div class="flex items-center">
                    <h5 class="text-md">An error has occurred</h5>
                </div>
            </template>
            <template v-slot:body>
                <p>
                    {{errorMessage}}
                </p>
            </template>
        </Modal>
    </div>
</template>

<script setup>
import { useErrorStore } from "@/stores/errors.js";
import { watch, ref } from "vue";
import Modal from "@/components/Modal.vue";

const errors = useErrorStore();
const modalActive = ref(false);
const errorMessage = ref('');

/**
 * Watch for any changes to the errorsStore 'errors' array and display the most recent one. 
 */
watch(errors.errors, () => {
    let latestError = errors.errors[errors.errors.length - 1];    
    modalActive.value = true;
    errorMessage.value = latestError?.customOutputMessage ?? latestError?.message ?? latestError?.response ?? latestError;
});
</script>