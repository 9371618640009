/**
 * Creates initials for the given name.
 *
 * @param name
 * @return string
 */
export const useInitials = (name) => {
    const nameList = name.split(' ');
    return `${nameList[0].charAt(0)}${
        nameList[1]
            ? nameList[1].charAt(0)
            : ''
    }`;
}