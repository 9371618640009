<template>
    <Transition name="fade" mode="in-out">
        <div v-if="alertStore.alertActive"
             class="absolute left-[50%] translate-x-[-50%] top-12 w-[46rem] p-4 border rounded backdrop-blur-sm bg-opacity-70"
             :class="[containerTheme]"
        >
            <div class="flex flex-col gap-4 items-center justify-between">
                <p class="text-center leading-5 whitespace-pre-line">
                    {{ alertStore.alertMessage }}
                </p>
                <div class="cursor-pointer"
                     :class="[buttonTheme]"
                     @click="alertStore.closeAlert"
                >
                    <svg class="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
            <!--  Make sure required classes aren't tree-shaken. Can be safe-listed instead when styles are final  -->
            <div class="hidden"
                 :class="getAllTailwindClasses()"
            />
        </div>
    </Transition>
</template>

<script setup>
import {AlertType, useAlertStore } from "@/stores/v4/alerts";
import {ref, watch} from "vue";

const alertStore = useAlertStore();

const alertThemes = {
    container: {
        [AlertType.Log]   : "bg-slate-50 border-slate-600 text-slate-600",
        [AlertType.Info]    : "bg-green-50 border-green-600 text-green-700",
        [AlertType.Error]   : "bg-red-50 border-red-700 text-red-800"
    },
    button: {
        [AlertType.Log]   : "hover:text-slate-400",
        [AlertType.Info]    : "hover:text-green-600",
        [AlertType.Error]   : "hover:text-red-600"
    }
}

const containerTheme = ref(alertThemes.container[AlertType.Log]);
const buttonTheme = ref(alertThemes.button[AlertType.Log]);

const getAllTailwindClasses = () => {
    return Object.values(alertThemes).reduce((finalOutput, element) => {
        return finalOutput +
            Object.values(element).reduce((classOutput, classes) => {
                return [...classOutput, classes];
            }, []).join(' ');

    }, "");
}

watch(() => alertStore.alertType, (newVal) => {
    containerTheme.value = alertThemes.container[newVal];
    buttonTheme.value = alertThemes.button[newVal];
});

</script>