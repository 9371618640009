<template>
    <div class="">
        <div class="mb-2 text-sm text-gray-500">
            <p>Showing {{ offset + 1 }} to {{ (offset + limit) > total ? total : offset + limit }} of {{ total }} results</p>
        </div>

        <div>
            <slot />
        </div>

        <div class="grid grid-cols-2 gap-2 mt-4 items-center">
            <div>
                <slot name="summary" />
            </div>

            <div v-if="showPagination">
                <nav class="isolate flex justify-end -space-x-px rounded-md" aria-label="Pagination">
                    <a href="#" @click="handlePageChange(currentPage - 1)" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                        </svg>
                    </a>

                    <a href="#" @click="handlePageChange(1)" v-if="showFirstPage" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">1</a>
                    <span v-if="showLeftMoreButton" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>

                    <a
                        href="#"
                        v-for="page in pages"
                        @click="handlePageChange(page)"
                        class="relative inline-flex items-center px-4 py-2 text-sm font-semibold"
                        :class="{'text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0': page !== currentPage, 'z-10 bg-cyan-500 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-500': page === currentPage}"
                    >
                        {{ page }}
                    </a>

                    <span v-if="showRightMoreButton" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
                    <a href="#" @click="handlePageChange(totalPages)" v-if="showLastPage" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">{{ totalPages }}</a>

                    <a href="#" @click="handlePageChange(currentPage + 1)" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                        </svg>
                    </a>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    offset: Number,
    limit: Number,
    total: Number,
    pageLimit: {
        type: Number,
        default: 3
    }
});

const emit = defineEmits(["change-page"]);

const hangingOffset = computed(() => props.offset > 0 && (props.offset / props.limit % 0 > 0))
const totalPages = computed(() => Math.ceil(props.total / props.limit));
const maxHangingPages = computed(() => Math.floor(props.pageLimit / 2));
const currentPage = computed(() => props.offset / props.limit + 1);
const firstPages = computed(() => currentPage.value - maxHangingPages.value - 1 <= 1);
const lastPages = computed(() => currentPage.value >= totalPages.value - maxHangingPages.value - 1);
const showLeftMoreButton = computed(() => !firstPages.value);
const showFirstPage = computed(() => !firstPages.value);
const showRightMoreButton = computed(() => !lastPages.value);
const showLastPage = computed(() => !lastPages.value);
const showPagination = computed(() => props.pageLimit >= 3 && totalPages.value > 1);

const pages = computed(() => {
    let minPage, maxPage;

    if(totalPages.value <= props.pageLimit) {
        minPage = 1;
        maxPage = totalPages.value;
    } else if(firstPages.value) {
        minPage = 1;
        maxPage = props.pageLimit + 1;
    } else if(lastPages.value) {
        minPage = totalPages.value - props.pageLimit;
        maxPage = totalPages.value;
    } else {
        minPage = currentPage.value - maxHangingPages.value;
        maxPage = currentPage.value + maxHangingPages.value;
    }

    const pages = [];
    for(let i = minPage; i <= maxPage; i++)
        pages.push(i);

    return pages;
});

const handlePageChange = (number) => {
    if(number !== currentPage.value && number > 0 && number <= totalPages.value)
        emit('change-page', {page: number, offset: (number - 1) * props.limit});
}
</script>