import { BaseApiServiceV4, ApiServiceV4Contract, ApiResponse } from "@/services/api/v4/BaseApiServiceV4";
import { CrmDeliverer } from "@/stores/v4/delivery";
import { CampaignPausePayload } from "@/stores/v4/future-campaigns";
import { ProductAssignmentSearchOptions, RejectionPayload } from "@/stores/v4/products";

export type DummyResponse = {
    data: { [key: string]: any },
    status: number;
    statusText: string,
    headers: [],
    request: { [key: string]: any },
}

export class DummyApiServiceV4 extends BaseApiServiceV4 implements ApiServiceV4Contract {

    delay: number = 250;

    constructor(delay?: number) {
        super();
        this.delay = delay ?? this.delay;
    }

    _debounce(data: any): Promise<DummyResponse> {
        return new Promise((resolve) => {
            setTimeout(() => resolve({
                data: { data },
                status: 200,
                statusText: 'OK',
                headers: [],
                request: {},
            }), this.delay);
        });
    }

    getModuleConfigurations(): Promise<DummyResponse> {
        return this._debounce([]);
    }

    getCrmConfigurations(): Promise<DummyResponse> {
        return this._debounce([]);
    }

    saveCrmConfiguration(_payload: CrmDeliverer): Promise<DummyResponse> {
        return this._debounce({});
    }

    saveNewCampaign(_payload: GenericObject): Promise<ApiResponse> {
        return this._debounce({ status: false });
    }

    getCountyFloorPrices(_stateLocationKey: string, _countyLocationKey: string, _zipCodeTargeted: boolean): Promise<ApiResponse> {
        return this._debounce([]);
    }

    getStateFloorPrices(_stateLocationKey: string, _zipCodeTargeted: boolean): Promise<ApiResponse> {
        return this._debounce([]);
    }

    getCampaigns(_allProducts = false): Promise<ApiResponse> {
        return this._debounce([]);
    }

    getCampaignDetail(_campaignReference: string) {
        return this._debounce({});
    }

    updateCampaign(_payload: GenericObject, _campaignReference: string) {
        return this._debounce({});
    }


    getStateBidPrices(_stateLocationKey: string, _campaignReference: string): Promise<ApiResponse> {
        return this._debounce({});
    }

    getCountyBidPrices(_stateLocationKey: string, _countyLocationKey: string, _campaignReference: string, _propertyType: string): Promise<ApiResponse> {
        return this._debounce({})
    }

    getPriceRangeForZipCodes(_zipCodes: number[], _propertyTypes: string[]) {
        return this._debounce({});
    }

    getProductConfigurations(): Promise<ApiResponse> {
        return this._debounce({});
    }

    pauseCampaigns(_payload: CampaignPausePayload): Promise<ApiResponse> {
        return this._debounce({});
    }

    unpauseCampaigns(_payload: GenericObject): Promise<ApiResponse> {
        return this._debounce({});
    }

    deleteCampaign(_campaignReference: string): Promise<ApiResponse> {
        return this._debounce({});
    }

    executeCrmInteractable(_crmId: number, _methodName: string, _payload: CrmDeliverer): Promise<ApiResponse> {
        return this._debounce({});
    }

    getCrmImportOptions(_campaignReference: string): Promise<ApiResponse> {
        return this._debounce([]);
    }

    searchProductAssignments(_options: ProductAssignmentSearchOptions): Promise<ApiResponse> {
        return this._debounce({});
    }

    rejectProductAssignment(_payload: RejectionPayload): Promise<ApiResponse> {
        return this._debounce({});
    }

    unrejectProductAssignment(_productAssignmentId: number): Promise<ApiResponse> {
        return this._debounce({});
    }

    checkRejectionWillExceedQuota(_productAssignmentId: number): Promise<ApiResponse> {
        return this._debounce({});
    }

    getLeadVolume(_params: Object): Promise<ApiResponse> {
        return this._debounce([]);
    }

    getStatisticsByLocation(_params: Object): Promise<ApiResponse> {
        return this._debounce([]);
    }

    getZipCodes(_params: Object): Promise<ApiResponse> {
        return this._debounce([]);
    }

    validateTargetedZipCodes(_zipCodeArray: number[]) {
        return this._debounce({});
    }
}