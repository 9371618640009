<template>
    <div>
        <Modal
            @clicked:confirm="submitRejection"
            @clicked:cancel="cancelModal"
            :disable-buttons="saving"
            confirm-label="Ok"
        >
            <template v-slot:header>
                <div class="flex items-center">
                    <h5 class="text-md">Do you want to reject this lead?</h5>
                </div>
            </template>
            <template v-slot:body>
                <div v-if="loading">
                    <p class="text-gray-500 text-center">Loading...</p>
                </div>
                <div v-else>
                    <div v-if="rejectionLoading">
                        <LoadingSpinner :small="true" />
                        <p class="text-gray-500 text-center">Submitting Rejection...</p>
                    </div>
                    <div class="mb-4 border-b border-slate-300 px-6">
                        <div class="grid xl:grid-cols-3 items-center gap-4 gap-y-8 pb-6">
                            <div>
                                <p>Lead ID: {{ product.id }}</p>
                            </div>
                            <div class="xl:col-span-2">
                                <p>Date Delivered: {{ useFormatTimestamp(product.date_sent) }}</p>
                            </div>
                            <div>
                                <div class="flex items-center mb-1 text-slate-500">
                                    <svg  class="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                    </svg>
                                    <p class="ml-2">Name</p>
                                </div>
                                <p class="ml-2">{{ product.name }}</p>
                            </div>
                            <div class="xl:col-span-2">
                                <div class="flex items-center mb-1 text-slate-500">
                                    <svg  class="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                    </svg>
                                    <p class="ml-2">Address</p>
                                </div>
                                <p class="ml-2">{{ product.address }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col gap-y-6 px-4 py-4">
                        <CustomSelect
                            :options="rejectionReasonOptions"
                            v-model="selectedRejection"
                            label="Reason to reject:"
                        />
                        <div>
                            <p class="block mb-1 text-sm font-medium">Notes:</p>
                            <textarea
                                class="w-full border border-slate-300 rounded-lg resize-none h-[8rem] py-2 px-3 overflow-y-auto"
                                v-model="rejectionDescription"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="warningMessage" class="py-4">
                    <p class="text-red-900 text-sm text-center">{{ warningMessage }}</p>
                </div>
            </template>

        </Modal>
        <Modal
            v-if="exceedingRejectionQuota"
            :show-cancel-button="false"
            confirm-label="Ok"
            :small="true"
            @clicked:confirm="exceedingRejectionQuota = false"
        >
            <template v-slot:header>
                About to exceed rejection threshold.
            </template>
            <template v-slot:body>
                <p class="text-red-700 text-center">{{ warningMessage }}</p>
            </template>
        </Modal>
    </div>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import { useFormatTimestamp } from "@/composables/useFormatTimestamp.js";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { computed, ComputedRef, onMounted, Ref, ref } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { ProductAssignment, RejectionPayload, useProductAssignmentStore } from "@/stores/v4/products";
import { useProductConfigurationStore } from "@/stores/v4/product-configuration";
import { useAlertStore } from "@/stores/v4/alerts";

interface Props {
    product: ProductAssignment
}
const props = defineProps<Props>();

const emit = defineEmits(['close:modal']);

const productStore = useProductAssignmentStore();
const productConfiguration = useProductConfigurationStore();
const alertStore = useAlertStore();

const saving = ref(false);
const loading = ref(true);
const rejectionLoading = ref(false);

const selectedRejection: Ref<string|null> = ref(null);
const rejectionDescription = ref('');

const warningMessage: Ref<string|null> = ref(null);
const exceedingRejectionQuota = ref(false);

const rejectionReasonOptions: ComputedRef<CustomSelectOption[]> = computed(() => [
    { label: '-', value: null },
    ...Object.entries(productConfiguration.configuration.rejection_reasons).map(([key, value]) => ({ label: value, value: key })),
]);

onMounted(async () => {
    loading.value = true;
    const { status, message, data } = await productStore.checkRejectionWillExceedQuota(props.product.id);
    if (!status) {
        alertStore.showError(message ?? "An error has occurred.");
    }
    else {
        if (data?.message) {
            exceedingRejectionQuota.value = true;
            warningMessage.value = data.message;
        }
    }

    loading.value = false;
});

const submitRejection = async () => {
    if (!validateRejection()) return;
    warningMessage.value = null;
    saving.value = true;

    rejectionLoading.value = true;

    const payload: RejectionPayload = {
        product_assignment_id: props.product.id,
        reason: selectedRejection.value,
        description: rejectionDescription.value,
    }
    const { status, message } = await productStore.rejectProduct(payload);
    if (!status) {
        alertStore.showError(message ?? `An error occurred while attempting to reject this ${productStore.productScope}.`);
    }
    else {
        alertStore.showInfo(`The ${productConfiguration.productScope} was successfully rejected.`);
        await productStore.search();
        cancelModal();
    }

    saving.value = false;
    rejectionLoading.value = false;
}

const validateRejection = () => {
    const error = !selectedRejection.value
        ? 'Please select a rejection reason'
        : selectedRejection.value === 'ot' && rejectionDescription.value.length < 8
            ? `Please provide a brief description of the reason for rejection`
            : null;
    if (error)
        alertStore.showError(error);

    return !error;
}

const cancelModal = () => {
    emit('close:modal');
}

</script>