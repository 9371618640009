<template>
    <div class="bg-white border border-gray-200 rounded">
        <div class="py-5 px-3 md:px-6 border-b border-gray-200">
            <slot name="title" />
        </div>
        <div class="p-3 md:px-6 md:py-4">
            <slot />
        </div>
    </div>
</template>

<script setup>
</script>