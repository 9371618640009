<template>
    <main
        class="h-screen w-full relative bg-image-gradient"
    >
        <GlobalAlerts class="z-[1000]"/>
        <div class="relative flex h-full justify-center items-center p-3">
            <div class="pb-8 relative">
                <div class="absolute w-full h-full" v-if="saving">
                    <LoadingSpinner :small="true"/>
                </div>
                <div class="bg-white p-10 py-10 gap-6 rounded-xl relative md:w-128">
                    <fixr-roofing-calculator-logo class="mb-2"/>

                    <div v-if="invalidLink" class="flex items-center justify-center mt-5">
                        <p>
                            The password reset link appears to be invalid.
                        </p>
                    </div>
                    <div v-else
                         class="flex flex-col gap-y-3"
                         :class="[saving && 'opacity-50 grayscale-[50%] pointer-events-none']"
                         @keyup.enter="submitPasswordReset"
                    >
                        <TextInput
                            v-model="email"
                            label="Email"
                            type="email"
                        ></TextInput>
                        <TextInput
                            v-model="password"
                            label="New Password"
                            type="password"
                        ></TextInput>
                        <TextInput
                            v-model="passwordConfirm"
                            label="Confirm New Password"
                            type="password"
                        ></TextInput>
                    </div>
                    <CustomButton
                        v-if="!invalidLink"
                        @click.prevent="submitPasswordReset"
                    >
                        Reset Password
                    </CustomButton>
                </div>
            </div>
        </div>
    </main>
</template>

<script setup lang="ts">
import {useRoute, useRouter} from "vue-router";
import {onMounted, Ref, ref} from "vue";
import {useAlertStore} from "@/stores/v4/alerts";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import GlobalAlerts from "@/components/v4/GlobalAlerts.vue";
import {useAuthStore} from "@/stores/auth";
import FixrRoofingCalculatorLogo from "@/components/shared/FixrRoofingCalculatorLogo.vue";
import CustomButton from "@/components/authentication/components/CustomButton.vue";
import TextInput from "@/components/authentication/components/TextInput.vue";

const route = useRoute();
const router = useRouter();
const auth = useAuthStore();

const email: Ref<string> = ref('');
const token: Ref<string> = ref('');
const password: Ref<string> = ref('');
const passwordConfirm: Ref<string> = ref('');

const saving: Ref<boolean> = ref(false);
const invalidLink: Ref<boolean> = ref(false);

const alertStore = useAlertStore();

const minimumPasswordLength = 8;

onMounted(() => {
    email.value = `${route.query.email ?? ''}`;
    token.value = `${route.query.token ?? ''}`;

    if (!token.value)
        invalidLink.value = true;
});

const submitPasswordReset = async () => {
    if (saving.value || !validatePassword()) return;
    saving.value = true;

    const result = await auth.updatePassword({
        token: token.value,
        email: email.value,
        password: password.value,
        password_confirmation: passwordConfirm.value
    });

    if (result.status) {
        const message = result.token
            ? "Password has been updated. Attempting to log you in..."
            : "Password has been updated."

        alertStore.showInfo(message);
        await new Promise(res => setTimeout(() => res(true), 2000));
        alertStore.clearAlerts();

        router.push({
            path: '/login-with-token',
            query: {
                token: result.token
            }
        });
    } else {
        alertStore.showError(result.message ?? "Failed to update password.");
    }

    saving.value = false;
}

const validatePassword = () => {
    const errors = [];
    if (password.value.trim() !== passwordConfirm.value.trim())
        errors.push('Passwords do not match.');
    if (password.value.trim().length < minimumPasswordLength)
        errors.push(`Password must be at least ${minimumPasswordLength} characters.`);

    if (errors.length) {
        alertStore.showError(errors.join("\n"));

        return false;
    }

    return true;
}
</script>
<style scoped>
.bg-image-gradient {
    background: linear-gradient(360deg, #001B35 12.49%, rgba(0, 35, 68, 0.85) 63.1%, rgba(0, 79, 155, 0.8) 137.42%), url("/login_background.png");
    background-size: cover;
    background-position: center;
}

</style>