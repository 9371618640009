<template>
    <ModuleBasic v-if="!loading && anyCampaignsPaused">
        <LoadingSpinner v-if="saving"/>
        <div v-else>
            <div class="py-5 px-3 md:px-6 gap-y-3 flex items-center">
                <div class="mr-4 text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                    </svg>
                </div>
                <div class="mr-auto">
                    <div class="flex items-center">
                        <h5 class="pb-0 mr-3 text-lg">Campaigns Activation</h5>
                    </div>
                    <p class="text-gray-500 pb-0 mr-auto">{{ allCampaignsPaused ? 'All' : 'Some' }} of your Lead Campaigns are paused</p>
                </div>

                <SolidButton @click="activateCampaigns" :disabled="saving">
                    {{ saving ? 'Activating...' : 'Activate all' }}
                </SolidButton>
            </div>
        </div>
    </ModuleBasic>
</template>

<script setup>
import ModuleBasic from "@/components/ModuleBasic.vue";
import {ref} from "vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import {useServicesStore} from "@/stores/services";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const loading = ref(true);
const saving = ref(false);
const allCampaignsPaused = ref(false);
const anyCampaignsPaused = ref(false);

const pausedCampaignIds = ref([]);

const services = useServicesStore();

services.apiService.getCampaignOverview().then(resp => {
    anyCampaignsPaused.value = resp.data.data.paused_campaigns > 0;
    allCampaignsPaused.value = resp.data.data.paused_campaigns === resp.data.data.campaign_count;
    pausedCampaignIds.value = resp.data.data.campaign_ids;
    loading.value = false;
}).catch(() => {})

const activateCampaigns = () => {
    if (saving.value) return;
    saving.value = true;

    const payload = {
        campaigns: pausedCampaignIds.value.map(id => ({ id, status: true })),
    };

    if (!payload.campaigns?.length) return;
    services.apiService.bulkUpdateCampaigns(payload).then(resp => {
        if (resp.data?.data?.status) {
            anyCampaignsPaused.value = false;
        }
    }).catch(err => {
        console.error(err);
    }).finally(() => {
        saving.value = false;
    });
}

</script>