import { defineStore } from "pinia";
import { ref } from "vue";
import { useCompanyStore } from "@/stores/company";
import { useLocationsStore } from "@/stores/locations";
import {usePurchaseMissedProductsStore} from "@/stores/purchase-missed-products";

export const useCheckoutStore = defineStore('checkout', () => {
    const companyStore = useCompanyStore();
    const locationStore = useLocationsStore();
    const purchaseMissedProductsStore = usePurchaseMissedProductsStore();

    const loading = ref(false);
    const lineItems = ref([]);
    const lineItemsTotal = ref(0);
    const defaultErrorMessage = ref('An error has occurred while fetching items.');
    const companyContact = ref([]);
    const company = ref([]);
    const campaign = purchaseMissedProductsStore.editingCampaign;

    const getLineItemsPreparedForLead = (leads) => {
        lineItems.value = [];
        lineItemsTotal.value = 0;
        let itemKeys = [];

        leads['items'].forEach(item => {
            if (!itemKeys.find(key => key === item.price)) {
                let matchingPriceLineItems = fetchLeadsMatchingLineItemPrice(leads, item.price);
                lineItems.value.push({
                    price       : item.price,
                    service     : item.requested_service,
                    industry    : item.industry,
                    quantity    : matchingPriceLineItems.length,
                    totalPrice  : item.price * matchingPriceLineItems.length,
                    description : 'Chargeable Leads (Pay per Lead)',
                });

                lineItemsTotal.value += (item.price * matchingPriceLineItems.length);
                itemKeys.push(item.price);
            }
        });
    };

    const fetchLeadsMatchingLineItemPrice = (leads, lineItemPrice) => {
        return leads['items'].filter(item => item.price === lineItemPrice);
    }

    const getCompanyContactDetails = () => {
        if (purchaseMissedProductsStore.editingCampaign.contact_deliveries.length) {
            companyContact.value = purchaseMissedProductsStore.editingCampaign.contact_deliveries[0];
        }
    }

    const getCompanyDetails = () => {
        company.value = {name: companyStore.name, ...locationStore.locations?.[0]?.address};
    }

    return {
        loading,
        lineItems,
        lineItemsTotal,
        defaultErrorMessage,
        companyContact,
        company,
        campaign,
        getCompanyDetails,
        getCompanyContactDetails,
        getLineItemsPreparedForLead,
    };
});
