<template>
    <button
        v-if="isButton"
        @click="handleClick"
        :disabled="disabled"
        class="text-center inline-block py-2 px-4 rounded cursor-pointer font-display capitalize transition duration-300"
        :class="[classes, colorClasses, disabled ? 'grayscale-[80%] opacity-50 pointer-events-none' : '']"
        ref="buttonElement"
    >
        <slot />
    </button>

    <div
        v-else
        @click="handleClick"
        class="text-center inline-block py-2 px-4 rounded cursor-pointer font-display capitalize transition duration-300"
        :class="[classes, colorClasses, disabled ? 'grayscale-[80%] opacity-50 pointer-events-none' : '']"
    >
        <slot />
    </div>
</template>

<script setup>
import { computed, ref } from "vue";

const buttonElement = ref(null);

const emit = defineEmits(['click']);

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false
    },
    color: {
        type: String,
        default: 'cyan'
    },
    isButton: {
        type: Boolean,
        default: true
    },
    classes: {
        type: String,
        default: '',
    }
});

const handleClick = () => {
    emit('click');
    buttonElement.value.blur();
}

const colorClasses = computed(() => {
    switch(props.color) {
        case 'cyan':
            return `border border-cyan-500 text-cyan-500 hover:text-white hover:bg-cyan-400 focus:bg-cyan-600`;

        case 'green':
            return `border border-green-500 text-green-500 hover:text-white hover:bg-green-400 focus:bg-green-600`;

        case 'darkgreen':
            return `border border-green-700 text-green-700 hover:text-white hover:bg-green-600 focus:bg-green-800`;

        case 'red':
            return `border border-red-500 text-red-500 hover:text-white hover:bg-red-400 focus:bg-red-600`;

        case 'darkred':
            return `border border-red-800 text-red-800 hover:text-white hover:bg-red-700 focus:bg-red-800`;

        case 'yellow':
            return `border border-yellow-500 text-yellow-500 hover:text-white hover:bg-yellow-400 focus:bg-yellow-600`;

        case 'blue':
            return `border border-blue-500 text-blue-500 hover:text-white hover:bg-blue-400 focus:bg-blue-600`;

        case 'gray':
            return `border border-gray-300 text-gray-600 hover:bg-gray-200 focus:bg-gray-200`;

        case 'slate':
            return `border border-slate-500 text-slate-500 hover:text-white hover:bg-slate-400 focus:bg-slate-600`;

        case 'orange':
            return `border border-orange-500 text-orange-500 hover:text-white hover:bg-orange-400 focus:bg-orange-600`;

        default:
            return `border border-${props.color}-500 text-${props.color}-500 hover:text-white hover:bg-${props.color}-400 focus:bg-${props.color}-600`;
    }
});

</script>