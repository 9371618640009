<template>
    <div
        @click="toggle"
        class="flex items-center"
        :class="{'grayscale-[70%] opacity-40 pointer-events-none': disabled, '': !disabled }"
    >
        <div class="text-white w-4 h-4 rounded border cursor-pointer transition-all flex items-center justify-center"
            :class="{'bg-cyan-500 border-cyan-500': modelValue, 'border-gray-200': !modelValue}"
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor"
                 class="w-2 h-2">
                <path
                    class="transition-all"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    :d="modelValue ? 'M4.5 12.75l6 6 9-13.5' : ''"
                />
            </svg>
        </div>
        <p v-if="label" class="ml-2">{{ label }}</p>
    </div>
</template>

<script setup>
const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: null,
    },
});

const emit = defineEmits(['update:modelValue']);

const toggle = () => {
    if (!props.disabled) emit('update:modelValue', !props.modelValue);
}

</script>