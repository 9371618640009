<template>
    <div class="fixed z-[1001] bg-opacity-50 bg-slate-600 inset-0 flex items-center backdrop-blur-sm justify-center">
        <div class="bg-white border border-gray-200 rounded-lg bg-opacity-100 overflow-y-auto w-full md:w-auto mx-4 md:mx-0"
            :class="modalClasses"
        >
            <div class="py-4 px-5 md:px-6 bg-gray-100">
                <slot name="header" />
            </div>
            <div class="lg:p-5 md:px-6 md:pb-4 md:pt-2 h-auto overflow-y-auto overflow-x-hidden"
                :class="[ small ? 'min-h-[8rem] max-h-[35vh]' : 'min-h-[30vh] max-h-[60vh]' ]"
            >
                <slot name="body" />
            </div>
            <div class="py-5 px-5 md:px-6 bg-gray-100">
                <slot name="footer">
                    <div class="flex flex-center gap-3"
                        :class="[ showCancelButton ? 'justify-end' : 'justify-center',
                             disableButtons ? 'grayscale-[50%] opacity-50' : '',
                         ]"
                    >
                        <OutlineButton v-if="showCancelButton" color="gray"
                            @click="$emit('clicked:cancel')"
                        >
                            {{ cancelLabel }}
                        </OutlineButton>
                        <SolidButton v-if="showConfirmButton" classes="px-4"
                            :disabled="disableConfirm"
                            @click="$emit('clicked:confirm')"
                        >
                            {{ confirmLabel }}
                        </SolidButton>
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import SolidButton from "@/components/inputs/SolidButton.vue";
import OutlineButton from "@/components/inputs/OutlineButton.vue";

const props = defineProps({
    small: {
        type: Boolean,
        default: false,
    },
    confirmLabel: {
        type: String,
        default: 'Save',
    },
    cancelLabel: {
        type: String,
        default: 'Cancel'
    },
    showCancelButton: {
        type: Boolean,
        default: true,
    },
    showConfirmButton: {
        type: Boolean,
        default: true,
    },
    disableButtons: {
        type: Boolean,
        default: false,
    },
    modalClasses: {
        type: String
    },
    disableConfirm: {
        type: Boolean,
        default: false,
    }
});

const modalClasses = props.modalClasses ? props.modalClasses :  props.small ? 'md:min-w-[25vw] md:max-w-[50vw]' : ' md:min-w-[50vw] md:max-w-[75vw]';

const emits = defineEmits(['clicked:confirm', 'clicked:cancel'])

</script>