<template>
  <Modal
      @clicked:confirm="confirmDelete"
      @clicked:cancel="cancelDelete"
      confirm-label="Delete"
      small
  >
    <template v-slot:header>
      <div class="flex items-center">
        <svg class="w-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z" clip-rule="evenodd" />
        </svg>
        <h5 class="text-md">
          Delete Schedule
        </h5>
      </div>
    </template>
    <template v-slot:body>
      <div v-if="saving" class="relative min-h-[10rem]">
        <LoadingSpinner
            :small="true"
        />
      </div>
      <div v-else>
        <div class="flex flex-col gap-2">
          <span class="text-center text-red-500">{{errorMessage}}</span>
          <span>Are you sure you wish to delete {{props.schedule.name}}?</span>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref } from "vue";
import { useSchedulesStore } from "@/stores/schedules.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Modal from "@/components/Modal.vue";

const scheduleStore = useSchedulesStore();

const storeInitialized = ref(false);
const loading = ref(false);
const saving = ref(false);

const errorMessage = ref(null);

const props = defineProps({
  schedule: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'canceled',
  'deleted',
]);

const confirmDelete = async () => {
  if (saving.value) return;

  errorMessage.value = null;
  saving.value = true;
  const { status, message } = await scheduleStore.deleteSchedule(props.schedule.id);
  if (!status) {
    errorMessage.value = message;
  }

  saving.value = false;

  emit("deleted")
}

const cancelDelete = () => {
  emit("canceled")
}

</script>