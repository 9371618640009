<template>
  <div class="py-4">
    <h4 class="font-semibold pb-1">
      {{ tableSetup.title }}
    </h4>
    <p class="text-sm text-gray-500 pb-4">
      {{ tableSetup.subTitle }}
    </p>
    <div
        class="hidden md:sticky md:top-20 md:grid text-xs text-gray-700 uppercase px-4 bg-cyan-50 font-bold py-3 items-center border-b border-gray-200"
        :class="[gridColumnClasses]"
    >
      <div v-for="(item) in tableSetup?.columns"
           class="flex items-center"
           :key="getColumnKey(item)"
      >
        <div :class="item.headerClass ?? ''">
            {{ item.label }}
        </div>
        <div v-if="item.selectAll">
            <CustomCheckbox
                v-model="selectAll"
            />
        </div>
        <div v-if="item.tooltip" class="pr-2">
          <Tooltip :align-left="true">{{ item.tooltip }}</Tooltip>
        </div>
      </div>
    </div>
    <div
        class="relative border-b odd:bg-gray-50 text-gray-600 text-sm items-start md:items-center md:grid grid-cols-1 gap-4 md:gap-0 px-4 py-6 md:py-4 md:px-4"
        :class="[gridColumnClasses]"
        v-for="(tableItem, index) in tableData"
    >
      <div v-for="(columnItem, columnIndex) in tableSetup?.columns"
           :key="tableItem.key ?? index"
      >
        <p class="font-bold text-sm md:hidden text-gray-900">{{ columnItem.label }}</p>
        <!--     TOGGLE SWITCH       -->
        <div v-if="columnItem.toggle">
          <ToggleSwitch
              v-model="tableItem[getColumnKey(columnItem)]"
              :small="true"
              color="green"
              @update:model-value="(newValue) => handleToggleSwitch(newValue, tableItem, columnItem)"
          />
        </div>
        <!--    CUSTOM CHECKBOX      -->
        <div v-else-if="columnItem.checkbox"
             class="flex items-center gap-x-3"
             :class="[checkboxDisabled(tableItem, columnItem) && 'pointer-events-none grayscale-[50%] opacity-50']"
        >
          <CustomCheckbox
              v-model="tableItem[columnItem.emitKey ?? getColumnKey(columnItem)]"
              @update:model-value="emitCheckboxEvent(tableItem, columnItem.emitKey ?? getColumnKey(columnItem), columnItem?.singleSelection)"
              :input-disabled="checkboxDisabled(tableItem, columnItem)"
          />
          <div class="whitespace-pre-line"
               :class="tailwindClasses[columnIndex]"
               @click="handleClick(tableItem, columnItem)"
          >
            {{ getTableValue(index, columnItem.key, columnItem) }}
          </div>
        </div>

        <!--    SELECT-ALL COLUMN      -->
        <div v-else-if="columnItem.selectAll"
             class="flex items-center gap-x-3"
        >
          <CustomCheckbox
              v-model="selection[tableItem[getColumnEmitKey(columnItem)]]"
              @update:model-value="emitUpdateSelection"
          />
        </div>

        <div v-else-if="columnItem.editDelete"
             class="flex justify-start items-start text-left"
        >
          <ButtonDropdown>
            <template v-slot:trigger>
              <div class="cursor-pointer flex text-cyan-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"/>
                </svg>
              </div>
            </template>
            <div
                class="bg-white border border-gray-300 shadow rounded absolute top-2 left-8 z-50 p-2 w-36">
              <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                   @click="emitActionEvent(ActionType.Edit, tableItem, columnItem)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                </svg>
                <p class="ml-2 pb-0 text-cyan-500">Edit</p>
              </div>
              <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                   @click="emitActionEvent(ActionType.Delete, tableItem, columnItem)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                </svg>
                <p class="ml-2 pb-0 text-cyan-500">Delete</p>
              </div>
            </div>
          </ButtonDropdown>
        </div>
        <div v-else class="whitespace-pre-line"
             :class="tailwindClasses[columnIndex]"
             @click="handleClick(tableItem, columnItem)"
        >
          <p>{{ getTableValue(index, columnItem.key, columnItem) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, ComputedRef, nextTick, onBeforeMount, ref, Ref, watch} from "vue";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import ButtonDropdown from "@/components/ButtonDropdown.vue";
import Tooltip from "@/components/Tooltip.vue";
import ToggleSwitch from "@/components/inputs/ToggleSwitch.vue";

type HeaderItem = {
  label: string,
  key: string | string[], // Key to look up on table items to retrieve values. Can be an array for multiple keys.
  class?: string, // Override class of value displays
  headerClass?: string, // Override class of header display
  text?: string, // Text for display in addition to key values. Use %0 placeholders to replace with key value(s)
  itemClickable?: boolean, // Make value clickable, cursor-pointer, emit event
  emitKey?: string, // Any emitted events will emit this key instead of the main key if provided
  defaultValue?: string, // Can be set per-column where value is falsy
  checkbox?: boolean | string, // set to true to enable checkbox, or use a data key to disable if that key is falsy
  editDelete?: boolean, // Action handle
  tooltip?: string, // Will display tooltip if provided
  toggle?: boolean, // Toggle switch with emitter
  selectAll?: boolean, // Column will become a selection column, including Header item for select-all
  singleSelection?: boolean, // Set others to false
}

export interface TableSetup {
  title?: string,
  subTitle?: string,
  columns: HeaderItem[],
  columnClasses?: string, // Override auto Tailwind column classes
}

enum ActionType {
  Edit = 'edit',
  Delete = 'delete',
}

interface Props {
  tableSetup: TableSetup,
  tableData: GenericObject[],
  defaultValue?: string,
}

const props = withDefaults(defineProps<Props>(), {
  defaultValue: 'N/A',
});

const emit = defineEmits([
  'change:checkbox',
  'action:edit',
  'action:delete',
  'update:selection',
  'update:toggle-switch',
  'click:item',
]);

const tailwindClasses: Ref<string[]> = ref([]);

const selectAll: Ref<boolean> = ref(false);
watch(selectAll, (newValue) => handleSelectAll(newValue));

const selection: Ref<{ [key: string]: boolean }> = ref({});
watch(() => selection, () => emitUpdateSelection());

const gridColumnClasses: ComputedRef<string> = computed(() => {
  return props.tableSetup?.columnClasses ?? `grid-cols-${props.tableSetup?.columns.length ?? 4}`;
});

const initialize = () => {
  initializeSelectAll();
  compileTailwindClasses();
}
onBeforeMount(() => initialize());

const initializeSelectAll = (column?: HeaderItem) => {
  column = column ?? props.tableSetup.columns.find(column => column.selectAll);
  if (!column) return;

  const selectAllKey = getColumnEmitKey(column);
  props.tableData.forEach(item => {
    selection.value[item[selectAllKey]] = false;
  });
}
watch(() => props.tableData, () => {
  initializeSelectAll()
});

const compileTailwindClasses = () => {
  props.tableSetup.columns.forEach(column => {
    let classes = '';
    if (column.itemClickable) classes += 'cursor-pointer ';
    if (column.class) classes += column.class;

    tailwindClasses.value.push(classes);
  });
}

const checkboxDisabled = (tableItem: GenericObject, columnItem: HeaderItem) => {
  return typeof (columnItem.checkbox) === 'string'
      ? !tableItem[columnItem.checkbox]
      : false;
}

const processReplacers = (keyValues: string[], text: string) => {
  if (keyValues.length > 1) {
    keyValues.forEach((keyValue, index) => {
      const rx = new RegExp(`%${index}`, 'g');
      text = text.replace(rx, keyValue);
    });
  } else
    text = text.replace(/%\d+/g, keyValues[0]);

  return text;
}

const getTableValue = (index: number, key: string | string[], column: HeaderItem) => {
  const keyValues = [];
  if (Array.isArray(key))
    keyValues.push(...key.map(k => props.tableData[index]?.[k]));
  else
    keyValues.push(props.tableData[index]?.[key]);

  return keyValues.length
      ? column.text
          ? processReplacers(keyValues, column.text)
          : keyValues.join("\n")
      : column.defaultValue ?? props.defaultValue;
}

const getColumnKey = (column: HeaderItem): string => {
  return Array.isArray(column.key)
      ? column.key[0]
      : column.key;
}

const getColumnEmitKey = (column: HeaderItem) => {
  return column.emitKey || getColumnKey(column);
}

const emitCheckboxEvent = (tableItem: GenericObject, emitKey: string, singleSelection = false): void => {
  if (singleSelection) {
    props.tableData.forEach(e => {
      if (e.id !== tableItem.id) {
        e[emitKey] = false
      }
    })
  }

  emit('change:checkbox', emitKey ? tableItem[emitKey] : tableItem);
}

const emitActionEvent = (actionType: ActionType, tableItem: GenericObject, column: HeaderItem) => {
  const emitKey = getColumnEmitKey(column);
  if (actionType === ActionType.Edit)
    emit('action:edit', tableItem[emitKey]);
  else if (actionType === ActionType.Delete)
    emit('action:delete', tableItem[emitKey]);
  else
    console.error(`Unknown action in GridTable: ${actionType}`);
}

const emitUpdateSelection = async () => {
  await nextTick();
  const selected: string[] = Object.entries(selection.value).reduce((output, [key, checked]) => {
    return checked
        ? [...output, key]
        : output;
  }, [] as string[]);

  emit('update:selection', selected);
}

const handleSelectAll = (checked: boolean) => {
  for (const key in selection.value) {
    selection.value[key] = checked;
  }

  emitUpdateSelection();
}

const handleToggleSwitch = (newValue: any, tableItem: GenericObject, column: HeaderItem) => {
  const emitKey = getColumnEmitKey(column);
  const keyValue = tableItem[emitKey];

  emit('update:toggle-switch', newValue, keyValue);
}

const handleClick = (item: GenericObject, column: HeaderItem) => {
  if (!column.itemClickable) return;
  const emitKey = getColumnEmitKey(column);
  emit('click:item', item[emitKey]);
}

</script>
