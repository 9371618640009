import { defineStore } from "pinia";
import { useServicesStore } from "@/stores/services";
import { ref } from "vue";

export const useLocalityDataStore = defineStore('localityData', () => {
    const serviceStore = useServicesStore();

    const storeInitialized = ref(false);

    const selectedCountry = ref('US');

    const countries = ref([]);
    const states = ref([]);
    const countyList = ref({});

    const stateDetailsStore = ref({});
    const countyDetailsStore = ref({});

    async function initialize() {
        if (storeInitialized.value) return { status: true };

        const resp = await serviceStore.apiService.getStates(selectedCountry.value).catch(e=>e);
        if (resp.data?.data?.status) {
            countries.value = [ { "display_name": "United States of America", "code": "US" } ];
            states.value    = resp.data.data.states;
            countyList.value  = resp.data.data.counties ?? {};
        }
        else {
            return genericErrorMessage(resp);
        }

        storeInitialized.value = true;
        return { status: true }
    }

    async function getStateDetails(stateKey) {
        if (!(stateKey in stateDetailsStore.value)) {
            const resp = await serviceStore.apiService.getStateDetails(stateKey);
            if (resp.data?.data?.status) {
                stateDetailsStore.value[stateKey] = resp.data.data.counties;
            }
        }
        return stateDetailsStore.value[stateKey];
    }

    async function getCountyDetails(countyKey, stateKey) {
        if (!(stateKey in countyDetailsStore.value) || !(countyKey in countyDetailsStore.value[stateKey])) {
            const resp = await serviceStore.apiService.getCountyDetails(countyKey, stateKey);
            if (resp.data?.data?.status) {
                countyDetailsStore.value[stateKey] = countyDetailsStore.value[stateKey] ?? {};
                countyDetailsStore.value[stateKey][countyKey] = resp.data.data.zip_codes;
            }
        }
        return countyDetailsStore.value[stateKey][countyKey];
    }

    async function getAllCountiesInState(stateKey) {
        if (!(stateKey in countyDetailsStore.value) || !(countyDetailsStore.value[stateKey].fully_loaded)) {
            const resp = await serviceStore.apiService.getStateZipCodes(stateKey);
            if (resp.data?.data?.status) {
                countyDetailsStore.value[stateKey] = countyDetailsStore.value[stateKey] ?? {};
                countyDetailsStore.value[stateKey] = resp.data.data.zip_codes;
                countyDetailsStore.value[stateKey].fully_loaded = true;
            }
        }
    }

    async function getFilteredCountiesInState(stateKey, countyKeyArray) {
        await getAllCountiesInState(stateKey);
        const useFilter = !!countyKeyArray?.length;
        return Object.entries(countyDetailsStore.value[stateKey] ?? {}).reduce((output, [countyKey, county]) => {
            if (!useFilter || countyKeyArray.includes(countyKey))
                output[countyKey] = county;
            return output;
        }, {});
    }

    async function getZipCodesByRadius(zipCode, radius) {
        const resp = await serviceStore.apiService.getZipCodesByRadius(zipCode, radius).catch(e=>e);
        if (resp.data?.data?.status) {
            return { status: true, zip_codes: resp.data.data.zip_codes }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function getZipCodesByStringArray(zipCodeArray) {
        const resp = await serviceStore.apiService.getZipCodesByZipCodeStrings(zipCodeArray).catch(e=>e);
        if (resp.data?.data?.status) {
            return { status: true, zip_codes: resp.data.data.zip_codes }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function validateImportedZipCodes(zipCodeArray) {
        const resp = await serviceStore.apiServiceV4.validateTargetedZipCodes(zipCodeArray).catch(e => e);

        if (resp.data?.data?.status) {
            return { status: true, data: resp.data.data.zip_codes }
        }
        else
            return genericErrorMessage(resp);
    }

    function clearStore() {
        storeInitialized.value = false;
        countries .value = [];
        states.value = [];
        countyList.value = [];
        stateDetailsStore.value = {};
        countyDetailsStore.value = {};
    }

    function genericErrorMessage(resp) {
        return { status: false, message: resp.data?.data?.message || resp.err || `An unknown error occurred fetching data.` };
    }

    function $reset() {
        clearStore();
    }

    return {
        storeInitialized,
        selectedCountry,
        countries,
        states,
        countyList,
        stateDetailsStore,
        countyDetailsStore,
        getStateDetails,
        getCountyDetails,
        getAllCountiesInState,
        getZipCodesByRadius,
        getZipCodesByStringArray,
        initialize,
        getFilteredCountiesInState,
        validateImportedZipCodes,
        $reset,
    }

});