<template>
  <div>
      <Modal
          :show-confirm-button="false"
          @clicked:cancel="handleCancel"
          modal-classes="md:min-w-[35vw] md:max-w-[35vw]"
      >
          <template v-slot:header>
              <h3 class="text-lg">{{ title }}</h3>
          </template>
          <template v-slot:body>
              <transition name="slide" mode="out-in">
                  <component :is="component" />
              </transition>
          </template>
      </Modal>

      <Modal
          v-if="store.displayErrorModal"
          :show-cancel-button="false"
          confirm-label="Dismiss"
          @clicked:confirm="store.dismissError"
      >
          <template v-slot:header>
              <h2 class="text-lg">An Error Has Occurred</h2>
          </template>
          <template v-slot:body>
              <div class="relative min-h-[10rem]">
                  {{ store.errorMessage }}
              </div>
          </template>
      </Modal>

  </div>
</template>

<script setup>
import RecurringStatus from "@/components/checkout/slides/RecurringStatus.vue";
import ServiceAreas from "@/components/checkout/slides/ServiceAreas.vue";
import Budget from "@/components/checkout/slides/Budget.vue";
import DeliveryMethod from "@/components/checkout/slides/DeliveryMethod.vue";
import DeliveryContacts from "@/components/checkout/slides/DeliveryContacts.vue";
import {usePurchaseMissedProductsStore} from "@/stores/purchase-missed-products";
import Modal from "@/components/Modal.vue";
import {computed} from "vue";
import {useClaimLeadsStore} from "@/stores/claim-leads.store";

const store = usePurchaseMissedProductsStore();
const claimLeadsStore = useClaimLeadsStore();

const component = computed(() => {
    switch (store.currentSlide) {
        case 'RecurringStatus':
            return RecurringStatus;
        case 'ServiceAreas':
            return ServiceAreas;
        case 'Budget':
            return Budget;
        case 'DeliveryMethod':
            return DeliveryMethod;
        case 'DeliveryContacts':
            return DeliveryContacts;
        default:
            return ''
    }
})

const title = computed(() => {
    switch (store.currentSlide) {
        case 'RecurringStatus':
            return 'Set Campaign Status';
        case 'ServiceAreas':
            return 'Choose Campaign Service Areas';
        case 'Budget':
            return 'Choose Campaign Budget';
        case 'DeliveryMethod':
            return 'Delivery Method';
        case 'DeliveryContacts':
            return 'Delivery Method';
        default:
            return ''
    }
})

const handleCancel = () => {
    store.resetCheckoutFlow()
    claimLeadsStore.clearCustomerSelectedLeads()
}

</script>

<style scoped lang="css">
.slide-leave-active,
.slide-enter-active {
    transition: .5s;
}
.slide-enter {
    transform: translate(100%, 0);
}
.slide-leave-to {
    transform: translate(-100%, 0);
}
</style>