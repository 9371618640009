<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
                </svg>

                <h5 class="text-md">Profitability Assumptions</h5>
            </div>
        </template>

        <div v-if="storeLoaded">
            <div class="grid grid-cols-2 gap-6">
              <div v-if="errorMessage" class="col-span-2 text-sm text-red-700">
                {{ errorMessage }}
              </div>
                <div>
                    <p class="pb-1 font-medium text-sm">Avg Revenue Per Job</p>
                    <div class="relative">
                        <input v-model="profitabilityAssumptionValues.averageLeadRevenue" type="number" class="appearance-none appearance-['textfield'] w-full text-center text-sm border border-gray-200 rounded pr-2 pl-6 py-2" placeholder="200" />
                        <span class="absolute left-0 inset-y-0 bg-gray-100 flex items-center justify-center px-2 border-t border-b border-l rounded-l text-sm">
                            $
                        </span>
                    </div>
                </div>

                <div class="col-span-1">
                    <Tooltip>
                        <template v-slot:title><p class="pb-1 font-medium text-sm">Avg Cost Per Job</p></template>
                        Including labour and materials
                    </Tooltip>
                    <div class="relative">
                        <input v-model="profitabilityAssumptionValues.averageJobCost" type="number" class="w-full text-center text-sm border border-gray-200 rounded pr-2 pl-6 py-2" placeholder="200" />
                        <span class="absolute left-0 inset-y-0 bg-gray-100 flex items-center justify-center px-2 border-t border-b border-l rounded-l text-sm">
                            $
                        </span>
                    </div>
                </div>

                <div>
                    <p class="pb-1 font-medium text-sm">% Leads Successful</p>
                    <div class="relative">
                        <input v-model="profitabilityAssumptionValues.percentageLeadsSuccessful" type="number" min="0" max="100"
                           class="w-full text-center text-sm border border-gray-200 rounded pl-2 pr-6 py-2" placeholder="8" />
                        <span class="absolute right-0 inset-y-0 bg-gray-100 flex items-center justify-center px-2 border-t border-b border-r rounded-r text-sm">
                            %
                        </span>
                    </div>
                </div>
            </div>

            <div class="flex flex-col md:flex-row mt-4">
                <div class="flex">
                    <SolidButton
                        @click="updateProfitabilityAssumption"
                        :disabled="saving"
                        :label="saving ? 'Saving...' : 'Save'"
                    />
                    <SolidButton
                        color="light-gray"
                        class="ml-2"
                        @click="getProfitabilityAssumptionValues()"
                        :disabled="saving"
                        label="Reset"
                    />
                </div>
            </div>
        </div>

        <div v-if="!storeLoaded || loading" class="text-gray-500 text-center">
            Loading...
        </div>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import { reactive, ref, watch } from "vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { useCompanyStore } from "@/stores/company.js";
import { useServicesStore } from "@/stores/services";
import {useProfitabilityAssumptionsStore} from "@/stores/profitability-assumptions";
import Tooltip from "@/components/Tooltip.vue";

const props = defineProps({
    storeLoaded: {
        type: Boolean,
        default: false,
    },
});

const services = useServicesStore();
const company = useCompanyStore();
const profitabilityAssumptionStore = useProfitabilityAssumptionsStore();

const loading = ref(false);
const saving = ref(false);
const errorMessage = ref('');

const profitabilityAssumptionValues = reactive({
    percentageLeadsSuccessful : 0,
    averageLeadRevenue        : 0,
    averageJobCost            : 0,
    labourMaterialsCost       : 0,
});

const getProfitabilityAssumptionValues = () => {
    profitabilityAssumptionValues.percentageLeadsSuccessful = profitabilityAssumptionStore.scoped.percentage_leads_successful;
    profitabilityAssumptionValues.averageLeadRevenue = profitabilityAssumptionStore.scoped.average_lead_revenue;
    profitabilityAssumptionValues.averageJobCost = profitabilityAssumptionStore.scoped.average_job_cost;
    profitabilityAssumptionValues.labourMaterialsCost = profitabilityAssumptionStore.scoped.labour_materials_cost;
}

const updateProfitabilityAssumption = async () => {
    clearError();
    saving.value = true;
    const { status, message } = await profitabilityAssumptionStore.set(profitabilityAssumptionValues);
    if (!status) {
        showError(message);
    }
    else {
        window.location.reload();
    }

    saving.value = false;
}

const showError = (message) => {
    if (message && !errorMessage.value) {
        errorMessage.value = message;
        setTimeout(() => {
              errorMessage.value = '';
        }, 10000);
    }
}
const clearError = () => { errorMessage.value = null; }

watch(() => props.storeLoaded, (value) => {
    if (value) getProfitabilityAssumptionValues();
});

// Support this component being included multiple times on the page.
profitabilityAssumptionStore.$subscribe((mutation, state) => {
    if(props.storeLoaded && !loading.value)
        getProfitabilityAssumptionValues();
})

</script>

<style scoped>
    input {
        appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

</style>