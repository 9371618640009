/**
 * Convert a string with camel, snake, kebab or spaces to Proper Case
 * @param {string} inputString
 * @returns {string}
 */
export const useProperCase = (inputString) => {
    if (typeof(inputString) !== 'string') return '';
    return inputString.trim()
        .replace(/([a-z])([A-Z])/g, '$1_$2')
        .replace(/([A-Z])([A-Z])/, '$1_$2')
        .replace(/-+/g, '_')
        .replace(/\s+/g, '_')
        .split(/_+/)
        .map(word => word ? `${word[0].toUpperCase()}${word.slice(1)}` : '')
        .join(' ');
}
