<template>
    <Module class="mt-4 mx-4 pb-4">
        <template v-slot:title>
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <h2 class="text-lg font-medium">Appointments</h2>
                </div>
                <div class="flex items-center">
                    <div class="flex items-center cursor-pointer" @click="showTermsAndConditions">
                        <svg class="w-5 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19 8H5C3.34 8 2 9.34 2 11V17H6V21H18V17H22V11C22 9.34 20.66 8 19 8ZM16 19H8V14H16V19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM18 3H6V7H18V3Z"
                                fill="#14B1E7"/>
                        </svg>
                        <p class="text-right mr-5 pb-0 text-cyan-500 hidden md:block">View Terms and Conditions</p>
                    </div>
                    <loading-spinner v-if="downloading"></loading-spinner>
                    <div class="flex items-center cursor-pointer" @click="downloadAppointmentsAsCsv">
                        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1666 15.8333L19.1666 17.5L3.83329 17.5L3.83329 15.8333L19.1666 15.8333ZM12.4583 10.8333L15.3333 10.8333L11.5 14.1667L7.66663 10.8333L10.5416 10.8333L10.5416 2.5L12.4583 2.5L12.4583 10.8333Z" fill="#20A5DC"/>
                        </svg>
                        <p class="text-right text-cyan-500 pb-0 block">Download</p>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:default>
            <div v-if="!storesInitialized">
                <p class="text-gray-500 text-center">Loading...</p>
            </div>
            <div v-else class="py-5 px-3 md:px-6 gap-y-3 flex flex-col justify-center"
                 :class="[loading || saving ? 'grayscale-[90%] opacity-50 pointer-events-none' : '']"
            >
                <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3 mb-6 items-end">
                    <div>
                        <p class="block mb-1 text-sm font-medium">Date Range</p>
                        <DatePicker
                            v-model="appointmentsStore.searchOptions.date_range"
                            range
                            input-class-name="h-11 rounded-md"
                            model-type="timestamp"
                            :enable-time-picker="false"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="appointmentsStore.searchOptions.contact_name"
                            label="Name or Email"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="appointmentsStore.searchOptions.appointment_id"
                            label="Appointment ID"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="appointmentsStore.searchOptions.address"
                            label="State or Zipcode"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="appointmentsStore.searchOptions.invoice_id"
                            label="Invoice No."
                        />
                    </div>
                    <div>
                        <CustomSelect
                            v-model="appointmentsStore.searchOptions.campaign_id"
                            :options="campaignOptions"
                            label="Campaign"
                        />
                    </div>
                    <div>
                        <CustomSelect
                            v-model="appointmentsStore.searchOptions.appointment_type"
                            label="Type"
                            :options="appointmentTypeOptions"
                        />
                    </div>
                    <div>
                        <SolidButton @click="submitAppointmentSearch" classes="w-full h-11 font-semibold">
                            Search
                        </SolidButton>
                    </div>
                </div>
                <div v-if="errorMessage">
                    <p class="text-red-900 text-sm text-center">{{ errorMessage }}</p>
                </div>
                <div class="mt-8">
                    <div v-if="loading">
                        <p class="text-gray-500 text-center">Loading...</p>
                    </div>
                    <div v-else-if="!(appointmentsStore.appointments.length > 0)">
                        <p>No Appointments were found.</p>
                    </div>
                    <div v-else>
                        <Pagination
                            :limit="appointmentsStore.paginationLimit"
                            :total="appointmentsStore.paginationTotal"
                            :offset="appointmentsStore.paginationOffset"
                            @change-page="handlePageChange"
                        >
                            <div class="hidden md:sticky md:top-20 md:grid grid-cols-7 text-xs text-gray-700 uppercase bg-cyan-50 text-center font-bold py-3 items-center border-b border-gray-200">
                                <div>ID</div>
                                <div>Type</div>
                                <div>Date / Time</div>
                                <div>Contact</div>
                                <div>Address</div>
                                <div>Cost</div>
                                <div>Time Remaining</div>
                            </div>
                            <div v-for="appointment in appointmentsStore.appointments"
                                 :key="appointment.id"
                                 class="border-b odd:bg-gray-50 text-gray-600 text-sm items-start md:items-center grid grid-cols-7 gap-4 md:gap-0 px-4 py-6 md:py-4 md:px-0">
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">ID</p>
                                    <p @click="showAppointmentDetail(appointment.id)" class="text-cyan-500 cursor-pointer md:text-center">
                                        {{ appointment.id }}
                                    </p>
                                </div>
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">Type</p>
                                    <p class="md:text-center">{{ useProperCase(appointment.appointment?.appointment_type) }}</p>
                                </div>
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">Date/Time</p>
                                    <p class="md:text-center">{{ appointment.appointment?.appointment_date }}</p>
                                    <p class="md:text-center">{{ appointment.appointment?.appointment_time }}</p>
                                </div>
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">Contact</p>
                                    <p class="md:text-center truncate" :title="appointment.name">{{ `${appointment.name}` }}</p>
                                    <p class="md:text-center truncate" :title="appointment.email">{{ appointment.email }}</p>
                                    <p class="md:text-center truncate" :title="appointment.phone">{{ appointment.phone }}</p>
                                </div>
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">Address</p>
                                    <p class="md:text-center">{{ appointment.address }}</p>
                                </div>
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">Cost</p>
                                    <p class="md:text-center">${{ getCost(appointment) }}</p>
                                </div>
                                <div class="col-span-1">
                                    <div v-if="canRejectLead(appointment)" class="flex flex-col items-center">
                                        <p class="text-sm md:justify-center text-red-500 cursor-pointer"
                                          @click="openRejectionModal(appointment)">
                                            Reject
                                        </p>
                                        <p class="text-gray-700 text-sm pb-0">Time remaining:</p>
                                        <p class="text-gray-900 text-sm font-bold text-center">{{useRelativeTimeRemaining(appointment.rejection_timer/1000)}}</p>
                                    </div>
                                    <div v-else-if="appointment.appointment_status === 'rejected'" class="flex flex-col items-center">
                                        <p class="font-bold">Appointment was rejected</p>
                                    </div>
                                    <div v-else-if="appointment.appointment_status === 'canceled'" class="flex flex-col items-center">
                                        <p class="font-bold">Cancelled by consumer</p>
                                    </div>
                                    <div v-else-if="appointment.appointment_status === 'rescheduled'" class="flex flex-col items-center">
                                        <p class="font-bold">Rescheduled by consumer</p>
                                    </div>
                                </div>
                            </div>
                            <template v-slot:summary>
                                <p class="text-gray-800 text-sm" v-if="appointmentsStore.appointments.length > 0 && pageCost > 0 && appointmentsStore.totalSpend > 0">
                                    Total: <span class="text-green-500">
                                    ${{ pageCost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                    (${{ appointmentsStore.totalSpend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} total)
                                </span>
                                </p>
                            </template>
                        </Pagination>
                    </div>
                </div>
            </div>
            <!--    Appointment Details modal  -->
            <AppointmentDetailsModal
                v-if="showAppointmentDetailsModal"
                :appointment="appointmentsStore.getAppointmentById(appointmentDetailId)"
                @closeModal="hideAppointmentDetailsModal"
                :small="true"
            />
            <Modal
                v-if="showContractModal"
                @clicked:confirm="hideContractModal"
                confirm-label="Ok"
                :show-cancel-button="false"
            >
                <template v-slot:header>
                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                        </svg>
                        <p class="ml-4 text-lg">Terms & Conditions</p>
                    </div>
                </template>
                <template v-slot:body>
                    <div class="overflow-auto h-full p-4 md:px-5 md:py-3 border-slate-300 border bg-slate-50">
                        <p v-html="companyContract" />
                    </div>
                </template>
            </Modal>
            <AppointmentRejectionModal
                :appointment="selectedAppointmentForRejection"
                v-if="showRejectionModal"
                @hide-appointment-rejection-modal="closeRejectionModal"
            >
            </AppointmentRejectionModal>
        </template>
    </Module>
</template>

<script setup>
import {computed, onBeforeMount, ref, watch} from "vue";
    import CustomInput from '@/components/inputs/CustomInput.vue';
    import SolidButton from "@/components/inputs/SolidButton.vue";
    import Module from "@/components/Module.vue";
    import CustomSelect from "@/components/inputs/CustomSelect.vue";
    import { useCampaignStore } from "@/stores/campaigns.js";
    import { useProperCase } from "@/composables/useProperCase.js";
    import Pagination from "@/components/pagination/Pagination.vue";
    import Modal from "@/components/Modal.vue";
    import { useCompanyStore } from "@/stores/company.js";
    import { useCreateCsvString } from "@/composables/useCreateCsvString.js";
    import { useDownloadFile } from "@/composables/useDownloadFile.js";
    import { useAppointmentsStore } from "@/stores/appointments.js";
    import { useRelativeTimeRemaining } from "@/composables/useRelativeTimeRemaining.js";
    import AppointmentDetailsModal from "@/components/appointments/modals/AppointmentDetailsModal.vue";
    import AppointmentRejectionModal from "@/components/appointments/modals/AppointmentRejectionModal.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

    const props = defineProps({
        storesInitialized: {
            type: Boolean,
            default: false,
        },
    });

    onBeforeMount(async () => {
        if (props.storesInitialized) {
            updateAllOptions();
        }
        else {
            watch(() => props.storesInitialized, () => {
                updateAllOptions();
            });
        }

        processQueryString();
    });

    const campaignStore = useCampaignStore();
    const companyStore = useCompanyStore();
    const appointmentsStore = useAppointmentsStore();

    const loading = ref(false);
    const saving = ref(false);
    const downloading = ref(false);
    const errorMessage = ref(null);

    const showAppointmentDetailsModal = ref(false);
    const showContractModal = ref(false);
    const appointmentDetailId = ref(null);

    const campaignOptions = ref([]);
    const appointmentTypeOptions = ref([]);

    const companyContract = ref("");

    const selectedAppointmentForRejection = ref(null);
    const showRejectionModal = ref(false);

    const pageCost = computed(() => appointmentsStore.appointments.map(appointment => getCost(appointment)).reduce((sum, cost) => sum + cost, 0));

    const updateAllOptions = () => {
        updateCampaignOptions();
        updateAppointmentTypeOptions();
    }

    const processQueryString = () => {
        const search = new URLSearchParams(window.location.search);

        if (search.has('appointment_id'))
            appointmentsStore.searchOptions.appointment_id = search.get('appointment_id');
    }

    const updateCampaignOptions = () => {
        campaignOptions.value = [{ value: '', label: '-' }];
        campaignOptions.value.push(...campaignStore.campaigns.appointments.map(campaign => ({ value: campaign.id, label: campaign.name })));
    }

    const updateAppointmentTypeOptions = () => {
        appointmentTypeOptions.value = appointmentsStore.appointmentConfigData.appointment_types.map(type => ({ label: type.name, value: type.id }));
    }

    const handlePageChange = (payload) => {
        submitAppointmentSearch(payload.page);
    }

    const submitAppointmentSearch = async (page = 1) => {
        loading.value = true;
        const { status, message } = await appointmentsStore.search(page);
        if (!status) {
            errorMessage.value = message;
        }
        loading.value = false;
    }

    const showTermsAndConditions = async () => {
        loading.value = true;
        const { status, contract, message } = await companyStore.getCompanyContractForUser();
        if (status) {
            companyContract.value = contract;
            showContractModal.value = true;
        }
        else {
            errorMessage.value = message;
        }
        loading.value = false;
    }

    const hideContractModal = () => {
        showContractModal.value = false;
    }

    const downloadAppointmentsAsCsv = async () => {
        downloading.value = true;
        await appointmentsStore.download();
        downloading.value = false;
    }

    const showAppointmentDetail = (leadId) => {
        if (leadId > 0) {
            appointmentDetailId.value = leadId;
            showAppointmentDetailsModal.value = true;
        }
    }

    const hideAppointmentDetailsModal = () => {
        appointmentDetailId.value = null;
        showAppointmentDetailsModal.value = false;
    }

    const canRejectLead = (appointment) => {
        return appointment.rejection_timer > 0 && appointment.appointment_status === 'purchased' && appointment.chargeable;
    };

    const openRejectionModal = (appointment) => {
        selectedAppointmentForRejection.value = appointment;
        showRejectionModal.value = true;
    }

    const closeRejectionModal = () => {
        selectedAppointmentForRejection.value = null;
        showRejectionModal.value = false;
    }

    const getCost = (appointment) => {
        if (!appointment.cost || !appointment.chargeable || appointment.appointment_status !== 'purchased')
            return 0;

        return appointment.cost;
    }

</script>