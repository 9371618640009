import { defineStore } from "pinia";
import { Component, computed, ComputedRef, nextTick, ref, Ref } from "vue";
import { ProductType, useServicesStore } from "@/stores/services";
import { AxiosResponse } from "axios";
import { BaseApiServiceV4 } from "@/services/api/v4/BaseApiServiceV4";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { useFutureCampaignStore } from "@/stores/v4/future-campaigns";

type ContactDelivery = {
    active: boolean,
    contact_id: number,
    email_active: boolean,
    sms_active: boolean,
    name: string,
    email: string,
}

export type DeliveryCollection = {
    contact_deliveries: ContactDelivery[],
    crm_deliveries: CrmDeliverer[],
    schedules: number[],
}

export enum DeliveryType {
    Crm = 'crm',
    Contact = 'contact',
}

export type CrmInteractable = {
    method: string,
    display_on_front_end: boolean,
    display_name: string,
    type: number,
    description?: string,
}

export type CrmDelivererPayload = {
    system_fields: CrmConfigurationField[],
    additional_fields: CrmConfigurationField[],
    custom_fields: CrmCustomField[],
    interactable_fields?: {
        [key:string]: CrmConfigurationField[]
    },
}

export type CrmDeliverer = {
    id: number|string,
    crm_type: number,
    active: boolean,
    display_name: string,
    crm_type_display: string,
    payload: CrmDelivererPayload,
    interactables?: CrmInteractable[],
}

export type CrmConfigurationField = {
    key: string,
    value: string,
    display_name: string,
    type: number,
    required?: boolean,
    payload: {
        options: string[],
    },
    display_flag: boolean,
}

export type CrmConfiguration = {
    id: number,
    key: string,
    name: string,
    system_fields: CrmConfigurationField[],
    additional_fields: CrmConfigurationField[],
    interactables: CrmInteractable[],
}

export type CrmCustomField = {
    key: string,
    value: string,
}

enum CrmInteractableType {
    Button = 0,
}

export enum CrmFieldType {
    Text = 0,
    Dropdown = 1,
}

export enum CrmType {
    StandardWebForm = 0,
    LeadConduit = 1,
    JobNimbus = 2,
    Pipedrive = 3,
    ZohoOAuth = 4,
}

const CrmInteractableTypeMap = {
    [CrmInteractableType.Button]: SolidButton,
}

const getDefaultCrmDeliverer = (): CrmDeliverer => structuredClone({
    id: `temp-${Date.now()}`, // temp timestamp-as-id for unsaved deliverers, in case they need deleting before saving
    crm_type: 0,
    crm_type_display: 'Standard Web Form',
    active: true,
    display_name: '',
    payload: {
        system_fields: [],
        additional_fields: [],
        custom_fields: [],
        interactable_fields: {},
    },
});

export const useDeliveryStore = defineStore('delivery', () => {
    const services = useServicesStore();
    const campaignStore = useFutureCampaignStore();

    const availableCrmConfigurations: Ref<CrmConfiguration[]> = ref([]);

    const editingCrmConfiguration: Ref<CrmDeliverer> = ref(getDefaultCrmDeliverer());

    const currentInteractables: ComputedRef<CrmInteractable[]> = computed(() =>
        mergeInteractables(availableCrmConfigurations.value.find(config => config.id === editingCrmConfiguration.value.crm_type)?.interactables ?? [], editingCrmConfiguration.value.interactables ?? []));

    const initialized: Ref<boolean> = ref(false);
    const showSchedules: ComputedRef<boolean> = computed(() => {
        return services.apiServiceV4.getProductKey() === ProductType.Appointment;
    });

    const crmConfigurationOptions: ComputedRef<CustomSelectOption[]> = computed(() => {
        return availableCrmConfigurations.value.map(config => ({ label: config.name, value: config.id }));
    });

    const crmImportOptions: Ref<CrmDeliverer[]> = ref([]);

    // For now, disable Custom Fields for Pipedrive as only fields fetched with the auth_token are relevant. We may want to move this setting to the backend.
    const usesCustomFields: ComputedRef<boolean> = computed(() => {
        return editingCrmConfiguration.value.crm_type !== CrmType.Pipedrive;
    });

    const initialize = async (): Promise<StatusResponse> => {
        if (initialized.value) return { status: true };

        const resp = await services.apiServiceV4.getCrmConfigurations().catch((e: AxiosResponse) => e);
        if (resp.data?.data?.status) {
            availableCrmConfigurations.value = resp.data.data.crm_configurations;
            initialized.value = true;
            return { status: true }
        }
        else
            return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const getCrmDisplayNameById = (id: number) => {
        const targetConfig = availableCrmConfigurations.value.find(config => config.id === id);
        return targetConfig ? targetConfig.name : 'N/A';
    }

    const getCrmInteractableComponentMap = (crmType: CrmInteractableType): Component => {
        return CrmInteractableTypeMap[crmType] ?? SolidButton
    }

    const executeInteractable = async (methodName: string): Promise<DataResponse> => {
        const crmId = editingCrmConfiguration.value.crm_type;
        const resp = await services.apiServiceV4.executeCrmInteractable(crmId, methodName, editingCrmConfiguration.value).catch(e => e);
        if (resp.data?.data?.status) {
            return { status: true, data: resp.data.data.crm_deliverer ?? {} }
        }
        else {
            return BaseApiServiceV4.transformErrorResponse(resp);
        }
    }

    const clearEditingConfiguration = () => {
        editingCrmConfiguration.value = getDefaultCrmDeliverer();
    }

    const loadDefaultFields = async () => {
        await nextTick();
        const targetConfiguration = availableCrmConfigurations.value.find(config => config.id === editingCrmConfiguration.value.crm_type);
        if (targetConfiguration) {
            Object.assign(editingCrmConfiguration.value.payload, {
                system_fields: targetConfiguration.system_fields ?? [],
                additional_fields: targetConfiguration.additional_fields ?? [],
            });
        }
    }

    const getCrmImportOptions = async (): Promise<StatusResponse> => {
        const currentCampaignReference = campaignStore.editingCampaign.reference;
        const resp = await services.apiServiceV4.getCrmImportOptions(currentCampaignReference).catch(e => e);
        if (resp.data?.data?.status) {
            crmImportOptions.value = resp.data.data.crm_deliverers;
            return { status: true }
        }
        else
            return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const updateFieldValues = (keyValues: GenericObject, fieldType: string): void => {
        if (fieldType in editingCrmConfiguration.value.payload) {
            if (!Array.isArray(editingCrmConfiguration.value.payload[fieldType as keyof CrmDelivererPayload])) return;
            Object.entries(keyValues).forEach(([key, value]) => {
                const targetArray = editingCrmConfiguration.value.payload[fieldType as keyof CrmDelivererPayload];
                if (Array.isArray(targetArray)) {
                    const targetField = targetArray.find(field => field.key === key);
                    if (targetField) {
                        targetField.value = value;
                    }
                }
            });
        }
    }

    const mergeInteractables = (fieldArrayOne: CrmInteractable[], fieldArrayTwo: CrmInteractable[]) => {
        const outputArray = [...fieldArrayOne];
        fieldArrayTwo.forEach(field => {
            const targetIndex = outputArray.findIndex(targetField => targetField.method === field.method);
            if (targetIndex !== -1) outputArray[targetIndex] = field;
            else outputArray.push(field);
        });

        return outputArray;
    }

    return {
        availableCrmConfigurations,
        initialized,
        showSchedules,
        crmConfigurationOptions,
        editingCrmConfiguration,
        crmImportOptions,

        initialize,
        currentInteractables,
        getCrmDisplayNameById,
        getCrmInteractableComponentMap,
        executeInteractable,
        clearEditingConfiguration,
        loadDefaultFields,
        getCrmImportOptions,
        usesCustomFields,
        updateFieldValues,
    }
});