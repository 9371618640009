<template>
    <div class="mb-12">
        <CampaignEditor v-if="campaignEditMode"
                        @campaignEditClose="closeCampaignEditor"
                        @campaignCreated="closeCampaignEditor"
                        :store-initialized="campaignStoreInitialized"
        />
        <LeadsCampaignsList v-else
                       @campaignEditOpen="openCampaignEditor"
                       :store-initialized="campaignStoreInitialized"
        />
    </div>
</template>

<script setup>
import CampaignEditor from "@/components/campaigns/CampaignEditor.vue";
import { ref } from "vue";
import { useCampaignStore } from "@/stores/campaigns.js";
import Products from "@/Utilities/Products";
import LeadsCampaignsList from "@/components/leads/LeadsCampaignsList.vue";

const campaignStore = useCampaignStore();

const campaignEditMode = ref(false);

const campaignStoreInitialized = ref(false);

campaignStore.setProduct(Products.LEAD).then(() => campaignStore.initialize().then(() => campaignStoreInitialized.value = true));

const openCampaignEditor = async (campaignId) => {
    if (campaignId) campaignStore.editCampaign(campaignId);
    else campaignStore.newCampaign();
    campaignEditMode.value = true;
}

const closeCampaignEditor = () => {
    campaignEditMode.value = false;
}

</script>