<template>
    <div>
        <Modal
            @clicked:confirm="saveCrmDeliverer"
            @clicked:cancel="closeModal"
            :confirm-label="newConfiguration ? 'Create' : 'Update'"
            key="crmDeliveryModal"
        >
            <template v-slot:header>
                <div class="flex items-center">
                    <svg  class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    </svg>
                    <h5 class="text-md">{{ newConfiguration ? 'Add' : 'Edit'}} CRM Delivery</h5>
                </div>
            </template>
            <template v-slot:body>
                <div v-if="loading">
                    <p class="text-gray-500 text-center">Loading...</p>
                </div>
                <div v-else
                     :class="[saving ? 'pointer-events-none grayscale-[50%] opacity-50' : '']"
                >
                    <div class="grid grid-cols-2 items-start gap-4">
                        <CustomInput
                            label="* Name"
                            v-model="deliveryStore.editingCrmConfiguration.display_name"
                            :required-field="true"
                            placeholder="Delivery name..."
                        />
                        <CustomSelect
                            :dropdown-disabled="!newConfiguration"
                            :options="crmConfigurationOptions"
                            label="Integrated CRM Providers"
                            v-model="deliveryStore.editingCrmConfiguration.crm_type"
                            type="number"
                            @change="deliveryStore.loadDefaultFields()"
                        />
                    </div>
                    <div v-if="deliveryStore.editingCrmConfiguration.crm_type >= 0">
                        <div v-if="deliveryStore.editingCrmConfiguration.payload.system_fields" class="border-t border-gray-200 pt-6 mt-8">
                            <h2 class="font-semibold pb-3">System Fields</h2>
                            <div class="grid grid-cols-2 items-start gap-x-4">
                                <div v-for="systemField in deliveryStore.editingCrmConfiguration.payload.system_fields"
                                    :key="systemField.key"
                                >
                                    <div v-if="systemField.display_flag">
                                        <CustomSelect
                                            v-if="systemField.type === CrmFieldType.Dropdown"
                                            :options="(systemField.payload.options as string[] ?? []).map(option => ({ label: option, value: option }))"
                                            v-model="systemField.value"
                                            :label="`${systemField.required ? '* ' : ''}${systemField.display_name}`"
                                        />
                                        <CustomInput
                                            v-else
                                            v-model="systemField.value"
                                            :label="`${systemField.required ? '* ' : ''}${systemField.display_name}`"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="deliveryStore.currentInteractables?.length" class="flex gap-x-4 mt-6">
                            <div v-for="interactable in deliveryStore.currentInteractables">
                                <div class="flex items-center gap-x-4">
                                    <component
                                        v-if="interactable.display_on_front_end"
                                        :is="deliveryStore.getCrmInteractableComponentMap(interactable.type)"
                                        @click="executeInteractable(interactable.method)"
                                    >
                                        {{ interactable.display_name }}
                                    </component>
                                    <div v-if="interactable.description"
                                        class="flex items-center justify-center gap-1 py-2 px-4 rounded font-display text-xs whitespace-pre-line"
                                    >
                                        {{ interactable.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="deliveryStore.editingCrmConfiguration.payload.additional_fields.length" class="border-t border-gray-200 pt-6 mt-8">
                            <h2 class="font-semibold pb-3">Fields</h2>
                            <div class="grid grid-cols-2 items-start gap-4 gap-y-6">
                                <div v-for="additionalField in deliveryStore.editingCrmConfiguration.payload.additional_fields"
                                     :key="additionalField.key"
                                >
                                    <CustomSelect
                                        v-if="additionalField.type === CrmFieldType.Dropdown"
                                        placeholder="-"
                                        :options="(additionalField.payload.options as string[] ?? []).map(option => ({ label: option, value: option }))"
                                        v-model="additionalField.value"
                                        :label="`${additionalField.required ? '* ' : ''}${additionalField.display_name}`"
                                    />
                                    <CustomInput
                                        v-else
                                        v-model="additionalField.value"
                                        :label="`${additionalField.required ? '* ' : ''}${additionalField.display_name}`"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="Object.keys(deliveryStore.editingCrmConfiguration.payload.interactable_fields ?? {}).length" class="border-t border-gray-500 pt-6 mt-8 overflow-y-auto">
                            <div v-for="[interactableGroupName, interactableFieldGroup] in Object.entries(deliveryStore.editingCrmConfiguration.payload.interactable_fields ?? {})"
                                 class="pb-6"
                            >
                                <h2 class="font-semibold pb-3">{{ useProperCase(interactableGroupName) }}</h2>
                                <div class="grid grid-cols-2 items-start gap-4 gap-y-6 px-2">
                                    <div v-for="interactableField in interactableFieldGroup"
                                         :key="interactableField.key"
                                    >
                                        <CustomSelect
                                            v-if="interactableField.type === CrmFieldType.Dropdown"
                                            placeholder="-"
                                            :label="interactableField.display_name"
                                            :options="[{label: '', value: null}, ...(interactableField.payload.options ?? []).map(option => ({ label: option, value: option }))]"
                                            v-model="interactableField.value"
                                        />
                                        <CustomInput
                                            v-else
                                            v-model="interactableField.value"
                                            :label="interactableField.display_name"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="deliveryStore.usesCustomFields" class="border-t border-gray-200 pt-6 mt-8">
                            <h2 class="font-semibold pb-3">Custom Fields</h2>
                            <div class="grid grid-cols-2 items-start gap-4 gap-y-6">
                                <div v-for="(customField, index) in deliveryStore.editingCrmConfiguration.payload.custom_fields"
                                     :key="index"
                                >
                                    <div class="grid grid-cols-2 gap-x-2">
                                        <CustomInput
                                            label="Key"
                                            v-model="customField.key"
                                            @change="validateCustomFields(index)"
                                        />
                                        <div class="relative">
                                            <CustomInput
                                                label="Value"
                                                v-model="customField.value"
                                            />
                                            <div class="text-red-900 hover:text-red-700 absolute top-[0.25rem] right-2 z-10 cursor-pointer"
                                                @click="deleteCustomField(index)"
                                                title="Delete Field"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4">
                                                    <path fill-rule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <OutlineButton
                                class="my-4"
                                @click="addCustomField"
                            >
                                <p>Add Custom Field</p>
                            </OutlineButton>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import { computed, ComputedRef, onMounted, ref } from "vue";
import CustomInput from "@/components/inputs/CustomInput.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { CrmCustomField, CrmFieldType, useDeliveryStore } from "@/stores/v4/delivery";
import { useAlertStore } from "@/stores/v4/alerts";
import { useProperCase } from "@/composables/useProperCase";
import OutlineButton from "@/components/inputs/OutlineButton.vue";

interface Props {
    dataKey?: string,
    editingId: number|null,
}
const props = defineProps<Props>();

const deliveryStore = useDeliveryStore();
const alertStore = useAlertStore();

const emit = defineEmits(['close:modal', 'update:deliveries']);

const saving = ref(false);
const loading = ref(true);

const newConfiguration: ComputedRef<boolean> = computed(() => !props.editingId);
const crmConfigurationOptions: ComputedRef<CustomSelectOption[]> = computed(() => deliveryStore.availableCrmConfigurations.map(config => {
    return { label: config.name, value: config.id }
}));

onMounted(async () => {
    initialize();
});

const initialize = () => {
    if (newConfiguration.value) {
        deliveryStore.clearEditingConfiguration();
        deliveryStore.loadDefaultFields();
    }

    loading.value = false;
}

const executeInteractable = async (methodName: string) => {
    if (saving.value) return;
    saving.value = true;

    const { status, message, data } = await deliveryStore.executeInteractable(methodName);
    if (!status) {
        alertStore.showError(message ?? "CRM Integration interaction failed.");
    }
    else {
        if (!data || !data.status) {
            alertStore.showError(data?.message || "An error occurred.");
        }
        else {
            alertStore.closeAlert();

            if (data.interactable_fields) {
                deliveryStore.editingCrmConfiguration.payload.interactable_fields = data.interactable_fields;
            }
            if (Object.keys(data.system_fields ?? {})?.length) {
                deliveryStore.updateFieldValues(data.system_fields, 'system_fields');
            }
            if (Object.keys(data.additional_fields ?? {})?.length) {
                deliveryStore.updateFieldValues(data.additional_fields, 'additional_fields');
            }
            if (data.interactables?.length) {
                deliveryStore.editingCrmConfiguration.interactables = data.interactables;
            }
         }
    }

    saving.value = false;
}

const getDefaultCustomField = (): CrmCustomField => ({
    key: '',
    value: '',
});

const addCustomField = () => {
    deliveryStore.editingCrmConfiguration.payload.custom_fields = deliveryStore.editingCrmConfiguration.payload.custom_fields ?? [];
    deliveryStore.editingCrmConfiguration.payload.custom_fields.push(getDefaultCustomField());
}

const validateCustomFields = (validateIndex?: number): boolean => {
    const systemKeys = deliveryStore.editingCrmConfiguration.payload.system_fields.map(field => field.key);
    const additionalKeys = deliveryStore.editingCrmConfiguration.payload.additional_fields.map(field => field.key);
    const customKeys = deliveryStore.editingCrmConfiguration.payload.custom_fields.map(field => field.key);
    const errors: string[] = [];

    const validateField = (customField: CrmCustomField, fieldIndex: number) => {
        if (!customField.key)
            errors.push(`Custom field ${fieldIndex + 1} has no Key name.`);
        else if (systemKeys.includes(customField.key))
            errors.push(`"${customField.key}" is a System Field key and cannot be used as a Custom Field key.`);
        else if (additionalKeys.includes(customField.key))
            errors.push(`"${customField.key}" is an Additional Field key and cannot be used as a Custom Field key.`);
        else {
            customKeys.forEach((key, index) => {
                if (key === customField.key && index !== fieldIndex)
                    errors.push(`"${customField.key}" is used as more than one Custom Field key name. Keys must be unique`);
            });
        }
    }

    if (!validateIndex) {
        for (let i = 0; i < deliveryStore.editingCrmConfiguration.payload.custom_fields.length; i++) {
            validateField(deliveryStore.editingCrmConfiguration.payload.custom_fields[i], i);
        }
    }
    else {
        const target = deliveryStore.editingCrmConfiguration.payload.custom_fields[validateIndex];
        validateField(target, validateIndex);
    }

    if (errors.length)
        alertStore.showError(errors.join("\n"));

    return !errors.length;
}

const validateRequiredFields = (): boolean => {
    const errors: string[] = [];
    deliveryStore.editingCrmConfiguration.payload.system_fields.forEach(field => {
        if (field.required && !field.value?.trim())
            errors.push(`The System Field "${field.display_name}" must have a value.`);
    });
    deliveryStore.editingCrmConfiguration.payload.additional_fields.forEach(field => {
        if (field.required && !field.value?.trim())
            errors.push(`The Additional Field "${field.display_name}" must have a value.`);
    });

    if (errors.length) alertStore.showError(errors.join("\n"));

    return !errors.length;
}

const validateCrm = (): boolean => {
    const errors = [];
    if (!deliveryStore.editingCrmConfiguration.display_name)
        errors.push("The CRM Configuration must have a name.");
    else if (!validateRequiredFields()) return false;
    else if (!validateCustomFields()) return false;

    if (errors.length) alertStore.showError(errors.join("\n"));

    return !errors.length;
}

const deleteCustomField = (index: number) => {
    deliveryStore.editingCrmConfiguration.payload.custom_fields.splice(index, 1);
}

const saveCrmDeliverer = () => {
    if (!validateCrm()) return;

    const payload = JSON.parse(JSON.stringify(deliveryStore.editingCrmConfiguration));
    payload.crm_type_display = deliveryStore.getCrmDisplayNameById(payload.crm_type);

    emit('update:deliveries', payload, props.dataKey);
    closeModal();
}

const closeModal = () => {
    deliveryStore.clearEditingConfiguration();
    emit('close:modal');
}


</script>