<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg  class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                </svg>
                <h5 class="text-md ml-2">Licenses</h5>
            </div>
        </template>

        <div class="relative">
            <LoadingSpinner v-if="loading || !storeInitialized" :small="true" />
            <div class="flex items-center justify-between mb-1">
                <p>Please enter any relevant licenses your company holds.</p>
                <SolidButton
                    @click="toggleLicenseModal(true)"
                >
                    Add License
                </SolidButton>
            </div>
            <div class="mt-10" v-if="!licenseStore.licenses.length">
                <p>No licenses found.</p>
            </div>
            <div class="py-4 flex flex-col gap-y-6">
                <div v-for="license in licenseStore.licenses"
                    class="grid md:grid-cols-2 gap-2 border-t border-slate-200 pt-4 relative"
                >
                    <div class="flex items-center">
                        <p class="font-semibold mr-2">License:</p>
                        <p>{{ license.name }}</p>
                    </div>
                    <div class="flex items-center">
                        <p class="font-semibold mr-2">Industry:</p>
                        <p>{{ license.industry || '-' }}</p>
                    </div>
                    <div class="flex items-center">
                        <p class="font-semibold mr-2">Classification/Type:</p>
                        <p>{{ license.type || '-' }}</p>
                    </div>
                    <div class="flex items-center">
                        <p class="font-semibold mr-2">License Number:</p>
                        <p>{{ license.license_number || '-' }}</p>
                    </div>
                    <div class="flex items-center">
                        <p class="font-semibold mr-2">Date of Issue:</p>
                        <p>{{ useFormatTimestamp(license.issued_at) || 'n/a' }}</p>
                    </div>
                    <div class="flex items-center">
                        <p class="font-semibold mr-2">Expiry Date:</p>
                        <p>{{ useFormatTimestamp(license.expires_at) || 'n/a'  }}</p>
                    </div>
                    <div class="flex items-center">
                        <p class="font-semibold mr-2">State:</p>
                        <p>{{ license.state || '-' }}</p>
                    </div>
                    <div class="flex items-center">
                        <p class="font-semibold mr-2">Country:</p>
                        <p>{{ license.country }}</p>
                    </div>
                    <div class="flex items-center">
                        <p class="font-semibold mr-2">Link:</p>
                        <p>{{ license.url || '-' }}</p>
                    </div>
                    <!--  Action Handle -->
                    <ButtonDropdown class="absolute top-4 right-2">
                        <template v-slot:trigger>
                            <div class="cursor-pointer flex justify-center items-center text-cyan-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                </svg>
                            </div>
                        </template>
                        <div class="transform translate-y-7 bg-white border border-gray-300 shadow rounded absolute top-0 right-0 z-50 transform p-2 w-36">
                            <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                 @click="editLicense(license.id)"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                                <p class="ml-2 pb-0 text-cyan-500">Edit</p>
                            </div>
                            <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                 @click="deleteConfirm(license.id)"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                                <p class="ml-2 pb-0 text-cyan-500">Delete</p>
                            </div>
                        </div>
                    </ButtonDropdown>
                </div>
            </div>
        </div>
    </Module>
    <!--  Create/Edit modal  -->
    <CreateUpdateLicenseModal
        v-if="showLicenseModal"
        @hideLicenseModal="toggleLicenseModal(false)"
    />
    <!--    Delete confirmation Modal    -->
    <Modal
        v-if="showDeleteConfirmModal"
        @clicked:confirm="deleteLicense"
        @clicked:cancel="deleteCancel"
        confirm-label="Delete"
        :small="true"
        :disable-buttons="saving"
    >
        <template v-slot:header>
            Delete this license?
        </template>
        <template v-slot:body>
            Are you sure you wish to delete this license, "{{ licenseStore.getLicenseName(deletingLicenseId) }}"?
        </template>
    </Modal>
</template>

<script setup>
import Module from "@/components/Module.vue";
import { ref } from "vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { useCompanyLicenseStore } from "@/stores/company-licenses.js";
import CreateUpdateLicenseModal from "@/components/company/modals/CreateUpdateLicenseModal.vue";
import Modal from "@/components/Modal.vue";
import { useFormatTimestamp } from "@/composables/useFormatTimestamp.js";
import ButtonDropdown from "@/components/ButtonDropdown.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const props = defineProps({
    storeInitialized: {
        type: Boolean,
        default: false,
    },
});

const licenseStore = useCompanyLicenseStore();

const loading = ref(false);
const saving = ref(false);
const deletingLicenseId = ref(null);

const showLicenseModal = ref(false);
const showDeleteConfirmModal = ref(false);

const editLicense = (licenseId) => {
    licenseStore.editLicense(licenseId);
    toggleLicenseModal(true);
}

const deleteConfirm = (locationId) => {
    deletingLicenseId.value = locationId;
    showDeleteConfirmModal.value = true;
}

const deleteCancel = () => {
    deletingLicenseId.value = null;
    showDeleteConfirmModal.value = false;
}

const deleteLicense = async () => {
    if (saving.value) return;
    saving.value = true;
    const { status, message } = await licenseStore.destroy(deletingLicenseId.value);
    if (!status) {
        errorMessage.value = message;
    }
    saving.value = false;
    deleteCancel();
}

const toggleLicenseModal = (show) => {
    showLicenseModal.value = !!show;
}

</script>