<template>
    <div class="grid grid-cols-1 xl:grid-cols-9 xl:gap-5 p-4 xl:max-w-screen-2xl">
        <div class="xl:col-span-6">
            <div class="grid grid-col-1 gap-4 md:gap-5">
                <slot></slot>
            </div>
        </div>

        <div class="xl:col-span-3 mt-6 xl:mt-0">
            <div class="grid grid-col-1 gap-4 md:gap-5">
                <slot name="side"></slot>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>