<template>
    <div class="bg-white flex flex-col items-center p-20 py-10 gap-6 rounded-xl w-128">
        <LoadingSpinner v-if="locked" :small="true"/>

        <fixr-roofing-calculator-logo/>

        <div class="flex items-center gap-3 container">
            <div class="h-0.5 w-full bg-gray-200 flex-1"></div>
            <p class="font-semibold text-2xl">Login</p>
            <div class="h-0.5 w-10 bg-gray-200 flex-1"></div>
        </div>

        <span v-if="errors[0]" class="text-center text-red-500">{{errors[0]}}</span>

        <form class="flex flex-col container">
            <TextInput
                v-model="email"
                label="Email"
                type="email"
                class="mb-4"
                :disabled="locked"
            ></TextInput>
            <TextInput
                v-model="password"
                label="Password"
                type="password"
                class="mb-4"
                :disabled="locked"
            ></TextInput>
            <label class="flex items-center">
                <input :disabled="locked" class="w-4 mr-1" type="checkbox">
                <span class="text-sm text-gray-700">Remember me</span>
            </label>
            <div class="w-full">
                <CustomButton
                    :loading="locked"
                    @click.prevent="login"
                    type="submit"
                >
                    Login
                </CustomButton>
            </div>
            <div class="flex items-center justify-center">
                <a @click="forgotPassword" class="cursor-pointer pb-0 text-gray-700 underline">Forgot your password?</a>
            </div>
        </form>
    </div>
</template>

<script setup>
import {ref} from "vue";
import FixrRoofingCalculatorLogo from "@/components/shared/FixrRoofingCalculatorLogo.vue";
import TextInput from "@/components/authentication/components/TextInput.vue";
import CustomButton from "@/components/authentication/components/CustomButton.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const emit = defineEmits(["forgot-password", "login"]);
const props = defineProps({
    locked: {
        type: Boolean,
        default: false
    },
    errors: {
        type: Array,
        default: []
    }
});

const email = ref(null);
const password = ref(null);

const login = () => {
    if (!props.locked)
        emit('login', {email: email.value, password: password.value});
}

const forgotPassword = () => {
    emit("forgot-password", email.value);
}
</script>