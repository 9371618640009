<template>
    <MainGrid>
        <NotificationSettingsModule :stores-initialized="storesInitialized" />
    </MainGrid>
</template>

<script setup>
import MainGrid from "@/components/layouts/MainGrid.vue";
import NotificationSettingsModule from "@/components/company/NotificationSettingsModule.vue";
import { ref } from "vue";
import { useCompanyUsersStore } from "@/stores/company-users.js";
import { useNotificationSettingsStore } from "@/stores/notification-settings.js";

const notificationSettingsStore = useNotificationSettingsStore();
const storesInitialized = ref(false);

storesInitialized.value = false;

// Force refresh the store each visit to this view, as notifiable contacts will need to be
// recalculated if the User has just come from making changes to contacts
notificationSettingsStore.initialize(true).then(() => storesInitialized.value = true);

</script>