import { useServicesStore } from "@/stores/services";
import { reactive, ref, toRaw } from "vue";
import { defineStore } from "pinia";


export const useLocationsStore = defineStore('addresses', () => {
    const servicesStore = useServicesStore();

    const locations = ref([]);

    const editingLocation = reactive({
        id: null,
        name: '',
        phone: '',
        is_primary: null,
        address: {
            id: null,
            address_1: '',
            address_2: '',
            city: '',
            state: '',
            zip_code: '',
            country: 'US',
        },
    });

    const storeInitialized = ref(false);

    function getLocationById(locationId, returnIndex = false) {
        return returnIndex
            ? locations.value.findIndex(location => location.id === locationId)
            : locations.value.find(location => location.id === locationId);
    }

    function getLocationNameById(locationId) {
        const targetLocation = getLocationById(locationId);
        return targetLocation?.name ?? 'Unknown Office';
    }

    async function initialize() {
        if (storeInitialized.value) return { status: true }
        const resp = await servicesStore.apiService.getCompanyLocations().catch(e=>e);
        if (resp.data?.data?.status) {
            locations.value = resp.data.data.locations;
            storeInitialized.value = true;
            return { status: true }
        }
        else {
            return { status: false, message: resp.response?.data?.message || resp.err || `An unknown error occurred.`}
        }
    }

    function editLocation(locationId) {
        const targetLocation = getLocationById(locationId);
        if (targetLocation) {
            Object.assign(editingLocation, targetLocation);
            return true;
        }
        return false;
    }

    async function saveLocation() {
        const resp = editingLocation.id
            ? await servicesStore.apiService.updateCompanyLocation(toRaw(editingLocation)).catch(e=>e)
            : await servicesStore.apiService.createCompanyLocation(toRaw(editingLocation)).catch(e=>e);
        if (resp.data?.data?.status) {
            if (editingLocation.id) {
                const modifiedIndex = getLocationById(editingLocation.id, true);
                if (modifiedIndex > -1) locations.value[modifiedIndex] = resp.data.data.location
            } else {
                locations.value.push(resp.data.data.location);
            }
            clearEditing();
            return { status: true }
        }
        else {
            return { status: false, message: resp.response?.data?.message || resp.err || `An error occurred saving a location.` }
        }
    }

    async function deleteLocation(locationId) {
        const resp = await servicesStore.apiService.deleteCompanyLocation(locationId);
        if (resp.data?.data?.status) {
            const trashedLocationIndex = getLocationById(locationId, true);
            locations.value.splice(trashedLocationIndex, 1);
            return { status: true }
        }
        else {
            return { status: false, message: resp.response?.data?.message || resp.err || `An error occurred deleting a new location.` }
        }
    }

    async function makeLocationPrimary(locationId) {
        const resp = await servicesStore.apiService.makeCompanyLocationPrimary(locationId);
        if (resp.data?.data?.status) {
            locations.value.forEach(location => {
                location.is_primary = location.id === locationId;
            });
            return { status: true }
        }
        else {
            return { status: false, message: resp.response?.data?.message || resp.err || `An error occurred marking a primary location.` }
        }
    }

    function clearEditing() {
        Object.assign(editingLocation, {
            id: null,
            name: '',
            phone: '',
            address: {
                id: null,
                address_1: '',
                address_2: '',
                city: '',
                state: '',
                zip_code: '',
                country: 'US',
            },
        });
    }

    function clearStore() {
        storeInitialized.value = false;
        locations.value = [];
    }

    function $reset() {
        clearStore();
        clearEditing();
    }

    return {
        initialize,
        getLocationNameById,
        editLocation,
        saveLocation,
        clearEditing,
        deleteLocation,
        makeLocationPrimary,
        locations,
        editingLocation,
        $reset,
    }
});