<template>
    <div>
        <Module class="mt-4 mx-4 relative"
                :class="[ saving || loading ? 'grayscale-[60%] opacity-50 pointer-events-none' : '']"
        >
            <template v-slot:title>
                <div class="flex items-center justify-between py-2">
                    <div class="flex items-center">
                        <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                        </svg>
                        <h2 class="text-lg">
                            {{ getCampaignWizardHeader() }}
                        </h2>
                    </div>
                </div>
            </template>
            <template v-slot:default>
                <LoadingSpinner v-if="campaignsLoading || loading || saving" :small="true"/>
                <div v-if="!loading && !campaignsLoading">
                    <!--    CUSTOM HEADER    -->
                    <WizardHeaderContainer />
                    <!--    SLIDES    -->
                    <WizardSlideContainer
                        :configuration="moduleStore.wizardConfiguration"
                        :slide-map="moduleStore.wizardComponentMap"
                        @close:wizard="toggleShowConfirmCancelModal(true)"
                        @save:slide="updateSlidePayload"
                        @save:wizard="saveCampaign"
                    />
                </div>
            </template>
        </Module>
        <Modal
            v-if="showConfirmCancelModal"
            :small="true"
            confirm-label="Exit"
            @clicked:confirm="cancelWizard"
            @clicked:cancel="toggleShowConfirmCancelModal(false)"
        >
            <template v-slot:header>
                Exit Campaign Wizard
            </template>
            <template v-slot:body>
                <p class="text-center">Are you sure you wish to exit the Campaign Wizard? All unsaved changes will be lost.</p>
            </template>
        </Modal>
    </div>
</template>

<script setup lang="ts">
import Module from "@/components/Module.vue";
import WizardSlideContainer from "@/components/v4/wizard/generics/WizardSlideContainer.vue";
import { ref } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useCampaignModulesStore } from "@/stores/v4/campaign-modules.js";
import { useAlertStore } from "@/stores/v4/alerts";
import { useProperCase } from "@/composables/useProperCase";
import { useServicesStore } from "@/stores/services";
import { useCompanyStore } from "@/stores/company";
import WizardHeaderContainer from "@/components/v4/wizard/generics/WizardHeaderContainer.vue";
import { useFutureCampaignStore } from "@/stores/v4/future-campaigns";
import { useWizardStore } from "@/stores/v4/wizard";
import Modal from "@/components/Modal.vue";

interface Props {
	campaignsLoading: boolean,
}
withDefaults(defineProps<Props>(), {
	campaignsLoading: false,
});

const emit = defineEmits(['close:campaignWizard']);

const moduleStore = useCampaignModulesStore();
const alertStore = useAlertStore();
const services = useServicesStore();
const companyStore = useCompanyStore();
const campaignStore = useFutureCampaignStore();
const wizardStore = useWizardStore();

const saving = ref(false);
const loading = ref(false);
const showConfirmCancelModal = ref(false);

const getCampaignWizardHeader = () => {
    const header = useProperCase(`${services.apiServiceV4.getProductKey()} Campaign Wizard`);
    const industry = companyStore.getSelectedIndustryName();
    const service = companyStore.getSelectedServiceName();

    return `${header} - (${industry} / ${service})`;
}

const updateSlidePayload = (payload: GenericObject, slideKey: string) => {
    campaignStore.updateModulePayload(slideKey, payload);
    campaignStore.appendAdditionalCampaignComponents(wizardStore.fetchReservedComponents());
}

const saveCampaign = async (additionalInputs: GenericObject) => {
    if (saving.value) return;
    saving.value = true;

    campaignStore.appendAdditionalCampaignComponents(additionalInputs);
    const { status, message } = await campaignStore.saveCampaign();
    if (!status) {
        alertStore.showError(message ?? "An error occurred saving the Campaign.");
    }
    else {
        alertStore.showInfo("Campaign was successfully saved");
        await campaignStore.getCampaigns();
        cancelWizard();
    }

    saving.value = false;
}

const toggleShowConfirmCancelModal = (show?: boolean) => {
    showConfirmCancelModal.value = show === undefined
        ? !showConfirmCancelModal.value
        : show;
}

const cancelWizard = () => {
    wizardStore.resetWizard();
    emit('close:campaignWizard');
}
</script>