<template>
    <div>
        <div class="max-w-5xl">
            <h4 class="font-semibold pb-4">
                Appointment Budget
            </h4>
            <div class="relative hidden z-50 md:sticky md:top-20 md:grid md:grid-cols-4 bg-cyan-50 text-left font-bold py-2 items-center border-b border-gray-200 text-xs text-gray-700 uppercase md:px-5">
                <p>Active</p>
                <p>Name</p>
                <p class="md:col-span-2">Budget</p>
            </div>
            <div v-for="budget in campaignStore.editingCampaign.appointment_budgets"
                 :key="budget.quality_tier"
                 class="relative border-b even:bg-gray-50 text-gray-600 text-sm text-left items-center grid md:grid-cols-4 gap-4 md:gap-0 p-4 md:py-4 md:px-5">
                <div>
                    <p class="font-bold text-sm md:hidden text-gray-900">Active</p>
                    <ToggleSwitch
                        v-model="budget.status"
                        :small="true"
                        color="green"
                    />
                </div>
                <div class="">
                    <p class="font-bold text-sm md:hidden text-gray-900">Name</p>
                    <div class="mr-1">
                        <p class="font-bold">
                            {{ budget.quality_tier }}
                        </p>
                        <p class="font-xs text-gray-400">
                            {{ budget.quality_tier === AppointmentTypes.IN_HOME ? 'Face to face meetings with customer' : 'Zoom / Video call with customer' }}
                        </p>
                    </div>
                </div>
                <div class="md:col-span-2">
                    <div v-if="budget.status" class="flex items-center">
                        <CustomSelect
                            :label="null"
                            :options="BudgetValueTypes"
                            v-model="budget.budget_type"
                            class="mr-3 mt-2"
                        />

                        <CustomInput
                            v-if="budget.budget_type !== BUDGET_TYPE_NO_LIMIT"
                            type="text"
                            :prefix-text="budget.budget_type === BUDGET_TYPE_DAILY_SPEND ? '$' : ''"
                            classes="max-w-[10rem]"
                            v-model="budget.budget_value"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="justify-center flex flex-col max-w-5xl mt-7">
            <div class="flex flex-row items-center pb-4">
                <h4 class="font-semibold">
                    Appointment Prices
                </h4>
            </div>
            <div class="grid md:grid-cols-2">
                <div>
                    <div class="bg-cyan-50 px-5 py-2 font-bold text-xs text-gray-700 border-b border-gray-200 uppercase">{{ AppointmentTypes.IN_HOME }}</div>
                    <div
                        v-for="saleType in campaignStore.campaignConfigData.appointments_sales_types.slice(0, -1)"
                        class="grid grid-cols-2 gap-3 py-3 border-b border-gray-200 odd:bg-gray-50 text-sm text-gray-500"
                    >
                        <div class="ml-5 flex items-center">
                            {{ saleType.name }}
                            <Tooltip>
                                {{ saleType.description }}
                            </Tooltip>
                        </div>
                        <div v-if="prices[AppointmentTypes.IN_HOME] && prices[AppointmentTypes.IN_HOME][saleType.key]">
                            ${{ prices[AppointmentTypes.IN_HOME][saleType.key]['min'] }} - ${{ prices[AppointmentTypes.IN_HOME][saleType.key]['max'] }}
                        </div>
                        <div v-else>Searching prices...</div>
                    </div>
                </div>
            </div>
            <div class="grid md:grid-cols-2 mt-5">
                <div>
                    <div class="bg-cyan-50 px-5 py-2 font-bold text-xs text-gray-700 border-b border-gray-200 uppercase">{{ AppointmentTypes.ONLINE }}</div>
                    <div
                        v-for="saleType in campaignStore.campaignConfigData.appointments_sales_types"
                        class="grid grid-cols-2 gap-3 py-3 border-b border-gray-200 odd:bg-gray-50 text-sm text-gray-500"
                    >
                        <div class="ml-5 flex items-center">
                            {{ saleType.name }}
                            <Tooltip>
                                {{ saleType.description }}
                            </Tooltip>
                        </div>
                        <div v-if="prices[AppointmentTypes.ONLINE] && prices[AppointmentTypes.ONLINE][saleType.key]">
                            ${{ prices[AppointmentTypes.ONLINE][saleType.key]['min'] }} - ${{ prices[AppointmentTypes.ONLINE][saleType.key]['max'] }}
                        </div>
                        <div v-else>Searching prices...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import CustomInput from "@/components/inputs/CustomInput.vue";
import Tooltip from "@/components/Tooltip.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { useCampaignStore } from "@/stores/campaigns.js";
import ToggleSwitch from "@/components/inputs/ToggleSwitch.vue";
import { usePricingStore } from "@/stores/pricing.js";
import {AppointmentTypes, BUDGET_TYPE_DAILY_APPOINTMENTS, BUDGET_TYPE_DAILY_SPEND, BUDGET_TYPE_NO_LIMIT, BudgetValueTypes} from "@/Utilities/Appointment";


const campaignStore = useCampaignStore();
const pricingStore = usePricingStore();

const loading = ref(false);
const saving = ref(false);
const prices = ref([]);
const budgetValidationErrorMessage = ref('');

onMounted(() => {
    loading.value = true;
    if (campaignStore.editingCampaign.id) {
        pricingStore.getPriceRangeForCampaign(campaignStore.editingCampaign.id, 'appointments').then(({ status }) => {
            if (status) {
                prices.value = pricingStore.priceRanges.appointments[campaignStore.editingCampaign.id] ?? {};
            }
        }).catch(e => {
            console.error(e);
        }).finally(() => loading.value = false);
    } else {
        const zipCodeIds = Object.keys(campaignStore.editingCampaign.zip_codes);
        pricingStore.getPriceRangeForZipCodes(zipCodeIds, 'appointments').then(({ status }) => {
            if (status) {
                prices.value = pricingStore.priceRanges.appointments.new ?? {};
            }
        }).catch(e => {
            console.error(e);
        }).finally(() => loading.value = false);
    }
});

const validateBudgetInput = () => {
    const budgetErrors = [];

    if (!campaignStore.editingCampaign.appointment_budgets.filter(budget => !!budget.status).length) {
        budgetErrors.push('At least one appointment must be enabled.');
    } else {
        campaignStore.editingCampaign.appointment_budgets.forEach(budget => {
            switch (budget.budget_type) {
                case BUDGET_TYPE_DAILY_APPOINTMENTS:
                    if (budget.budget_value < campaignStore.campaignConfigData.minimumDailyAppointments)
                        budgetErrors.push(`${budget.quality_tier}: daily appointment limit must be at least ${campaignStore.campaignConfigData.minimumDailyAppointments}`);
                    break;
                case BUDGET_TYPE_DAILY_SPEND:
                    if (budget.budget_value < campaignStore.campaignConfigData.minimumDailyAppointmentSpend)
                        budgetErrors.push(`${budget.quality_tier}: daily appointment spend must be at least $${campaignStore.campaignConfigData.minimumDailyAppointmentSpend}`);
                    break;
            }
        });
    }

    if (budgetErrors.length > 0) budgetValidationErrorMessage.value = budgetErrors.join('\n');

    return !budgetErrors.length;
}

const validateSlide = () => {
    return validateBudgetInput()
        ? { status: true }
        : { status: false, message: budgetValidationErrorMessage.value };
}
defineExpose({ validateSlide });


</script>