<template>
    <div>
        <Modal
            @clicked:confirm="saveCrmDelivery"
            @clicked:cancel="closeModal"
            :confirm-label="newCrmDelivery ? 'Create' : 'Update'"
            key="crmDeliveryModal"
        >
            <template v-slot:header>
                <div class="flex items-center">
                    <svg  class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    </svg>
                    <h5 class="text-md">{{ newCrmDelivery ? 'Add' : 'Edit'}} CRM Delivery</h5>
                </div>
            </template>
            <template v-slot:body>
                <div v-if="loading">
                    <p class="text-gray-500 text-center">Loading...</p>
                </div>
                <div v-else
                    :class="[saving ? 'pointer-events-none grayscale-[50%] opacity-50' : '']"
                >
                    <div class="grid grid-cols-2 items-start gap-4">
                        <CustomInput
                            label="Name"
                            v-model="campaignStore.editingCrmDelivery.name"
                            :required-field="true"
                            placeholder="Delivery name..."
                        />
                        <CustomSelect
                            :dropdown-disabled="!newCrmDelivery"
                            :options="crmProviderOptions"
                            label="Integrated CRM Providers"
                            v-model="campaignStore.editingCrmDelivery.crm.id"
                            @update:modelValue="updateCrmFields"
                        />
                    </div>
                    <div v-if="errorMessage" class="text-red-900 text-sm pt-8 pb-4 text-center">
                        {{ errorMessage }}
                    </div>
                    <div v-if="crmSystemFields.length" class="border-t border-gray-200 pt-6 mt-8">
                        <h2 class="font-semibold pb-3">System Fields</h2>
                        <div class="grid grid-cols-2 items-start gap-x-4">
                            <CustomInput
                                v-for="systemField in crmSystemFields"
                                :key="systemField.id"
                                v-model="campaignStore.editingCrmDelivery.integration_fields.system_fields[systemField.key]"
                                :label="`${systemField.mandatory ? '*' : ''}${systemField.name}`"
                            />
                        </div>
                    </div>
                    <div v-if="crmSystemFields.length" class="border-t border-gray-200 pt-6 mt-8">
                        <h2 class="font-semibold pb-3">Fields</h2>
                        <div class="grid grid-cols-2 items-start gap-4 gap-y-6">
                            <CustomInput
                                v-for="field in crmFields"
                                :key="field.id"
                                v-model="campaignStore.editingCrmDelivery.integration_fields.fields[field.key]"
                                :label="`${field.mandatory ? '*' : ''}${field.name}`"
                                :required-field="!!field.mandatory"
                            />
                        </div>
                        <div class="grid grid-cols-1 my-6"
                            v-if="crmHasCustomFields"
                        >
                            <div v-for="(customField) in crmCustomFields"
                                :key="customField.index"
                                class="grid grid-cols-2 col-start-1 items-start gap-4 relative mb-6 w-full"
                            >
                                <CustomInput
                                    label="Custom Field Name"
                                    v-model="customField.key"
                                    @change="validateCustomKey(customField.key)"
                                />
                                <CustomInput
                                    label="Custom Field Value"
                                    v-model="customField.value"
                                />
                                <button
                                    class="text-red-900 hover:text-red-700 absolute top-[-0.125rem] right-2"
                                    @click="deleteCustomField(customField.index)"
                                    title="Delete Row"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5">
                                        <path fill-rule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clip-rule="evenodd" />
                                    </svg>

                                </button>
                            </div>
                            <OutlineButton
                                class="col-span-2"
                                @click="addCustomField"
                            >
                                <p>Add Custom Field</p>
                            </OutlineButton>
                        </div>

                    </div>
                </div>
            </template>
        </Modal>
        <Modal
            v-if="errorPopupMessage"
            :small="true"
            :show-cancel-button="false"
            confirm-label="Ok"
            @clicked:confirm="errorPopupMessage = null"
        >
            <template v-slot:header>
                CRM Integration error
            </template>
            <template v-slot:body>
                {{ errorPopupMessage }}
            </template>
        </Modal>
    </div>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import { onMounted, ref, toRaw } from "vue";
import { useCampaignStore } from "@/stores/campaigns.js";
import CustomInput from "@/components/inputs/CustomInput.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import OutlineButton from "@/components/inputs/OutlineButton.vue";

const campaignStore = useCampaignStore();

const newCrmDelivery = ref(!campaignStore.editingCrmDelivery.id);

const saving = ref(false);
const loading = ref(true);
const errorMessage = ref(null);
const errorPopupMessage = ref(null);

const crmProviderOptions = ref([]);

const crmFields = ref([]);
const crmSystemFields = ref([]);
const crmHasCustomFields = ref(false);
const crmCustomFields = ref([]);
const customIndex = ref(0);

const emit = defineEmits(['hideCrmDeliveryModal']);

onMounted(async () => {
    loading.value = true;
    if (campaignStore.editingCrmDelivery.id) {
        const { status, message } = await campaignStore.getCrmDeliveryDetail();
        if (!status) { errorMessage.value = message; }
        else {
            updateCrmProviderOptions();
            getCustomFieldsFromStore();
        }
    }
    else {
        updateCrmProviderOptions();
    }
    loading.value = false;
});

const updateCrmProviderOptions = () => {
    crmProviderOptions.value = campaignStore.campaignConfigData.crm_providers.map(crmOption => ({ label: crmOption.name, value: crmOption.id }));
    campaignStore.editingCrmDelivery.crm.id = campaignStore.editingCrmDelivery.crm.id ?? crmProviderOptions.value[0]?.value ?? null;
    if (campaignStore.editingCrmDelivery.crm.id) updateCrmFields();
}

const updateCrmFields = () => {
    const { fields, systemFields } = campaignStore.getCrmFieldsById(campaignStore.editingCrmDelivery.crm.id);
    crmHasCustomFields.value = campaignStore.editingCrmDelivery.crm.id !== 1;
    crmFields.value = fields;
    crmSystemFields.value = systemFields;
    crmFields.value.forEach(field => { campaignStore.editingCrmDelivery.integration_fields.fields[field.key] = field.default; });
    crmSystemFields.value.forEach(field => { campaignStore.editingCrmDelivery.integration_fields.system_fields[field.key] = campaignStore.editingCrmDelivery.integration_fields.system_fields[field.key] || field.default; })
}

const saveCrmDelivery = async () => {
    if (!updateCustomFieldsInStore()) return;
    saving.value = true;
    const { status, message, id } = await campaignStore.saveCrmDeliveryMethod();
    if (!status) {
        errorMessage.value = message;
    }
    else {
        if (newCrmDelivery.value) {
            campaignStore.campaignConfigData.crm_deliveries.push({ ...toRaw(campaignStore.editingCrmDelivery), id });
            campaignStore.editingCampaign.crm_deliveries[id] = true;
        }
        else {
            campaignStore.editingCampaign.crm_deliveries[campaignStore.editingCrmDelivery.id] = true;
        }
        campaignStore.clearCrmEditing();
        closeModal();
    }
    saving.value = false;
}

const addCustomField = () => {
    customIndex.value ++;
    crmCustomFields.value.push({
        index: customIndex.value,
        key: '',
        value: '',
    });
}

const deleteCustomField = (customIndex) => {
    for (let i=crmCustomFields.value.length-1; i>=0; i--) {
        if (crmCustomFields.value[i].index === customIndex) {
            crmCustomFields.value.splice(i, 1);
            break;
        }
    }
}

const validateCustomKey = (keyName) => {
    const keysInUse = Object.values(crmFields.value).map(field => field.key.toLowerCase());
    return !(keyName.trim() === '') && !(keysInUse.includes(keyName.toLowerCase()));
}

const updateCustomFieldsInStore = () => {
    campaignStore.editingCrmDelivery.integration_fields.custom_fields = {};
    const errors = [];
    crmCustomFields.value.forEach(field => {
        if (!validateCustomKey(field.key)) {
            errors.push(`The following custom field name is invalid: "${field.key}"`);
        }
        else {
            campaignStore.editingCrmDelivery.integration_fields.custom_fields[field.key] = field.value;
        }
    });
    if (errors.length) {
        errorPopupMessage.value = errors.join('\n');
        return false;
    }
    else {
        return true;
    }
}

const getCustomFieldsFromStore = () => {
    for (const customField in campaignStore.editingCrmDelivery.integration_fields.custom_fields) {
        customIndex.value ++;
        crmCustomFields.value.push({
            index: customIndex.value,
            key: customField,
            value: campaignStore.editingCrmDelivery.integration_fields.custom_fields[customField]
        });
    }
}

const closeModal = () => {
    emit('hideCrmDeliveryModal');
}


</script>