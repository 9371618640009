<template>
    <MainGrid>
        <AddressesModule :addresses-store-initialized="locationsStoreInitialized"/>
    </MainGrid>
</template>

<script setup>
import MainGrid from "@/components/layouts/MainGrid.vue";
import AddressesModule from "@/components/company/AddressesModule.vue";
import { ref } from "vue";
import { useLocationsStore } from "@/stores/locations.js";

const locationsStore = useLocationsStore();
const locationsStoreInitialized = ref(false);
locationsStore.initialize().then(() => { locationsStoreInitialized.value = true });


</script>