<template>
    <Modal
        @clicked:confirm="saveLocation"
        @clicked:cancel="toggleConfirmModal(true)"
    >
        <template v-slot:header>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>

                <h5 class="text-md">{{ newAddress ? 'Add' : 'Edit'}} Address</h5>
            </div>
        </template>
        <template v-slot:body>
            <div class="relative min-h-[20rem]">
                <LoadingSpinner v-if="loading || saving" :small="true" />
                <div v-else class="grid xl:grid-cols-3 gap-x-6 gap-y-6 grid-flow-row-dense">
                    <CustomInput
                        class="col-span-2"
                        label="*Office Name"
                        placeholder="Head Office..."
                        v-model="locationStore.editingLocation.name"
                    />
                    <CustomInput
                        class="col-span-2"
                        placeholder="2 Example St..."
                        label="*Address Line 1"
                        v-model="locationStore.editingLocation.address.address_1"
                    />
                    <CustomInput
                        class="col-span-2"
                        label="Address Line 2"
                        v-model="locationStore.editingLocation.address.address_2"
                    />
                    <CustomInput
                        class="xl:col-start-3"
                        label="Office Phone"
                        placeholder="555 555 5555..."
                        v-model="locationStore.editingLocation.phone"
                    />
                    <CustomInput
                        class=""
                        placeholder="Exampletown..."
                        label="*City"
                        v-model="locationStore.editingLocation.address.city"
                    />
                    <CustomSelect
                        label="*State"
                        :options="stateOptions"
                        v-model="locationStore.editingLocation.address.state"
                    />
    <!--    Enable Country Selector when required
                    <CustomSelect
                        label="Select Country"
                        :options="countryOptions"
                        selected-option="US"
                    />
    -->
                    <CustomInput
                        placeholder="55555..."
                        label="*Zip Code"
                        v-model="locationStore.editingLocation.address.zip_code"
                    />
                </div>
            </div>

            <div v-if="errorMessage"
                 class="text-red-900 text-sm pt-8 pb-4 text-center"
             >
                {{ errorMessage }}
            </div>
        </template>
    </Modal>
    <Modal v-if="showConfirmModal"
        @clicked:confirm="hideAddressModal"
        @clicked:cancel="toggleConfirmModal(false)"
        confirm-label="Confirm"
        :small="true"
    >
        <template v-slot:header>
            Cancel Address
        </template>
        <template v-slot:body>
            Are you sure you wish to cancel updating this Address?
        </template>
    </Modal>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import { useLocationsStore } from "@/stores/locations.js";
import { onBeforeMount, ref } from "vue";
import CustomInput from "@/components/inputs/CustomInput.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { useLocalityDataStore } from "@/stores/locality-data.js";
import { useProperCase } from "@/composables/useProperCase.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const loading = ref(true);
const saving = ref(false);

const showConfirmModal = ref(false);

const errorMessage = ref(null);

const countryOptions = ref([]);
const stateOptions = ref([]);

const locationStore = useLocationsStore();
const localityData = useLocalityDataStore();

const newAddress = ref(!locationStore.editingLocation.id);

const requiredLocationFields = [ 'name' ];
const requiredAddressFields = [ 'address_1', 'city', 'state', 'zip_code' ];

const emit = defineEmits([ 'hideAddressModal' ]);

onBeforeMount(async () => {
     await localityData.initialize();
     countryOptions.value = localityData.countries.map(country => ({ label: country.code, value: country.code }));
     stateOptions.value = [ {label: '-', value: '' }, ...localityData.states.map(state => ({ label: state.state_name, value: state.state_key })) ];
     loading.value = false;
});

const validateInput = () => {
    const missingFields = [];
    requiredLocationFields.forEach(fieldName => {
        if (!locationStore.editingLocation[fieldName]) missingFields.push(useProperCase(fieldName));
    });
    requiredAddressFields.forEach(fieldName => {
        if (!locationStore.editingLocation.address[fieldName]) missingFields.push(useProperCase(fieldName));
    });
    if (missingFields.length) {
        errorMessage.value = `The following fields are required: ${missingFields.join(', ')}.`;
        return false;
    }
    return true;
}

const saveLocation = async () => {
    if (saving.value) return;
    errorMessage.value = null;
    if (!validateInput()) return;
    saving.value = true;
    const { status, message } = await locationStore.saveLocation();
    if (!status) {
        errorMessage.value = message;
    }
    else {
        hideAddressModal();
    }
    saving.value = false;
}

const toggleConfirmModal = (showModal) => {
    showConfirmModal.value = !!showModal;
}

const hideAddressModal = () => {
    locationStore.clearEditing();
    toggleConfirmModal(false);
    emit('hideAddressModal');
}


</script>