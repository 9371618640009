<template>
    <div class="grid h-full">
        <div class="pb-3 pt-4">
            <p class="mx-4 font-semibold text-xs mb-3 text-gray-500 uppercase">Industry Management</p>
            <div
                v-for="link in industryLinks"
                @click="toggleItem(link.id)"
                :class="{'text-cyan-500 bg-cyan-25': activeLink.id === link.id}"
            >
                <div v-if="activeLink.id === link.id && link.children !== undefined && link.children.length > 0">
                    <div>
                        <div class="relative flex items-center hover:text-cyan-500 transition duration-200 cursor-pointer pl-4"
                             :class="[activeLink.id === link.id ? 'text-cyan-500 py-2' : 'text-black']"
                        >
                            <component class="w-6 text-cyan-500 outline-cyan-500" :is="link.image" />
                            <p class="pl-2 pb-0 font-display text-base-2 font-medium">{{ link.name }}</p>
                        </div>

                        <div>
                            <div v-for="child in link.children" class="flex">
                                <div v-if="child.enabled" class="block w-full">
                                    <router-link :to="'/' + child.id">
                                        <div @click="$emit('item-clicked')">
                                            <p
                                                class="cursor-pointer pl-14 hover:text-cyan-500 transition duration-200 font-display py-1.5"
                                                :class="{'text-black': !route.name.endsWith(child.id), 'bg-cyan-50 text-cyan-500 border-r-2 border-cyan-500': route.name.endsWith(child.id)}"
                                            >
                                                {{ child.text }}
                                            </p>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RouterLink v-else :to="`/${link.id}`">
                    <div>
                        <div class="relative flex items-center hover:text-cyan-500 transition duration-200 pl-4 py-2"
                             :class="{'text-black': !activeLink.id === link.id, 'text-cyan-500 border-r-2 border-cyan-500 font-medium bg-cyan-25': activeLink.id === link.id}"
                        >
                            <component class="w-6 outline-current" :is="link.image" />
                            <p class="pl-2 pb-0 font-display text-base-2">{{ link.name }}</p>
                        </div>
                    </div>
                </RouterLink>
            </div>
        </div>
        <div class="flex items-end mb-6">
            <div class="w-full">
                <div class="pt-4 border-t" v-if="company.industries.length > 0">
                    <p class="px-4 font-semibold text-xs mb-3 text-gray-500 uppercase">Global Management</p>
                </div>
                <div >
                    <div
                        v-for="link in globalLinks"
                        @click="toggleItem(link.id)"
                        :class="{'text-cyan-500 bg-cyan-25': activeLink.id === link.id}"
                    >
                        <div v-if="activeLink.id === link.id && link.children !== undefined && link.children.length > 0">
                            <div>
                                <div class="relative flex items-center hover:text-cyan-500 transition duration-200 cursor-pointer pl-4"
                                     :class="[activeLink.id === link.id ? 'text-cyan-500 py-2' : 'text-black']"
                                >
                                    <component class="w-6 text-cyan-500 outline-cyan-500" :is="link.image" />
                                    <p class="pl-2 pb-0 font-display text-base-2 font-medium">{{ link.name }}</p>
                                </div>

                                <div>
                                    <div v-for="child in link.children" class="flex">
                                        <div v-if="child.enabled" class="block w-full">
                                            <router-link :to="'/' + child.id">
                                                <div @click="$emit('item-clicked')">
                                                    <p
                                                        class="cursor-pointer pl-14 hover:text-cyan-500 transition duration-200 font-display py-1.5"
                                                        :class="{'text-black': !route.name.endsWith(child.id), 'bg-cyan-50 text-cyan-500 border-r-2 border-cyan-500': route.name.endsWith(child.id)}"
                                                    >
                                                        {{ child.text }}
                                                    </p>
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <RouterLink v-else :to="`/${link.id}`">
                            <div>
                                <div class="relative flex items-center hover:text-cyan-500 transition duration-200 pl-4 py-2"
                                     :class="{'text-black': !activeLink.id === link.id, 'text-cyan-500 border-r-2 border-cyan-500 font-medium bg-cyan-25': activeLink.id === link.id}"
                                >
                                    <component class="w-6 outline-current" :is="link.image" />
                                    <p class="pl-2 pb-0 font-display text-base-2">{{ link.name }}</p>
                                </div>
                            </div>
                        </RouterLink>
                    </div>
                </div>
                <div class="mb-4">
                    <AccountManager
                        :account-manager="company.accountManager"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import {useCompanyStore} from "@/stores/company";
import { computed, ref } from "vue";
import {useRoute, RouterLink} from "vue-router";
import AccountManager from "../shared/AccountManager.vue";
import { useNavigationStore } from "@/stores/v4/navigation";

const navLinkStore = useNavigationStore();

const company = useCompanyStore();
const route = useRoute();

const links = ref(navLinkStore.links);

const toggleItem = (_link) => {};

const activeLink = computed(() => {
    const active = links.value.filter(
      link => link.id === route.name || (link.children !== undefined && link.children.filter(child => route.name === child.id).length > 0)
    );

    return active.length > 0 ? active[0] : {};
});

const enabledLinks = computed(() => links.value.filter(item => item.enabled));

const industryLinks = computed(() => enabledLinks.value.filter(item => !item.global));
const globalLinks = computed(() => enabledLinks.value.filter(item => item.global));
</script>