<template>
    <Module class="relative">
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"/>
                </svg>

                <h5 class="text-md">Logo</h5>
            </div>
        </template>

        <div class="relative">
            <LoadingSpinner v-if="loading" :small="true" />
            <div class="flex flex-col md:flex-row md:items-center">
                <div class="p-2 flex items-center justify-center h-56 w-56 border border-gray-200 bg-cover bg-center">
                    <img :src="logoUrl" :alt="`${company.name} logo`" v-if="logoUrl && logoUrl.trim().length > 0"/>
                    <p v-else class="text-gray-500">No Logo Uploaded</p>
                </div>

                <div class="md:ml-6 my-5 md:my-0">
                    <p class="font-medium text-md">Please note that logos and images cannot contain contact details.</p>

                    <ul class="list-disc list-inside font-light text-cyan-500 ml-2 mt-2">
                        <li>Recommended size: 200 x 200 pixels</li>
                        <li>2MB file size maximum</li>
                        <li>Logo image cannot contain details e.g. phone number, email address, address.</li>
                        <li>Logos will appear on your site profile after a review period.</li>
                    </ul>
                </div>
            </div>

            <div class="my-6" v-if="!updating">
                <SolidButton @click="startUpdate">Update Logo</SolidButton>
            </div>

            <div class="my-6" v-if="updating">
                <FileUpload @file-uploaded="getFile"/>

                <div class="flex flex-row items-center mt-4 justify-center">
                    <SolidButton class="mr-2" @click="uploadLogo">Upload</SolidButton>
                    <SolidButton color="light-gray" @click="stopUpdate">Cancel</SolidButton>
                </div>
            </div>
        </div>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import { ref } from "vue";
import { useCompanyStore } from "@/stores/company";
import SolidButton from "@/components/inputs/SolidButton.vue";
import FileUpload from "@/components/inputs/FileUpload.vue";
import { useServicesStore } from "@/stores/services";
import { useErrorStore } from "@/stores/errors.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const company = useCompanyStore();
const services = useServicesStore();
const errors = useErrorStore();

const logoUrl = ref(null);
const file = ref(null);
const updating = ref(false);
const loading = ref(false);

const startUpdate = () => updating.value = true;
const stopUpdate = () => {
    file.value = null;
    updating.value = false;
}

const getFile = (payload) => file.value = payload[0];

const uploadLogo = () => {
    if (file.value == null) return;

    loading.value = true;
    const data = new FormData();
    data.append('logo', file.value);

    services.apiService.updateCompanyLogo(data).then(resp => {
        logoUrl.value = resp.data.data.logo;
    }).catch(e => {
        errors.report(e, "There was an error updating the company logo.")
    }).finally(() => {
        loading.value = false
        stopUpdate();
    });
}

const getLogo = () => {
    loading.value = true;
    services.apiService.getCompanyLogo().then(resp => {
        logoUrl.value = resp.data.data.logo;
    }).catch(e => {
        console.error("There was an issue fetching the company logo.", e);
    }).finally(() => loading.value = false);
}

getLogo();
</script>