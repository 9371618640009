<template>
    <div class="relative min-h-[48rem]">
        <LoadingSpinner v-if="loading" :small="true" />
        <BiddingTable
            v-else
            :initial-data="slideInputs.location_bids"
            @update:bidding-input="(newValue) => handleInputUpdate(newValue, 'location_bids')"
        />
    </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, Ref, ref, watch } from "vue";
import { SlideInputs } from "@/stores/v4/wizard";
import { ReactiveVariable } from "vue/macros";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { LocationBidCollection } from "@/stores/v4/bidding";
import BiddingTable from "@/components/v4/campaigns/BiddingTable.vue";

interface Props {
    initialSlideData: SlideInputs,
}
const props = defineProps<Props>();

const emit = defineEmits(['update:slideInput']);

const slideInputs: ReactiveVariable<{ location_bids: LocationBidCollection }> = reactive({
    location_bids: {},
});
watch(() => slideInputs, (newValue) => handleInputUpdate(newValue, 'key'), { deep: true });

const loading: Ref<boolean> = ref(false);

const initialize = async () => {
    loading.value = true;
    Object.assign(slideInputs.location_bids, props.initialSlideData.location_bids ?? {});
    loading.value = false;
}
onMounted(() => initialize());

const handleInputUpdate = (newValue: any, inputKey: string) => {
    emit('update:slideInput', newValue, inputKey);
}

</script>
