<template>
    <MainGrid>
        <Module class=""
            :class="[(saving || loading) ? 'pointer-events-none' : '']">
            <template v-slot:title>
                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z" clip-rule="evenodd" />
                        </svg>

                        <h2 class="text-lg font-medium">Appointment Schedules</h2>
                    </div>
                </div>
            </template>

            <template v-slot:default>
                <div class="relative">
                    <div v-if="!storeInitialized || loading" class="min-h-[16rem]">
                        <LoadingSpinner :small="true" />
                    </div>
                    <div v-else class="py-5 px-3 md:px-6 gap-y-3 flex flex-col justify-center">
                        <div v-for="schedule in scheduleStore.schedules" :key="schedule.id"
                             class="grid lg:grid-cols-4 gap-1 p-4 mb-2 items-center rounded-md cursor-pointer bg-cyan-100 hover:bg-cyan-200 hover:bg-opacity-75 transition duration-200 relative"
                             @click="editSchedule(schedule.id)">
                            <div class="lg:col-span-4 flex items-center">
                                <svg class="w-4 mr-2" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 9H6V11H4V9ZM4 13H6V15H4V13ZM8 9H10V11H8V9ZM8 13H10V15H8V13ZM12 9H14V11H12V9ZM12 13H14V15H12V13Z" fill="black"/>
                                    <path d="M2 20H16C17.103 20 18 19.103 18 18V4C18 2.897 17.103 2 16 2H14V0H12V2H6V0H4V2H2C0.897 2 0 2.897 0 4V18C0 19.103 0.897 20 2 20ZM16 6L16.001 18H2V6H16Z" fill="black"/>
                                </svg>

                                <p class="text-lg font-medium ">{{ schedule.name }}</p>
                            </div>
                            <div class="lg:col-span-2">
                                <p class="text-sm italic text-gray-500">Created by <span class="text-gray-900 font-medium text-sm">{{ schedule.owner }}</span></p>
                            </div>
                            <div class="lg:col-span-2">
                                <p class="text-sm">{{ schedule.platform === 'internal' ? 'Availability Timeframe' : schedule.platform }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

        </Module>
    </MainGrid>

    <CreateOrEditScheduleModal
        v-if="showScheduleModal"
        @close-modal="closeScheduleModal()"
        @modal-errors="showErrors"
        :timezones="referenceStore.timezones"
        :edit-schedule="editingSchedule"
        :saving="saving"
    />

    <!--  Modal Errors modal  -->
    <Modal
        v-if="errorMessage"
        :show-cancel-button="false"
        confirm-label="OK"
        @clicked:confirm="clearErrorModal"
        :small="true"
    >
        <template v-slot:header>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>
                <h5 class="text-md">
                    Schedule Error
                </h5>
            </div>
        </template>
        <template v-slot:body>
            Saving the Schedule failed with the following errors:
            <div v-if="errorMessage" class="py-4">
                <p class="text-red-900 text-center whitespace-pre">{{ errorMessage }}</p>
            </div>
        </template>
    </Modal>
</template>

<script setup>
import Module from "@/components/Module.vue";
import { onMounted, ref } from "vue";
import { useSchedulesStore } from "@/stores/schedules.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import MainGrid from "@/components/layouts/MainGrid.vue";
import { useReferenceDataStore } from "@/stores/reference-data.js";
import CreateOrEditScheduleModal from "@/views/appointments/modals/CreateOrEditScheduleModal/CreateOrEditScheduleModal.vue";
import Modal from "@/components/Modal.vue";

const scheduleStore = useSchedulesStore();
const referenceStore = useReferenceDataStore();

const storeInitialized = ref(false);
const loading = ref(false);
const saving = ref(false);

const editingSchedule = ref(null);
const deletingSchedule = ref(null);

const showScheduleModal = ref(false);
const errorMessage = ref(null);

onMounted(() => {
    scheduleStore.initialize().then(() => storeInitialized.value = true);
});


const toggleScheduleModal = (show = true) => {
    showScheduleModal.value = !!show;
}

const editSchedule = (editScheduleId = null) => {
    if (editScheduleId) {
        const targetSchedule = scheduleStore.schedules.find(schedule => schedule.id === editScheduleId);
        if (targetSchedule) {
            editingSchedule.value = JSON.parse(JSON.stringify(targetSchedule));
            showScheduleModal.value = true;
        }
    }
}

const clearErrorModal = () => {
    errorMessage.value = null;
}

const showErrors = (errors) => {
    errorMessage.value = errors;
}

const closeScheduleModal = () => {
    editingSchedule.value = null;
    toggleScheduleModal(false);
}

</script>