<template>
    <main class="h-auto w-full relative p-6">
        <div class="w-full justify-center items-center p-6 bg-white shadow">
            <div class="pb-6 w-full">
                <p class="text-2xl text-center">Terms & Conditions</p>
            </div>
            <div class="p-6 shadow-inner bg-gray-50 border border-gray-200 relative min-h-[24rem] h-[60vh] overflow-y-auto overflow-x-hidden"
                 @scroll="handleScrollEvent"
            >
                <LoadingSpinner v-if="loading || saving" />
                <div v-if="!loading && contractContent"
                    :class="[saving ? 'opacity-50' : '']"
                >
                    <p v-html="contractContent" />
                </div>
            </div>
            <div class="pt-6 flex flex-col items-center"
                :class="[saving ? 'pointer-events-none' : '']"
            >
                <div v-if="errorMessage" class="text-red-900 text-sm py-2 text-center">
                    {{ errorMessage }}
                </div>
                <div class="flex mt-2"
                    :class="[userHasScrolledDown ? '' : 'opacity-50']"
                     @click="handleNotScrolled"
                >
                    <CustomCheckbox
                        :input-disabled="!userHasScrolledDown"
                        v-model="acceptedAgreement"
                    />
                    <span class="ml-2"><strong>I accept this agreement</strong></span>
                </div>
                <div class="text-sm py-4 w-[80%] text-center">
                    <strong class="pr-1">IMPORTANT:</strong>
                    <p class="inline">{{ runReplacers(contractFooter) }}</p>
                </div>
                <SolidButton
                    :disabled="!acceptedAgreement"
                    @click="acceptContract"
                    label="Continue"
                />
            </div>
        </div>
    </main>
</template>

<script setup>

import { useCompanyStore } from "@/stores/company.js";
import { onMounted, ref } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useUserStore } from "@/stores/user.js";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { useRouter } from "vue-router";

const router = useRouter();
const companyStore = useCompanyStore();
const userStore = useUserStore();

const contractContent = ref(null);
const contractFooter = ref('By accepting the agreement above, you are agreeing that our supply of services to you will be governed by the terms in this agreement and you are also warranting that you have the authority to bind %COMPANY_NAME% into the agreement and that you have the authority to charge amounts to the nominated credit card.');

const userHasScrolledDown = ref(false);
const acceptedAgreement = ref(false);

const loading = ref(false);
const saving = ref(false);
const errorMessage = ref(null);
let throttleScroll = false;

onMounted(() => {
    getContractForCompany();
});

const getContractForCompany = async () => {
    loading.value = true;
    const { status, contract, message } = await companyStore.getNewContractForCompany();
    if (!status) {
        errorMessage.value = message;
    }
    else {
        contractContent.value = contract;
    }
    loading.value = false;
}

const acceptContract = async () => {
    errorMessage.value = null;
    if (saving.value) return;
    saving.value = true;
    const { status, message } = await companyStore.agreeToCompanyContract();
    if (!status) {
        errorMessage.value = message;
    }
    else {
        router.push('dashboard');
    }
    saving.value = false;
}

const handleScrollEvent = ({ target }) => {
    if (throttleScroll || userHasScrolledDown.value) return;
    throttleScroll = true;
    if (Math.abs(target.scrollTop + target.offsetHeight - target.scrollHeight) < 500) {
        userHasScrolledDown.value = true;
        errorMessage.value = null;
    }
    setTimeout(() => {
        throttleScroll = false;
    }, 10);
}

const handleNotScrolled = () => {
    if (!userHasScrolledDown.value) errorMessage.value = `Please read to the end of the Terms & Conditions before continuing.`;
}

const runReplacers = (inputString) => {
    const userName = userStore.firstName
        ? `${userStore.firstName}${userStore.lastName ? ` ${userStore.lastName}` : ``}`
        : `The Company User`;
    const companyName = companyStore.name || 'Your Company';

    return inputString
        .replace('%COMPANY_NAME%', companyName)
        .replace('%USER_FULL_NAME%', userName);
}

</script>