import {ref} from 'vue';
import {defineStore} from 'pinia';
import {useServicesStore} from "@/stores/services";
import { BaseApiServiceV4 } from "@/services/api/v4/BaseApiServiceV4";

export const useAuthStore = defineStore('auth', () => {
    const services = useServicesStore();

    const token = ref(false);
    const expiry = ref(null);
    const isShadower = ref(false);

    function set(payload) {
        token.value = payload.token ?? token.value;
        expiry.value = payload.expiry ?? expiry.value;
        isShadower.value = payload.is_shadower ?? isShadower.value;

        services.apiService.setBearer(payload.token);
        services.apiServiceV4.setBearer(payload.token);
    }

    function isSessionValid() {
        const now = (new Date()).valueOf() / 1000;
        const expireAt = expiry.value ?? 0;

        return now < expireAt;
    }

    async function updatePassword(payload) {
        const resp = await services.apiService.updatePassword(payload).catch(e => e);

        if (resp.data?.data?.status) {
            return resp.data.data;
        }
        else {
            return BaseApiServiceV4.transformErrorResponse(resp);
        }
    }

    async function requestPasswordReset(email) {
        const resp = await services.apiService.requestPasswordReset(email).catch(e => e);
        if (resp.data?.data?.status) {
            return { status: true }
        }
        else {
            return BaseApiServiceV4.transformErrorResponse(resp);
        }
    }

    function $reset() {
        token.value = false;
        expiry.value = null;
    }

    return {
        token,
        expiry,
        isShadower,

        set,
        isSessionValid,
        updatePassword,
        requestPasswordReset,
        $reset
    };
});