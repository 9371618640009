import { defineStore } from "pinia";
import { ref } from "vue";
import { useServicesStore } from "@/stores/services";

export const useCompanyMediaStore = defineStore('companyMedia', () => {
    const servicesStore = useServicesStore();

    const companyMediaAssets = ref([]);

    const storeInitialized = ref(false);

    async function initialize() {
        if (storeInitialized.value) return;
        await get();
        storeInitialized.value = true;
    }

    async function get() {
        const resp = await servicesStore.apiService.getCompanyMedia().catch(e=>e);
        if (!resp.data?.data?.status) {
            return genericErrorMessage(resp);
        }
        else {
            companyMediaAssets.value = resp.data.data.media_files;
        }
    }

    async function upload(fileArray) {
        const payload = new FormData();
        for(const item of fileArray)
            payload.append('media_files[]', item);
        const resp = await servicesStore.apiService.uploadCompanyMedia(payload).catch(e=>e);
        if (resp.data?.data?.status) {
            companyMediaAssets.value.push(...resp.data?.data?.media_files);
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function addYoutubeLink(url) {
        const resp = await servicesStore.apiService.addYoutubeLink(url).catch(e=>e);
        const files = resp.data?.data?.media_files;
        if (resp.data?.data?.status && files) {
            if (typeof files.length !== 'undefined') {
                companyMediaAssets.value.push(...files);
            } else {
                companyMediaAssets.value.push(files);
            }
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function deleteCompanyMediaAsset(assetId) {
        const resp = await servicesStore.apiService.deleteCompanyMediaAsset(assetId).catch(e=>e);
        if (resp.data?.data?.status) {
            companyMediaAssets.value = companyMediaAssets.value.filter(asset => asset.id !== assetId);
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    function clearStore() {
        companyMediaAssets.value = [];
        storeInitialized.value = false;
    }

    function genericErrorMessage(resp) {
        return { status: false, message: resp.data?.data?.message || resp.err || `An unknown error occurred fetching data.` };
    }

    function $reset() {
        clearStore();
    }

    return {
        initialize,
        get,
        upload,
        addYoutubeLink,
        deleteCompanyMediaAsset,
        companyMediaAssets,
        $reset,
    }

});