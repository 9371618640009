<template>
    <div>
        <Module>
            <template v-slot:title>
                <div class="flex items-center">
                    <svg class="w-5 mr-2" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path clip-rule="evenodd"
                              d="M9.33341 1.33331H4.00008C3.26675 1.33331 2.66675 1.93331 2.66675 2.66665V13.3333C2.66675 14.0666 3.26008 14.6666 3.99341 14.6666H12.0001C12.7334 14.6666 13.3334 14.0666 13.3334 13.3333V5.33331L9.33341 1.33331ZM12 13.3333H4V2.66663H8.66667V5.99996H12V13.3333ZM5.33341 7.99998H10.6667V9.33331H5.33341V7.99998ZM5.33341 10.6666H10.6667V12H5.33341V10.6666Z"
                              fill="#192A3E"
                              fill-rule="evenodd"/>
                    </svg>
                    <h5 class="pb-0">Customer Reviews</h5>
                </div>
            </template>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <!-- Overall Rating Container -->
                <div class="border border-gray-300">
                    <div class="" v-if="costumerReviewStore.overallReviewsCount > 0">
                        <div class="p-4">
                            <h6 class="pb-2 font-semibold mb-2">Overall rating</h6>
                            <div class="grid grid-cols-2 md:grid-cols-4">
                                <!-- overall rating-->
                                <div class="flex flex-col gap-4">
                                    <div class="inline-flex items-baseline">
                                        <span class="pb-0 text-xl font-bold leading-tight px-2">{{
                                                costumerReviewStore.overallReviewsCount
                                            }}</span>
                                        <span>/</span>
                                        <span class="text-xl leading-tight px-2">{{ totalRatings }}</span>
                                        <Stars :score="costumerReviewStore.overallReviewsCount"></Stars>
                                    </div>
                                    <div class="ml-2">
                                        <p class="pb-0 text-gray-500">Based on <span class="text-gray-700">{{ costumerReviewStore.reviewsCount }}</span> reviews</p>
                                    </div>
                                </div>
                                <div></div>
                                <!-- review bars component -->
                                <div class="col-span-2">
                                    <div>
                                        <div v-for="(count, rating) in costumerReviewStore.countBreakdown" :key="rating"
                                             class="flex items-center mb-2">
                                            <div class="w-12 text-right text-sm mr-2">
                                                <p class="pb-0">{{ rating }}</p>
                                            </div>
                                            <div class="flex-1 h-3 bg-gray-200 flex items-center relative rounded-md mx-1">
                                                <div v-if="fullWidth"
                                                     class="absolute bg-cyan-500 opacity-50 cursor-pointer transition duration-300 hover:bg-cyan-300 h-full z-10 rounded-md"
                                                     :style="{width: Math.round((countsFull[rating] * 100)) + '%'}"></div>
                                            </div>
                                            <p class="pb-0 text-sm  ml-2">{{ count }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p class="text-center text-xl pt-4 pb-4 pl-4 pr-4 mt-4 mb-4">No Reviews yet</p>
                    </div>
                    <!-- Review Link container -->
                    <div class="p-4 mt-auto" v-if="costumerReviewStore.reviewLink">
                        <div class="grid grid-cols-1 gap-3 text-sm">
                            <div class="grid grid-cols-8 gap-2 w-full">
                                <input id="review-link"
                                             :value="costumerReviewStore.reviewLink"
                                             class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                             disabled readonly type="text">
                                <button
                                        id="copy-button"
                                        class="col-span-2 text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 foscus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 items-center inline-flex justify-center"
                                        @click="copyToClipboard"
                                        data-copy-to-clipboard-target="review-link">
                                    <span    v-if="!copySuccessMessage" id="default-message">Copy Link</span>
                                    <span id="success-message" :class="{ 'hidden': !copySuccessMessage }" class="inline-flex items-center">
                                        <svg aria-hidden="true" class="w-3 h-3 text-white me-1.5" fill="none"
                                             viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 5.917 5.724 10.5 15 1.5" stroke="currentColor" stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  stroke-width="2"/>
                                        </svg>
                                        Copied!
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- End of review link container -->
                </div>
                <!-- END Overall Rating Container -->

                <!-- Recent Reviews Container -->
                <div v-if="costumerReviewStore.reviews.length > 0" class="border border-gray-300">
                    <div class="container mx-auto">
                        <div class="p-4 overflow-y-auto">
                            <div class="relative">
                                <h6 class="pb-2 font-semibold mb-2">Recent reviews</h6>
                                <!-- Review -->
                                <div class="grid grid-rows-1 grid-flow-col gap-4">
                                    <div class="flex flex-row">
                                        <div v-for="(review, index) in costumerReviewStore.reviews" :key="index">
                                            <div class="space-y-2 p-4" :class="{'border-r': index === 0, 'border-gray-500': true}" >
                                                <div class="flex flex-row justify-between">
                                                    <div><stars :score="review.score"></stars></div>
                                                    <div class="text-gray-800 text-sm">{{ formatDate(review.created) }}</div>
                                                </div>
                                                <h3 class="text-md font-medium flex-grow">{{ review.title }}</h3>
                                                <p class="text-sm text-gray-600 flex-wrap flex-grow">{{ truncateString(review.comment, 300) }}</p>
                                                <p class="text-sm text-cyan-500 cursor-pointer" @click="toggleConsumerReviewModal(true, review)">Read more</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- End review container -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END Recent Reviews Container -->
            </div>

            <div class="mt-8 border border-gray-200">
                <div class="p-4">
                    <div class="flex flex-row justify-between">
                        <div class="flex items-center">
                            <svg class="w-5 mr-2" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                <path clip-rule="evenodd"
                                      d="M9.33341 1.33331H4.00008C3.26675 1.33331 2.66675 1.93331 2.66675 2.66665V13.3333C2.66675 14.0666 3.26008 14.6666 3.99341 14.6666H12.0001C12.7334 14.6666 13.3334 14.0666 13.3334 13.3333V5.33331L9.33341 1.33331ZM12 13.3333H4V2.66663H8.66667V5.99996H12V13.3333ZM5.33341 7.99998H10.6667V9.33331H5.33341V7.99998ZM5.33341 10.6666H10.6667V12H5.33341V10.6666Z"
                                      fill="#192A3E"
                                      fill-rule="evenodd"/>
                            </svg>
                            <h6 class="pb-0">Reviews for {{ company.name }}</h6>
                        </div>
                        <div class="text-sm inline-flex gap-4">
                            <CustomSelect :options="reviewSortByOptions" :label="null" v-model="costumerReviewStore.searchOptions.sort_by"/>
                            <CustomSelect :options="reviewStatusOptions" :label="null" v-model="costumerReviewStore.searchOptions.status"/>
                            <CustomSelect :options="reviewScoreOptions" :label="null" v-model="costumerReviewStore.searchOptions.score"/>
                            <CustomInput
                                v-model="costumerReviewStore.searchOptions.text"
                                placeholder="Search reviews"
                            />
                            <SolidButton @click="searchConsumerReviews" classes="h-11 font-semibold">
                                Search
                            </SolidButton>
                            <OutlineButton @click="clearSearch" color="gray" classes="h-11 font-semibold">
                                Reset
                            </OutlineButton>
                        </div>
                    </div>
                    <div class="mt-8">
                        <div class="grid grid-cols-12 mb-2 text-center">
                            <p class="text-slate-500 font-semibold uppercase text-xs col-span-1">ID</p>
                            <p class="text-slate-500 font-semibold uppercase text-xs col-span-1">Name</p>
                            <p class="text-slate-500 font-semibold uppercase text-xs col-span-2">Title</p>
                            <p class="text-slate-500 font-semibold uppercase text-xs col-span-3">Comment</p>
                            <p class="text-slate-500 font-semibold uppercase text-xs col-span-1">Score</p>
                            <p class="text-slate-500 font-semibold uppercase text-xs col-span-2">Added</p>
                            <p class="text-slate-500 font-semibold uppercase text-xs">Replies</p>
                            <p class="text-slate-500 font-semibold uppercase text-xs">Status</p>
                        </div>
                        <div class="border-t border-b h-[25rem] overflow-y-auto divide-y text-center">
                            <div @click="toggleConsumerReviewModal(true, review)" v-for="review in costumerReviewStore.allReviews" :key="review.id" class="bg-gray-50 grid cursor-pointer grid-cols-12 gap-x-10 py-3 px-5 group relative transition duration-100 items-center hover:bg-white">
                                <div class="absolute left-0 h-full w-1 bg-slate-600 invisible group-hover:visible">
                                </div>
                                <p class="text-sm text-cyan-500 font-medium cursor-pointer">
                                    {{review.id}}
                                </p>
                                <p class="text-sm col-span-1 truncate">
                                    {{review.reviewer_name}}
                                </p>
                                <p class="text-sm col-span-2 truncate">
                                    {{review.title ?? '-'}}
                                </p>
                                <p class="text-sm col-span-3 truncate">
                                    {{review.comment }}
                                </p>
                                <p class="text-sm text-center col-span-1">
                                    {{review.score}}
                                </p>
                                <p class="text-sm col-span-2">
                                    {{ formatDate(review.created) }}
                                </p>
                                <p class="text-sm col-span-1">
                                    <p v-if="review.review_replies.length === 0" class="text-slate-500 text-sm"></p>
                                    <p v-else-if="review.review_replies.length === 1" class="text-slate-500 text-sm">1 Reply</p>
                                    <p v-else class="text-slate-500 text-sm">{{review.review_replies.length}} Replies</p>
                                </p>
                                <p>
                                    <p class="text-sm capitalize inline-flex items-center gap-3">
                                        <StatusBadge v-if="review.display_status === 'approved'" color="green" :dark-mode="darkMode">
                                            {{review.display_status}}
                                        </StatusBadge>
                                        <StatusBadge v-else-if="review.display_status === 'rejected'" color="red" :dark-mode="darkMode">
                                            {{review.display_status}}
                                        </StatusBadge>
                                        <StatusBadge v-else color="amber" :dark-mode="darkMode">
                                            {{review.display_status}}
                                        </StatusBadge>
                                    </p>
                                </p>
                            </div>
                        </div>

                    </div>
                    <LoadingSpinner v-if="costumerReviewStore.loading" :small="true" />
                </div>

                <div v-if="costumerReviewStore.allReviews.length > 0" class="p-4 ml-4">
                    <Pagination
                        :limit="costumerReviewStore.paginationLimit"
                        :total="costumerReviewStore.paginationTotal"
                        :offset="costumerReviewStore.paginationOffset"
                        @change-page="handlePaginationEvent">
                    </Pagination>
                </div>
            </div>

            <!-- Review Modal -->
            <CustomerReviewModal
                v-if="showCostumerReviewModal"
                :review="selectedReview"
                @hideCustomerReviewModal="toggleConsumerReviewModal(false)"
            />
        </Module>

    </div>
</template>
<script setup>
import Module from "@/components/Module.vue";
import Stars from "@/components/shared/Stars.vue";
import {useCostumerReviewsStore} from "@/stores/customer-reviews";
import {computed, onMounted, ref} from "vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import {useCompanyStore} from "@/stores/company";
import CustomerReviewModal from "@/components/customer_reviews/CustomerReviewModal.vue";
import {formatDate} from "@/Utilities/Helpers.js";
import Pagination from "@/components/pagination/Pagination.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import CustomInput from "@/components/inputs/CustomInput.vue";
import StatusBadge from "@/components/StatusBadge.vue";

const costumerReviewStore = useCostumerReviewsStore();
const company = useCompanyStore();

costumerReviewStore.initialize();
costumerReviewStore.getOverallRatings();

onMounted(() => {
    searchConsumerReviews();
})
const props = defineProps({
    storeInitialized: {
        type: Boolean,
        default: false
    },
    fullWidth: {
        type: Boolean,
        default: true
    }
});

const reviewSortByOptions = [
    {
        'value': 0,
        'label': "SORT BY"
    },
    {
        'value': 'ASC',
        'label': "ASCENDING"
    },
    {
        'value': 'DESC',
        'label': "DESCENDING"
    }
];

const reviewStatusOptions = [
    {
        'value' : 0,
        'label' : 'STATUS'
    },
    {
        'value' : 1,
        'label' : 'APPROVED'
    },
    {
        'value' : 2,
        'label' : 'PENDING'
    }
];

const reviewScoreOptions = [
    {
        'value' : '',
        'label' : 'SCORE'
    },
    {
        'value' : 0,
        'label' : '0'
    },
    {
        'value' : 1,
        'label' : '1'
    },
    {
        'value' : 2,
        'label' : '2'
    },
    {
        'value' : 3,
        'label' : '3'
    },
    {
        'value' : 4,
        'label' : '4'
    },
    {
        'value' : 5,
        'label' : '5'
    }
];

const showCostumerReviewModal = ref(false);

const totalRatings = ref('5');

const copySuccessMessage = ref(false);

const selectedReview = ref(null);

const copyToClipboard = () => {
    const reviewLinkInput = document.getElementById('review-link');
    if (!reviewLinkInput) return;

    navigator.clipboard.writeText(reviewLinkInput.value)
            .then(() => {
                copySuccessMessage.value = true;
                setTimeout(() => {
                    copySuccessMessage.value = false;
                }, 2000);
            })
            .catch((error) => {
                console.error('Failed to copy:', error);
            });
};

const countsFull = computed(() => {
    let fullWidth = [];
    let max = 0;

    for (const key in costumerReviewStore.countBreakdown) {
        if (costumerReviewStore.countBreakdown.hasOwnProperty(key)) {
            const value = costumerReviewStore.countBreakdown[key];
            if (value > max) {
                max = value;
            }
        }
    }

    for (const key in costumerReviewStore.countBreakdown) {
        if (costumerReviewStore.countBreakdown.hasOwnProperty(key)) {
            const value = costumerReviewStore.countBreakdown[key];
            fullWidth[key] = value / max;
        }
    }
    return fullWidth;
});

const toggleConsumerReviewModal = (showModal, review = {}) => {
    selectedReview.value = review;
    showCostumerReviewModal.value = !!showModal;
}

const handlePaginationEvent = (payload) => {
    searchConsumerReviews(payload.page);
}

const searchConsumerReviews = (page = 1) => {
    costumerReviewStore.getAllCostumerReviews(page);
}

const clearSearch = () => {
    costumerReviewStore.clearSearchOptions();
    searchConsumerReviews();
}

function truncateString(comments, max) {
    if (comments.length > max) {
        return comments.substring(0, max) + '...'; // Add ellipsis to indicate truncation
    } else {
        return comments;
    }
}

</script>