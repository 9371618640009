<template>
    <AppointmentsSearch :stores-initialized="storesInitialized" />
</template>

<script setup>
import { useCampaignStore } from "@/stores/campaigns.js";
import { onBeforeMount, ref } from "vue";
import AppointmentsSearch from "@/components/appointments/AppointmentsSearch.vue";
import { useAppointmentsStore } from "@/stores/appointments.js";
import Products from "@/Utilities/Products";

const props = defineProps({
    initialSearch: {
        type: Object,
        default: null,
    },
});

const campaignStore = useCampaignStore();
const appointmentsStore = useAppointmentsStore();

const storesInitialized = ref(false);

onBeforeMount(async () => {
    await campaignStore.setProduct(Products.APPOINTMENT);
    await Promise.all([
        campaignStore.initialize(),
        appointmentsStore.initialize(),
    ]);
    storesInitialized.value = true;
});

</script>