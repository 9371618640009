<template>
    <MainGrid>
        <LicensesModule :store-initialized="storesInitialized" />
    </MainGrid>
</template>

<script setup>
import MainGrid from "@/components/layouts/MainGrid.vue";
import LicensesModule from "@/components/company/LicensesModule.vue";
import { useCompanyLicenseStore } from "@/stores/company-licenses.js";
import { ref } from "vue";

const storesInitialized = ref(false);

const licenseStore = useCompanyLicenseStore();
licenseStore.initialize().then(() => storesInitialized.value = true);

</script>