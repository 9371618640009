<template>
    <div class="mb-12">
        <CampaignWizard
            v-if="showWizard"
            @close:campaignWizard="toggleWizard(false)"
        />
        <CampaignList
            v-show="!showWizard"
            :product="ProductType.DirectLeads"
            @show:campaignWizard="startCampaignWizard"
        />
    </div>
</template>

<script setup lang="ts">
import CampaignList from "@/components/v4/campaigns/CampaignList.vue";
import CampaignWizard from "@/components/v4/wizard/CampaignWizard.vue";
import { Ref, ref } from "vue";
import { ProductType, useServicesStore } from "@/stores/services";
import { useWizardStore } from "@/stores/v4/wizard";
import { useFutureCampaignStore } from "@/stores/v4/future-campaigns";
import { CampaignTransformerService } from "@/services/v4/CampaignTransformerService";
import { useCampaignModulesStore } from "@/stores/v4/campaign-modules";
import { useAlertStore } from "@/stores/v4/alerts";

const services = useServicesStore();
const wizardStore = useWizardStore();
const campaignStore = useFutureCampaignStore();

const showWizard = ref(false);
const moduleStore = useCampaignModulesStore();
const alertStore = useAlertStore();

const loading: Ref<boolean> = ref(false);

services.apiServiceV4.setProductKey(ProductType.DirectLeads);

const startCampaignWizard = async (newCampaign?: boolean) => {
    loading.value = true;
    if (moduleStore.wizardConfiguration) {
        const { status, message } = wizardStore.initialize(moduleStore.wizardConfiguration, moduleStore.wizardComponentMap, CampaignTransformerService);
        if (!status) {
            alertStore.showDevError(message ?? "Configuration error in Slide Wizard.");
        }
    }

    loading.value = false;
    if (newCampaign) {
        wizardStore.resetInputs();
        campaignStore.$reset();
    }
    else {
        wizardStore.loadInputs(campaignStore.editingCampaign);
    }
    toggleWizard(true);
    loading.value = false;
}

const toggleWizard = (show: boolean|undefined|null) => {
    if (show == null)
        showWizard.value = !showWizard.value;
    else
        showWizard.value = show;
}

</script>