<template>
    <Module class="relative">
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"/>
                </svg>
                <h5 class="text-md">Profitability from Leads Purchased</h5>
            </div>
        </template>

        <div class="relative min-h-[12rem]">
            <LoadingSpinner v-if="loading || !storesInitialized" :small="true" />
            <div v-else class="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div>
                <div class="flex items-center mb-5">
                    <h6
                        class="py-2 px-3 cursor-pointer"
                        v-for="label in selectorLabels"
                        :class="{'text-cyan-500 border-b border-cyan-500 font-medium': label === selectedLabel, 'text-gray-500 ': label !== selectedLabel}"
                        @click="handleSelectorClick(label)"
                    >
                        {{ label }}
                    </h6>
                </div>

                <div v-if="isForecast">
                    <div class="mb-3 flex items-center justify-between">
                        <div>{{ forecastPercentLeadsPurchased }}% of available leads purchased</div>

                        <div class="relative">
                            <ButtonDropdown>
                                <template v-slot:trigger>
                                    <span class="text-cyan-500 flex items-center cursor-pointer">
                                        {{ forecastLabel }}
                                        <svg class="w-3 ml-1" viewBox="0 0 13 8" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.5 1.5L6.5 6.5L11.5 1.5" stroke="#20A5DC" stroke-width="2"
                                                  stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </template>

                                <div class="transform translate-y-7 bg-white border border-gray-300 shadow rounded absolute top-0 right-0 z-50 transform p-2 w-36">
                                    <div
                                        v-for="label in forecastLabels"
                                        @click="updateForecastLabel(label)"
                                        class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500">
                                        <p class="ml-2 pb-0 text-cyan-500">{{ label }}</p>
                                    </div>
                                </div>
                            </ButtonDropdown>
                        </div>
                    </div>
                    <div class="mb-2">
                        <InputRange v-model="forecastPercentLeadsPurchased" />
                    </div>
                </div>

                <div>
                    <div>
                        <div class="flex justify-between py-3 border-b">
                            <p># of Leads Purchased:</p>
                            <p>{{ leadsPurchased || 0 }}</p>
                        </div>

                        <div class="flex justify-between py-3 border-b">
                            <p>Amount Spent:</p>
                            <p>${{ formatNumber(spentAmount || 0) }}</p>
                        </div>

                        <div class="flex justify-between py-3 border-b">
                            <Tooltip>
                                <template v-slot:title>Jobs Won:</template>

                                <strong>Jobs won</strong> uses the conversion rate assumption in the Lead Profitability
                                Assumptions Box in which you enter the % of leads that close in the first 2
                                months. You can change this assumption. We then add an assumption for
                                conversions after 2 months because the average time from lead to close is
                                six months. This is the best way to properly measure lead buying
                                profitability.
                            </Tooltip>
                            <p>{{ formatNumber(jobsWon || 0) }}</p>
                        </div>

                        <div class="flex justify-between py-3 border-b">
                            <Tooltip>
                                <template v-slot:title>Gross Margin In Jobs Won:</template>

                                <strong>Gross Margin in jobs won</strong> is the revenue from all the sold jobs less the
                                direct cost of labor and materials to do the jobs but before accounting for overheads.
                                It uses the assumptions in the Profitability Assumptions box, all of which you can
                                adjust to suit your business.
                            </Tooltip>
                            <p>${{ formatNumber(grossMargin || 0) }}</p>
                        </div>

                        <div class="flex justify-between py-3">
                            <Tooltip>
                                <template v-slot:title>
                                    <p class="font-semibold">Profit From Lead Buying:</p>
                                </template>

                                <strong>Profit from lead buying</strong> is Gross margin generated by jobs won less the
                                cost of the leads. It represents the amount by which your company is more profitable
                                because of your lead buying activity.
                            </Tooltip>
                            <p class="font-semibold">${{ formatNumber(profit || 0) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <PieChart :chart-options="chartOptions"/>
            </div>
        </div>
        </div>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import { computed, onMounted, reactive, ref, watch } from "vue";
import PieChart from "@/components/charts/PieChart.vue";
import Tooltip from "@/components/Tooltip.vue";
import {useServicesStore} from "@/stores/services";
import {useProfitabilityAssumptionsStore} from "@/stores/profitability-assumptions";
import InputRange from "@/components/inputs/InputRange.vue";
import ButtonDropdown from "@/components/ButtonDropdown.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {useCompanyStore} from "@/stores/company";

const props = defineProps({
    global: {
        type: Boolean,
        default: false
    },
    services: {
        type: Array,
        default: null
    },
    storesInitialized: {
        type: Boolean,
        default: false,
    },
});

const services = useServicesStore();
const profitability = useProfitabilityAssumptionsStore();

const secondsInDay = 60 * 60 * 24;
function daysIntoYear(date){
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
}

const diffMapping = {
    '30 Days': 30,
    '90 Days': 90,
    '12 Months': 365,
    'Year to Date': daysIntoYear(new Date())
};

const loading = ref(false);
const selectorLabels = ref(['30 Days', '90 Days', '12 Months', 'Year to Date', 'Forecast']);
const forecastLabels = ref(['30 Days', '90 Days', '12 Months', 'Year to Date']);
const selectedLabel = ref('30 Days');
const forecastLabel = ref('30 Days');

const scopedProfitabilities = reactive({
    verified: {},
    unverified: {},
});

// Profitability fields
const verifiedLeadsPurchased = ref(0);
const verifiedLeadsAvailable = ref(0);
const amountSpentVerifiedLeads = ref(0);
const unverifiedLeadsPurchased = ref(0);
const unverifiedLeadsAvailable = ref(0);
const amountSpentUnverifiedLeads = ref(0);
const forecastPercentLeadsPurchased = ref(50);

const jobsWon = ref(0);
const grossMargin = ref(0);
const profit = ref(0);

const averagePriceVerifiedLeads = computed(() => amountSpentVerifiedLeads.value / (verifiedLeadsPurchased.value === 0 ? 1 : verifiedLeadsPurchased.value));
const averagePriceUnverifiedLeads = computed(() => amountSpentUnverifiedLeads.value / (unverifiedLeadsPurchased.value === 0 ? 1 : unverifiedLeadsPurchased.value));
const amountSpend = computed(() => amountSpentVerifiedLeads.value + amountSpentUnverifiedLeads.value);

const spentAmount = computed(() => {
    if(!isForecast.value)
        return amountSpend.value;

    const verifiedLeadsPurchased = Math.round(verifiedLeadsAvailable.value * forecastPercentLeadsPurchased.value / 100);
    const unverifiedLeadsPurchased = Math.round(unverifiedLeadsAvailable.value * forecastPercentLeadsPurchased.value / 100);

    return Math.round(verifiedLeadsPurchased * (averagePriceVerifiedLeads.value === 0 ? 60 : averagePriceVerifiedLeads.value) + unverifiedLeadsPurchased * (averagePriceUnverifiedLeads.value === 0 ? 25 : averagePriceUnverifiedLeads.value));
})

const isForecast = computed(() => selectedLabel.value === 'Forecast');

const startTimestamp = computed(() => (new Date()).getTime() / 1000 - secondsInDay * (diffMapping[isForecast.value ? forecastLabel.value : selectedLabel.value] ?? 1));
const endTimestamp = computed(() => (new Date()).getTime() / 1000);
const leadsPurchased = computed(() => {
    if (!isForecast.value) {
        return formatNumber(verifiedLeadsPurchased.value + unverifiedLeadsPurchased.value);
    }

    return formatNumber(Math.round((verifiedLeadsAvailable.value + unverifiedLeadsAvailable.value) * forecastPercentLeadsPurchased.value / 100));
});

const formatNumber = num => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

const handleSelectorClick = (selector) => {
    selectedLabel.value = selector;
}

/**
 * Get the total leads keyed by IndustryService id
 * @param key - purchased / received etc.
 * @returns {object} { ...[serviceId]: [total] }
 */
const getLeadTotalsByServiceId = (key) => {
    const leadTotals =  Object.values(scopedProfitabilities).reduce((totals, leadType) => {
        const subtotalByGroup = Object.values(leadType).reduce((subtotals, serviceGroup) => {
            subtotals[serviceGroup.industry_service_id] = (subtotals[serviceGroup] ?? 0) + serviceGroup[key];
            return subtotals;
        }, {});
        for (const serviceGroup in subtotalByGroup) {
            totals[serviceGroup] = (totals[serviceGroup] ?? 0) + subtotalByGroup[serviceGroup]
        }
        return totals;
    }, {});
    props.services?.forEach(serviceId => {
        if (!(serviceId in leadTotals)) leadTotals[serviceId] = 0;
    });
    return leadTotals;
}

/**
 * Weight each IndustryService's profitability configurations by the number of leads purchased in that service for the period
 * Add 1 to each to still get a return if 0 leads were purchased
 * @returns {Object}
 */
const calculateGlobalWeightedAverages = () => {
    const purchasedLeadsByService = getLeadTotalsByServiceId('purchased');
    const totalPurchasedLeads = Object.values(purchasedLeadsByService).reduce((out, v) => out + v, 0);

    const weightedTotals = Object.values(profitability.configurations).reduce((output, config) => {
        if (!props.services.includes(config.industry_service_id)) return output;
        output.averageLeadRevenue = (output.averageLeadRevenue ?? 0) + (config.average_lead_revenue * (1 + (purchasedLeadsByService[config.industry_service_id] ?? 0) ));
        output.labourMaterialsCost = (output.labourMaterialsCost ?? 0) + (config.labour_materials_cost * (1 + (purchasedLeadsByService[config.industry_service_id] ?? 0) ));
        output.averageJobCost = (output.averageJobCost ?? 0) + (config.average_job_cost * (1 + (purchasedLeadsByService[config.industry_service_id] ?? 0) ));
        output.percentageLeadsSuccessful = (output.percentageLeadsSuccessful ?? 0) + (config.percentage_leads_successful * (1 + (purchasedLeadsByService[config.industry_service_id] ?? 0) ));
        return output;
    }, {});

    for (const total in weightedTotals) {
        // add services length to total to account for the 1+ added to handle zero values
        weightedTotals[total] /= (totalPurchasedLeads + props.services.length);
    }
    return weightedTotals;
}

const calculateProfitability = async () => {
    const weightedAverages = props.global
        ? calculateGlobalWeightedAverages()
        : {}


    let revenuePerJob, grossMarginPerJob, verifiedSellPercentage, unverifiedSellPercentage;
    revenuePerJob = props.global ? weightedAverages.averageLeadRevenue : profitability.scoped.average_lead_revenue;
    grossMarginPerJob = revenuePerJob - (props.global ? weightedAverages.averageJobCost : profitability.scoped.average_job_cost);

    verifiedSellPercentage = ((props.global ? weightedAverages.percentageLeadsSuccessful : profitability.scoped.percentage_leads_successful) * 1.5) / 100;
    unverifiedSellPercentage = verifiedSellPercentage / 2;

    let vlp = verifiedLeadsPurchased.value;
    let ulp = unverifiedLeadsPurchased.value;

    if (isForecast.value) {
        vlp = Math.round(verifiedLeadsAvailable.value * forecastPercentLeadsPurchased.value / 100);
        ulp = Math.round(unverifiedLeadsAvailable.value * forecastPercentLeadsPurchased.value / 100);
    }

    jobsWon.value = Math.round((verifiedSellPercentage * vlp) + (unverifiedSellPercentage * ulp));
    grossMargin.value = Math.round(jobsWon.value * grossMarginPerJob);
    profit.value = Math.round(grossMargin.value - spentAmount.value);
    profit.value = profit.value > 0 ? profit.value : 0;
}

/**
 * Get the total of a given lead key across all the current scoped services
 * @param {string} verifiedStatus - verified or unverified
 * @param {string} key - key to total e.g. 'purchased'
 * @returns {number}
 */
const getTotalOfScopedKeys = (verifiedStatus, key) => {
    if (!scopedProfitabilities[verifiedStatus]) {
        console.warn(`Bad verified status, "${verifiedStatus}" not found in profitability stats`);
        return 0;
    }
    return parseInt(Object.values(scopedProfitabilities[verifiedStatus]).reduce((total, value) => {
        return total + (value[key] || 0);
    }, 0));
}

const company = useCompanyStore();

const loadData = () => {
    loading.value = true;
    Object.assign(scopedProfitabilities, {
        verified: {},
        unverified: {},
    });

    const promises = [];

    let apiService = services.apiService;

    if (company.futureCampaignsActive)
        apiService = services.apiServiceV4;

    promises.push(apiService.getLeadVolume({
        start_timestamp: startTimestamp.value,
        end_timestamp: endTimestamp.value,
        grouping: 'total',
        sales_type: 'exclusive,duo,trio,quad',
        global: props.global,
        services_filter: props.services
    }).then(resp => {
        Object.assign(scopedProfitabilities.verified, resp.data.data.groups);

        verifiedLeadsPurchased.value = getTotalOfScopedKeys('verified', 'purchased');
        verifiedLeadsAvailable.value = getTotalOfScopedKeys('verified','available');
        amountSpentVerifiedLeads.value = getTotalOfScopedKeys('verified', 'spent');
    }));

    promises.push(apiService.getLeadVolume({
        start_timestamp: startTimestamp.value,
        end_timestamp: endTimestamp.value,
        grouping: 'total',
        sales_type: 'unverified',
        global: props.global,
        services_filter: props.services
    }).then(resp => {
        Object.assign(scopedProfitabilities.unverified, resp.data.data.groups);

        unverifiedLeadsPurchased.value = getTotalOfScopedKeys('unverified', 'purchased');
        unverifiedLeadsAvailable.value = getTotalOfScopedKeys('unverified', 'available');
        amountSpentUnverifiedLeads.value = getTotalOfScopedKeys('unverified', 'spent');
    }));

    Promise.all(promises).then(() => {
        calculateProfitability();
        loading.value = false;
    });
}

const updateForecastLabel = (label) => {
    forecastLabel.value = label;
    loadData();
}

onMounted(async () => {
    if (props.services || !props.global) loadData();
    watch(() => props.services, () => {
        loadData();
    });
    watch(selectedLabel, () => loadData());
    watch(forecastPercentLeadsPurchased, () => calculateProfitability());
});

const chartOptions = computed(() => {
    return {
        legend: {
            selectedMode: false,
            orient: 'vertical',
            top: 0,
            name: ['Amount Spent on Leads', 'Gross Profit Generated']
        },
        series: [
            {
                label: {
                    normal: {
                        formatter: '${c}',
                        position: 'inside',
                        color: 'white',
                        textStyle: {
                            fontWeight: 'semibold',
                            fontSize: 18
                        }
                    }
                },
                name: 'Profitability',
                type: 'pie',
                center: ['50%', '58%'],
                data: [
                    {value: spentAmount.value, name: 'Amount Spent on Leads'},
                    {value: profit.value, name: 'Gross Profit Generated'},
                ],
                emphasis: {},
            }
        ]
    }
});
</script>