<template>
  <div>
    <!--    NEW DELIVERY METHOD BUTTONS    -->
    <div class="grid grid-cols-1 gap-y-12"
         :class="[saving ? 'pointer-events-none grayscale-[50%] opacity-50' : '']"
    >
      <div class="grid md:grid-cols-4 max-w-5xl items-center pt-4 gap-2">
        <OutlineButton @click="toggleContactDeliveryModal(true)">
          New Contact Delivery
        </OutlineButton>
        <OutlineButton @click="toggleCrmDeliveryModal(true)">
          New CRM Delivery
        </OutlineButton>
        <OutlineButton @click="toggleCrmImportModal(true)">
          Import CRM Delivery
        </OutlineButton>
        <OutlineButton v-if="deliveryStore.showSchedules" @click="toggleShowViewScheduleModal(true)">
          New Schedule
        </OutlineButton>
      </div>
    </div>

    <LoadingSpinner v-if="loading" :small="true"/>
    <div v-else>
      <div v-if="deliveryStore.showSchedules" class="py-4">
        <GridTable
            :tableSetup="schedulesTableSetup"
            :tableData="campaignSchedules"
			@change:checkbox="updateActiveSchedules"
            @action:edit="editSchedule"
            @action:delete="(id) => deleteSchedule(id)"
        />
      </div>
      <!--    CONTACT TABLE    -->
      <div class="py-4">
        <GridTable
            :tableSetup="contactTableSetup"
            :tableData="slideInputs.contact_deliveries"
            @action:edit="editContact"
            @action:delete="(id) => deleteDelivery(DeliveryType.Contact, id)"
        />
      </div>

      <!--	CRM TABLE	-->
      <div class="py-4 border-t border-slate-300">
        <GridTable
            :tableSetup="crmTableSetup"
            :tableData="slideInputs.crm_deliveries"
            @action:edit="editCrm"
            @action:delete="(id) => deleteDelivery(DeliveryType.Crm, id)"
        />
      </div>
    </div>

    <!--    Add Contact Delivery Modal  -->
    <ContactDeliveryModal
        v-if="showContactDeliveryModal"
        data-key="contact_deliveries"
        :future-campaigns="true"
        @hideContactDeliveryModal="toggleContactDeliveryModal(false)"
        @update:deliveries="handleContactModalUpdate"
    />

    <!--    Add CRM Delivery Modal      -->
    <CrmConfigurationModal
        v-if="showCrmDeliveryModal"
        data-key="crm_deliveries"
        :editing-id="editingCrmId"
        @close:modal="closeCrmModal"
        @update:deliveries="handleCrmModalUpdate"
    />

    <!--    Add contact modal    -->
    <CreateUpdateCompanyUserModal
        :deliveries-slide="true"
        @hideEditUserModal="closeEditContactModal"
        v-if="showEditContactModal"
    />

    <ImportCrmConfigurationModal
        v-if="showCrmImportModal"
        @close:modal="toggleCrmImportModal(false)"
        @import:crm="handleImportCrmConfiguration"
    />

    <!--    Delete confirm modal    -->
    <Modal
        v-if="showDeleteConfirmModal"
        @clicked:confirm="confirmDelete"
        @clicked:cancel="cancelConfirm"
        confirm-label="Delete"
        :small="true"
    >
      <template v-slot:header>
        <p>Delete Delivery Method</p>
      </template>
      <template v-slot:body>
        <p class="whitespace-pre-wrap">
          {{
            deleting.type === DeliveryType.Crm
                ? `Are you sure you wish to delete the CRM Delivery method "${deleting.name}"?\n\nThis will completely remove the CRM and its settings. If the CRM is attached to other Campaigns, it will not be deleted.`
                : `Are you sure you wish to delete the Contact Delivery method for "${deleting.name}"?\nThis will remove them as a lead delivery option for this Campaign only - it will not delete the contact.`
          }}
        </p>
      </template>
    </Modal>

    <!--    View Schedule Modal    -->
    <CreateOrEditScheduleModal
        v-if="showViewScheduleModal"
        :view-mode="!viewingSchedule === null"
        :edit-schedule="viewingSchedule"
        :timezones="referenceStore.timezones"
        @close-modal="toggleShowViewScheduleModal(false)"
    />


    <!--  Delete confirm modal  -->
    <DeleteScheduleModal
        v-if="showDeleteScheduleModal"
        :schedule="viewingSchedule"
        @deleted="toggleShowDeleteScheduleModal(false)"
        @canceled="toggleShowDeleteScheduleModal(false)"
    />
  </div>

</template>

<script setup lang="ts">

import OutlineButton from "@/components/inputs/OutlineButton.vue";
import CreateUpdateCompanyUserModal from "@/components/company/modals/CreateUpdateCompanyUserModal.vue";
import ContactDeliveryModal from "@/components/campaigns/EditorSlides/Modals/ContactDeliveryModal.vue";
import CreateOrEditScheduleModal from "@/views/appointments/modals/CreateOrEditScheduleModal/CreateOrEditScheduleModal.vue";
import Modal from "@/components/Modal.vue";
import {onMounted, reactive, Ref, ref, watch} from "vue";
import {DeliveryCollection, DeliveryType, useDeliveryStore} from "@/stores/v4/delivery";
import {useAlertStore} from "@/stores/v4/alerts";
import {SlideInputs} from "@/stores/v4/wizard";
import {useCompanyUsersStore} from "@/stores/company-users";
import GridTable from "@/components/v4/wizard/generics/GridTable.vue";
import {ReactiveVariable} from "vue/macros";
import {ValidatorResults} from "@/services/v4/WizardValidationService";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import CrmConfigurationModal from "@/components/v4/campaigns/modals/CrmConfigurationModal.vue";
import ImportCrmConfigurationModal from "@/components/v4/campaigns/modals/ImportCrmConfigurationModal.vue";
import {useSchedulesStore} from "@/stores/schedules";
import DeleteScheduleModal from "@/views/appointments/modals/DeleteScheduleModal.vue";
import {useReferenceDataStore} from "@/stores/reference-data";

type Schedule = {
	active: boolean,
	id: number,
}

interface Props {
  initialSlideData: SlideInputs,
}

const props = defineProps<Props>();

const emit = defineEmits(['update:slideInput']);

const slideInputs: ReactiveVariable<DeliveryCollection> = reactive({
  contact_deliveries: [],
  crm_deliveries: [],
  schedules: [],
});

watch(() => slideInputs.contact_deliveries, (newValue) => handleInputUpdate(newValue, 'contact_deliveries'), {deep: true});
watch(() => slideInputs.crm_deliveries, (newValue) => handleInputUpdate(newValue, 'crm_deliveries'), {deep: true});
watch(() => slideInputs.schedules, (newValue) => handleInputUpdate(newValue, 'schedules'), {deep: true});

const alertStore = useAlertStore();
const deliveryStore = useDeliveryStore();
const companyUserStore = useCompanyUsersStore();
const scheduleStore = useSchedulesStore();
const referenceStore = useReferenceDataStore();

const loading: Ref<boolean> = ref(false);
const saving: Ref<boolean> = ref(false);

const showContactDeliveryModal: Ref<boolean> = ref(false);
const showCrmDeliveryModal: Ref<boolean> = ref(false);
const showDeleteConfirmModal: Ref<boolean> = ref(false);
const showEditContactModal: Ref<boolean> = ref(false);
const showViewScheduleModal: Ref<boolean> = ref(false);
const showDeleteScheduleModal: Ref<boolean> = ref(false);
const showCrmImportModal: Ref<boolean> = ref(false);

const viewingSchedule = ref(null);

const campaignSchedules: Ref<Schedule[]> = ref([]);

const deleting: Ref<{ id: number | null, type: DeliveryType | null, name: string }> = ref({
  type: null,
  id: null,
  name: '',
});

const editingCrmId: Ref<number | null> = ref(null);

const initialize = async () => {
  loading.value = true;

  Object.assign(slideInputs, props.initialSlideData);

  const [companyUserResult, deliveryResult] = await Promise.all([
    companyUserStore.initialize(),
    deliveryStore.initialize(),
    scheduleStore.initialize(),
    referenceStore.getTimezones()
  ]);
  updateCampaignSchedules();

  const errorMessage = companyUserResult.message || deliveryResult?.message || null;
  if (errorMessage) alertStore.showError(errorMessage);

  slideInputs.crm_deliveries = slideInputs.crm_deliveries.filter(crm => crm.display_name);
  slideInputs.contact_deliveries = slideInputs.contact_deliveries.filter(contact => contact.name);
  watch(() => scheduleStore.schedules, () => updateCampaignSchedules(), { deep: true });

  loading.value = false;
}
onMounted(() => initialize());

const deleteDelivery = (type: DeliveryType, deliveryId: number) => {
  deleting.value = {
    type: type,
    id: deliveryId,
    name: getDeliveryName(type, deliveryId),
  }
  toggleShowDeleteConfirmModal(true);
}

const updateCampaignSchedules = () => {
	campaignSchedules.value = (scheduleStore.schedules as Schedule[] ?? []).map(schedule => {
		return {
			...schedule,
			active: (slideInputs.schedules ?? []).includes(schedule.id),
		}
	});
}

const confirmDelete = () => {
  if (deleting.value.type === DeliveryType.Crm) {
    const targetIndex = slideInputs.crm_deliveries.findIndex(crm => crm.id === deleting.value.id);
    if (targetIndex !== -1) slideInputs.crm_deliveries.splice(targetIndex, 1);
  } else {
    const targetIndex = slideInputs.contact_deliveries.findIndex(contact => contact.contact_id === deleting.value.id);
    if (targetIndex !== -1) slideInputs.contact_deliveries.splice(targetIndex, 1);
  }

  cancelConfirm();
}

const cancelConfirm = () => {
  deleting.value = {
    type: null,
    id: null,
    name: '',
  }
  toggleShowDeleteConfirmModal(false);
}

const editCrm = (crmId: number) => {
  const targetCrm = slideInputs.crm_deliveries.find(crm => crm.id === crmId);
  if (targetCrm) {
    deliveryStore.editingCrmConfiguration = targetCrm;
    editingCrmId.value = crmId;
    toggleCrmDeliveryModal(true);
  }
}

const closeCrmModal = () => {
  editingCrmId.value = null;
  toggleCrmDeliveryModal(false);
}

const editContact = (editContactId: number) => {
  companyUserStore.editCompanyUser(editContactId);
  toggleShowEditContactModal(true);
}

const closeEditContactModal = (user: any) => {
  updateContactDelivery(user);
  companyUserStore.clearEditing();
  toggleShowEditContactModal(false);
}

const updateContactDelivery = (user: any) => {
  const contact = slideInputs.contact_deliveries.find(contact => contact.contact_id === user.id);

  if (contact) {
    contact.email = user.email;
    contact.name = `${user.first_name} ${user.last_name}`;
    contact.cell_phone = user.cell_phone;
  }
}

const handleContactModalUpdate = (newValues: any) => {
  slideInputs.contact_deliveries.push(...newValues);
}

const handleCrmModalUpdate = (newValue: any) => {
  const targetCrmIndex = newValue.id
      ? slideInputs.crm_deliveries.findIndex(crm => crm.id === newValue.id)
      : -1;

  if (targetCrmIndex >= 0)
    slideInputs.crm_deliveries[targetCrmIndex] = {...newValue}
  else
    slideInputs.crm_deliveries.push({...newValue});
}

const handleImportCrmConfiguration = (importId: number) => {
  const target = deliveryStore.crmImportOptions.find(crm => crm.id === importId);
  if (target) {
    const clone = JSON.parse(JSON.stringify(target));
    clone.display_name = `${clone.display_name} copy`;
    slideInputs.crm_deliveries.push(clone);
  }
}

const handleInputUpdate = (newValue: any, inputKey: string) => {
  emit('update:slideInput', newValue, inputKey);
}

const toggleModal = (modalToggle: Ref<boolean>, show?: boolean) => {
  modalToggle.value = show === undefined
      ? !modalToggle.value
      : show;
}

const getDeliveryName = (type: DeliveryType, id: number): string => {
  return type === DeliveryType.Crm
      ? slideInputs.crm_deliveries.find(crm => crm.id === id)?.display_name ?? 'this CRM delivery'
      : slideInputs.contact_deliveries.find(contact => contact.contact_id === id)?.name ?? 'this contact delivery';
}

const updateActiveSchedules = () => {
	slideInputs.schedules = campaignSchedules.value.reduce((output, schedule) => {
		return schedule.active
			? [...output, schedule.id]
			: output;
	}, [] as number[]);
}

const customValidation = (): ValidatorResults => {
  const activeContacts: number = slideInputs.contact_deliveries.reduce((total: number, contact: GenericObject) => total + (contact.sms_active || contact.email_active) ? 1 : 0, 0);
  const activeCrm: number = slideInputs.crm_deliveries.reduce((total: number, crm: GenericObject) => total + crm.active ? 1 : 0, 0);
  const errors = []

  if (activeContacts + activeCrm === 0) {
    errors.push("At least one delivery method must be active.")
  }

  if (deliveryStore.showSchedules && !slideInputs.schedules.length) {
    errors.push('At least one schedule must be selected. If you no longer wish to receive appointments through this campaign, you can pause or delete it.')
  }

  return errors.length === 0
      ? {valid: true}
      : {valid: false, errorBag: errors, message: errors.join('')}
}
defineExpose({customValidation});

const toggleContactDeliveryModal = (show?: boolean) => toggleModal(showContactDeliveryModal, show);
const toggleCrmDeliveryModal = (show?: boolean) => toggleModal(showCrmDeliveryModal, show);
const toggleShowDeleteConfirmModal = (show?: boolean) => toggleModal(showDeleteConfirmModal, show);
const toggleShowEditContactModal = (show?: boolean) => toggleModal(showEditContactModal, show);
const toggleShowViewScheduleModal = (show?: boolean) => {
  if (!show) {
    viewingSchedule.value = null;
  }
  toggleModal(showViewScheduleModal, show)
};
const toggleShowDeleteScheduleModal = (show?: boolean) => {
  if (!show) {
    viewingSchedule.value = null;
  }
  toggleModal(showDeleteScheduleModal, show)
};
const toggleCrmImportModal = (show?: boolean) => toggleModal(showCrmImportModal, show);

const contactTableSetup = {
  title: 'Company Contacts',
  subTitle: 'Decide who receives emails or texts for this campaign.',
  columns: [
    {label: 'Name', key: 'name'},
    {label: 'Email', key: 'email', checkbox: 'email', emitKey: 'email_active'},
    {label: 'Cell (SMS)', key: 'cell_phone', checkbox: 'cell_phone', emitKey: 'sms_active'},
    {label: 'Actions', key: '', editDelete: true, emitKey: 'contact_id'},
  ],
}

const crmTableSetup = {
  title: 'CRM Integrations',
  columns: [
    {label: 'Active', key: '', checkbox: true, emitKey: 'active'},
    {label: 'Name', key: 'display_name'},
    {label: 'CRM', key: 'crm_type_display'},
    {label: 'Actions', key: '', editDelete: true, emitKey: 'id'},
  ],
}

const schedulesTableSetup = {
  title: 'Schedules',
  columns: [
    {label: 'Active', key: '', checkbox: true, emitKey: 'active'},
    {label: 'Name', key: 'name'},
    {label: 'Actions', key: '', editDelete: true, emitKey: 'id'},
  ],
}

const editSchedule = (id: number) => {
  const targetSchedule = scheduleStore.schedules.find(schedule => schedule.id === id);
  if (targetSchedule) {
    viewingSchedule.value = JSON.parse(JSON.stringify(targetSchedule));
    toggleShowViewScheduleModal(true)
  }
}

const deleteSchedule = (id: number) => {
  const targetSchedule = scheduleStore.schedules.find(schedule => schedule.id === id);
  if (targetSchedule) {
    viewingSchedule.value = JSON.parse(JSON.stringify(targetSchedule));
    toggleShowDeleteScheduleModal(true)
  }
}

</script>
