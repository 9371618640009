<template>
    <div>
        <Modal
            @clicked:confirm="addContactDeliveries"
            @clicked:cancel="closeModal"
            confirm-label="Add Selected"
        >
            <template v-slot:header>
                <div class="flex items-center">
                    <svg  class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    </svg>
                    <h5 class="text-md">Add Contact Delivery</h5>
                </div>
            </template>
            <template v-slot:body>
                <div class="relative min-h-[16rem]">
                    <LoadingSpinner v-if="loading || saving" :small="true"/>
                    <div v-if="!loading" class="overflow-x-hidden">
                        <div class="hidden md:grid md:grid-cols-4 text-xs text-gray-700 uppercase px-4 bg-cyan-50 font-bold py-3 items-center border-b border-gray-200" >
                            <div>Select</div>
                            <div>Name</div>
                            <div>Email</div>
                            <div>Cell Phone</div>
                        </div>
                        <div v-for="user in availableContacts"
                             :key="user.id"
                             class="relative border-b odd:bg-gray-50 text-gray-600 text-sm items-start md:items-center grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-0 px-4 py-6 md:py-4 md:px-4" >
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">Select</p>
                                <CustomCheckbox v-if="futureCampaigns" v-model="selectedOdinContacts[user.id]" />
                                <CustomCheckbox v-else v-model="selectedContacts[user.legacy_id]" />
                            </div>
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">Name</p>

                                <p>{{ user.first_name }} {{user.last_name}}</p>
                            </div>
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">Email</p>

                                <p>{{ user.email }}</p>
                            </div>
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">Cell Phone</p>

                                <p>{{ user.cell_phone }}</p>
                            </div>
                        </div>
                        <div class="mt-4">
                            <SolidButton
                                class="mb-4"
                                @click="addNewContact"
                                label="Create New Contact"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
        <!--    Add contact modal    -->
        <CreateUpdateCompanyUserModal
            :deliveries-slide="true"
            @hideEditUserModal="closeAddUserModal"
            v-if="showAddContactModal"
        />
    </div>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import { useCompanyUsersStore } from "@/stores/company-users.js";
import { onBeforeMount, ref } from "vue";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import { useCampaignStore } from "@/stores/campaigns.js";
import SolidButton from "@/components/inputs/SolidButton.vue";
import CreateUpdateCompanyUserModal from "@/components/company/modals/CreateUpdateCompanyUserModal.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useWizardStore } from "@/stores/v4/wizard";

const loading = ref(true);
const saving = ref(false);

const showAddContactModal = ref(false);

const availableContacts = ref([]);
const selectedContacts = ref({});
const selectedOdinContacts = ref({});

const companyUserStore = useCompanyUsersStore();
const campaignStore = useCampaignStore();
const wizardStore = useWizardStore();

const props = defineProps({
    // Setting this will cause the component to emit updates instead of committing to the legacy campaign store
    futureCampaigns: {
        type: Boolean,
        default: false,
    },
	dataKey: {
		type: String,
		default: '',
	}
});

onBeforeMount(async () => {
    await companyUserStore.initialize(true);
    updateAvailableContacts();
    loading.value = false;
});

const updateAvailableContacts = () => {
    availableContacts.value = [];
    selectedContacts.value = {};
    selectedOdinContacts.value = {};
    const currentLegacyIds = campaignStore.editingCampaign.contact_deliveries.map(delivery => delivery.id ?? null).filter(v=>v);
	const currentContactIds = (wizardStore.fetchInputValue('delivery', 'contact_deliveries') ?? [])
		?.map(delivery => delivery.contact_id)
		.filter(v=>v);

	companyUserStore.companyUsers.forEach(user => {
        if (props.futureCampaigns) {
            if (!currentContactIds.includes(user.id) && (user.email || user.phone)) {
                availableContacts.value.push(user);
                selectedOdinContacts[user.id] = false;
            }
        }
        else {
            if (user.is_contact
                && user.legacy_id
                && !currentLegacyIds.includes(user.id)
                && (user.email || user.cell_phone)
            ) {
                availableContacts.value.push(user);
                selectedContacts[user.legacy_id] = false;
            }
        }
    });
}

const emit = defineEmits([
    'hideContactDeliveryModal',
    'update:deliveries',
]);

const addContactDeliveries = async () => {
	if (props.futureCampaigns && Object.keys(selectedOdinContacts.value)?.length) {
		saving.value = true;
		const payload = Object.entries(selectedOdinContacts.value).reduce((output, [ userId, checked ]) => {
			if (checked) {
				const contact = companyUserStore.companyUsers.find(user => userId && parseInt(user.id) === parseInt(userId));
				if (contact) {
					output.push({
						contact_id: contact.id,
						name: `${contact.first_name} ${contact.last_name}`,
						email: contact.email,
						cell_phone: contact.cell_phone,
						sms_active: false,
						email_active: false,
						new: true,
					});
				}
			}
			return output;
		}, []);

		selectedOdinContacts.value = {};
		emit('update:deliveries', payload, props.dataKey);
	}
    else if (Object.keys(selectedContacts.value).length) {
		saving.value = true;
		Object.keys(selectedContacts.value).forEach(legacyId => {
			const contact = companyUserStore.companyUsers.find(user => legacyId && parseInt(user.legacy_id) === parseInt(legacyId));
			if (contact) {
				campaignStore.editingCampaign.contact_deliveries.push({
					id: contact.id,
					legacy_id: legacyId,
					name: `${contact.first_name} ${contact.last_name}`,
					email: contact.email,
					cell_phone: contact.cell_phone,
					sms_active: false,
					email_active: false,
					new: true,
				});
			}
		});
		selectedContacts.value = {};
	}
    updateAvailableContacts();
	saving.value = false;
    emit('hideContactDeliveryModal');
}

const addNewContact = () => {
    companyUserStore.editingUser.user_type = 'contact';
    companyUserStore.editingUser.is_contact = 1;
    showAddContactModal.value = true;
}

const closeAddUserModal = async () => {
    loading.value = true;
    showAddContactModal.value = false;
    updateAvailableContacts();
    loading.value = false;
}

const closeModal = () => {
    emit('hideContactDeliveryModal');
}

</script>