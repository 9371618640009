<template>
    <div :class="[ saving || loading || !storeInitialized ? 'grayscale-[60%] opacity-50 pointer-events-none' : '' ]"
        class="min-h-[40rem]"
    >
        <div
            v-if="showZipCodeUploadButton || showZipCodeRadiusButton"
            class="flex items-center gap-x-6 pb-6"
        >
            <OutlineButton
                v-if="showZipCodeUploadButton"
                @click="uploadFile"
            >
                Upload File
            </OutlineButton>
            <OutlineButton
                v-if="showZipCodeRadiusButton"
                @click="addZipCodesByRadius"
            >
                Add Zip Codes By Radius
            </OutlineButton>
        </div>
        <div v-if="autoSelectStates.length && !loading"
             class="w-full flex rounded-lg bg-cyan-50 p-4 mb-3 text-gray-700"
        >
            <InformationCircleIcon class="h-5 w-5"/>
            <p class="ml-4 text-sm font-medium">
                {{ getAutoServicedZipCodesInfo() }}
            </p>
        </div>
        <div class="relative">
            <div class="flex items-center py-6">
                <button @click="loadAllStatesView">
                    All States
                </button>
                <!-- Navigation -->
                <div v-if="selectedState" class="flex items-center">
                    <svg class="h-4 mx-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    <button :class="[ selectedCounty ? 'text-slate-800 cursor-pointer' : 'text-cyan-500 cursor-default' ]"
                        @click="loadStateView(selectedState.state_key)"
                        @disabled="!selectedCounty"
                    >
                        {{ selectedState.state_name }}
                    </button>
                    <div v-if="selectedCounty" class="flex items-center">
                        <svg class="h-4 mx-4"
                             v-if="selectedCounty" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                        <p :class="[ selectedCounty ? 'text-cyan-500' : 'text-slate-800' ]">
                            {{ selectedCounty.county_name }}
                        </p>
                    </div>
                </div>
            </div>
            <!--   All states view  -->
            <div v-if="currentView === 'all'">
                <div class="relative hidden z-50 md:sticky md:top-20 md:grid md:grid-cols-4 text-xs text-gray-700 uppercase bg-cyan-50 font-bold py-2 px-6 items-center border-b border-gray-200">
                    <p>State</p>
                    <p class="text-center">Serviced Zip Codes</p>
                    <p class="text-center">Total Zip Codes</p>
                    <p class="text-center">Actions</p>
                </div>
                <div v-for="state in localityDataStore.states"
                     :key="state.key"
                     class="relative border-b odd:bg-gray-50 text-gray-600 items-center grid md:grid-cols-4 gap-4 md:gap-0 py-4 px-4 md:px-6">
                    <div>
                        <button class="text-sm hover:text-cyan-500" @click="loadStateView(state.state_key)">{{ state.state_name }}</button>
                    </div>
                    <div class="text-sm md:text-center">
                        {{ getServicedZipCodesOfState(state) }}
                    </div>
                    <div class="text-sm md:text-center">
                        {{ getTotalZipCodesOfLocation(state) }}
                    </div>
                    <div class="flex gap-x-6 md:justify-center text-sm">
                        <OutlineButton
                            @click="handleBulkCodes('state', state.state_key, true)"
                            :disabled="getServiceAreaActionButtonState(state, 'state')"
                        >
                            Add All
                        </OutlineButton>
                        <OutlineButton
                            @click="handleBulkCodes('state', state.state_key, false)"
                            color="red"
                            :disabled="!getServiceAreaActionButtonState(state, 'state', true)"
                        >
                            Remove All
                        </OutlineButton>
                    </div>
                </div>
            </div>
            <!--    State view  -->
            <div v-else-if="currentView === 'state'">
                <div class="relative hidden z-50 md:sticky md:top-20 md:grid md:grid-cols-4 text-xs text-gray-700 uppercase bg-cyan-50 font-bold py-2 px-6 items-center border-b border-gray-200">
                    <p>County</p>
                    <p class="text-center">Serviced Zip Codes</p>
                    <p class="text-center">Total Zip Codes</p>
                    <p class="text-center">Actions</p>
                </div>
                <div v-for="county in currentStateView"
                     :key="county.count_key"
                     class="relative border-b odd:bg-gray-50 text-gray-600 items-center grid md:grid-cols-4 gap-4 md:gap-0 py-4 px-4 md:px-6">
                    <div>
                        <button class="text-sm hover:text-cyan-500" @click="loadCountyView(county.county_key)">{{ county.county_name }}</button>
                    </div>
                    <div class="text-sm md:text-center">
                        {{ getActiveZipCodes('county', county.county_key )}}
                    </div>
                    <div class="text-sm md:text-center">
                        {{ getTotalZipCodesOfLocation(county) }}
                    </div>
                    <div class="flex gap-x-6 md:justify-center text-sm">
                        <OutlineButton
                            @click="handleBulkCodes('county', county.county_key, true)"
                            :disabled="getServiceAreaActionButtonState(county, 'county')"
                        >
                            Add All
                        </OutlineButton>
                        <OutlineButton
                            @click="handleBulkCodes('county', county.county_key, false)"
                            color="red"
                            :disabled="!getServiceAreaActionButtonState(county, 'county', true)"
                        >
                            Remove All
                        </OutlineButton>
                    </div>
                </div>
            </div>
            <!--    County view  -->
            <div v-else-if="currentView === 'county'">
                <div class="relative hidden z-50 md:sticky md:top-20 md:grid md:grid-cols-4 text-xs text-gray-700 uppercase bg-cyan-50 font-bold py-2 px-6 items-center border-b border-gray-200">
                    <p>Zip Code</p>
                    <p>City</p>
                    <p class="text-center">Serviced</p>
                </div>
                <div v-for="locality in currentCountyView"
                     :key="locality.id"
                     class="relative border-b odd:bg-gray-50 text-gray-600 items-center grid md:grid-cols-4 gap-4 md:gap-0 py-4 px-4 md:px-6">
                    <p class="text-sm">{{ locality.zip_code }}</p>
                    <p class="text-sm">{{ locality.city_name }}</p>
                    <p class="text-sm text-center">
                        <CustomCheckbox
                          :model-value="isActiveZipCode(locality.id)"
                          @update:modelValue="(checked) => updateZipCodes([ locality ], checked)"
                        />
                    </p>
                </div>
            </div>
        </div>
        <!--    Zip Radius modal    -->
        <ZipCodeRadiusModal
            v-if="showZipCodeRadiusModal"
            @hideZipRadiusModal="hideZipRadiusModal"
            @addZipCodesFromModal="addZipCodesFromModal"
        />
        <!--    Zip Upload modal    -->
        <ZipCodeUploadModal
            v-if="showZipCodeUploadModal"
            @hideZipUploadModal="hideZipUploadModal"
            @addZipCodesFromModal="addZipCodesFromModal"
        />
    </div>
</template>

<script setup>
import { onMounted, reactive, ref, toRaw } from "vue";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import { useLocalityDataStore } from "@/stores/locality-data.js";
import { useCampaignStore } from "@/stores/campaigns.js";
import ZipCodeRadiusModal from "@/components/campaigns/EditorSlides/Modals/ZipCodeRadiusModal.vue";
import ZipCodeUploadModal from "@/components/campaigns/EditorSlides/Modals/ZipCodeUploadModal.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {InformationCircleIcon} from "@heroicons/vue/solid";

const props = defineProps({
    showZipCodeRadiusButton: {
        type: Boolean,
        default: true,
    },
    showZipCodeUploadButton: {
        type: Boolean,
        default: true,
    },
    autoSelectStates: {
        type: Array,
        default: []
    },
});


const localityDataStore = useLocalityDataStore();
const campaignStore = useCampaignStore();

const storeInitialized = ref(false);
const loading = ref(false);
const saving = ref(false);

const servicedZipCodes = reactive({});

const currentStateView = ref([]);
const currentCountyView = ref([]);

const selectedState = ref(null);
const selectedCounty = ref(null);
const currentView = ref('all');

const showZipCodeRadiusModal = ref(false);
const showZipCodeUploadModal = ref(false);

localityDataStore.initialize().then(() => storeInitialized.value = true);

onMounted(() => {
    updateActiveZipCodeTotals()
});

const isActiveZipCode = (id) => {
    return Object.keys(campaignStore.editingCampaign.zip_codes).includes(`${id}`);
};

const loadAllStatesView = () => {
    loading.value = true;
    selectedState.value = null;
    selectedCounty.value = null;
    currentView.value = 'all';
    loading.value = false;
}

const loadStateView = async (stateKey) => {
    loading.value = true;
    currentStateView.value = await localityDataStore.getStateDetails(stateKey);
    selectedState.value = localityDataStore.states.find(state => state.state_key === stateKey);
    currentView.value = 'state';
    selectedCounty.value = null;
    loading.value = false;
}

const loadCountyView = async (countyKey) => {
    loading.value = true;
    currentCountyView.value = await localityDataStore.getCountyDetails(countyKey, selectedState.value.state_key);
    selectedCounty.value = localityDataStore.stateDetailsStore[selectedState.value.state_key].find(county => county.county_key === countyKey);
    currentView.value = 'county';
    loading.value = false;
}

const updateActiveZipCodeTotals = () => {
    for (const key in servicedZipCodes) servicedZipCodes[key] = { total: 0 };
    Object.entries(campaignStore.editingCampaign.zip_codes).forEach(([ zipId, zipCode]) => {
        servicedZipCodes[zipCode.state_key] = servicedZipCodes[zipCode.state_key] ?? { total: 0 };
        servicedZipCodes[zipCode.state_key][zipCode.county_key] = servicedZipCodes[zipCode.state_key][zipCode.county_key] ?? { total: 0, ids: [] };
        servicedZipCodes[zipCode.state_key].total ++;
        servicedZipCodes[zipCode.state_key][zipCode.county_key].total ++;
        servicedZipCodes[zipCode.state_key][zipCode.county_key].ids.push(parseInt(zipId));
    });
}

const getActiveZipCodes = (locationType, locationKey) => {
    return locationType === 'state'
        ? servicedZipCodes[locationKey]?.total ?? 0
        : servicedZipCodes[selectedState.value.state_key]?.[locationKey]?.total ?? 0;
}

const updateZipCodes = (zipCodeArray, addCodes) => {
    loading.value = true;
    if (addCodes) {
        zipCodeArray.forEach(code => {
            campaignStore.editingCampaign.zip_codes[code.id] = campaignStore.editingCampaign.zip_codes[code.id] ?? { city: code.city_name, zip_code: code.zip_code, county_key: code.county_key, state_key: code.state_key }
        });
    }
    else {
        const ids = zipCodeArray.map(code => code.id);
        ids.forEach(id => delete campaignStore.editingCampaign.zip_codes[id]);
    }
    updateActiveZipCodeTotals();
    loading.value = false;
}

const replaceZipcodes = (zipCodes) => {
    loading.value = true;

    campaignStore.editingCampaign.zip_codes = {};

    zipCodes.forEach(zipCode => {
        campaignStore.editingCampaign.zip_codes[zipCode.id] = {city: zipCode.city_name, zip_code: zipCode.zip_code, county_key: zipCode.county_key, state_key: zipCode.state_key};
    });

    updateActiveZipCodeTotals();

    loading.value = false;
}

const handleBulkCodes = async (locationType, locationKey, addCodes) => {
    saving.value = true;
    if (locationType === 'county') {
        const currentStateKey = selectedState.value.state_key;
        await localityDataStore.getCountyDetails(locationKey, currentStateKey);
        updateZipCodes(toRaw(localityDataStore.countyDetailsStore[currentStateKey][locationKey]), addCodes);
    }
    else if (locationType === 'state') {
        await localityDataStore.getAllCountiesInState(locationKey);
        const allZipCodes = Object.values(localityDataStore.countyDetailsStore[locationKey]).reduce((output, county) => Array.isArray(county) ? [ ...output, ...county ] : output );
        updateZipCodes(allZipCodes,  addCodes);
    }
    saving.value = false;
}

const preSelectTheServicedZipCodes = () => {
    if (!props.autoSelectStates.length) {
        return;
    }

    props.autoSelectStates.forEach((stateKey) => {
        handleBulkCodes('state', stateKey, true);
    });
}

preSelectTheServicedZipCodes();

const addZipCodesFromModal = (payload, append = true) => {
    if (append)
        updateZipCodes(payload, true);
    else
        replaceZipcodes(payload);

    hideZipRadiusModal();
    hideZipUploadModal();
}

const uploadFile = () => {
    showZipCodeUploadModal.value = true;
}

const addZipCodesByRadius = () => {
    showZipCodeRadiusModal.value = true;
}

const hideZipRadiusModal = () => {
    showZipCodeRadiusModal.value = false;
}

const hideZipUploadModal = () => {
    showZipCodeUploadModal.value = false;
}

const getServiceAreaActionButtonState = (location, locationType, applyOneZipCodeFilter = false) => {
    let servicedZipCodes = locationType === 'county'
        ? getActiveZipCodes(locationType, location.county_key)
        : getServicedZipCodesOfState(location);

    return applyOneZipCodeFilter
        ? servicedZipCodes > 0
        : servicedZipCodes === getTotalZipCodesOfLocation(location);
}

const getServicedZipCodesOfState = (state) => {
    return state && state.state_key
        ? servicedZipCodes[state.state_key]?.total ?? 0
        : 0;
}

const getTotalZipCodesOfLocation = (location) => {
    return location?.total_zip_codes ?? 0
}

const getAutoServicedZipCodesInfo = () => {
    return `The campaign has been auto-setup for the service areas of your selected lead${props.autoSelectStates.length > 1 ? 's' : '' }.`;
}

const validateSlide = () => {
    return Object.keys(campaignStore.editingCampaign.zip_codes).length > 0
        ? { status: true }
        : { status: false, message: `At least one zip code must be selected.` }
}
defineExpose({ validateSlide });


</script>
