import {ref} from 'vue';
import {defineStore} from 'pinia';

export const useErrorStore = defineStore('errors', () => {
    const errors = ref([]);

    function report(error, customOutputMessage) {

        if(!customOutputMessage) {
            errors.value.push(error);
        } else {
            errors.value.push({error: error, customOutputMessage: customOutputMessage});
        }

        // Log error to the console
        // Sentry will pick up on anything logged using console.error()
        console.error(error);
    }

    function $reset() {
        errors.value = [];
    }

    return {
        errors,
        report,
        $reset
    };
});