<template>
    <MainGrid>
        <CompanyOverview />

        <template v-slot:side>
            <CompanyAddresses :store-initialized="initializedLocationStore"/>
        </template>
    </MainGrid>
</template>

<script setup>
import MainGrid from "@/components/layouts/MainGrid.vue";
import CompanyOverview from "@/components/company/CompanyOverview.vue";
import CompanyAddresses from "@/components/company/CompanyAddresses.vue";
import { ref } from "vue";
import { useLocationsStore } from "@/stores/locations.js";

const locationStore = useLocationsStore();

const initializedLocationStore = ref(false);

locationStore.initialize().then(() => initializedLocationStore.value = true);

</script>