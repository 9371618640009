<template>
    <Module class="mt-4 mx-4">
        <template v-slot:title>
            <div class="flex items-center justify-between py-2">
                <div class="flex items-center">
                    <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                    </svg>
                    <h2 class="text-lg font-medium">{{ campaignStore.getProductLabel() }} Campaigns</h2>
                </div>
                <div class="flex items-center cursor-pointer text-cyan-500" @click="selectZipCodeTargetingType">
                    <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <p class="text-right text-cyan-500 pb-0 block">New Campaign</p>
                </div>
            </div>
        </template>

        <template v-slot:default>
            <div class="gap-y-4 flex flex-col justify-center relative min-h-[12rem]">
                <div class="pb-2 flex flex-wrap gap-4 justify-between items-center"
                     :class="[ saving ? 'grayscale-[60%] opacity-50 pointer-events-none' : '' ]"
                >
                    <div class="w-64">
                        <CustomSelect
                            v-model="selectedCampaignFilter"
                            :options="campaignFilterOptions"
                            type="number"
                        />
                    </div>

                    <div class="flex items-center justify-end cursor-pointer"
                         @click="downloadCampaignZipCodes"
                    >
                        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1666 15.8333L19.1666 17.5L3.83329 17.5L3.83329 15.8333L19.1666 15.8333ZM12.4583 10.8333L15.3333 10.8333L11.5 14.1667L7.66663 10.8333L10.5416 10.8333L10.5416 2.5L12.4583 2.5L12.4583 10.8333Z" fill="#20A5DC"/>
                        </svg>
                        <p class="text-right text-cyan-500 pb-0 block">Download All Zips</p>
                    </div>
                </div>
                <LoadingSpinner v-if="loading || saving" :small="true" />
                <div v-if="!loading"
                    :class="[saving && 'grayscale-[50%] pointer-events-none']"
                >
                    <div v-if="!campaignStore.scopedCampaignList.length" class="py-4">
                        No Campaigns found.
                    </div>

                    <div class="left-0 top-0 inline-flex items-center gap-x-8 z-[60]">
                        <div v-if="selectedCampaignReferences.length" class="flex items-center gap-3 text-sm">
                            <p class="text-gray-600 text-sm">Selection: </p>
                            <p @click="activateCampaigns(null)" class="text-cyan-500 font-medium cursor-pointer hover:text-cyan-600">Activate</p>
                            <p class="text-gray-400 text-sm">/</p>
                            <p @click="showPauseCampaignModal(null)" class="text-red-500 font-medium cursor-pointer hover:text-red-600">Pause</p>
                        </div>
                    </div>

                    <GridTable
                        :table-data="filteredCampaigns"
                        :table-setup="campaignTableSetup"
                        @action:edit="editCampaign"
                        @action:delete="confirmCampaignDelete"
                        @update:selection="updateSelectedCampaigns"
                        @update:toggle-switch="handleCampaignStatusToggle"
                        @click:item="editCampaign"
                    />
                </div>

                <!--    Delete campaign confirmation    -->
                <Modal
                    v-if="modals.delete"
                    :class="[ saving ? 'grayscale-[60%] pointer-events-none' : '' ]"
                    confirm-label="Yes"
                    @clicked:confirm="deleteCampaign"
                    @clicked:cancel="cancelDelete"
                    :small="true"
                >
                    <template v-slot:header>
                        Delete Campaign
                    </template>
                    <template v-slot:body>
                        <p class="text-center">Are you sure you wish to delete Campaign "{{ campaignStore.getCampaignNameByUuid(deletingCampaign ?? '') }}"? The data will be permantently lost.</p>
                    </template>
                </Modal>

                <!--    Pause campaign confirmation    -->
                <PauseCampaignModal
                    v-if="modals.pause"
                    :campaign-name="getCampaignDisplayName()"
                    :saving="saving"
                    @confirm-pause="pauseCampaigns"
                    @cancel-pause="cancelPause"
                />

                <!--    Zip code targeting modal    -->
                <Modal
                    v-if="modals.zipCode"
                    :class="[ saving ? 'grayscale-[60%] pointer-events-none' : '' ]"
                    confirm-label="Yes"
                    cancel-label="No"
                    @clicked:confirm="confirmZipCodeTargeting(true)"
                    @clicked:cancel="confirmZipCodeTargeting(false)"
                    :small="true"
                >
                    <template v-slot:header>
                        Zip Code Targeting
                    </template>
                    <template v-slot:body>
                        <p class="font-bold ">You have the option to create a zip code targeted campaign for certain counties.</p>
                        <p class="mt-2">If you choose to take advantage of zip code targeting for this campaign, the following constraints will be applied:</p>
                        <ul class="mb-2">
                            <li>- Verified budget will be locked at 'No Limit'</li>
                            <li>- Bids for each county will be 20% above the floor at minimum</li>
                        </ul>
                        <p class="font-bold mt-2">Would you like to enable zip code targeting for this campaign?</p>
                    </template>
                </Modal>
            </div>
        </template>
    </Module>
</template>

<script setup lang="ts">
import Module from "@/components/Module.vue";
import { ProductType } from "@/stores/services";
import { CampaignStatus, CampaignPausePayload, CampaignSummary, useFutureCampaignStore } from "@/stores/v4/future-campaigns";
import { computed, ComputedRef, onBeforeMount, reactive, Ref, ref } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Modal from "@/components/Modal.vue";
import GridTable, { TableSetup } from "@/components/v4/wizard/generics/GridTable.vue";
import { ReactiveVariable } from "vue/macros";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { useAlertStore } from "@/stores/v4/alerts";
import PauseCampaignModal from "@/components/v4/campaigns/modals/PauseCampaignModal.vue";

interface Props {
    product: ProductType,
}
defineProps<Props>();

const emit = defineEmits(['show:campaignWizard']);

const campaignStore = useFutureCampaignStore();
const alertStore = useAlertStore();

const loading: Ref<boolean> = ref(false);
const saving: Ref<boolean> = ref(false);

const modals: ReactiveVariable<{ [key: string]: boolean }> = reactive({
    delete: false,
    pause: false,
    zipCode: false,
});

const campaignFilterOptions = [
    {label:'All', value: 0},
    {label:'Active', value: 1},
    {label:'Inactive', value: 2}
];
const selectedCampaignFilter: Ref<number> = ref(0);

const deletingCampaign: Ref<string|null> = ref(null);
const pausingCampaign: Ref<string|null> = ref(null);
const bulkPauseCampaign: Ref<boolean> = ref(false);

const selectedCampaignReferences: Ref<string[]> = ref([]);

const filteredCampaigns: ComputedRef<CampaignSummary[]> = computed(() => {
    return selectedCampaignFilter.value === 0
        ? campaignStore.scopedCampaignList
        : selectedCampaignFilter.value === 1
            ? campaignStore.scopedCampaignList.filter(campaign => campaign.status === CampaignStatus.Active)
            : campaignStore.scopedCampaignList.filter(campaign => campaign.status !== CampaignStatus.Active);
});

const initialize = async () => {
    loading.value = true;
    await campaignStore.initialize();
    loading.value = false;
}
onBeforeMount(() => initialize());

const launchCampaignWizard = (freshCampaign?: boolean, zipCodeTargetedCampaign?: boolean) => {
    emit('show:campaignWizard', !!freshCampaign, !!zipCodeTargetedCampaign);
}

const selectZipCodeTargetingType = () => {
    if (campaignStore.zipCodeTargetingEnabled)
        modals.zipCode = true;
    else
        launchCampaignWizard(true);
}

const editCampaign = async (campaignReference: string) => {
    if (loading.value) return;
    loading.value = true;

    const { status, message } = await campaignStore.getCampaignDetailByUuid(campaignReference);
    if (!status)
        alertStore.showError(message ?? 'An error occurred fetching the Campaign.');
    else
        launchCampaignWizard(false);

    loading.value = false;
}

const confirmZipCodeTargeting = (enableZipCodeTargeting: boolean) => {
    launchCampaignWizard(true, enableZipCodeTargeting);
    modals.zipCode = false;
}

const cancelPause = () => {
    const targetCampaign = campaignStore.scopedCampaignList.find(campaign => campaign.reference === pausingCampaign.value);
    if (targetCampaign) targetCampaign.active = true;
    pausingCampaign.value = null;
    toggleModal('pause', false);
}

const confirmCampaignDelete = (campaignReference: string) => {
    deletingCampaign.value = campaignReference;
    toggleModal('delete', true);
}

const deleteCampaign = async () => {
    if (!deletingCampaign.value || saving.value) return;
    saving.value = true;

    const { status, message } = await campaignStore.deleteCampaign(deletingCampaign.value);
    if (!status)
        alertStore.showError(message ?? 'An error occurred deleting the Campaign.');
    else {
        toggleModal('delete', false);
        alertStore.showInfo('Campaign was deleted successfully.');
    }

    saving.value = false;
}

const cancelDelete = () => {
    deletingCampaign.value = null;
    toggleModal('delete', false);
}

const toggleModal = (modalName: string, show?: boolean) => {
    if (modalName in modals) {
        modals[modalName] = show === undefined
            ? !modals[modalName]
            : show;
    }
}

const downloadCampaignZipCodes = () => {
    campaignStore.downloadAllCampaignsZipCodes();
}

const handleCampaignStatusToggle = (newValue: boolean, campaignReference: string) => {
    if (newValue) {
        activateCampaigns(campaignReference);
    }
    else {
        showPauseCampaignModal(campaignReference);
    }
}

const showPauseCampaignModal = (campaignReference: string|null) => {
    pausingCampaign.value = campaignReference ?? null;
    toggleModal('pause', true);
}

const pauseCampaigns = async (payload: CampaignPausePayload) => {
    if (saving.value) return;
    saving.value = true;

    payload.references = pausingCampaign.value ? [pausingCampaign.value] : selectedCampaignReferences.value;
    if (!payload.references?.length) return;

    const { status, message } = await campaignStore.pauseCampaigns(payload);
    if (!status) {
        alertStore.showError(message ?? 'An error occurred pausing the Campaign(s).');
    }
    else {
        pausingCampaign.value = null;
        toggleModal('pause', false);
    }

    saving.value = false;
}

const activateCampaigns = async (campaignReference: string|null) => {
    if (saving.value) return;
    saving.value = true;

    const references = campaignReference ? [campaignReference] : selectedCampaignReferences.value;
    const { status, message } = await campaignStore.unpauseCampaigns({
        references,
    });
    if (!status) {
        alertStore.showError(message ?? "There was an error activating the Campaign(s).");
    }

    saving.value = false;
}

const updateSelectedCampaigns = (newValue: string[]) => {
    selectedCampaignReferences.value = newValue;
}

const getCampaignDisplayName = (): string => {
    return bulkPauseCampaign.value
        ? `the ${selectedCampaignReferences.value.length} selected campaigns`
        : campaignStore.getCampaignNameByUuid(pausingCampaign.value ?? '');
}

const tooltips = {
    averageDailySpend: `Average daily spend over last 30 days (or since last budget change). Daily Budget is set within your campaign.`,
    averageDailyWon: `Average daily leads purchased last 30 days (or since last budget change).`,
    averageDailyAvailable: `Average daily leads available in your zip codes last 30 days (or since last budget change).`
}

// Create Product-dependant factory if different columns are needed for different Products
const campaignTableSetup: TableSetup = {
    columns: [
        { label: '', selectAll: true, emitKey: 'reference', key: '', },
        { label: 'Name', key: 'name', class: 'text-cyan-500', itemClickable: true, emitKey: 'reference' },
        { label: 'Status', key: 'active', toggle: true, emitKey: 'reference' },
        { label: 'Region', key: ['zip_code_count', 'state_count'], text: "%0 Zip Codes\n%1 States" },
        { label: 'Avg Daily Spend / Daily Budget', key: 'average_daily_spend', tooltip: tooltips.averageDailySpend },
        { label: `Avg Daily ${campaignStore.getProductLabel(true)} Won`, key: 'average_daily_won', tooltip: tooltips.averageDailyWon },
        { label: `Avg Daily ${campaignStore.getProductLabel(true)} Available`, key: 'average_daily_available', tooltip: tooltips.averageDailyAvailable },
        { label: 'Actions', key: '', editDelete: true, emitKey: 'reference' },
    ]
}

</script>