//TODO: add options and handle non-unix timestamps
export const useFormatTimestamp = (input, options = {
        locale: 'en-US',
        date: true,
        time: false,
}) => {
    const date = typeof(input) === 'number'
        ? new Date(input)
        : null; //TODO: handle ISO / datetime string
    if (!date) return '';
    return options.date && options.time
        ? date.toLocaleString()
        : options.date
            ? date.toLocaleDateString(options.locale)
            : options.time
                ? date.toLocaleTimeString()
                : null;
}