<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"/>
                </svg>

                <h5 class="text-md">Profile Progress</h5>
            </div>
        </template>

        <div v-if="!loading">
            <h6>Your profile is {{ percentComplete }}% complete</h6>

            <div class="h-1.5 w-full relative rounded-full bg-gray-200 mt-2">
                <div class="absolute h-1.5 rounded-full bg-green-500 left-0"
                     :style="{width: percentComplete + '%'}"></div>
            </div>

            <div class="flex flex-col mt-4">
                <div v-for="item in progressItems" class="flex items-center mt-2"
                     :class="{'text-green-600': item.completed, 'text-red-600': !item.completed}">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 mr-2" v-if="item.completed">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 mr-2" v-if="!item.completed">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>

                    <p class="">{{ item.title }}</p>
                </div>
            </div>
        </div>

        <div v-if="loading" class="text-gray-500 text-center">
            Loading...
        </div>
    </Module>
</template>
<script setup>
import Module from "@/components/Module.vue";
import {computed, ref} from "vue";
import {useServicesStore} from "@/stores/services";

const loading = ref(true);
const progressItems = ref([
    {title: "Logo and description have been added", completed: false},
    {title: "Contact details have been added", completed: false},
    {title: "Address has been added", completed: false},
    {title: "Services have been added", completed: false},
]);

const percentComplete = computed(() => {
    const completed = progressItems.value.filter(i => i.completed);

    return Math.round((completed.length / progressItems.value.length) * 100);
})

const services = useServicesStore();

services.apiService.getProfileProgress().then(resp => {
    progressItems.value = resp.data.data.items;

    loading.value = false;
}).catch(e => {})

</script>