<template>
    <div>
        <Modal
            @clicked:confirm="addZipCodes"
            @clicked:cancel="closeModal"
            confirm-label="Add"
            :class="[saving && 'pointer-events-none grayscale-[80%]']"
        >
            <template v-slot:header>
                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                    </svg>
                    <h5 class="text-md ml-2">Add Zip Codes by Radius</h5>
                </div>
            </template>
            <template v-slot:body>
                <div v-if="loading">
                    <p class="text-gray-500 text-center">Loading...</p>
                </div>
                <div :class="[ loading ? 'hidden' : '', searching ? 'pointer-events-none opacity-50' : '' ]">
                    <div class="pb-4 border-b border-slate-300 mb-4">
                        <div class="flex items-end gap-x-6">
                            <CustomInput
                                type="number"
                                v-model="radius"
                                label="Radius (miles)"
                            />
                            <CustomInput
                                v-model="centralZip"
                                label="Central Zip Code"
                            />
                            <SolidButton
                                @click="submitSearch"
                                label="Search"
                                classes="py-2.5 px-[4rem]"
                            />
                        </div>
                        <div v-if="errorMessage" class="pt-4">
                            <p class="text-red-900 text-center whitespace-pre">{{ errorMessage }}</p>
                        </div>
                    </div>
                    <div v-if="googleMapsError" class="py-8">
                        <p class="text-red-800 text-center">{{ googleMapsError }}</p>
                    </div>
                    <div class="flex gap-x-4">
                        <div class="google-map-container w-full h-[30rem]">
                            <div ref="mapElement" class="w-full h-full"></div>
                        </div>
                        <div v-if="searchResults?.length" class="w-[100%] max-h-[30rem] overflow-y-auto">
                            <div class="grid justify-center text-center grid-cols-3 text-sm text-gray-700 capitalize bg-cyan-25 font-bold py-2 px-6 items-center border-b border-gray-200">
                                <div class="flex items-center justify-self-center">
                                    <CustomCheckbox
                                        @update:modelValue="(checked) => selectAllZipCodes(checked)"
                                    />
                                    <p class="ml-4">Selected</p>
                                </div>
                                <p>Zip Code</p>
                                <p>City</p>
                            </div>
                            <div v-for="zipCode in searchResults"
                                 :key="zipCode.id"
                                 class="text-center relative border-b odd:bg-gray-50 text-gray-600 items-center grid md:grid-cols-3 gap-4 md:gap-0 py-4 px-4 md:px-6"
                            >
                                <div class="flex text-sm justify-self-center">
                                    <CustomCheckbox
                                        v-model="selectedZipCodes[zipCode.id]"
                                    />
                                </div>
                                <p class="text-sm">{{ zipCode.zip_code }}</p>
                                <p class="text-sm">{{ zipCode.city_name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import { onBeforeMount, onMounted, ref } from "vue";
import CustomInput from "@/components/inputs/CustomInput.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { useLocalityDataStore } from "@/stores/locality-data.js";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";

const props = defineProps({
    initialLat: {
        type: Number,
        default: 40.6047217
    },
    initialLng: {
        type: Number,
        default: -122.35249
    },
    saving: {
        type: Boolean,
        default: false,
    }
});

const localityDataStore = useLocalityDataStore();

const emit = defineEmits([
    'hideZipRadiusModal',
    'addZipCodesFromModal'
]);

const loading = ref(false);
const searching = ref(false);
const selectedZipCodes = ref({});

const googleMapsError = ref(null);
const errorMessage = ref(null);

const radius = ref(null);
const centralZip = ref(null);

const searchResults = ref([]);

const mapElement = ref(null);
const googleMapInstance = ref(null);
const mapRadiusCircle = ref(null);
const mapMarker = ref(null)

onBeforeMount(() => {
    mapElement.value = null;
});

onMounted(async () => {
    loading.value = true;
    if (await initializeGoogleMaps()) {
        await loadMapElement();
    } else {
        googleMapsError.value = `The was an error loading Google Maps.`;
    }
    loading.value = false;
});

const googleMapsLoaded = () => !!window.google?.maps;

const initializeGoogleMaps = async () => {
    const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
    if (!apiKey) {
        googleMapsError.value = `The was an error loading Google Maps.`;
        return false;
    }
    if (googleMapsLoaded()) {
        return true;
    }
    const mapsUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
    const googleMapsScript = document.createElement('script');
    googleMapsScript.src = mapsUrl;
    googleMapsScript.async = true;
    document.body.appendChild(googleMapsScript);
    return await awaitCondition(() => googleMapsLoaded());
}

const loadMapElement = async () => {
    googleMapInstance.value = new window.google.maps.Map(mapElement.value, {
        center: {
            lat: props.initialLat,
            lng: props.initialLng,
        },
        zoom: 10,
    });
}

const clearMapMarkers = () => {
    mapMarker.value = null;
    mapRadiusCircle.value = null;
}

const asyncGeocode = async (geoCoder, searchValue) => {
    return new Promise(res => {
         geoCoder.geocode?.({ address: searchValue }, (results, status) => {
             res({ results, status });
         });
    });
}

const showRadius = async () => {
    if (!googleMapsLoaded() || !googleMapInstance.value) return;
    const geoCoder = new window.google.maps.Geocoder();
    const { results, status } = await asyncGeocode(geoCoder, centralZip.value);
    if (results) {
        clearMapMarkers();
        googleMapInstance.value.setCenter(results[0].geometry.location);

        mapMarker.value = new window.google.maps.Marker({
            position: results[0].geometry.location,
            map: googleMapInstance.value
        });
        mapRadiusCircle.value = new window.google.maps.Circle({
            strokeColor: '#0082CC',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#00A3FF',
            fillOpacity: 0.35,
            map: googleMapInstance.value,
            center: results[0].geometry.location,
            radius: radius.value * 1609.344
        });

        mapRadiusCircle.value.bindTo('center', mapMarker.value, 'position');
    }
}

const submitSearch = async () => {
    errorMessage.value = null;
    if (!validateSearch()) return;
    showRadius();
    searching.value = true;
    const { status, message, zip_codes } = await localityDataStore.getZipCodesByRadius(centralZip.value, radius.value);
    if (!status) {
        errorMessage.value = message;
    }
    else {
        zip_codes.forEach(zipCode => {
            selectedZipCodes.value[zipCode.id] = false
        });
        searchResults.value = zip_codes;
    }
    searching.value = false;
}

const validateSearch = () => {
    const errors = [];
    const zipNumbers = `${centralZip.value}`.replace(/\D/g, '');
    if (!(radius.value > 0)) errors.push(`Please enter a number for the radius`);
    if (zipNumbers.length !== 5) errors.push(`Please enter a valid zip code`);
    if (errors) errorMessage.value = errors.join('\n');
    return !errors.length;
}

const addZipCodes = () => {
    if (!(Object.values(selectedZipCodes.value).filter(v=>v).length)) {
        errorMessage.value = "No zip codes are selected!"
    }
    else {
        const payload = searchResults.value.filter(zipCode => selectedZipCodes.value[zipCode.id]);
        emit('addZipCodesFromModal', payload);
    }
}

const selectAllZipCodes = (checked) => {
    for (const id in selectedZipCodes.value) {
        selectedZipCodes.value[id] = !!checked;
    }
}

const clearData = () => {
    selectedZipCodes.value = [];
}

const closeModal = () => {
    mapElement.value = null;
    clearData();
    emit('hideZipRadiusModal');
}

const awaitCondition = async (predicateFunction, timeout = 5000, interval = 100) => {
    let timer = timeout;
    return new Promise(res => {
        const awaiting = setInterval(() => {
            if (predicateFunction()) {
                clearInterval(awaiting);
                res(true);
            } else if (timer < 0) {
                clearInterval(awaiting);
                res(false);
            }
            timer -= interval;
        }, interval);
    });
}

</script>