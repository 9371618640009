export class BaseApiService {
    constructor() {
        this.bearer = null;
        this.companyId = null;
        this.userId = null;
        this.industry = null;
        this.service = null;
    }

    _notImplemented(name) {
        throw new Error(`Function '${name}' is not implemented in this API driver.`);
    }

    setBearer(bearer) {
        this.bearer = bearer;
    }

    setCompanyId(companyId) {
        this.companyId = companyId;
    }

    setUserId(userId) {
        this.userId = userId;
    }

    getIndustry() {
        return this.industry;
    }

    setIndustry(industry) {
        this.industry = industry;
    }

    getService() {
        return this.service;
    }

    setService(service) {
        this.service = service;
    }

    login(email, password) {
        return this._notImplemented("login");
    }

    loginWithToken(token) {
        return this._notImplemented("loginWithToken");
    }

    updatePassword(payload) {
        return this._notImplemented("updatePassword");
    }

    requestPasswordReset(payload) {
        return this._notImplemented("requestPasswordReset");
    }

    profile() {
        return this._notImplemented("profile");
    }

    getCompany() {
        return this._notImplemented("getCompany");
    }

    getCompanyLogo() {
        return this._notImplemented("getCompanyLogo");
    }

    updateCompanyLogo(logo) {
        return this._notImplemented("updateCompanyLogo");
    }

    getProfitabilityAssumption() {
        return this._notImplemented("getProfitabilityAssumption");
    }

    getGlobalProfitabilityAssumptions() {
        return this._notImplemented("getGlobalProfitabilityAssumptions");
    }

    updateProfitabilityAssumption(payload) {
        return this._notImplemented("updateProfitabilityAssumption");
    }

    getCompanyLocations() {
        return this._notImplemented("getCompanyLocations");
    }

    getCountries() {
        return this._notImplemented("getCountries");
    }

    getStates() {
        return this._notImplemented("getStates");
    }

    updateCompanyLocation() {
        return this._notImplemented("updateCompanyLocation");
    }

    createCompanyLocation() {
        return this._notImplemented("createCompanyLocation");
    }

    deleteCompanyLocation() {
        return this._notImplemented("deleteCompanyLocation");
    }

    makeCompanyLocationPrimary() {
        return this._notImplemented("makeCompanyLocationPrimary");
    }

    getLeadTypesPurchasedBreakdown(start, end, data = {}) {
        return this._notImplemented("getLeadTypesPurchasedBreakdown");
    }

    getProfileProgress() {
        return this._notImplemented("getProfileProgress");
    }

    getCampaignOverview() {
        return this._notImplemented("getCampaignOverview");
    }

    getLeadVolume(params = {}) {
        return this._notImplemented("getLeadVolume");
    }

    getMissedReasons(params = {}) {
        return this._notImplemented("getMissedReasons");
    }

    getCompanyUsers() {
        return this._notImplemented("getCompanyUsers");
    }

    updateCompanyUser(companyUserData) {
        return this._notImplemented("updateCompanyUser");
    }

    createCompanyUser(companyUserData) {
        return this._notImplemented("createCompanyUser");
    }

    deleteCompanyUser(companyUserId) {
        return this._notImplemented("deleteCompanyUser");
    }

    getCompanyNotificationSettings() {
        return this._notImplemented("getCompanyNotificationSettings");
    }

    updateCompanyNotificationSettings() {
        return this._notImplemented("updateCompanyNotificationSettings");
    }

    getCampaigns(product) {
        return this._notImplemented("getCampaigns");
    }

    bulkUpdateCampaigns() {
        return this._notImplemented("bulkUpdateCampaigns");
    }

    getStateDetails() {
        return this._notImplemented("getStateDetails");
    }

    getCountyDetails() {
        return this._notImplemented("getCountyDetails");
    }

    getStateZipCodes() {
        return this._notImplemented("getStateZipCodes");
    }

    saveCampaign() {
        return this._notImplemented("saveCampaign");
  }

    getCampaignDetail() {
        return this._notImplemented("getCampaignDetail");
    }

    deleteCampaign() {
        return this._notImplemented("deleteCampaign");
    }

    getPaymentMethods() {
        return this._notImplemented("getPaymentMethods");
    }

    createPaymentMethod(token, type = 'Credit Card') {
        return this._notImplemented("createPaymentMethod");
    }

    deletePaymentMethod(id) {
        return this._notImplemented("deletePaymentMethod");
    }

    makePaymentMethodPrimary(id) {
        return this._notImplemented("makePaymentMethodPrimary")
    }

    searchLeads(payload, productType = 'lead') {
        return this._notImplemented("searchLeads");
    }

    checkRejectionQuota(leadId) {
        return this._notImplemented("checkRejectionQuota");
    }

    rejectLead(leadId, payload) {
        return this._notImplemented("rejectLead");
    }

    rejectAppointment(appointmentId) {
        return this._notImplemented("rejectLead");
    }

    unrejectLead(leadId) {
        return this._notImplemented("unrejectLead");
    }

    getZipCodesByRadius(zipCode, radius) {
        return this._notImplemented("getZipCodesByRadius");
    }

    getZipCodesByZipCodeStrings(zipCodeArray) {
        return this._notImplemented("getZipCodesByZipCodeStrings");
    }

    searchInvoices(searchOptions = {}, withBillingConfig = false) {
        return this._notImplemented("searchInvoices");
    }

    addCrmDeliveryIntegration(payload) {
        return this._notImplemented("addCrmDeliveryIntegration");
    }

    updateCrmDeliveryIntegration(crmDeliveryId, payload) {
        return this._notImplemented("updateCrmDeliveryIntegration");
    }

    getCrmDeliveriesForCampaign(campaignUuid) {
        return this._notImplemented("getCrmDeliveriesForCampaign");
    }

    getCrmDeliveryDetail(crmDeliveryId) {
        return this._notImplemented("getCrmDeliveryDetail");
    }

    deleteCrmDelivery(campaignUuid = null, crmDeliveryId) {
        return this._notImplemented("deleteCrmDelivery");
    }

    getAllServicesByIndustry() {
        return this._notImplemented("getAllServicesByIndustry");
    }

    updateCompanyIndustryServices(serviceIds) {
        return this._notImplemented("updateCompanyIndustryServices");
    }

    deleteContactDelivery(campaignUuid, leadDeliveryMethodId) {
        return this._notImplemented("deleteContactDelivery");
    }

    downloadInvoice(invoiceId) {
        return this._notImplemented("downloadInvoice");
    }

    getTransactionsForInvoice(invoiceId) {
        return this._notImplemented("getTransactionsForInvoice");
    }

    getCompanyContractForUser() {
        return this._notImplemented("getCompanyContractForUser");
    }

    getNewCompanyContract() {
        return this._notImplemented("getNewCompanyContract");
    }

    agreeToCompanyContract() {
        return this._notImplemented("agreeToCompanyContract");
    }

    getZipCodesForCampaignList(campaignUuidArray) {
        return this._notImplemented("getZipCodesForCampaignList");
    }

    updateCompanyProfile(payload) {
    return this._notImplemented("updateCompanyProfile");
    }

    getCompanyMedia() {
        return this._notImplemented("getCompanyMedia");
    }

    uploadCompanyMedia(payload) {
        return this._notImplemented("uploadCompanyMedia");

    }

    addYoutubeLink(urlString) {
        return this._notImplemented("addYoutubeLink");

    }

    deleteCompanyMediaAsset(assetId) {
        return this._notImplemented("deleteCompanyMediaAsset");
    }


    getCompanyLicenses() {
        return this._notImplemented("getCompanyLicenses");
    }

    createCompanyLicense(payload) {
        return this._notImplemented("createCompanyLicense");
    }

    updateCompanyLicense(licenseId, payload) {
        return this._notImplemented("updateCompanyLicense");
    }

    deleteCompanyLicense(licenseId) {
        return this._notImplemented("deleteCompanyLicense");
    }
    searchAppointments(payload) {

    }

    getPriceRangeForCampaign(campaignUuid, productName) {
        return this._notImplemented("getPriceRangeForCampaign");
    }

    getPriceRangeForZipCodes(zipCodeIds, productName) {
        return this._notImplemented("getPriceRangeForZipCodes");
    }

    getProductStatisticsByLocation(stateAbbreviation, countyKey, productCategory) {
        return this._notImplemented("getProductStatisticsByLocation");
    }

    getPricesForLocation(campaignUuid, params) {
        return this._notImplemented("getPricesForLocation");
    }

    updateBidPriceForLocation(campaignUuid, payload) {
        return this._notImplemented("updateBidPriceForLocation");
    }

    getTimezones() {
        return this._notImplemented("getTimezones");
    }

    getAllCompanySchedules() {
        return this._notImplemented("getAllCompanySchedules");
    }

    createInternalCalendar(schedulePayload) {
        return this._notImplemented("createInternalCalendar");
    }

    updateSchedule(schedulePayload) {
        return this._notImplemented("updateSchedule");
    }

    deleteSchedule(scheduleId) {
        return this._notImplemented("deleteSchedule");
    }
}