<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
                </svg>

                <h5 class="text-md">Lead Volume</h5>
            </div>
        </template>
        <div class="relative min-h-[12rem]">
            <LoadingSpinner v-if="loading || !storesInitialized" :small="true" />
            <div v-else>
                <div class="flex items-center mb-5">
                    <h6
                        class="py-2 px-3 cursor-pointer"
                        v-for="label in selectorLabels"
                        :class="{'text-cyan-500 border-b border-cyan-500 font-medium': label === selectedLabel, 'text-gray-500 ': label !== selectedLabel}"
                        @click="handleSelectorClick(label)"
                    >
                        {{ label }}
                    </h6>
                </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-7 my-5">
                    <div class="bg-cyan-50 rounded p-3 flex">
                        <div class="w-1/2 bg-white rounded-md flex items-center justify-center">
                            <span class="text-3xl text-cyan-500 my-4 overflow-hidden px-3">
                                {{ (totalNotPurchased || 0).toLocaleString() }}
                            </span>
                        </div>
                        <div class="w-1/2 p-3 flex items-center">
                            <span class="font-display">Leads in service areas not purchased</span>
                        </div>
                    </div>

                    <div class="bg-cyan-50 rounded p-3 flex">
                        <div class="w-1/2 bg-white rounded-md flex items-center justify-center">
                            <span class="text-3xl text-cyan-500 my-4 overflow-hidden px-3">
                                ${{ (potentialProfit || 0).toLocaleString() }}
                            </span>
                        </div>
                        <div class="w-1/2 p-3 flex items-center">
                            <span class="font-display">Potential profit if all available leads purchased</span>
                        </div>
                    </div>
                </div>

                <div class="text-center mt-8">
                    <LineChart
                        :x-axis-labels="xLabels"
                        :data="series"
                    />
                </div>

                <div v-if="reasons.length > 0">
                    <h5 class="mt-7 text-md font-semibold">Reasons for missed leads</h5>

                    <div class="grid grid-cols-1 md:grid-cols-2 md:gap-x-7 gap-y-2 mb-2">
                        <div class="w-full border-b border-gray-200 flex justify-between" v-for="reason in reasons">
                            <div class="py-2">
                                <Tooltip>
                                    <template v-slot:title><h6 class="font-medium mr-1">{{ reason.title }}:</h6></template>
                                    {{ reason.message }}
                                </Tooltip>
                            </div>
                            <span class="py-2">
                                <RouterLink v-if="reason.category === 'bidding'" to="/leads-bidding" class="text-cyan-500">Adjust Bids</RouterLink>
                                <RouterLink v-if="reason.category === 'campaigns'" to="/leads-campaigns" class="text-cyan-500">Turn On</RouterLink>
                                <RouterLink v-if="reason.category === 'leads'" to="/leads" class="text-cyan-500">See Leads</RouterLink>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import { computed, onMounted, ref, watch } from "vue";
import Tooltip from "@/components/Tooltip.vue";
import {useServicesStore} from "@/stores/services";
import LineChart from "@/components/charts/LineChart.vue";
import {useProfitabilityAssumptionsStore} from "@/stores/profitability-assumptions";
import { useCompanyStore } from "@/stores/company.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useProductConfigurationStore } from "@/stores/v4/product-configuration";

const diffMapping = {
    '30 Days': 30,
    '90 Days': 90,
    '12 Months': 365
};

const profitability = useProfitabilityAssumptionsStore();
const productConfiguration = useProductConfigurationStore();

const secondsInDay = 60 * 60 * 24;

const loading = ref(false);
const services = useServicesStore();
const selectorLabels = ref(['30 Days', '90 Days', '12 Months']);
const selectedLabel = ref('30 Days');
const potentialProfit = ref(0);
const reasons = ref([]);

const globalProfitability = ref({});

const leadStatisticsScoped = ref({});
const leadStatisticsTotals = ref({});
const leadStatisticsGrandTotals = ref({});
const unverifiedScoped = ref({});
const unverifiedTotals = ref({});
const unverifiedGrandTotals = ref({});

const totalNotPurchased = ref(0);
const unverifiedNotPurchased = ref(0);
const verifiedPurchased = ref(0);
const verifiedNotPurchased = ref(0);
const verifiedCostPerLead = ref(0);
const verifiedSpent = ref(0);
const unverifiedCostPerLead = ref(0);
const series = ref([]);
const xLabels = ref([]);

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const grouping = computed(() => ['30 Days', '90 Days'].includes(selectedLabel.value) ? 'daily' : 'monthly');
const startTimestamp = computed(() => (new Date()).getTime() / 1000 - secondsInDay * diffMapping[selectedLabel.value]);
const endTimestamp = computed(() => (new Date()).getTime() / 1000);

const handleSelectorClick = selector => selectedLabel.value = selector;

const props = defineProps({
    global: {
        type: Boolean,
        default: false
    },
    services: {
        type: Array,
        default: null
    },
    storesInitialized: {
        type: Boolean,
        default: false,
    },
});

/**
 * Total the leads from a Lead Volume response - grand totals, totals by timestamp and totals by IndustryService id
 * @param inputLeads - lead volume response
 * @returns { { totals: {object}, grandTotals: {object} } }
 */
const getTotalsForServiceScopedLeads = (inputLeads) => {
    const grandTotals = {};
    const totals = Object.values(inputLeads).reduce((totalOutput, serviceGroup) => {
        const serviceGroupTotals = Object.values(serviceGroup).reduce((serviceGroupOutput, leadGroup) => {
            serviceGroupOutput[leadGroup.start_timestamp] = {
                startTimestamp: leadGroup.start_timestamp,
                endTimestamp: leadGroup.end_timestamp,
                received: parseInt(leadGroup.received) ?? 0,
                available: parseInt(leadGroup.available) ?? 0,
                purchased: parseInt(leadGroup.purchased) ?? 0,
                spent: parseFloat(leadGroup.spent) ?? 0,
            }
            serviceGroup.received = (serviceGroup.received ?? 0) + parseInt(leadGroup.received) ?? 0;
            serviceGroup.available = (serviceGroup.available ?? 0) + parseInt(leadGroup.available) ?? 0;
            serviceGroup.purchased = (serviceGroup.purchased ?? 0) + parseInt(leadGroup.purchased) ?? 0;
            serviceGroup.spent = (serviceGroup.spent ?? 0) + parseFloat(leadGroup.spent) ?? 0;
            return serviceGroupOutput;
        }, {});
        for (const timestampGroup in serviceGroupTotals) {
            totalOutput[timestampGroup] = totalOutput[timestampGroup] ?? {};
            totalOutput[timestampGroup].startTimestamp = totalOutput[timestampGroup].startTimestamp ?? serviceGroupTotals[timestampGroup].startTimestamp;
            totalOutput[timestampGroup].endTimestamp = totalOutput[timestampGroup].endTimestamp ?? serviceGroupTotals[timestampGroup].endTimestamp;
            totalOutput[timestampGroup].received = (totalOutput[timestampGroup].received ?? 0) + serviceGroupTotals[timestampGroup].received ?? 0;
            totalOutput[timestampGroup].available = (totalOutput[timestampGroup].available ?? 0) + serviceGroupTotals[timestampGroup].available ?? 0;
            totalOutput[timestampGroup].purchased = (totalOutput[timestampGroup].purchased ?? 0) + serviceGroupTotals[timestampGroup].purchased ?? 0;
            totalOutput[timestampGroup].spent = (totalOutput[timestampGroup].spent ?? 0) + serviceGroupTotals[timestampGroup].spent ?? 0;
            grandTotals.received = (grandTotals.received ?? 0) + serviceGroupTotals[timestampGroup].received ?? 0;
            grandTotals.available = (grandTotals.available ?? 0) + serviceGroupTotals[timestampGroup].available ?? 0;
            grandTotals.purchased = (grandTotals.purchased ?? 0) + serviceGroupTotals[timestampGroup].purchased ?? 0;
            grandTotals.spent = (grandTotals.spent ?? 0) + serviceGroupTotals[timestampGroup].spent ?? 0;
        }
        return totalOutput;
    }, {});
    return { totals, grandTotals }
}

const getLeadVolume = async () => {
    loading.value = true;
    totalNotPurchased.value = 0;
    unverifiedNotPurchased.value = 0;
    verifiedNotPurchased.value = 0;
    verifiedCostPerLead.value = 0;
    unverifiedCostPerLead.value = 0;
    series.value = [];
    xLabels.value = [];

    const params = {
        sales_type: 'exclusive,duo,trio,quad,unverified',
        start_timestamp: startTimestamp.value,
        end_timestamp: endTimestamp.value,
        grouping: grouping.value,
        global: props.global,
        services_filter: props.services
    }

    let apiService = services.apiService;

    if (company.futureCampaignsActive)
        apiService = services.apiServiceV4;

    await apiService.getLeadVolume(params).then(resp => {
        if (!resp.data?.data?.status) {
            console.error(`Error getting lead volume.`);
        }
        else {
            leadStatisticsScoped.value = resp.data.data.groups.scoped;
            const { totals, grandTotals } = getTotalsForServiceScopedLeads(leadStatisticsScoped.value);
            leadStatisticsTotals.value = totals;
            leadStatisticsGrandTotals.value = grandTotals;

            series.value = [ {
                name: 'Available',
                data: Object.values(leadStatisticsTotals.value).map(i => i.available)
            }, { name: 'Received', data: Object.values(leadStatisticsTotals.value).map(i => i.received) } ];

            if (grouping.value === 'daily') {
                xLabels.value = Object.values(leadStatisticsTotals.value).map(stat => {
                    const date = new Date(stat.startTimestamp * 1000);
                    return months[date.getMonth()] + ' ' + date.getDate();
                });
            } else {
                xLabels.value = Object.values(leadStatisticsTotals.value).map(stat => {
                    const date = new Date(stat.startTimestamp * 1000);
                    return date.getFullYear() + '/' + months[date.getMonth()];
                });
            }
        }
    }).catch(e => console.error(`Error getting lead volume: ${e}`));

    params.sales_type = "unverified";

    await apiService.getLeadVolume(params).then(resp => {
        if (!resp.data?.data?.status) {
            console.error(`Error getting lead volume.`);
        }
        else {
            unverifiedScoped.value = resp.data.data.groups.scoped;
            const { totals, grandTotals } = getTotalsForServiceScopedLeads(unverifiedScoped.value);
            unverifiedTotals.value = totals;
            unverifiedGrandTotals.value = grandTotals;

            totalNotPurchased.value = (leadStatisticsGrandTotals.value.available || 0) - (leadStatisticsGrandTotals.value.purchased || 0);
            unverifiedNotPurchased.value = (unverifiedGrandTotals.value.available || 0) - (unverifiedGrandTotals.value.purchased || 0);
            verifiedNotPurchased.value = totalNotPurchased.value - unverifiedNotPurchased.value;
            verifiedPurchased.value = (leadStatisticsGrandTotals.value.purchased || 0) - (unverifiedGrandTotals.value.purchased || 0);
            verifiedSpent.value = (leadStatisticsGrandTotals.value.spent || 0) - (unverifiedGrandTotals.value.spent || 0);

            verifiedCostPerLead.value = verifiedSpent.value > 0 ? (verifiedSpent.value / verifiedPurchased.value) : 60;
            unverifiedCostPerLead.value = unverifiedGrandTotals.value.spent > 0 ? (unverifiedGrandTotals.value.spent / unverifiedGrandTotals.value.purchased) : 25;

            calculatePotentialProfit();
        }
    }).catch(e => console.error(`Error getting lead volume: ${e}`));

    loading.value = false
}

const calculatePotentialProfit = async () => {
    let revenuePerJob, grossMarginPerJob, verifiedSoldPercentage, unverifiedSoldPercentage, potentialJobsWon,
        grossMargin, actualJobsWon, totalJobsWon;

    if (props.global) {
        globalProfitability.value = calculateGlobalWeightedAverages();
    }

    verifiedSoldPercentage = ((props.global ? globalProfitability.value.percentageLeadsSuccessful : profitability.scoped.percentage_leads_successful) * 1.5) / 100; // Andy wants to increase by 50% to account for full lifecycle of leads
    unverifiedSoldPercentage = verifiedSoldPercentage / 2; // Halve the verified sold percentage

    revenuePerJob = props.global ? globalProfitability.value.averageLeadRevenue : profitability.scoped.average_lead_revenue;
    grossMarginPerJob = revenuePerJob - (props.global ? globalProfitability.value.averageJobCost : profitability.scoped.average_job_cost);

    potentialJobsWon = Math.round(verifiedSoldPercentage * (verifiedNotPurchased.value || 0) + (unverifiedSoldPercentage * (unverifiedNotPurchased.value || 0)));
    actualJobsWon = Math.round((verifiedSoldPercentage * (verifiedPurchased.value || 0)) + (unverifiedSoldPercentage * (unverifiedGrandTotals.value.purchased || 0)));

    totalJobsWon = actualJobsWon + potentialJobsWon;

    grossMargin = Math.round(totalJobsWon * grossMarginPerJob);
    potentialProfit.value = Math.round(grossMargin - ((verifiedCostPerLead.value * ((verifiedNotPurchased.value || 0) + (unverifiedNotPurchased.value || 0))) + (unverifiedCostPerLead.value * ((unverifiedGrandTotals.value.purchased || 0) + (unverifiedNotPurchased.value || 0)))));
    potentialProfit.value = potentialProfit.value > 0 ? potentialProfit.value : 0;
}

/**
 * Weight the profitability configurations by the number of leads purchased in each Industry Service
 * Add 1 to each to still get a return if 0 leads were purchased
 * @returns {object}
 */
const calculateGlobalWeightedAverages = () => {
    const weightedTotals = Object.values(profitability.configurations).reduce((output, config) => {
        if (!props.services.includes(config.industry_service_id) || !(config.industry_service_id in leadStatisticsScoped.value)) return output;
        output.averageLeadRevenue = (output.averageLeadRevenue ?? 0) + (config.average_lead_revenue * (1 + (leadStatisticsScoped.value[config.industry_service_id].purchased ?? 0) ));
        output.labourMaterialsCost = (output.labourMaterialsCost ?? 0) + (config.labour_materials_cost * (1 + (leadStatisticsScoped.value[config.industry_service_id].purchased ?? 0) ));
        output.averageJobCost = (output.averageJobCost ?? 0) + (config.average_job_cost * (1 + (leadStatisticsScoped.value[config.industry_service_id].purchased ?? 0) ));
        output.percentageLeadsSuccessful = (output.percentageLeadsSuccessful ?? 0) + (config.percentage_leads_successful * (1 + (leadStatisticsScoped.value[config.industry_service_id].purchased ?? 0) ));
        return output;
    }, {});

    for (const total in weightedTotals) {
        weightedTotals[total] /= (leadStatisticsGrandTotals.value.purchased + props.services.length ?? 0);
    }

    return weightedTotals;
}


const company = useCompanyStore();
onMounted(async () => {
    watch(selectedLabel, () => getLeadVolume());
    if (props.services || !props.global) getLeadVolume();
    watch(() => props.services, () => getLeadVolume());
});

services.apiService.getMissedReasons().then(resp => {
    reasons.value = Object.entries(resp.data.data.reasons)
        .filter(([key, i]) => i.impact && (key !== 'email' || productConfiguration.emailOnlyBudgetEnabled))
        .map(([_key, i]) => i);
}).catch(e => console.error(`Error getting missed reasons: ${e}`));

</script>