import { defineStore } from "pinia";
import { useServicesStore } from "@/stores/services";
import { reactive, ref, toRaw } from "vue";
import {useCreateCsvString} from "@/composables/useCreateCsvString";
import {useDownloadFile} from "@/composables/useDownloadFile";


export const useAppointmentsStore = defineStore('appointments', () => {
    const servicesStore = useServicesStore();

    const appointments = ref([]);
    const totalSpend = ref(0);

    const searchOptions = reactive({
        date_range: null,
        appointment_id: null,
        contact_id: null,
        contact_name: '',
        address: '',
        invoice_id: null,
        campaign_id: null,
        appointment_type: null,
        over_budget: null,
        page: 1,
    });

    const appointmentConfigData = {
        appointment_types: [
            { name: 'All', id: '' },
            { name: 'Online Consultation', id: 'online-consultation'},
            { name: 'In-Home Appointment ', id: 'in-home-appointment'}
        ],
    }

    const paginationLimit = ref(0);
    const paginationTotal = ref(0);
    const paginationOffset = ref(0);

    const initialize = async (initialSearchConditions = null) => {
        if (initialSearchConditions) {
            Object.assign(searchOptions, initialSearchConditions);
        }
        if (!appointments.value.length) {
            search();
        }
        return { status: true }
    }

    const search = async (page = 1) => {
        searchOptions.page = page;
        const payload = { ...toRaw(searchOptions) };
        processRequestData(payload);
        const resp = await servicesStore.apiService.searchAppointments(payload).catch(e=>e);
        if (resp.data?.data?.status) {
            appointments.value = resp.data.data.products;
            paginationTotal.value = resp.data.data.total;
            paginationLimit.value = resp.data.data.limit;
            paginationOffset.value = resp.data.data.offset;
            totalSpend.value = resp.data.data.total_spend;
            return { status: true }
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    const download = async () => {
        const payload = { ...toRaw(searchOptions), download: true };
        processRequestData(payload);

        const resp = await servicesStore.apiService.searchAppointments(payload).catch(e=>e);

        if (resp.data?.data?.status) {
            const header = ["Appointment ID", "Campaign Name", "Email Address", "Phone Number",
                "Name", "Address", "Appointment Date", "Type"
            ];
            const formattedLeads = resp.data.data.products.map(appointment => [
                appointment.id, appointment.campaign_name, appointment.email, appointment.phone,
                appointment.name, appointment.address, `${appointment.appointment?.appointment_date} ${appointment.appointment?.appointment_time} `, appointment.appointment?.appointment_type,
            ]);
            const filename = `Fixr_Appointment_${Date.now()}.csv`;
            const encodedCsv = useCreateCsvString(header, formattedLeads);

            useDownloadFile(encodedCsv, filename);
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    const getAppointmentById = (appointmentId) => {
        return appointments.value.find(appointment => appointment.id === appointmentId);
    }

    const processRequestData = (payload) => {
        [ payload.date_start, payload.data_end ] = payload.date_range || [ null, null ];
    }

    const genericErrorResponse = (response) => ({ status: false, message: response?.data?.data?.message || response?.response?.data?.message || response.message | response.err || 'An unknown error occurred.' });

    const resetSearch = () => {
        Object.assign(searchOptions, {
            date_range: null,
            appointment_id: null,
            contact_id: null,
            contact_name: '',
            address: '',
            invoice_id: null,
            campaign_id: null,
            appointment_type: null,
            over_budget: null,
            page: 1,
        });
    }

    const clearStore = () => {
        appointments.value = [];
    }

    const $reset = () => {
        resetSearch();
        clearStore();
    }

    const rejectAppointment = async (appointmentId, payload) => {
        const resp =  await servicesStore.apiService.rejectAppointment(appointmentId, payload).catch(e=>e);
        if (resp.data?.data?.status) {
            return { status: true, rejection_percentage: resp.data.data.rejection_percentage }
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    return {
        appointments,
        searchOptions,
        appointmentConfigData,
        initialize,
        search,
        getAppointmentById,
        paginationTotal,
        paginationLimit,
        paginationOffset,
        $reset,
        rejectAppointment,
        totalSpend,
        download
    }

});