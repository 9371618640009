<template>
    <div v-if="banner && banner.show" tabindex="-1" class="sticky top-16 md:top-20 left-0 z-30 flex flex-col justify-between w-full p-4 border-b md:flex-row" :class="styleClasses">
        <div class="mb-4 md:mb-0 md:mr-4">
            <div class="flex gap-2 text-white mb-1 text-base font-semibold">
                <ExclamationIcon class="w-5"/>
                <h2>
                    {{ banner.title }}
                </h2>
            </div>
            <p class="flex items-center text-sm font-normal text-gray-100">{{ banner.text }}</p>
        </div>
        <div class="flex items-center flex-shrink-0">
            <SolidButton
                v-if="banner.button_text"
                @click="banner.callback"
                :color="banner.color"
            >
                {{ banner.button_text }}
            </SolidButton>
        </div>
    </div>
</template>
<script setup>
import {computed, ref} from "vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import {ExclamationIcon} from "@heroicons/vue/solid";

// TODO - Make icon dynamic
const props = defineProps({
    banner: {
        type: Object,
        required: false
    }
})

const color = ref('')

const styleClasses = computed(() => {
    const classes = []

    switch (props.banner.color){
        case 'yellow':
            classes.push('bg-yellow-700')
            break;

        case 'red':
            classes.push('bg-red-700')
            break;

        case 'blue':
        default:
            classes.push('bg-blue-700')
            break;
    }

    return classes;
})


</script>