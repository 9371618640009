<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
                </svg>

                <h5 class="text-md">Upload Youtube Link</h5>
            </div>
        </template>

        <div class="mt-4">
            <input class="w-full pl-4 rounded border border-grey-400 py-2" type="text" placeholder="https://youtube.com/watch?v="
                v-model="youtubeLink"
                @input="errorMessage = null"
            />
        </div>

        <div class="mt-4">
            <p v-if="errorMessage" class="text-red-900 text-center text-sm py-4">
                {{ errorMessage }}
            </p>
            <p v-if="successMessage" class="text-green-900 text-center text-sm py-4">
                {{ successMessage }}
            </p>
            <p class="text-cyan-500 text-sm">
                By uploading files into the Fixr dashboard, you warrant that your company is either the owner of the
                copyright of the files or has authorization from the owner of the copyright to use these files to
                display on the Fixr website. You agree to indemnify Fixr from any claims by third parties relating to
                the ownership, use or copyright of these files.
            </p>
            <div class="mt-4 flex justify-end">
                <SolidButton @click="uploadYoutubeLink" :disabled="saving">Upload</SolidButton>
            </div>
        </div>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { useCompanyMediaStore } from "@/stores/company-media.js";
import { useErrorStore } from "@/stores/errors.js";
import { ref } from "vue";

const companyMediaStore = useCompanyMediaStore();
const errors = useErrorStore();

const youtubeLink = ref('');

const saving = ref(false);
const errorMessage = ref(null);
const successMessage = ref(null);

const uploadYoutubeLink = async () => {
    if (saving.value) return;
    saving.value = true;
    successMessage.value = null;
    if (!validateUrl()) {
        errorMessage.value = `Please supply Youtube links in the format "https://www.youtube.com/watch?v=CANocXrAyk8"`;
    }
    else {
        const { status, message } = await companyMediaStore.addYoutubeLink(youtubeLink.value);
        if (!status) {
            errorMessage.value = message;
            errors.report(message);
        } else {
            const lastFileUploaded = youtubeLink.value;
            successMessage.value = `Successfully uploaded ${lastFileUploaded}`;
        }
    }
    youtubeLink.value = null;
    saving.value = false;
}

const validateUrl = () => {
    return /^(https:\/\/)?(www\.)?youtube\.com\/[^=]*=[0-z]+/i.test(youtubeLink.value.trim());
}

</script>