import { useServicesStore } from "@/stores/services";
import { reactive, ref, toRaw } from "vue";
import { defineStore } from "pinia";
import { useCampaignStore } from "@/stores/campaigns.js";

export const useCompanyUsersStore = defineStore('company-users', () => {
    const servicesStore = useServicesStore();
    const campaignStore = useCampaignStore();

    const companyUsers = ref([]);
    const isLoadingCompanyUsers = ref(false);

    const editingUser = reactive({
        id: null,
        legacy_id: null,
        status: 1,
        first_name: '',
        last_name: '',
        title: '',
        email: '',
        cell_phone: '',
        office_phone: '',
        password: '',
        password_confirm: '',
        user_type: '',
        is_contact: null,
        authentication_type: 2,
    });

    const storeInitialized = ref(false);

    function sortUsers() {
        companyUsers.value = companyUsers.value.sort((a,b) => a.is_contact && !b.is_contact ? 1 : -1);
    }

    async function initialize(forceRefresh = false) {
        if (!forceRefresh && storeInitialized.value) return { status: true };
        isLoadingCompanyUsers.value = true
        const resp = await servicesStore.apiService.getCompanyUsers().catch(e=>e);
        isLoadingCompanyUsers.value = false

        if (resp.data?.data?.status) {
            companyUsers.value = resp.data.data.company_users.map(user => {
                if (user.is_contact)
                    user.user_type = 'contact'
                else user.user_type = 'user'

                return user;
            });
            sortUsers();
            storeInitialized.value = true;
            return { status: true }
        }
        else {
            return { status: false, message: resp.response?.data?.message || resp.err || `An unknown error occurred.`}
        }
    }

    function getCompanyUserById(companyUserId, returnIndex = false) {
        return returnIndex
            ? companyUsers.value.findIndex(user => user.id === companyUserId)
            : companyUsers.value.find(user => user.id === companyUserId);
    }

    function getCompanyUserNameById(companyUserId) {
        const targetUser = getCompanyUserById(companyUserId);
        return targetUser?.first_name ?? 'Unknown User';
    }

    function editCompanyUser(companyUserId) {
        const targetUser = getCompanyUserById(companyUserId);
        if (targetUser) {
            Object.assign(editingUser, targetUser);
            return true;
        }
        return false;
    }

    async function saveCompanyUser(saveSynchronously = false) {
        editingUser.is_contact = editingUser.user_type === 'contact';
        
        const resp = editingUser.id
            ? await servicesStore.apiService.updateCompanyUser(toRaw(editingUser), saveSynchronously).catch(e=>e)
            : await servicesStore.apiService.createCompanyUser(toRaw(editingUser), saveSynchronously).catch(e=>e);

        if (resp.data?.data?.status) {
            const user = resp.data.data.company_user;

            user.user_type = editingUser.user_type;

            if (editingUser.id) {
                const modifiedIndex = getCompanyUserById(editingUser.id, true);
                if (modifiedIndex > -1) companyUsers.value[modifiedIndex] = user;
                // Notify the CampaignStore of the User update - if the end user is on the delivery slide the contact delivery details need to be updated
                campaignStore.updateContactDeliveries(user);
            } else {
                companyUsers.value.push(user);
            }

            return { status: true }
        }
        else {
            return { status: false, message: resp.data?.data?.message || resp.err || resp.response.data?.message || `An error occurred saving a company user.` }
        }
    }

    async function deleteCompanyUser(companyUserId) {
        const resp = await servicesStore.apiService.deleteCompanyUser(companyUserId);
        if (resp.data?.data?.status) {
            const trashedUserIndex = getCompanyUserById(companyUserId, true);
            companyUsers.value.splice(trashedUserIndex, 1);
            return { status: true }
        }
        else {
            return { status: false, message: resp.data?.data?.message || resp.err || `An error occurred deleting a new company user.` }
        }
    }

    function clearEditing() {
        Object.assign(editingUser, {
            id: null,
            reference: '',
            status: 1,
            first_name: '',
            last_name: '',
            title: '',
            department: '',
            email: '',
            cell_phone: '',
            office_phone: '',
            can_log_in: null,
            password: '',
            password_confirm: '',
            failed_login_count: 0,
            is_contact: null,
            user_type: '',
        });
    }

    function clearStore() {
        storeInitialized.value = false;
        companyUsers.value = [];
    }

    function $reset() {
        clearStore();
        clearEditing();
    }

    return {
        initialize,
        getCompanyUserNameById,
        saveCompanyUser,
        deleteCompanyUser,
        editCompanyUser,
        companyUsers,
        editingUser,
        isLoadingCompanyUsers,
        clearEditing,
        $reset,
    }

});