<template>
    <div v-click-outside="hide"
        class="relative"
    >
        <div @click.prevent="toggle" aria-haspopup="true" :aria-expanded="isOpen">
            <slot name="trigger" />
        </div>

        <div v-if="isOpen">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";

const isOpen = ref(false);

const hide = () => isOpen.value = false;
const toggle = () => isOpen.value = !isOpen.value;
</script>