<template>
    <label class="mb-2 font-medium relative w-auto"
        :class="[ dropdownDisabled ? 'grayscale-[50%] opacity-40 pointer-events-none' : '' ]"
    >
        <span v-if="label !== null" class="mb-1 block text-sm">{{ label }}</span>
        <div class="relative group">
            <select @change="updateModelValue"
                    :disabled="dropdownDisabled"
                    class="appearance-none bg-white cursor-pointer w-full rounded-md block group-hover:bg-slate-50 pr-12
                                    placeholder:font-normal focus:outline-none font-normal border border-slate-300 py-2 px-3 h-11"
            >
                <option v-for="(option, index) in props.options"
                        :key="index"
                        :value="option.value"
                        :selected="selectedOption && selectedOption === option.value || modelValue === option.value"
                >
                    {{ option.label }}
                </option>
            </select>
            <div class="absolute right-1 top-[50%] translate-y-[-50%] pointer-events-none bg-white p-3 group-hover:bg-slate-50">
                <svg class="w-3" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.467894 0.406234C0.767576 0.156774 1.17398 0.0166356 1.59773 0.0166356C2.02148 0.0166356 2.42788 0.156774 2.72756 0.406234L7.99001 4.78808L13.2525 0.406234C13.3999 0.279142 13.5762 0.17777 13.7712 0.108032C13.9662 0.0382934 14.1759 0.00158557 14.388 5.02416e-05C14.6002 -0.00148509 14.8107 0.0321825 15.0071 0.099089C15.2035 0.165996 15.3819 0.264801 15.5319 0.38974C15.682 0.514679 15.8006 0.663249 15.881 0.826782C15.9613 0.990314 16.0018 1.16553 15.9999 1.34222C15.9981 1.5189 15.954 1.69351 15.8703 1.85586C15.7865 2.0182 15.6648 2.16503 15.5121 2.28778L9.11985 7.6104C8.82016 7.85986 8.41376 8 7.99001 8C7.56626 8 7.15986 7.85986 6.86017 7.6104L0.467894 2.28778C0.168302 2.03825 0 1.69985 0 1.34701C0 0.994165 0.168302 0.655768 0.467894 0.406234Z" fill="#00A3FF"/>
                </svg>
            </div>
        </div>
    </label>
</template>

<script setup>
    const props = defineProps({
        modelValue: {
            type: [ String, Number, null ],
            default: '',
        },
        label: {
            type: String,
            default: 'Select an option',
        },
        options: {
            type: Array,
            default: [ { label: '', value: '' }],
        },
        type: {
            type: String,
            default: 'string'
        },
        dropdownDisabled: {
            type: Boolean,
            default: false,
        },
        selectedOption: {
            type: [ String, Number ],
            default: null,
        },
        dataKey: {
            type: String,
            default: '',
        }
    });
    const emit = defineEmits([
        'update:modelValue'
    ]);

    const updateModelValue = (event) => {
      let value = event.target.value;

      if (props.type === 'number' && !isNaN(Number(value))) {
        value = Number(value);
      }

      if (['true', 'false'].includes(value)) {
        value = value === 'true';
      }

      emit('update:modelValue', value, props.modelValue);
    };

</script>
