<template>
    <VueEcharts :option="chartOptions" :theme="{color: chartColors}" :style="`height: ${height}; width: ${width}`"/>
</template>

<script setup>
import {VueEcharts} from 'vue3-echarts';
import {computed, onMounted, ref, watch} from "vue";

const props = defineProps({
    chartColors: {
        type: Array,
        default: ['#2563eb', '#00A3FF']
    },
    lineColors: {
        type: Array,
        default: ['#2563eb', '#059669']
    },
    height: {
        type: String,
        default: '300px'
    },
    width: {
        type: String,
        default: '100%'
    },
    xAxisLabels: {
        type: Array,
        default: []
    },
    data: {
        type: Array,
        default: []
    }
});

const chartOptions = ref({
    grid: {
        left: '7%',
        right: '7%',
        top: '14%',
    },
    color: props.lineColors,
    legend: {
        right: '7%',
        top: '3%',
        data: [],
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
    },
    yAxis: {
        type: 'value'
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'line',
            label: {
                backgroundColor: '#6a7985'
            }
        },
        show: true,
    },
    series: []
});

const updateChart = () => {
    chartOptions.value.series = [];
    chartOptions.value.legend.data = [];

    props.data.forEach((number, index, array) => {
        let series = {
            name: array[index].name,
            data: array[index].data,
            type: 'line',
            areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: props.lineColors[index]
                    }, {
                        offset: 1, color: 'white'
                    }],
                }
            },
            smooth: true,
            lineStyle: {
                color: props.lineColors[index],
                width: 2
            },
            showSymbol: false
        }

        chartOptions.value.series.push(series);
        chartOptions.value.legend.data.push(array[index].name);
    });

    chartOptions.value.xAxis.data = props.xAxisLabels;
}

updateChart();

watch(() => props.xAxisLabels, () => updateChart());
watch(() => props.data, () => updateChart());
</script>