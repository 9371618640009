<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 00-1.883 2.542l.857 6a2.25 2.25 0 002.227 1.932H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-1.883-2.542m-16.5 0V6A2.25 2.25 0 016 3.75h3.879a1.5 1.5 0 011.06.44l2.122 2.12a1.5 1.5 0 001.06.44H18A2.25 2.25 0 0120.25 9v.776" />
                </svg>

                <h5 class="text-md">Uploaded Media</h5>
            </div>
        </template>
        <div class="relative grid grid-cols-2 md:grid-cols-4 gap-2 min-h-[10rem]">
            <LoadingSpinner v-if="!storeInitialized || saving" :small="true" />
            <p v-if="errorMessage" class="text-red-900 text-center text-sm py-4">
                {{ errorMessage }}
            </p>
            <div v-for="asset in companyMediaStore.companyMediaAssets"
                :key="asset.id"
                class="relative aspect-square border border-gray-200 p-4 bg-cover bg-center flex items-center justify-center"
            >
                <img :src="asset.type === 'media' ? asset.url : getYoutubeThumbnail(asset.url)"
                     :alt="`uploaded ${asset.type}`"
                />

                <ButtonDropdown class="absolute right-0 top-0 mt-2 mr-2">
                    <template v-slot:trigger>
                        <div class="cursor-pointer flex justify-center items-center text-cyan-500">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                            </svg>
                        </div>
                    </template>

                    <div class="transform translate-y-7 bg-white border border-gray-300 shadow rounded absolute top-0 right-0 z-60 transform p-2">
                        <div class="pb-1 cursor-pointer flex items-center mb-2"
                            @click="viewAsset(asset)"
                        >
                            <svg class="w-4 mr-1 pl-1" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M9.33341 1.33331H4.00008C3.26675 1.33331 2.66675 1.93331 2.66675 2.66665V13.3333C2.66675 14.0666 3.26008 14.6666 3.99341 14.6666H12.0001C12.7334 14.6666 13.3334 14.0666 13.3334 13.3333V5.33331L9.33341 1.33331ZM12 13.3333H4V2.66663H8.66667V5.99996H12V13.3333ZM5.33341 7.99998H10.6667V9.33331H5.33341V7.99998ZM5.33341 10.6666H10.6667V12H5.33341V10.6666Z"
                                      fill="#20A5DC"/>
                            </svg>
                            <p class="pb-0 text-cyan-500">View</p>
                        </div>
                        <div class="w-24 pb-1 cursor-pointer inline-flex items-center"
                            @click="deleteConfirm(asset.id)"
                        >
                            <svg class="w-4 mr-1" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3333 2.66667L9.66659 2H6.33325L5.66659 2.66667H3.33325V4H12.6666V2.66667H10.3333ZM3.99992 12.6667C3.99992 13.4 4.59992 14 5.33325 14H10.6666C11.3999 14 11.9999 13.4 11.9999 12.6667V4.66667H3.99992V12.6667ZM5.33325 6V9.33333V12.6667H10.6666V6H5.33325Z" fill="#20A5DC"/>
                            </svg>
                            <p class="pb-0 text-cyan-500">Delete</p>
                        </div>
                    </div>
                </ButtonDropdown>
            </div>
        </div>
        <Modal
            v-if="viewAssetLink"
            :show-cancel-button="false"
            confirm-label="Close"
            @clicked:confirm="closeAssetModal"
        >
            <template v-slot:header>
                View Asset
            </template>
            <template v-slot:body>
                <div v-if="viewAssetType === 'media'">
                    <img :src="viewAssetLink"
                         :alt="`uploaded ${viewAssetType}`"
                    />
                </div>
                <div v-else-if="viewAssetType === 'link'" class="h-full">
                    <p v-if="viewAssetError" class="text-red-900 text-center text-sm py-4">
                        {{ viewAssetError }}
                    </p>
                    <iframe width="600" height="335"
                        class="mx-auto my-auto pt-6"
                        :src="`https://www.youtube.com/embed/${viewAssetLink}`"
                        title="Company Video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    />
                </div>
            </template>
        </Modal>
        <Modal
            v-if="showDeleteConfirmModal"
            confirm-label="Yes"
            @clicked:confirm="deleteCompanyMediaAsset"
            @clicked:cancel="cancelConfirm"
            :small="true"
        >
            <template v-slot:header>
                Delete Asset
            </template>
            <template v-slot:body>
                Are you sure you wish to delete this media asset?
            </template>
        </Modal>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import ButtonDropdown from "@/components/ButtonDropdown.vue";
import { useCompanyMediaStore } from "@/stores/company-media.js";
import Modal from "@/components/Modal.vue";
import { ref } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const defaultImage = '';

const props = defineProps({
    storeInitialized: {
        type: Boolean,
        default: false,
    }
});

const companyMediaStore = useCompanyMediaStore();

const saving = ref(false);
const errorMessage = ref(null);

const showDeleteConfirmModal = ref(false);
const deletingAssetId = ref(null);

const viewAssetLink = ref(null);
const viewAssetType = ref(null);
const viewAssetError = ref(null);

const viewAsset = ({ url, type }) => {
    viewAssetError.value = null;
    if (url && type) {
        if (type === 'link') {
            const videoId = getYoutubeLink(url);
            if (!videoId) {
                viewAssetError.value = `There was an error fetching the Youtube video. Video links should be in the following format: https://www.youtube.com/watch?v=CANocXrAyk8`
            }
            else {
                viewAssetLink.value = videoId;
            }
        }
        else {
            viewAssetLink.value = url;
        }
        viewAssetType.value = type;
    }
}

const getYoutubeLink = (url) => {
    return url.match(/v=([0-z]+)/)?.[1];
}

const getYoutubeThumbnail = (url) => {
    const videoId = getYoutubeLink(url);
    return videoId
        ? `https://img.youtube.com/vi/${videoId}/0.jpg`
        : defaultImage;
}

const deleteCompanyMediaAsset = async () => {
    if (saving.value) return;
    errorMessage.value = null;
    saving.value = true;
    showDeleteConfirmModal.value = false;
    const { status, message } = await companyMediaStore.deleteCompanyMediaAsset(deletingAssetId.value);
    if (!status) {
        errorMessage.value = message;
    }
    cancelConfirm();
    saving.value = false;
}

const deleteConfirm = (assetId) => {
    deletingAssetId.value = assetId;
    showDeleteConfirmModal.value = true;
}

const cancelConfirm = () => {
    deletingAssetId.value = null;
    showDeleteConfirmModal.value = false;
}

const closeAssetModal = () => {
    viewAssetLink.value = null;
    viewAssetType.value = null;
}

</script>