<template>
    <div class="flex flex-col items-center justify-center min-h-[30vh]">
        <p class="mb-10 w-full md:w-2/3 text-center">
            How do you want to set your daily spending limit on this campaign?
        </p>
        <div class="flex flex-col gap-5 w-full md:w-2/3">
            <OutlineButton @click="setBudgetType('spend')">$ limit per day</OutlineButton>
            <CustomInput
                v-if="budgetType === 'spend'"
                type="text"
                prefix-text="$"
                classes="max-w-[10rem]"
                v-model="dailySpendLimit"
                @update:modelValue="validateBudget"
            />
            <span class="text-sm text-red-500" v-if="spendValidationMessage"> {{ spendValidationMessage }} </span>

            <OutlineButton @click="setBudgetType('leads')"># Of Opportunities per day</OutlineButton>
            <CustomInput
                v-if="budgetType === 'leads'"
                type="text"
                input-pattern="\d+"
                classes="max-w-[10rem]"
                v-model="dailyLeadLimit"
                @update:modelValue="validateBudget"
            />
            <span class="text-sm text-red-500" v-if="leadValidationMessage"> {{ leadValidationMessage }} </span>

            <SolidButton :disabled="!canProgress" @click="setCampaignBudget()">Continue</SolidButton>

        </div>
    </div>
</template>

<script setup>
import SolidButton from "@/components/inputs/SolidButton.vue";
import {usePurchaseMissedProductsStore} from "@/stores/purchase-missed-products";
import CustomInput from "@/components/inputs/CustomInput.vue";
import {ref} from "vue";
import {useClaimLeadsStore} from "@/stores/claim-leads.store";
import OutlineButton from "@/components/inputs/OutlineButton.vue";

const store = usePurchaseMissedProductsStore();
const missedProductsStore = useClaimLeadsStore();
const dailySpendLimit = ref(null);
const dailyLeadLimit = ref(null);
const budgetType = ref(null);
const canProgress = ref(false);
const leadValidationMessage = ref(null);
const spendValidationMessage = ref(null);
const minimumSpend = store.getMinimumSpend();

const setBudgetType = function (type) {
    budgetType.value = type;
    dailySpendLimit.value = minimumSpend;
    dailyLeadLimit.value = missedProductsStore.customerSelectedLeads.length;
    canProgress.value = true;
    spendValidationMessage.value = null;
    leadValidationMessage.value = null;
}

const validateBudget = function () {
    if (budgetType.value === 'spend' && dailySpendLimit.value < minimumSpend) {
        spendValidationMessage.value = `The minimum required amount is $${minimumSpend} or more`
        canProgress.value = false;
    } else if (budgetType.value === 'spend' && dailySpendLimit.value >= minimumSpend) {
        spendValidationMessage.value = null
        canProgress.value = true;
    }

    if (budgetType.value === 'leads' && dailyLeadLimit.value < missedProductsStore.customerSelectedLeads.length) {
        leadValidationMessage.value = `The minimum of opportunities should be equal the number of selected leads (${missedProductsStore.customerSelectedLeads.length}) or more`
        canProgress.value = false;
    } else if (budgetType.value === 'leads' && dailyLeadLimit.value >= missedProductsStore.customerSelectedLeads.length) {
        leadValidationMessage.value = null
        canProgress.value = true;
    }
}

const setCampaignBudget = function() {
    store.editingCampaign.max_daily_leads = dailyLeadLimit.value
    store.editingCampaign.max_daily_spend = dailySpendLimit.value
    store.editingCampaign.daily_limit_type = budgetType.value
    store.nextSlide()
}
</script>