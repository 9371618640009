<template>
    <MainGrid>
        <PendingApproval v-if="company.status === 'Pending Approval'" />
        <CampaignBulkActivate />
        <LeadProfitability :stores-initialized="profitabilityAssumptionStoreLoaded" />
        <LeadVolume :stores-initialized="profitabilityAssumptionStoreLoaded" />
        <LeadTypesPurchased :stores-initialized="profitabilityAssumptionStoreLoaded" />
        <MoreLeads />

        <template v-slot:side>
            <ProfitabilityAssumptionFields :store-loaded="profitabilityAssumptionStoreLoaded"/>
            <UnpaidPromotion />
            <ProfileProgress />
        </template>
    </MainGrid>
</template>

<script setup>
import MainGrid from "@/components/layouts/MainGrid.vue";
import PendingApproval from "@/components/dashboard/PendingApproval.vue";
import {useCompanyStore} from "@/stores/company";
import CampaignBulkActivate from "@/components/dashboard/CampaignBulkActivate.vue";
import LeadProfitability from "@/components/dashboard/LeadProfitability.vue";
import LeadVolume from "@/components/dashboard/LeadVolume.vue";
import LeadTypesPurchased from "@/components/dashboard/LeadTypesPurchased.vue";
import MoreLeads from "@/components/dashboard/MoreLeads.vue";
import ProfitabilityAssumptionFields from "@/components/dashboard/ProfitabilityAssumptionFields.vue";
import UnpaidPromotion from "@/components/dashboard/UnpaidPromotion.vue";
import ProfileProgress from "@/components/dashboard/ProfileProgress.vue";
import { ref } from "vue";
import {useProfitabilityAssumptionsStore} from "@/stores/profitability-assumptions";

const company = useCompanyStore();

const profitabilityAssumption = useProfitabilityAssumptionsStore();
const profitabilityAssumptionStoreLoaded = ref(false);

profitabilityAssumption.initialize().then(() => profitabilityAssumptionStoreLoaded.value = true);


</script>