<template>
  <ProductSearch :stores-initialized="storesInitialized">
    <template v-slot:title>
      <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
           stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>

      <h2 class="text-lg font-medium">Appointments</h2>
    </template>
    <template v-slot:filters>
      <div>
        <CustomSelect
            v-model="productStore.searchOptions.appointment_category"
            label="Appointment Category"
            :options="appointmentCategoryOptions"
        />
      </div>
    </template>
    <template v-slot:tableHeaders>
      <div>Type</div>
      <div>Date / Time</div>
      <div>Contact</div>
      <div>Address</div>
      <div>Cost</div>
      <div>Time Remaining</div>
    </template>
    <template v-slot:tableRow="{product: appointment}">
      <div>
        <p class="font-bold text-sm md:hidden text-gray-900">Type</p>
        <p class="md:text-center">{{ useProperCase(appointment.appointment?.appointment_type)
                                    || appointment.demoted_status || "" }}</p>
      </div>
      <div>
        <p class="font-bold text-sm md:hidden text-gray-900">Date/Time</p>
        <p class="md:text-center">{{ appointment.appointment?.appointment_date }}</p>
        <p class="md:text-center">{{ appointment.appointment?.appointment_time }}</p>
      </div>
      <div>
        <p class="font-bold text-sm md:hidden text-gray-900">Contact</p>
        <p class="md:text-center truncate" :title="appointment.name">{{ `${appointment.name}` }}</p>
        <p class="md:text-center truncate" :title="appointment.email">{{ appointment.email }}</p>
        <p class="md:text-center truncate" :title="appointment.phone">{{ appointment.phone }}</p>
      </div>
      <div>
        <p class="font-bold text-sm md:hidden text-gray-900">Address</p>
        <p class="md:text-center">{{ appointment.address }}</p>
      </div>
      <div>
        <p class="font-bold text-sm md:hidden text-gray-900">Cost</p>
        <p class="md:text-center">${{ getCost(appointment) }}</p>
      </div>
      <div class="col-span-1">
        <div v-if="canRejectLead(appointment)" class="flex flex-col items-center">
          <p class="text-sm md:justify-center text-red-500 cursor-pointer"
             @click="openRejectionModal(appointment)">
            Reject
          </p>
          <p class="text-gray-700 text-sm pb-0">Time remaining:</p>
          <p class="text-gray-900 text-sm font-bold text-center">{{useRelativeTimeRemaining(appointment.rejection_timer/1000)}}</p>
        </div>
        <div v-else-if="appointment.appointment_status === 'rejected'" class="flex flex-col items-center">
          <p class="font-bold">Appointment was rejected</p>
        </div>
        <div v-else-if="appointment.appointment_status === 'canceled'" class="flex flex-col items-center">
          <p class="font-bold">Cancelled by consumer</p>
        </div>
        <div v-else-if="appointment.appointment_status === 'rescheduled'" class="flex flex-col items-center">
          <p class="font-bold">Rescheduled by consumer</p>
        </div>
      </div>
    </template>
    <template v-slot:product-details-modal="{lead, handleModalClose}">
      <AppointmentDetailsModal
          :appointment="lead"
          @closeModal="handleModalClose"
          small
      />
    </template>
  </ProductSearch>
</template>

<script setup lang="ts">
import {computed, ComputedRef, onBeforeMount, ref} from "vue";
import {useFutureCampaignStore} from "@/stores/v4/future-campaigns.js";
import {ProductAssignment, useProductAssignmentStore} from "@/stores/v4/products.js";
import {ProductType, useServicesStore} from "@/stores/services";
import ProductSearch from "@/components/v4/products/ProductSearch.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import AppointmentDetailsModal from "@/components/appointments/modals/AppointmentDetailsModal.vue";
import {useProperCase} from "@/composables/useProperCase";
import {useRelativeTimeRemaining} from "@/composables/useRelativeTimeRemaining";

const campaignStore = useFutureCampaignStore();
const productStore = useProductAssignmentStore();
const services = useServicesStore();
const storesInitialized = ref(false);

onBeforeMount(async () => {
  services.apiServiceV4.setProductKey(ProductType.Appointment);
  productStore.showOverBudget = false
  await Promise.all([
    campaignStore.initialize(),
    productStore.initialize(),
  ]);
  storesInitialized.value = true;
});


const appointmentCategoryOptions: ComputedRef<CustomSelectOption[]> = computed(() => {
  return [
    {label: 'All', value: ''},
    ...['In-Home', 'Online'].map(category => ({label: category, value: category})),
  ];
});

const canRejectLead = (appointment: ProductAssignment) => {
  return appointment.rejection_timer > 0 && appointment.appointment_status === 'purchased' && appointment.chargeable;
};

const openRejectionModal = (appointment: ProductAssignment) => {
  productStore.setProductToReject(appointment)
}

const getCost = (appointment: ProductAssignment) => {
  if (!appointment.cost || !appointment.chargeable || appointment.appointment_status !== 'purchased')
    return 0;

  return appointment.cost;
}

</script>