<template>
    <div class="w-full">
        <input
            type="range"
            :class="inputClasses"
            :step="step"
            :max="max"
            :min="min"
            v-model="value"
            :style="`background: ${trackColor};`"
            @change="handleChange($event.target.value)"
        />

        <div class="w-full flex justify-between">
            <span class="text-sm inline-block">{{ min }}%</span>
            <span class="text-sm inline-block">{{ max }}%</span>
        </div>
    </div>
</template>

<script setup>
import {computed, ref, watch} from "vue";

const props = defineProps({
    inputClasses: {
        type: Array,
        default: ['w-full']
    },
    min: {
        type: Number,
        default: 0
    },
    max: {
        type: Number,
        default: 100
    },
    step: {
        type: Number,
        default: 5
    },
    modelValue: {
        type: Number,
        default: 10
    }
});

const emit = defineEmits(["update:modelValue"]);
const value = ref(props.modelValue);

const percent = computed(() => (value.value - props.min) / (props.max - props.min) * 100)
const trackColor = computed(() => 'linear-gradient(to right, #00A3FF 0%, #00A3FF ' + percent.value + '%, #F0FAFF ' + percent.value + '%, #F0FAFF 100%)');

const handleChange = (val) => {
    emit('update:modelValue', parseFloat(val));
};

watch(() => props.modelValue, () => {
    if(value.value !== props.modelValue)
        value.value = props.modelValue;
});

watch(value, () => handleChange(value.value));
</script>