<template>
    <div class="grid grid-cols-2 gap-x-8 pr-2 gap-y-4 items-start justify-start">
        <div class="relative">
            <!--    Hidden fields to catch auto-complete scanning    -->
            <input class="hidden" tabindex="-1" type="email" />
            <input class="hidden" tabindex="-1" type="password" />
            <!--     -->
            <CustomInput
                :input-type="innerInputType"
                @update:model-value="handleChangePassword"
                :model-value="passwordValue"
                :placeholder="placeholder"
                :auto-complete="newPassword ? 'new-password' : 'current-password'"
                :label="`*Password${minLength > 1 ? ` (must be at least ${minLength} characters)` : ''}`"
                :min-length="minLength"
                :input-disabled="disabled"
                @change="checkPasswordsMatch"
            >
            </CustomInput>
            <svg class="w-6 absolute right-4 top-9 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                @click="handleToggleShowPassword"
                :class="[ showPassword ? 'text-slate-800' : 'text-slate-300']"
            >
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
        </div>
        <div class="relative">
            <CustomInput
                v-if="newPassword"
                :input-type="innerInputType"
                :placeholder="placeholder"
                label="*Confirm Password"
                auto-complete="new-password"
                @update:model-value="handleChangeConfirmPassword"
                :model-value="confirmPasswordValue"
                :min-length="minLength"
                :input-disabled="disabled"
                @change="checkPasswordsMatch"
                :classes="passwordsMatch ? '' : 'outline outline-1 outline-red-700' "
            />
            <svg class="w-6 absolute right-4 top-9 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                 @click="handleToggleShowPassword"
                 :class="[ showPassword ? 'text-slate-800' : 'text-slate-300']"
            >
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <div v-if="!passwordsMatch"
                class="text-red-900 text-xs absolute left-[50%] translate-x-[-50%] top-[0.15rem]">
                Passwords do not match
            </div>
        </div>
    </div>
</template>

<script setup>

import CustomInput from "@/components/inputs/CustomInput.vue";
import { ref, watch } from "vue";

const props = defineProps({
    darkMode: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: '',
    },
    passwordValue: {
        type: String,
        default: '',
    },
    confirmPasswordValue: {
        type: String,
        default: '',
    },
    newPassword: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: 'Password...',
    },
    minLength: {
        type: Number,
        default: 8,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits([
    'update:passwordValue',
    'update:confirmPasswordValue',
]);

const showPassword = ref(false);
const innerInputType = ref('password');
const passwordsMatch = ref(true);

const checkPasswordsMatch = () => {
    passwordsMatch.value = !props.confirmPasswordValue || props.confirmPasswordValue === props.passwordValue;
}

const handleChangePassword = (newValue) => {
    emit('update:passwordValue', newValue);
}

const handleChangeConfirmPassword = (newValue) => {
    emit('update:confirmPasswordValue', newValue);
}

const handleToggleShowPassword = () => {
    showPassword.value = !showPassword.value;
    innerInputType.value = showPassword.value
        ? 'text'
        : 'password';
}

</script>
