<template>
    <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-12">
        <CustomInput
            :label="wizardStore.getInputName('name', ReservedComponent.Header)"
            input-key="name"
            :model-value="headerInputs.name"
            @update:model-value="handleInputUpdate"
            placeholder="My Campaign..."
        />
        <div class="xl:col-span-2">
            <p class="block mb-3 text-sm font-medium">{{ wizardStore.getInputName('property_types', ReservedComponent.Header) }}</p>
            <div class="flex gap-3 flex-wrap">
                <label v-for="name in Object.keys(propertyTypeInputs)"
                       class="inline-flex items-center mr-4"
                >
                    <CustomCheckbox
                        :model-value="propertyTypeInputs[name]"
                        @update:model-value="(newValue) => updatePropertyTypes(newValue, name)"
                        :input-disabled="propertyTypeInputs[name] && selectedInputs.length === 1"
                    />
                    <span class="ml-2">{{ name }}</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import CustomInput from "@/components/inputs/CustomInput.vue";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import { ReactiveVariable } from "vue/macros";
import { computed, ComputedRef, onMounted, reactive, ref, Ref } from "vue";
import { ReservedComponent, SlideInputs, useWizardStore } from "@/stores/v4/wizard";
import { useProductConfigurationStore } from "@/stores/v4/product-configuration";

interface Props {
    initialHeaderData: SlideInputs,
}
const props = defineProps<Props>();

const emit = defineEmits(['update:headerInput']);

const wizardStore = useWizardStore();
const productConfigurationStore = useProductConfigurationStore();

const propertyTypeInputs: Ref<{ [key: string]: boolean }> = ref({});

const headerInputs: ReactiveVariable<GenericObject> = reactive({
    name: '',
    property_types: [],
});

const selectedInputs: ComputedRef<string[]> = computed(() => {
    return Object.entries(propertyTypeInputs.value).reduce((output, [key, checked]) => {
        return checked
            ? [...output, key]
            : output;
    }, [] as string[]);
})

onMounted(() => {
    Object.assign(headerInputs, props.initialHeaderData);

    initializePropertyInputs();
});

const initializePropertyInputs = () => {
    const types = productConfigurationStore.getPropertyTypes();
    types.forEach(type => {
        propertyTypeInputs.value[type] = headerInputs.property_types?.includes(type) ?? false;
    });
    if (!headerInputs.property_types.length)
        setDefaultPropertyType();

    handleInputUpdate(selectedInputs.value, 'property_types');
}

const setDefaultPropertyType = () => {
    propertyTypeInputs.value[productConfigurationStore.getPropertyTypes()[0] ?? null] = true;
}

const updatePropertyTypes = (checked: boolean, propertyKey: string) => {
    propertyTypeInputs.value[propertyKey] = checked;

    headerInputs.property_types = selectedInputs.value;
    handleInputUpdate(headerInputs.property_types, 'property_types');
}

const handleInputUpdate = (newValue: any, inputKey: string) => {
    if (inputKey in headerInputs) {
        headerInputs[inputKey] = newValue;
        emit('update:headerInput', newValue, inputKey);
    }
}
</script>
