<template>
    <div class="min-h-[50vh]">
        <!--   HEADER     -->
        <div class="flex gap-x-3 py-1 my-4 overflow-x-auto w-72 sm:w-88 md:w-auto">
            <div v-for="[slideKey, slideName] in Object.entries(wizardStore.slideNameMap)"
                 :key="slideKey"
                 class="inline-flex items-center"
            >
                <div @click="saveAndProgressSlide(slideKey)"
                     :class="[wizardStore.currentSlideKey === slideKey ? 'text-cyan-500' : wizardStore.isSlideValid(slideKey) ? 'text-gray-500 cursor-pointer' : 'text-slate-300 cursor-pointer' ]"
                >
                    <span class="font-medium text-md">
                        {{ slideName }}
                    </span>
                </div>
                <svg class="inline w-2 mx-4" v-if="!wizardStore.isFinalSlide(slideKey)" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.417527 0.677659C0.808051 0.287135 1.44122 0.287135 1.83174 0.677659L8.83174 7.67766C9.22227 8.06818 9.22227 8.70135 8.83174 9.09187L1.83174 16.0919C1.44122 16.4824 0.808051 16.4824 0.417527 16.0919C0.0270027 15.7013 0.0270027 15.0682 0.417527 14.6777L6.71042 8.38477L0.417527 2.09187C0.0270027 1.70135 0.0270027 1.06818 0.417527 0.677659Z" fill="#64748B"/>
                </svg>
            </div>
        </div>
        <!--   CURRENT SLIDE   -->
        <Transition name="slide" mode="out-in">
            <component
                ref="currentSlideInstance"
                v-if="wizardStore.initialized"
                :is="wizardStore.currentSlideComponent"
                :initial-slide-data="wizardStore.getInitialSlideData"
                @update:slide-input="handleInputUpdate"
            />
        </Transition>
        <!--    FOOTER    -->
        <div class="sticky bottom-0 bg-white border-t flex justify-between px-4 py-6 mt-6 z-10">
            <SolidButton @click="cancelWizard"
                         label="Cancel"
                         color="gray"
            />

            <div class="flex items-center gap-6">
                <SolidButton v-if="!wizardStore.isFinalSlide()"
                     @click="saveAndProgressSlide"
                     label="Continue"
                />
                <SolidButton @click="saveWizard"
                     v-if="wizardStore.onFinalIncompleteSlide"
                     color="orange"
                     label="Save"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
/**
 * This Component should be kept generic, so it is re-usable along with wizardStore. Any Campaign specific requirements
 * should be handled by firing an event back up to the CampaignWizard container
 */
import SolidButton from "@/components/inputs/SolidButton.vue";
import { CustomSlideValidator, ModuleConfiguration, useWizardStore, WizardComponentMap } from "@/stores/v4/wizard.js";
import { onMounted, ref, Ref } from "vue";
import { useAlertStore } from "@/stores/v4/alerts";
import { ValidatorResults } from "@/services/v4/WizardValidationService";

interface Props {
    configuration: ModuleConfiguration[]|null,
    slideMap: WizardComponentMap
}

defineProps<Props>();

const emit = defineEmits([
    'save:slide',
    'close:wizard',
    'save:wizard',
]);

const wizardStore = useWizardStore();
const alertStore = useAlertStore();

const currentSlideInstance: Ref<HTMLElement|null> = ref(null);

const initialize = () => {

}
onMounted(() => initialize());

const validateSlide = (): ValidatorResults => {
    if (currentSlideInstance.value && 'customValidation' in currentSlideInstance.value) {
        // First, run any custom validation on the Slide Component
        const validator: CustomSlideValidator = currentSlideInstance.value.customValidation as CustomSlideValidator;
        if (typeof(validator) === "function") {
            const { valid, errorBag, message } = validator();
            if (!valid) {
                const errorMessage = errorBag?.length
                    ? wizardStore.validationService.getErrorBagSummary(errorBag)
                    : message ?? 'Validation error';

                alertStore.showError(errorMessage);

                return { valid };
            }
        }
    }
    // Then run the generic Wizard validation service, driven by back-end data
    const { valid, errorBag, data } = wizardStore.validateSlide();
    if (!valid) {
        if (errorBag) alertStore.showError(wizardStore.validationService.getErrorBagSummary(errorBag));
    }

    return { valid, data };
}

const handleInputUpdate = (newValue: any, inputKey: string) => {
    wizardStore.updateWizardInput(newValue, inputKey);
}

const cancelWizard = () => {
    emit('close:wizard');
}

const saveAndProgressSlide = (goToSlideKey?: string) => {
    const validateSlideData = validateSlide();
    if (validateSlideData.valid) {
        emitSlideUpdate(validateSlideData.data);
        if (goToSlideKey)
            wizardStore.goToSlide(goToSlideKey);
        else
            wizardStore.nextSlide();
    }
}

const emitSlideUpdate = (validatedData?: GenericObject) => {
    const payloadKey = wizardStore.getPayloadKey();
    const slidePayload = validatedData ?? wizardStore.fetchSlideInputValues();
    if (payloadKey) {
        const newPayload: GenericObject = { [payloadKey]: {} };
        for (const input in slidePayload) {
            newPayload[payloadKey][input] = slidePayload[input];
            emit('save:slide', newPayload, wizardStore.currentSlideKey);
        }
    }
    else {
        emit('save:slide', slidePayload, wizardStore.currentSlideKey);
    }
}

const saveWizard = () => {
    const currentSlideValid = validateSlide();
    if (!currentSlideValid.valid) return;
    else {
        emitSlideUpdate(currentSlideValid.data);
    }

    const { valid, message, errorBag } = wizardStore.validateWizard();
    if (!valid) {
        alertStore.showError(message ?? wizardStore.validationService.getErrorBagSummary(errorBag ?? []) ?? "Unable to save the Slide Wizard.");
    }
    else emit('save:wizard', wizardStore.fetchReservedComponents());
}

</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
    opacity: 1;
    transition: opacity 0.25s ease-in 0s;
}

.slide-enter-from,
.slide-leave-to {
    opacity: 0;
    transition: opacity 0.25s 0s;
}
</style>