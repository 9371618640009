/**
 * Get a human-readable string of relative time
 * @param {number} seconds
 * @returns {string}
 */
export const useRelativeTimeRemaining = (seconds) => {
    if(typeof seconds !== 'number' || !(seconds > 0)) {
        return '';
    }

    let remainingDays = Math.floor(seconds / (60 * 60 * 24));
    let remainingDaysInSeconds = remainingDays * 60 * 60 * 24;
    let remainingHours = Math.floor((seconds - remainingDaysInSeconds) / (60 * 60));
    let remainingHoursInSeconds = remainingHours * 60 * 60;
    let remainingMinutes = Math.floor((seconds - remainingDaysInSeconds - remainingHoursInSeconds) / 60);
    let remainingMinutesInSeconds = remainingMinutes * 60;
    let remainingSeconds = Math.floor(seconds - remainingDaysInSeconds - remainingHoursInSeconds - remainingMinutesInSeconds);

    let formatted = '';
    let separatorRequired = false;

    if(remainingDays > 0) {
        formatted += remainingDays + ' day' + (remainingDays > 1 ? 's' : '');
        separatorRequired = true;
    }

    if(remainingHours > 0) {
        if(separatorRequired) formatted += ', ';
        formatted += remainingHours + ' hour' + (remainingHours > 1 ? 's' : '');
        separatorRequired = true;
    }

    if(remainingMinutes > 0) {
        if(separatorRequired) formatted += ', ';
        formatted += remainingMinutes + ' minute' + (remainingMinutes > 1 ? 's' : '');
        separatorRequired = true;
    }

    if(remainingSeconds > 0 && !(remainingDays > 0 && remainingHours > 0 && remainingMinutes > 0)) {
        if(separatorRequired) formatted += ', ';
        formatted += remainingSeconds + ' second' + (remainingSeconds > 1 ? 's' : '');
    }

    return formatted;
}