<template>
    <div>
        <div v-show="loading">
            <loading-spinner/>
        </div>
        <div v-show="!loading" class="text-sm">
            <div class="grid grid-cols-1 md:grid-cols-3 items-center mb-2 gap-2">
                <div class="grid grid-cols-4 gap-5 col-span-1 md:col-span-2">
                    <div class="rounded text-sm px-3 py-2 bg-cyan-200 bg-opacity-50 text-center">
                        Available Times
                    </div>
                    <!-- <div class="rounded text-sm px-3 py-2 bg-yellow-300 bg-opacity-50 border border-yellow-500 text-center">
                        Online
                    </div> -->
                    <div class="rounded text-sm px-3 py-2 bg-red-300 bg-opacity-50 border border-red-500 text-center">
                        In Home
                    </div>
                    <div class="rounded text-sm px-3 py-2 bg-indigo-400 bg-opacity-50 border border-indigo-500 text-center">
                        Multiple
                    </div>
                </div>
                <div class="grid grid-cols-2 md:grid-cols-1 gap-5">
                    <div :class="calendarLoading ? 'visible' : 'invisible'" class="flex flex-row items-center justify-start md:justify-end gap-1 text-sm">
                        Loading Events <loading-spinner :theme="'w-5 h-5 border-cyan-500'" />
                    </div>
                    <div class="flex-row justify-end md:hidden flex">
                        <button class="w-full rounded-md px-4 py-2 font-medium text-white bg-cyan-500 hover:bg-cyan-300" @click="selectedDate = new Date()">Today</button>
                    </div>
                </div>
            </div>
            <vue-cal
                :style="`height: ${height}`"
                :disable-views="['years']"
                :active-view="'week'"
                :time-step="30"
                :special-hours="availableTimes"
                :events="Object.values(groupedEvents.groupedEventsForDisplay)"
                :drag-to-create-event="false"
                :selected-date="selectedDate"
                :time-from="6 * 60"
                :time-to="19 * 60"
                today-button
                small
                twelve-hour
                click-to-navigate
                overlaps-per-time-step
                :min-event-width="50"
                :on-event-click="handleEventClick"
                @view-change="getCalendarEvents"
                @ready="getCalendarEvents">

                <template #no-event="">No events</template>

                <template #today-button>
                    <button class="rounded-md p-1 border border-white text-sm hover:text-cyan-400 hover:bg-white hover:border-cyan-400 hidden md:block">Today</button>
                </template>

                <template #title="{ title, view }">
                    <span v-if="view.id === 'years'">Years</span>
                    <!-- Using Vue Cal injected Date prototypes -->
                    <span v-else-if="view.id === 'year'">{{ view.startDate.format('YYYY') }}</span>
                    <span v-else-if="view.id === 'month'">{{ (new Intl.DateTimeFormat("en-US", {month: "long"})).format(view.startDate) + " " + view.startDate.format('YYYY') }}</span>
                    <span v-else-if="view.id === 'week'">{{ (new Intl.DateTimeFormat("en-US", {month: "long"})).format(view.startDate) + " " + view.startDate.format('YYYY') }}</span>
                    <span v-else-if="view.id === 'day'">{{ (new Intl.DateTimeFormat("en-US", {weekday: "long"})).format(view.startDate) + ', ' + (new Intl.DateTimeFormat("en-US", {month: "long"})).format(view.startDate) + " " + view.startDate.format('DD, YYYY') }}</span>
                </template>

            </vue-cal>

            <Modal v-if="showEventDetailsModal"
                :show-cancel-button="false"
                confirm-label="Ok"
                :small="true"
                @clicked:confirm="toggleEventDetailsModal(false)"
            >
                <template v-slot:header>
                    <span class="font-medium">
                        {{ selectedEventToView.modalTitle }}
                    </span>
                </template>
                <template v-slot:body>
                    <div class="overflow-y-auto bg-cyan-200 rounded p-5">
                        <div class="flex flex-col">
                            <div class="text-sm uppercase mb-2 text-gray-500">
                                Consumer
                            </div>
                            <div>
                                {{ selectedEventToView.invitee }}
                            </div>
                        </div>
                        <div class="flex flex-col mt-2">
                            <div class="text-sm uppercase mb-2 text-gray-500">
                                Time
                            </div>
                            <div>
                                {{ formatDateStringForDisplay(selectedEventToView.start) }} to {{ formatDateStringForDisplay(selectedEventToView.end) }}
                            </div>
                        </div>
                        <div class="flex flex-col mt-2">
                            <div class="text-sm uppercase mb-2 text-gray-500">
                                Type
                            </div>
                            <div>
                                {{ selectedEventToView.appointmentType }}
                            </div>
                        </div>
                    </div>
                </template>
            </Modal>

            <Modal
                v-if="showGroupedEventsModal"
                :show-cancel-button="false"
                confirm-label="Ok"
                :small="true"
                @clicked:confirm="toggleGroupedEventsModal(false)"
            >
                <template v-slot:header>
                    <span class="font-medium">
                        Events from {{ formatDateStringForDisplay(selectedUngroupedEventsToView[0].start) }} to {{ formatDateStringForDisplay(selectedUngroupedEventsToView[0].end) }}
                    </span>
                </template>
                <template v-slot:content>
                    <div v-for="event in selectedUngroupedEventsToView" :key="event.title" class="overflow-y-auto bg-cyan-200 rounded mb-5 p-5">
                        <div class="uppercase text-sm">
                            {{ event.title }}
                        </div>
                        <div class="flex flex-col mt-2">
                            <div class="text-sm uppercase mb-2 text-gray-500">
                                Consumer
                            </div>
                            <div>
                                {{ event.invitee }}
                            </div>
                        </div>
                        <div class="flex flex-col mt-2">
                            <div class="text-sm uppercase mb-2 text-gray-500">
                                Time
                            </div>
                            <div>
                                {{ formatDateStringForDisplay(event.start) }} to {{ formatDateStringForDisplay(event.end) }}
                            </div>
                        </div>
                        <div class="flex flex-col mt-2">
                            <div class="text-sm uppercase mb-2 text-gray-500">
                                Type
                            </div>
                            <div>
                                {{ event.appointmentType }}
                            </div>
                        </div>
                    </div>
                </template>
            </Modal>
        </div>
    </div>
</template>

<script>
    import VueCal from 'vue-cal';
    import 'vue-cal/dist/vuecal.css';
    import Modal from "@/components/Modal.vue";
    import LoadingSpinner from "@/components/LoadingSpinner.vue";
    import {useServicesStore} from "@/stores/services";

    export default {
        name: "IntegratedCalendarEvents",
        components: {
          LoadingSpinner,
            VueCal,
            Modal
        },
        props: {
            calendarId: {
                type: Number,
                default: 0
            },
            height: {
                type: String,
                default: '35rem'
            }
        },
        data: function() {
            return {
                admin2Api: useServicesStore().apiService,

                calendarItems: {},
                calendarOverrideItems: [],
                calendarEvents: [],

                calendarLoading: false,

                daysIndexes: {
                    mondays: 1,
                    tuesdays: 2,
                    wednesdays: 3,
                    thursdays: 4,
                    fridays: 5,
                    saturdays: 6,
                    sundays: 7
                },

                selectedDate: null,

                showEventDetailsModal: false,
                selectedEventToView: null,

                showGroupedEventsModal: false,
                selectedUngroupedEventsToView: null,

                loading: true
            };
        },
        computed: {
            availableTimes() {
                let availableTimes = {
                    1: [],
                    2: [],
                    3: [],
                    4: [],
                    5: [],
                    6: [],
                    7: []
                };
                for(const [day, timeSlots] of Object.entries(this.calendarItems)) {
                    timeSlots.forEach((timeSlot) => {
                        availableTimes[this.daysIndexes[day]].push({
                            from: this.convertTwentyFourHourTimeToMinutes(timeSlot.start),
                            to: this.convertTwentyFourHourTimeToMinutes(timeSlot.end),
                            class: 'bg-cyan-200 opacity-50 bg-blur'
                        });
                    });
                }

                return availableTimes;
            },
            events() {
                let events = this.calendarEvents.map((event) => {
                    return {
                        start: `${event.date} ${event.start_time}`,
                        end: `${event.date} ${event.end_time}`,
                        title: event.invitee_name,
                        content: event.title,
                        class: "bg-blur " + (event.type === "In-Home" ? "in-home" : "online"),
                        appointmentType: event.type,
                        groupKey: `${event.date} ${event.start_time}` + `${event.date} ${event.end_time}`,
                        invitee: `${event.invitee_name} (${event.invitee_email})`,
                        modalTitle: event.title
                    };
                });

                let i = 0;
                for(const overrideItem of this.calendarOverrideItems) {
                    events.push({
                        start: `${overrideItem.date} ${overrideItem.start}`,
                        end: `${overrideItem.date} ${overrideItem.end}`,
                        title: 'Unavailable',
                        class: "bg-blur override-item",
                        groupKey: `unavailable${i}`
                    });

                    i++;
                }

                return events;
            },
            groupedEvents() {
                let groupedEvents = {
                    ungroupedEvents: {},
                    groupedEventsForDisplay: {}
                };

                let key = '';
                let eventsPerTimeSlot = {};
                for(const event of Object.values(this.events)) {
                    key = event.groupKey;

                    if(eventsPerTimeSlot[key] === undefined
                    || eventsPerTimeSlot[key] === null) {
                        eventsPerTimeSlot[key] = 1;
                    }
                    else {
                        eventsPerTimeSlot[key] += 1;
                    }
                }

                key = '';
                this.events.forEach((event) => {
                    key = event.groupKey;

                    if(groupedEvents.ungroupedEvents[key] === undefined
                    || groupedEvents.ungroupedEvents[key] === null) {
                        groupedEvents.ungroupedEvents[key] = [];
                    }

                    groupedEvents.ungroupedEvents[key].push(event);

                    if(eventsPerTimeSlot[key] > 1) {
                        if(groupedEvents.groupedEventsForDisplay[key] === undefined
                        || groupedEvents.groupedEventsForDisplay[key] === null) {
                            groupedEvents.groupedEventsForDisplay[key] = {
                                start: event.start,
                                end: event.end,
                                title: 1,
                                class: 'multiple bg-blur',
                                grouped: true,
                                groupKey: key
                            };
                        }
                        else {
                            groupedEvents.groupedEventsForDisplay[key].title += 1;
                        }
                    }
                    else {
                        event.grouped = false;

                        groupedEvents.groupedEventsForDisplay[key] = event;
                    }
                });

                Object.values(groupedEvents.groupedEventsForDisplay).forEach((groupedEvent) => {
                    if(groupedEvent.grouped) {
                        groupedEvent.title = groupedEvent.title + ' Appointments';
                    }
                });

                return groupedEvents;
            }
        },
        created: function() {

        },
        methods: {
            getCalendarEvents(calData) {
                this.calendarLoading = true;

                let startDate = calData.startDate.format('YYYY-MM-DD');
                let endDate = calData.endDate.format('YYYY-MM-DD');

                this.admin2Api.getCalendarEventsByDateRange(this.calendarId, startDate, endDate).then(res => {
                    if(res.data.data.status === true) {
                        this.calendarItems = res.data.data.calendar_events.schedule_items;
                        this.calendarOverrideItems = res.data.data.calendar_events.schedule_override_items;
                        this.calendarEvents = res.data.data.calendar_events.events;
                    }
                    else {
                        console.error(res);
                    }
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                    this.loading = false;
                    this.calendarLoading = false;
                });
            },
            convertTwentyFourHourTimeToMinutes(time) {
                const timeParts = time.split(':');

                return timeParts[0] * 60 + parseInt(timeParts[1]);
            },
            formatDateStringForDisplay(dateString, locale = 'en-US') {
                const date = new Date(dateString);

                const formatter = new Intl.DateTimeFormat(
                    locale,
                    {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    }
                );

                const parts = formatter.formatToParts(date);

                return parts.map((part) => part.value).join('');
            },
            handleEventClick(calEvent) {
                if(calEvent.groupKey.startsWith('unavailable')) {
                    return;
                }

                if(calEvent.grouped) {
                    this.toggleGroupedEventsModal(true, this.groupedEvents.ungroupedEvents[calEvent.groupKey]);
                }
                else {
                    this.toggleEventDetailsModal(true, calEvent);
                }
            },
            toggleEventDetailsModal(show, calEvent = null) {
                this.selectedEventToView = calEvent;
                this.showEventDetailsModal = !!show;
            },
            toggleGroupedEventsModal(show, ungroupedEventsList = null) {
                this.selectedUngroupedEventsToView = ungroupedEventsList;

                this.showGroupedEventsModal = !!show;
            }
        }
    }
</script>

<style lang="css">
    .bg-blur {
        backdrop-filter: blur(1px);
    }

    .vuecal__event-time span {
        @apply text-sm;
    }

    .vuecal__cell--today {
        background-color: transparent !important;
    }

    .vuecal__cell--today::before {
        border-top: 2px #1e90ff solid;
        content: 'TODAY';
        position: absolute;
        font-size: 10px;
        font-weight: 700;
    }

    .today {
        border-bottom: 3px #1e90ff solid;
    }

    .vuecal {
        /* Cyan-theme. */
        .vuecal__menu, .vuecal__cell-events-count {
            @apply bg-cyan-500 text-white;
        }

        .vuecal__title-bar {
            @apply bg-cyan-400 text-white;
        }

        .vuecal__cell--today, .vuecal__cell--current {
            background-color: rgba(191, 226, 202, 0.6);
        }

        .vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
            background-color: rgba(196, 236, 249, 0.6);
        }

        .vuecal__cell--selected:before {
            @apply border-cyan-300;
        }

        /* Cells and buttons get highlighted when an event is dragged over it. */
        .vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
        .vuecal__cell-split--highlighted {
            @apply bg-cyan-300;
        }

        .vuecal__arrow.vuecal__arrow--highlighted,
        .vuecal__view-btn.vuecal__view-btn--highlighted {
            @apply bg-cyan-200;
        }

        .vuecal__event {
            @apply font-medium text-black rounded-lg p-1 border;

            &.online {
                @apply border-yellow-500 bg-yellow-300 bg-opacity-75;
            }

            &.in-home {
                @apply bg-red-300 border-red-500 bg-opacity-75;
            }

            &.multiple {
                @apply bg-indigo-400 border-indigo-500 bg-opacity-75;
            }

            &.override-item {
                @apply bg-black border-black bg-opacity-75;

                pointer-events: none;
                color: white;
            }
        }
    }
</style>