<template>
    <div class="flex flex-col gap-1 box-content">
        <p class="text-sm">{{ label }}</p>
        <input
            :value="modelValue"
            :disabled="disabled"
            class="w-full pl-4 rounded border border-gray-600 bg-gray-50 py-2.5"
            :type="type"
            @input="updateModel"
        />
        <p class="text-red-500 text-sm">{{ errorMessage }}</p>
    </div>
</template>
<script setup>
const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    },
    label: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    errorMessage: {
        type: String,
        default: ''
    },
    type: {
        type: String,
        default: 'text'
    }
});

const emit = defineEmits(['update:modelValue'])

const updateModel = (newValue) => {
    emit('update:modelValue', newValue.target.value);
}
</script>