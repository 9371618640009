<template>
  <Modal
      @clicked:confirm="saveSchedule"
      @clicked:cancel="$emit('closeModal')"
      :disable-confirm="readOnly"
  >
    <template v-slot:header>
      <div class="flex items-center">
        <svg class="w-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path fill-rule="evenodd"
                d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                clip-rule="evenodd"/>
        </svg>
        <h5 class="text-md">
          {{
            editSchedule
                ? `Edit schedule - ${getPlatformName()}`
                : `New Schedule${editingSchedule.platform ? ` - ${getPlatformName()}` : ''}`
          }}
        </h5>
      </div>
    </template>
    <template v-slot:body>
      <div class="flex flex-col gap-2">
        <ul v-if="editingSchedule.id" class="list-none uppercase pl-0 text-sm">
          <li class="float-left mr-5 cursor-pointer"
              @click="changeTab('details')">
                  <span :class="tab === 'details' ? 'text-cyan-500' : 'hover:text-cyan-500 text-gray-500'">
                      Details
                  </span>
          </li>
          <li class="float-left cursor-pointer"
              @click="changeTab('events')">
                  <span :class="tab === 'events' ? 'text-cyan-500' : 'hover:text-cyan-500 text-gray-500'">
                      Events
                  </span>
          </li>
        </ul>
        <div v-if="tab === 'details'">
          <div v-if="!editingSchedule.platform && !viewMode">
            <div>Please select a platform:</div>
            <div
                class="grid grid-cols-3 mt-4 w-[90%] mx-auto"
                v-for="option in platformOptions">
              <OutlineButton
                  @click="selectPlatform(option.value)"
              >
                {{ option.label }}
              </OutlineButton>
            </div>
          </div>
          <div v-else-if="saving" class="relative min-h-[20rem]">
            <LoadingSpinner
                :small="true"
            />
          </div>
          <div v-else
               :class="[readOnly ? 'pointer-events-none' : '']"
          >
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-5 mb-5">
              <div class="flex flex-col lg:col-span-2">
                        <span class="font-medium mb-2">
                            Schedule Name
                        </span>
                <CustomInput
                    type="text"
                    v-model="editingSchedule.name"
                />
              </div>
              <div class="flex flex-col">
                      <span class="font-medium mb-2">
                        Timezone
                      </span>
                <CustomSelect
                    v-model="editingSchedule.timezone_offset"
                    :options="timeZoneOptions"
                    :label="null"
                />
              </div>
              <div class="flex flex-col">
                <span class="font-medium mb-2"># of Events per Time Slot</span>
                <div class="flex gap-2">
                  <CustomSelect
                      class="w-full"
                      v-model="editingSchedule.allow_unlimited_overlapping_events"
                      :options="overlappingEventsOptions"
                      :label="null"
                  />
                  <CustomInput
                      v-if="editingSchedule.allow_unlimited_overlapping_events === 'set_amount'"
                      type="number"
                      v-model="editingSchedule.overlapping_events_allowed"
                  />
                </div>
              </div>
              <div class="flex flex-col">
                <span class="font-medium mb-2">Allow In Home same day appointments?</span>
                <CustomSelect
                    v-model="editingSchedule.in_home_same_day_appointments"
                    :options="booleanOptions"
                    :label="null"
                />
              </div>
              <div class="flex flex-col">
                <span class="font-medium mb-2">Allow Online same day appointments?</span>
                <CustomSelect
                    v-model="editingSchedule.online_same_day_appointments"
                    :options="booleanOptions"
                    :label="null"
                />
              </div>
            </div>

            <div v-if="readOnly" class="pb-4">
              <p class="text-red-700">
                Schedules cannot currently be edited.
              </p>
            </div>

            <div class="grid lg:grid-cols-2 gap-4">
              <div class="rounded p-2"
                   :class="[readOnly ? 'bg-gray-100' : 'bg-cyan-100']"
              >
                <div class="text-center">
                  <span class="text-cyan-600 font-medium">In Home</span>
                </div>
                <div class="mb-2">
                  <div class="font-medium mb-2">
                    How long should an appointment take?
                  </div>
                  <CustomSelect
                      v-model="editingSchedule.in_home_duration"
                      :options="getTimeOptions(15)"
                      :label="null"
                  />
                </div>
                <div class="mb-2">
                  <div class="font-medium mb-2">
                    Time needed <span class="text-cyan-600">before</span> an appointment?
                  </div>
                  <CustomSelect
                      v-model="editingSchedule.in_home_buffer_before"
                      :options="getTimeOptions()"
                      :label="null"
                  />
                </div>
                <div class="mb-2">
                  <div class="font-medium mb-2">
                    Time needed <span class="text-cyan-600">after</span> an appointment?
                  </div>
                  <CustomSelect
                      v-model="editingSchedule.in_home_buffer_after"
                      :options="getTimeOptions()"
                      :label="null"
                  />
                </div>
              </div>
              <div class="rounded p-2"
                   :class="[readOnly ? 'bg-gray-100' : 'bg-cyan-100']"
              >
                <div class="text-center">
                  <span class="text-cyan-600 font-medium">Online</span>
                </div>
                <div class="mb-2">
                  <div class="font-medium mb-2">
                    How long should an appointment take?
                  </div>
                  <CustomSelect
                      v-model="editingSchedule.online_duration"
                      :options="getTimeOptions(15)"
                      :label="null"
                  />
                </div>
                <div class="mb-2">
                  <div class="font-medium mb-2">
                    Time needed <span class="text-cyan-600">before</span> an appointment?
                  </div>
                  <CustomSelect
                      v-model="editingSchedule.online_buffer_before"
                      :options="getTimeOptions()"
                      :label="null"
                  />
                </div>
                <div class="mb-2">
                  <div class="font-medium mb-2">
                    Time needed <span class="text-cyan-600">after</span> an appointment?
                  </div>
                  <CustomSelect
                      v-model="editingSchedule.online_buffer_after"
                      :options="getTimeOptions()"
                      :label="null"
                  />
                </div>
              </div>
            </div>
            <div v-if="editSchedule">
              <div class="mt-5">
                <hr class="my-5">
                <div class="font-medium text-lg mb-4">
                  Available Days & Times
                </div>
                <div v-if="!readOnly" class="px-4 py-3 my-4 rounded-md bg-cyan-100 border border-cyan-200">
                  <p class="text-sm font-">Add the times you are available for each day</p>
                </div>
                <div v-for="(timeBlocks, day) in editingSchedule.items" :key="day" class="mb-3 pb-4">
                  <div class="font-medium text-lg mb-2">
                    {{ day.slice(0, 1).toUpperCase() + day.slice(1) }}
                  </div>
                  <div v-for="(timeBlock, idx) in timeBlocks" :key="idx"
                       class="grid md:inline-flex md:flex-wrap gap-2 md:gap-6 border-l-2 mr-3 border-cyan-600 px-3 rounded-md pb-4 pt-2 bg-gray-100 mb-3 relative items-center">
                    <div class="flex flex-col">
                      <p class="pb-0 text-gray-900 font-medium mb-1 text-sm">Time range</p>

                      <DatePicker
                          v-if="!readOnly"
                          v-model="timeBlock.times"
                          time-picker
                          range
                          model-type="H:mm:ss"
                      />
                      <div v-else>
                        {{ timeBlock.start }} - {{ timeBlock.end }}
                      </div>
                    </div>
                    <div>
                      <p class="pb-0 text-gray-900 mt-6 font-bold text-sm">-</p>
                    </div>

                    <div class="mt-7" v-if="!readOnly">
                      <button class="p-1 rounded text-gray-500 bg-transparent hover:text-red-500 hover:bg-red-100"
                              @click="deleteScheduleTimeBlock(day, idx)">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div v-if="!readOnly">
                    <button @click="addScheduleTimeBlock(day)"
                            class="rounded px-3 py-2 text-cyan-500 font-medium hover:bg-cyan-100 transition duration-200">
                      + Availability
                    </button>
                  </div>
                </div>
                <hr class="my-5">
                <div>
                  <div class="font-medium text-lg mb-4">
                    Unavailable Dates & Times
                  </div>
                  <div v-if="!readOnly" class="px-4 py-3 my-4 rounded-md bg-cyan-100 border border-cyan-200">
                    <p class="text-sm font-">Specify the dates and times that you are <strong>not</strong> available.
                      <em>(These dates and times will take precedence over your availability times)</em></p>
                  </div>
                  <div v-for="(overrideItem, idx) in editingSchedule.override_items" :key="idx"
                       class="grid md:inline-flex gap-2 md:gap-6 mr-3 border-l-2 border-cyan-600 px-3 rounded-md pb-4 pt-2 bg-gray-100 mb-3 relative items-center">
                    <div>
                      <p class="pb-0 text-gray-900 font-medium mb-1 text-sm">Date</p>
                      <DatePicker
                          v-if="!readOnly"
                          v-model="overrideItem.date"
                          :enable-time-picker="false"
                          model-type="yyyy-MM-dd"
                          :min-date="new Date()"
                      />
                      <div v-else>
                        {{ overrideItem.date }}
                      </div>
                    </div>
                    <div>
                      <p class="pb-0 text-gray-900 font-medium mb-1 text-sm">Time range</p>

                      <DatePicker
                          v-if="!readOnly"
                          v-model="overrideItem.times"
                          time-picker
                          range
                          model-type="H:mm:ss"
                      />
                      <div v-else>
                        {{ overrideItem.start }} - {{ overrideItem.end }}
                      </div>
                    </div>
                    <div class="mt-7" v-if="!readOnly">
                      <button class="p-1 rounded text-gray-500 bg-transparent hover:text-red-500 hover:bg-red-100"
                              @click="deleteOverrideItem(idx)">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div v-if="!readOnly">
                    <button @click="addOverrideItem"
                            class="rounded px-3 py-2 text-cyan-500 font-medium hover:bg-cyan-100 transition duration-200">
                      + Unavailable Date
                    </button>
                  </div>
                </div>
              </div>

            </div>
            <div v-if="errorMessage" class="py-4">
              <p class="text-red-900 text-center whitespace-pre">{{ errorMessage }}</p>
            </div>
          </div>
        </div>
        <div v-else>
          <integrated-calendar-events v-if="tab === 'events'" @change-tab="changeTab($event)"
                                      :calendar-id="editingSchedule.id"/>


        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import {onMounted, reactive, ref, toRaw, watch} from "vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import CustomInput from "@/components/inputs/CustomInput.vue";
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import IntegratedCalendarEvents
  from "@/views/appointments/modals/CreateOrEditScheduleModal/IntegratedCalendarEvents.vue";
import {useSchedulesStore} from "@/stores/schedules";

const props = defineProps({
  timezones: {
    type: Object,
    default: {},
  },
  editSchedule: {
    type: Object,
    default: null,
  },
  viewMode: {
    type: Boolean,
    default: false,
  },
});

const editingSchedule = reactive({
  id: null,
  name: '',
  platform: null,
  in_home_duration: 15,
  online_duration: 15,
  online_buffer_before: 0,
  online_buffer_after: 0,
  in_home_buffer_before: 0,
  in_home_buffer_after: 0,
  is_owner: false,
  override_items: [],
  items: {},
  timezone_offset: null,
  allow_unlimited_overlapping_events: 'unlimited',
  overlapping_events_allowed: 0,
  in_home_same_day_appointments: false,
  online_same_day_appointments: false,
});


watch(() => editingSchedule.allow_unlimited_overlapping_events, (newValue) => {
  if (newValue === 'unlimited' && editingSchedule.overlapping_events_allowed > 1) {
    editingSchedule.overlapping_events_allowed = newValue === 'unlimited' ? 0 : 1;
  }
}, {deep: true});


const errorMessage = ref(null);
const saving = ref(false);
const tab = ref('details');

const scheduleStore = useSchedulesStore();

const changeTab = (newTab) => {
  tab.value = newTab
}

const readOnly = true;

const timeZoneOptions = [
  {value: null, label: "Select Timezone"},
  ...Object.entries(props.timezones).map(([name, modifier]) => ({value: modifier, label: name}))
];

onMounted(() => {
  if (props.editSchedule) {
    Object.assign(editingSchedule, props.editSchedule);
    for (const day in editingSchedule.items) {
      editingSchedule.items[day].forEach(item => {
        item.times = [item.start, item.end];
      });
    }
    editingSchedule.override_items.forEach(item => {
      item.times = [item.start, item.end];
    })
    editingSchedule.allow_unlimited_overlapping_events = editingSchedule.overlapping_events_allowed === 0 ? 'unlimited' : 'set_amount'
  }
});

const getPlatformName = () => platformOptions.find(({value}) => value === editingSchedule.platform)?.label ?? 'Unknown Platform';

const getTimeOptions = (minimumValue = -1) => {
  return minimumValue
      ? timeOptions.filter(({value}) => value >= minimumValue)
      : timeOptions;
}

const timeOptions = [
  {value: 0, label: 'No Buffer'},
  {value: 5, label: '5 mins'},
  {value: 10, label: '10 mins'},
  {value: 15, label: '15 mins'},
  {value: 30, label: '30 mins'},
  {value: 45, label: '45 mins'},
  {value: 60, label: '60 mins'},
];

const overlappingEventsOptions = [{label: 'Unlimited', value: 'unlimited'}, {label: 'Set Amount', value: 'set_amount'}];

const booleanOptions = [{label: 'Yes', value: true}, {label: 'No', value: false}]

const platformOptions = [
  {value: 'internal', label: 'Availability Timeframe'},
];

const selectPlatform = (platformValue) => {
  editingSchedule.platform = platformValue;
}

const emit = defineEmits(['closeModal']);

const saveSchedule = async () => {
  if (!validateSchedule()) return;
  if (props.editSchedule) {
    if (!editingSchedule.is_owner) return;
    await updateSchedule(transformPayload(false))
  } else {
    await createSchedule(transformPayload(true))
  }
}

const updateSchedule = async (updatedSchedule) => {
  if (saving.value) return;

  saving.value = true;
  errorMessage.value = null;
  const {status, message} = await scheduleStore.updateSchedule(updatedSchedule);
  if (status) {
    emit('closeModal')
  } else {
    errorMessage.value = message;
  }
  saving.value = false;
}

const createSchedule = async (schedulePayload) => {
  if (saving.value) return;

  saving.value = true;
  errorMessage.value = null;
  const {status, message} = await scheduleStore.createSchedule(schedulePayload);
  if (!status) {
    errorMessage.value = message;
  } else {
    emit('closeModal')
  }

  saving.value = false;
}

const transformPayload = (isNewSchedule) => {
  const payload = JSON.parse(JSON.stringify(toRaw(editingSchedule)));
  if (!isNewSchedule) transformDatesAndTimes(payload);
  transformTimezone(payload);

  return payload;
}

const transformTimezone = (payload) => {
  payload.timezone = payload.timezone_offset;
}

const transformDatesAndTimes = (payload) => {
  for (const day in payload.items) {
    payload.items[day].forEach(item => {
      [item.start, item.end] = item.times;
      delete item.times;
    });
  }
  payload.override_items.forEach(item => {
    [item.start, item.end] = item.times;
    delete item.times;
  });
}

const validateSchedule = () => {
  const errors = [];
  errorMessage.value = null;

  if (editingSchedule.name.length < 1) errors.push('Schedule must have a name.');
  if (!Object.values(props.timezones).includes(parseInt(editingSchedule.timezone_offset))) errors.push('Invalid Timezone selected.');
  if (editingSchedule.in_home_duration < 15 || editingSchedule.online_duration < 15) errors.push('Appointment duration must be at least 15 minutes');
  for (const buffer in [editingSchedule.in_home_buffer_before, editingSchedule.in_home_buffer_after, editingSchedule.online_buffer_before, editingSchedule.online_buffer_after]) {
    if (buffer < 0) {
      errors.push('Buffer time must be zero or greater');
      break;
    }
  }

  const validateStartAndEnd = (timePickerArray, day, index) => {
    const start = parseInt(timePickerArray.times[0]?.replace(/:/g, '') ?? '');
    const end = parseInt(timePickerArray.times[1]?.replace(/:/g, '') ?? '');

    if (!start) errors.push(`Item ${index} in "${day}" has an invalid start time.`);
    if (!end) errors.push(`Item ${index} in "${day}" has an invalid end time.`);

    if (start && end && (start >= end)) {
      errors.push(`Item ${index} in "${day}" end time must be later than start time.`);
    }
  }

  if (props.editSchedule) {
    for (const day in editingSchedule.items) {
      for (let i = 0; i < editingSchedule.items[day].length; i++) {
        validateStartAndEnd(editingSchedule.items[day][i], day, i + 1);
      }
    }
    editingSchedule.override_items.forEach((item, index) => {
      validateStartAndEnd(item, 'Unavailable Dates', index + 1);
    });
  }

  if (errors.length) {
    emit('modalErrors', errors.join("\n"));
    return false;
  } else return true;
}

const addScheduleTimeBlock = (day) => {
  editingSchedule.items[day] = editingSchedule.items[day] ?? [];
  editingSchedule.items[day].push({
    times: ['8:00:00', '20:00:00'],
  });
}

const deleteScheduleTimeBlock = (day, index) => {
  editingSchedule.items[day].splice(index, 1);
}

const addOverrideItem = () => {
  editingSchedule.override_items.push({
    date: (new Date().toISOString().split('T'))[0],
    times: ['8:00:00', '20:00:00'],
  });
}

const deleteOverrideItem = (index) => {
  editingSchedule.override_items.splice(index, 1);
}


</script>