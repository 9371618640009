import { defineStore } from "pinia";
import { useServicesStore } from "@/stores/services";
import { ref } from "vue";
import { useReferenceDataStore } from "@/stores/reference-data.js";


export const useSchedulesStore = defineStore('schedules', () => {
    const servicesStore = useServicesStore();
    const referenceStore = useReferenceDataStore();

    const schedules = ref([]);
    const timezones = ref({});
    const storeInitialized = ref(false);

    const initialize = async () => {
        if (storeInitialized.value) return { status: true }

        const [timezonesLoaded, calendarsLoaded] = await Promise.all([
            referenceStore.getTimezones(),
            getAllSchedules(),
        ]);

        if (timezonesLoaded.status && calendarsLoaded.status) {
            timezones.value = referenceStore.timezones;
            storeInitialized.value = true;
            return { status: true }
        }
        else {
            return { status: false, message: 'Failed to load initial data.' }
        }
    }

    const getAllSchedules = async (initialLoad = false) => {
        if (initialLoad && schedules.value.length) return { status: true }

        const resp = await servicesStore.apiService.getAllCompanySchedules().catch(e=>e);
        if (resp.data?.data?.status) {
            schedules.value = resp.data.data.schedules;
            return { status: true }
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    const createSchedule = async (schedulePayload) => {
        const resp = await servicesStore.apiService.createInternalCalendar(schedulePayload).catch(e=>e);
        if (resp.data?.data?.status) {
            await getAllSchedules();
            return { status: true }
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    const updateSchedule = async (schedulePayload) => {
        const resp = await servicesStore.apiService.updateSchedule(schedulePayload).catch(e=>e);
        if (resp.data?.data?.status) {
            await getAllSchedules();
            return { status: true }
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    const deleteSchedule = async (scheduleId) => {
        const resp = await servicesStore.apiService.deleteSchedule(scheduleId).catch(e=>e);
        if (resp.data?.data?.status) {
            await getAllSchedules();
            return { status: true }
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    const genericErrorResponse = (resp) => ({ status: false, message: resp.response?.data?.message || resp.message || 'An unknown error occurred.' });

    return {
        timezones,
        schedules,

        initialize,
        getAllSchedules,
        createSchedule,
        updateSchedule,
        deleteSchedule,
    }
});