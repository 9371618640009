import { defineStore } from "pinia";
import { Component, computed, ComputedRef, reactive, ref, Ref, ShallowRef, shallowRef } from "vue";
import { ValidatorResults, ValidTypes, WizardValidationService } from "@/services/v4/WizardValidationService";
import { Environments } from "@/stores/services";
import { WizardTransformerService, WizardTransformerServiceContract } from "@/services/v4/WizardTransformerService";

export type WizardInputMap = {
    [slideKey: string]: {
        [inputKey: string]: any
    }
}

export type ModuleInput = {
    validation: string,
    component: string,
    name?: string,
    initialData?: any,
    containedKeys?: SlideInputs
}

export type SlideInputs = {
    [slideKey: string]: ModuleInput,
}

export type ModuleConfiguration = {
    name: string,
    key: string,
    inputs?: SlideInputs,
    header?: {
        inputs: SlideInputs,
    },
    payload?: string
}

export type CustomSlideValidator = () => ValidatorResults;

export type WizardComponentMap = {
    [key: string]: Component,
}

// These can not be used as Slide Names for the Wizard
export enum ReservedComponent {
    Header = 'header',
}

/**
 * This store should be kept generic. It can be referenced by Campaign specific components to get Slide info,
 * but should not contain any Campaign-specific logic
 */
export const useWizardStore = defineStore('wizard', () => {
    const initialized: Ref<boolean> = ref(false);
    const configurationId: Ref<number|string|null> = ref(null)

    const configuration: Ref<ModuleConfiguration[]> = ref([]);

    const currentSlideIndex: Ref<number> = ref(0);
    const currentSlideKey: ComputedRef<string> = computed(() => configuration.value?.[currentSlideIndex.value]?.key ?? null);
    const currentSlideComponent = computed(() => slideMap.value?.[currentSlideKey.value] ?? null);

    const slideNameMap: ComputedRef<{ [slideKey: string]: string }> = computed(() => configuration.value?.reduce((output, slide) => {
        return { ...output, [slide.key]: slide.name ?? slide.key }
    }, {}))

    const slideMap: Ref<WizardComponentMap> = ref({});
    const wizardInputMap: WizardInputMap = reactive({});
    const slideCompletionMap: Ref<{ [slideKey: string]: boolean|undefined }> = ref({});

    const headerInputMap: SlideInputs = reactive({});
    const headerComponent: ShallowRef<Component|null> = shallowRef(null);
    const headerConfiguration: Ref<SlideInputs> = ref({});

    const validationService = new WizardValidationService();
    const transformerService: Ref<WizardTransformerServiceContract> = ref(new WizardTransformerService());

    const onFinalIncompleteSlide: ComputedRef<boolean> = computed(() => {
        for (const slideKey in slideMap.value) {
            if (slideKey !== currentSlideKey.value && !slideCompletionMap.value[slideKey]) return false;
        }
        return true;
    });

    const initialize = (newConfigurationId: number|string, wizardConfiguration: ModuleConfiguration[], wizardSlideMap: WizardComponentMap, wizardTransformerService?: typeof WizardTransformerService) => {
        if (configurationId.value === newConfigurationId && initialized.value)
            return { status: true }

        initialized.value = false;
        configurationId.value = newConfigurationId;

        if (!Array.isArray(wizardConfiguration) || !wizardConfiguration.length || !wizardSlideMap) {
            return { status: false, message: `Configuration error in Slide Wizard` };
        }
        if (wizardTransformerService !== undefined) {
            transformerService.value = new wizardTransformerService();
        }

        processConfiguration(wizardConfiguration);
        processSlideComponentMap(wizardSlideMap);
        const { status, message } = checkSlideComponentsExist();

        if (status) {
            initializeInputs()
            setToFirstSlide();
            initialized.value = true;
        }

        return { status, message }
    }

    /**
     * Currently only extracts Custom Header inputs from modules
     */
    const processConfiguration = (wizardConfiguration: ModuleConfiguration[]) => {
        wizardConfiguration.forEach(config => {
            if (config[ReservedComponent.Header]?.inputs) {
                Object.entries(config[ReservedComponent.Header].inputs).forEach(([ inputKey, inputConfig]) => {
                    headerConfiguration.value[inputKey] = inputConfig;
                });
            }
        });
        configuration.value = wizardConfiguration;
    }

    /**
     * Any reserved components (like custom header) should be processed here before being removed from the main slide map
     */
    const processSlideComponentMap = (wizardSlideMap: WizardComponentMap) => {
        if (wizardSlideMap[ReservedComponent.Header]) {
            headerComponent.value = { ...wizardSlideMap[ReservedComponent.Header] };
            for (const componentName of Object.values(ReservedComponent)) {
                if (wizardSlideMap[componentName]) delete wizardSlideMap[componentName];
            }
        }
        slideMap.value = wizardSlideMap;
    }

    const checkSlideComponentsExist = () => {
        if (import.meta.env.VITE_ENVIRONMENT === Environments.Production) return { status: true };
        const missingComponents: string[] = [];
        configuration.value.forEach(slideConfiguration => {
            if (!(slideConfiguration.key in slideMap.value)) {
                missingComponents.push(slideConfiguration.key);
            }
        });

        return missingComponents.length
            ? { status: false, message: `The Wizard has no mapping for: ${missingComponents.join(', ')} ` }
            : { status: true }
    }

    const updateCurrentSlide = (index: number) => {
        if (index >= 0 && index <= configuration.value.length - 1) {
            currentSlideIndex.value = index;
        }
    }

    const nextSlide = () => {
        if (configuration.value.length > currentSlideIndex.value)
            updateCurrentSlide(currentSlideIndex.value + 1);
    }

    const previousSlide = () => {
        if (currentSlideIndex.value > 0)
            updateCurrentSlide(currentSlideIndex.value - 1);
    }

    const goToSlide = (slideKey: string) => {
        const targetIndex = configuration.value.findIndex(slide => slide.key === slideKey);
        if (targetIndex >= 0)
            updateCurrentSlide(targetIndex);
    }

    const setToFirstSlide = () => {
        updateCurrentSlide(0);
    }

    const getCurrentSlideComponent = () => {
        return slideMap.value[currentSlideKey.value];
    }

    const processInputLevel = (currentInputs: SlideInputs, parentObject: GenericObject) => {
        Object.entries(currentInputs ?? {}).forEach(([inputKey, inputConfig]) => {
            if (inputConfig.containedKeys) {
                const expectedType = validationService.getExpectedType(inputConfig);
                if (expectedType === ValidTypes.Array) {
                    parentObject[inputKey] = [];
                    parentObject[inputKey].push({});
                    processInputLevel(inputConfig.containedKeys, parentObject[inputKey][parentObject[inputKey].length - 1]);
                }
                else if (expectedType === ValidTypes.Object) {
                    parentObject[inputKey] = {};
                    processInputLevel(inputConfig.containedKeys, parentObject[inputKey]);
                }
            }
            else {
                parentObject[inputKey] = inputConfig.initialData ?? validationService.getDefaultTypeValue(inputConfig);
            }
        });
    }

    const initializeInputs = () => {
        configuration.value.forEach(slideConfig => {
            if (!slideConfig.inputs) return;

            wizardInputMap[slideConfig.key] = {};
            processInputLevel(slideConfig.inputs, wizardInputMap[slideConfig.key]);

            if (slideConfig[ReservedComponent.Header]) {
                processInputLevel(slideConfig[ReservedComponent.Header].inputs, headerInputMap)
            }
        });
    }

    const getInitialSlideData = computed(() => {
        return wizardInputMap[currentSlideKey.value] ?? {};
    });

    const getInitialHeaderData = () => {
        return headerInputMap;
    }

    const checkInputsAreMapped = (mappedKeys: string[], expectedInputs: SlideInputs) => {
        const unmappedKeys: string[] = [];
        for (const key in expectedInputs) {
            if (!mappedKeys.includes(key)) unmappedKeys.push(key);
        }

        return unmappedKeys.length
            ? { status: false, message: `The current slide fails to map the keys: ${unmappedKeys.join(', ')}`}
            : { status: true }
    }

    const updateWizardInput = (newValue: any, inputKey: string) => {
        if (inputKey in wizardInputMap[currentSlideKey.value]) {
            wizardInputMap[currentSlideKey.value][inputKey] = newValue;
        }
    }

    const updateWizardHeader = (newValue: any, inputKey: string) => {
        if (inputKey in headerInputMap) {
            headerInputMap[inputKey] = newValue;
        }
    }

    const getInputName = (inputKey: string, slideKey?: ReservedComponent|string) => {
        slideKey = slideKey ?? currentSlideKey.value;
        const targetInputs = slideKey === ReservedComponent.Header
            ? headerConfiguration.value
            : configuration.value.find(config => config.key === currentSlideKey.value)?.inputs;

        return targetInputs?.[inputKey]?.name ?? 'Input';
    }

    const getSlideName = (slideKey?: string) => {
        slideKey = slideKey ?? currentSlideKey.value;
        return configuration.value.find(slide => slide.key === slideKey)?.name ?? "Slide";
    }

    const markSlideValid = (isValid: boolean, slideKey?: string): void => {
        slideKey = slideKey ?? currentSlideKey.value;
        slideCompletionMap.value[slideKey] = isValid;
    }

    //TODO: handle validation of nested objects and arrays as per initializeInputs
    const validateSlide = (slideKey?: string): ValidatorResults => {
        slideKey = slideKey ?? currentSlideKey.value;
        const validationResult: ValidatorResults = { valid: true };

        const data = slideKey === ReservedComponent.Header
            ? headerInputMap
            : JSON.parse(JSON.stringify(wizardInputMap[slideKey]));
        const slideInputs: SlideInputs|undefined = slideKey === ReservedComponent.Header
            ? headerConfiguration.value
            : configuration.value.find(slide => slide.key === slideKey)?.inputs;

        if (!slideInputs) return validationResult;

        for (const inputKey in slideInputs) {
            const transformedData = transformerService.value.transformPayload(slideKey, inputKey, data[inputKey]);
            const { valid, errorBag } = validationService.validate(slideInputs[inputKey], transformedData ?? null);
            if (!valid) {
                validationResult.errorBag = validationResult.errorBag ?? [];
                validationResult.errorBag.push(...(errorBag || []));
                validationResult.valid = false;
            }
            else {
                validationResult.data = validationResult.data ?? {};
                validationResult.data[inputKey] = transformedData;
            }

            markSlideValid(valid, slideKey);
        }

        return validationResult;
    }

    const isSlideValid = (slideKey?: string): boolean => {
        slideKey = slideKey || currentSlideKey.value;
        return !!slideCompletionMap.value[slideKey];
    }

    /**
     * Final validation for all slides and reserved components
     */
    const validateWizard = () => {
        const headerValidation = validateSlide(ReservedComponent.Header);
        if (!headerValidation.valid)
            return headerValidation;

        const runningValidation: {[ slideKey: string]: string[] } = {};
        for (const slideKey in slideMap.value) {
            if (slideKey !== currentSlideKey.value) {
                const { valid, errorBag, message } = validateSlide(slideKey);
                if (!valid && message) runningValidation[slideKey] = errorBag ?? [message];
            }
        }

        if (Object.keys(runningValidation).length) {
            const errorMessage = Object.entries(runningValidation).reduce((outputString, [slideKey, errorBag]) => {
                const slideName = getSlideName(slideKey);
                return outputString + `${slideName}: ${errorBag.join("\n")}\n`;
            }, 'The following errors were found:\n');
            return { valid: false, message: errorMessage }
        }
        else {
            return { valid: true }
        }
    }

    const fetchReservedComponents = (): GenericObject => {
        return {
            [ReservedComponent.Header]: JSON.parse(JSON.stringify(headerInputMap))
        }
    }

    const isFinalSlide = (slideKey?: string): boolean => {
        slideKey = slideKey || currentSlideKey.value;
        const index = configuration.value.findIndex(slide => slide.key === slideKey);
        return index + 1 === configuration.value.length;
    }

    const fetchInputValue = (slideKey: string, inputKey: string) => {
        if (slideKey === ReservedComponent.Header) {
            return headerInputMap[inputKey] ?? null
        }

        return wizardInputMap[slideKey]?.[inputKey] ?? null;
    }

    const fetchSlideInputValues = (slideKey?: string) => {
        slideKey = slideKey ?? currentSlideKey.value;
        if (slideKey === ReservedComponent.Header) {
            return headerInputMap;
        }

        return wizardInputMap[slideKey];
    }

    const setSlideInputValue = (slideKey: string, inputKey: string, newValue: any, allowCreateMissing?: false) => {
        if (slideKey === ReservedComponent.Header) {
            if (inputKey in headerInputMap || allowCreateMissing) {
                headerInputMap[inputKey] = newValue;
            }
        }
        else {
            if (wizardInputMap[slideKey] && (inputKey in wizardInputMap[slideKey] || allowCreateMissing)) {
                return wizardInputMap[slideKey][inputKey] = newValue;
            }
        }
    }

    const getPayloadKey = (slideKey?: string): string | null => {
        slideKey = slideKey ?? currentSlideKey.value;

        return configuration.value.find(slide => slide.key === slideKey)?.payload ?? null;
    }

    const resetInputs = () => {
        initializeInputs();
        setToFirstSlide();
    }

    const loadInputs = (loadInputValues: GenericObject) => {
        resetInputs();
        const validSlideKeys = Object.keys(slideMap.value);
        for (const slideKey of validSlideKeys) {
            if (slideKey in loadInputValues) {
                wizardInputMap[slideKey] = loadInputValues[slideKey];
            }
        }
        for (const baseKey in loadInputValues) {
            if (baseKey in headerInputMap) {
                headerInputMap[baseKey] = loadInputValues[baseKey];
            }
        }

        markAllSlidesValid();
    }

    const markAllSlidesValid = () => {
        for (const key in slideMap.value) markSlideValid(true, key);
    }

    const resetWizard = () => {
        slideCompletionMap.value = {};
        resetInputs();
    }

    return {
        initialized,
        currentSlideKey,
        currentSlideIndex,
        currentSlideComponent,
        headerInputMap,
        wizardInputMap,
        validationService,
        slideNameMap,
        headerComponent,
        headerConfiguration,
        onFinalIncompleteSlide,
        configuration,
        slideMap,
        slideCompletionMap,
        transformerService,

        initialize,
        getCurrentSlideComponent,
        getInitialSlideData,
        getInitialHeaderData,
        checkInputsAreMapped,
        updateWizardInput,
        updateWizardHeader,
        getInputName,
        getSlideName,
        validateSlide,
        setToFirstSlide,
        nextSlide,
        previousSlide,
        goToSlide,
        isSlideValid,
        isFinalSlide,
        fetchInputValue,
        fetchSlideInputValues,
        getPayloadKey,
        validateWizard,
        fetchReservedComponents,
        setSlideInputValue,
        resetInputs,
        loadInputs,
        resetWizard,
    }
});