import { defineStore } from "pinia";
import { reactive, ref, toRaw } from "vue";
import { useServicesStore } from "@/stores/services";

export const useCompanyLicenseStore = defineStore('companyLicenses', () => {
    const servicesStore = useServicesStore();

    const licenses = ref([]);

    const editingLicense = reactive({
        id: null,
        name: null,
        type: null,
        url: null,
        license_number: null,
        country: null,
        state: null,
        issued_at: null,
        expires_at: null,
        industry: null,
    });

    const storeInitialized = ref(false);

    async function initialize() {
        if (storeInitialized.value) return;
        await get();
        storeInitialized.value = true;
    }

    async function get() {
        const resp = await servicesStore.apiService.getCompanyLicenses().catch(e => e);
        if (resp.data?.data?.status) {
            licenses.value = resp.data.data.licenses;
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function save() {
        return await editingLicense.id
            ? update()
            : create();
    }

    async function create() {
        const resp = await servicesStore.apiService.createCompanyLicense(toRaw(editingLicense)).catch(e => e);
        if (resp.data?.data?.status) {
            licenses.value.push(resp.data.data.license);
            clearEditing();
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function update() {
        const resp = await servicesStore.apiService.updateCompanyLicense(editingLicense.id, toRaw(editingLicense)).catch(e => e);
        if (resp.data?.data?.status) {
            const targetLicense = getLicenseById(editingLicense.id);
            Object.assign(targetLicense, resp.data.data.license);
            clearEditing();
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function destroy(licenseId) {
        const resp = await servicesStore.apiService.deleteCompanyLicense(licenseId).catch(e => e);
        if (resp?.data?.data?.status) {
            licenses.value = licenses.value.filter(license => license.id !== licenseId);
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    function getLicenseById(licenseId) {
        return licenses.value.find(license => license.id === licenseId);
    }

    function getLicenseName(licenseId) {
        const target = getLicenseById(licenseId);
        return target?.name ?? 'Unknown License';
    }

    async function editLicense(licenseId) {
        if (!storeInitialized) await initialize();
        const target = getLicenseById(licenseId);
        if (target) {
            Object.assign(editingLicense, target);
        }
    }

    function clearEditing() {
        Object.assign(editingLicense, {
            id: null,
            name: null,
            type: null,
            url: null,
            license_number: null,
            country: null,
            state: null,
            issued_at: null,
            expires_at: null,
            industry: null,
        });
    }

    function clearStore() {
        storeInitialized.value = false;
        licenses.value = [];
    }

    function genericErrorMessage(resp) {
        return { status: false, message: resp.data?.data?.message || resp.response?.data?.message || resp.err || `An unknown error occurred fetching data.` };
    }

    function $reset() {
        clearStore();
        clearEditing();
    }

    return {
        licenses,
        get,
        editingLicense,
        initialize,
        editLicense,
        getLicenseById,
        save,
        destroy,
        getLicenseName,
        clearEditing,
        $reset,
    }

});