export const AppointmentTypes = {
    IN_HOME: 'In-Home',
    ONLINE: 'Online'
}

export const BUDGET_TYPE_NO_LIMIT = 'no_limit';
export const BUDGET_TYPE_DAILY_APPOINTMENTS = 'avg_daily_appointments';
export const BUDGET_TYPE_DAILY_SPEND = 'avg_daily_spend';


export const BudgetValueTypes = [
    {
        label: 'No Limit',
        value: BUDGET_TYPE_NO_LIMIT
    },
    {
        label: 'Average Daily Appointments',
        value: BUDGET_TYPE_DAILY_APPOINTMENTS
    },
    {
        label: 'Average Daily Spend',
        value: BUDGET_TYPE_DAILY_SPEND
    },
];

export const BudgetCategory = {
    VERIFIED: 'verified'
}