<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
                </svg>

                <h5 class="text-md">Notification Settings</h5>
            </div>
        </template>

        <div class="relative min-h-[24rem]">
            <LoadingSpinner v-if="loading || saving || !storesInitialized" :small="true" />
            <div v-if="!loading && storesInitialized" class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div v-for="user in notificationSettingsStore.editingNotificationsArray"
                     class="border-b border-gray-200 pb-4 mt-2"
                    :key="user.id"
                >
                    <div class="flex flex-col">
                        <h3 class="font-md">{{ user.name }}</h3>

                        <h4 class="text-gray-500 uppercase text-xs font-medium mt-4">Reviews</h4>
                        <div class="grid grid-cols-2 items-center mt-1 border-b border-gray-200 pb-4">
                            <div class="flex items-center text-sm">
                                <Checkbox
                                    :disabled="!user.cell_phone"
                                    v-model="user.alerts.reviews.sms"
                                    label="SMS"
                                />
                            </div>
                            <div class="flex items-center text-sm">
                                <Checkbox
                                    :disabled="!user.email"
                                    v-model="user.alerts.reviews.email"
                                    label="Email"
                                />
                            </div>
                        </div>

                        <h4 class="text-gray-500 uppercase text-xs font-medium mt-4">Invoices</h4>
                        <div class="grid grid-cols-2 items-center mt-1 border-b border-gray-200 pb-4">
                            <div class="flex items-center text-sm">
                                <Checkbox
                                    :disabled="!user.cell_phone"
                                    v-model="user.alerts.invoices.sms"
                                    label="SMS"
                                />
                            </div>
                            <div class="flex items-center text-sm">
                                <Checkbox
                                    :disabled="!user.email"
                                    v-model="user.alerts.invoices.email"
                                    label="Email"
                                />
                            </div>
                        </div>

                        <h4 class="text-gray-500 uppercase text-xs font-medium mt-4">Outbids</h4>
                        <div class="grid grid-cols-2 items-center mt-1 pb-2">
                            <div class="flex items-center text-sm">
                                <Checkbox
                                    :disabled="!user.cell_phone"
                                    v-model="user.alerts.outbid.sms"
                                    label="SMS"
                                />
                            </div>
                            <div class="flex items-center text-sm">
                                <Checkbox
                                    :disabled="!user.email"
                                    v-model="user.alerts.outbid.email"
                                    label="Email"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="errorMessage" class="mt-4 py-4">
            <p class="text-red-900 text-sm text-center">
                {{ errorMessage }}
            </p>
        </div>
        <div class="flex mt-4">
            <SolidButton class="ml-auto"
                :class="[ saving ? 'pointer-events-none grayscale-[70%] opacity-50' : '' ]"
                @click="saveNotificationSettings"
            >Save</SolidButton>
        </div>

    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import Checkbox from "@/components/inputs/Checkbox.vue";
import { ref } from "vue";
import { useNotificationSettingsStore } from "@/stores/notification-settings.js";
import { useErrorStore } from "@/stores/errors.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const props = defineProps({
    storesInitialized: {
        type: Boolean,
        default: false
    },
});

const notificationSettingsStore = useNotificationSettingsStore();
const errors = useErrorStore();

const loading = ref(false);
const saving = ref(false);

const errorMessage = ref(null);

const saveNotificationSettings = async () => {
    if (saving.value) return;
    errorMessage.value = null;
    saving.value = true;

    try {
        const { status, message } = await notificationSettingsStore.set();
        if (!status) {
            errors.report(message);
            errorMessage.value = message;
        }   
    } catch (e) {
        errors.report(e, 'There was an error saving notification settings, please try again or contact support.');
    }

    saving.value = false;
}

</script>