import {ref} from 'vue';
import {defineStore} from 'pinia'
import { ENVIRONMENT_ENUM } from "@/sentry.js";

export const useConfigStore = defineStore('config', () => {
    const debug = ref(import.meta.env.VITE_DEBUG ?? true);
    const environment = ref(import.meta.env.VITE_ENVIRONMENT ?? Environments.Development);
    const appUrl = ref(import.meta.env.VITE_APP_URL ?? "http://localhost:5173");

    const apiDriver = ref(import.meta.env.VITE_API_DRIVER ?? "dummy");
    const apiDummyDelay = ref(Number(import.meta.env.VITE_API_DUMMY_DELAY ?? 250));
    const apiBaseUrl = ref(import.meta.env.VITE_API_BASE_URL ?? "http://localhost:8000/api");

    const stripePublishableKey = ref(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ?? null);

    const googleMapsApiKey = ref(import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? null);

    const fixrUrl = ref(import.meta.env.VITE_FIXR_URL ?? "https://dev.fixr.com");

    const defaultSupportEmail = ref(import.meta.env.VITE_DEFAULT_SUPPORT_EMAIL ?? "support@fixr.com");
    const defaultSupportPhone = ref(import.meta.env.VITE_DEFAULT_SUPPORT_PHONE ?? "555-555-5555");
    const defaultSupportMeetingUrl = ref(import.meta.env.VITE_DEFAULT_SUPPORT_MEETING_URL ?? "https://example.meeting.url");

    const watchdogUrl = ref(import.meta.env.VITE_WATCHDOG_URL ?? "https://api.dev.tcpawatchdog.com/");
    const watchdogPlaybackUrl = ref(import.meta.env.VITE_WATCHDOG_PLAYBACK_URL ?? "/v1/playback/");

    return {
        debug,
        environment,
        appUrl,

        apiDriver,
        apiDummyDelay,
        apiBaseUrl,

        stripePublishableKey,

        googleMapsApiKey,

        fixrUrl,

        defaultSupportEmail,
        defaultSupportPhone,
        defaultSupportMeetingUrl,

        watchdogUrl,
        watchdogPlaybackUrl
    }
})