import { defineStore } from "pinia";
import { reactive, Ref, ref } from "vue";
import { useServicesStore } from "@/stores/services";
import { ApiResponse, BaseApiServiceV4 } from "@/services/api/v4/BaseApiServiceV4";
import { useProperCase } from "@/composables/useProperCase";
import { useFutureCampaignStore } from "@/stores/v4/future-campaigns";
import { CampaignSlide } from "@/stores/v4/campaign-modules";
import { ReservedComponent } from "@/stores/v4/wizard";

//TODO
export type BiddingPriceStore = GenericObject;

export enum QualityTier {
    Standard = 'Standard',
    Premium = 'Premium',
}

export enum SaleType {
    Exclusive = 'Exclusive',
    Duo = 'Duo',
    Trio = 'Trio',
    Quad = 'Quad',
    EmailOnly = 'Email Only',
    Unverified = 'Unverified',
}

export enum SaleTypeKey {
    Exclusive = 'exclusive',
    Duo = 'duo',
    Trio = 'trio',
    Quad = 'quad',
    EmailOnly = 'email_only',
    Unverified = 'unverified',
}

export enum PropertyType {
    Residential = 'Residential',
    Commercial = 'Commercial',
}

export type SaleTypePrices = {
    bid_price: number,
    floor_price: number,
    minimum_price?: number,
    initial_bid_price: number,
}

export type SaleTypePriceCollection = {
    [saleTypeKey: string]: SaleTypePrices,
}

type StatePriceCollection = {
    [countyKey: string]: SaleTypePriceCollection
}

type QualityTierPriceCollection = {
    [stateKey: string]: StatePriceCollection
}

type PropertyTypePriceCollection = {
    [qualityTypeKey: string]: QualityTierPriceCollection
}

type ProductTypePriceCollection = {
    [propertyTypeKey: string]: PropertyTypePriceCollection
}

type PriceCollection = {
    [productTypeKey: string]: ProductTypePriceCollection
}

export type LocationBidPayload = {
    bid: number,
    location_id: number,
    property_type: PropertyType,
    quality_tier: QualityTier,
    is_state_bid: boolean,
    state_location_id: number,
}

export type LocationBidCollection = {
    [saleTypeKey: string]: LocationBidPayload[]
}

export const useBiddingStore = defineStore('bidding', () => {
    const services = useServicesStore();
    const campaignStore = useFutureCampaignStore();

    const bidPrices: PriceCollection = reactive({});

    const cachedPriceRanges: Ref<GenericObject|null> = ref(null);

    const loadStateFloorPrices = async (propertyType: PropertyType, stateLocationKey: string): Promise<DataResponse> => {
        const cached = fetchBidPrices(propertyType, stateLocationKey, 'state');
        if (Object.keys(cached).length)
            return { status: true, data: cached };

        const campaignReference = campaignStore.editingCampaign.reference;
        const resp = campaignReference
            ? await services.apiServiceV4.getStateBidPrices(stateLocationKey, campaignReference).catch(e => e)
            : await services.apiServiceV4.getStateFloorPrices(stateLocationKey, campaignStore.zipCodeCampaign).catch(e => e);
        if (resp.data?.data?.status) {
            transformAndStoreBidPrices(resp.data.data.prices);
            return { status: true, data: fetchBidPrices(propertyType, stateLocationKey, 'state') }
        }
        else
            return transformErrorResponse(resp);
    }

    const loadCountyFloorPrices = async (propertyType: PropertyType, stateLocationKey: string, countyLocationKey: string): Promise<DataResponse> => {
        const cached = fetchBidPrices(propertyType, stateLocationKey, countyLocationKey);

        if (Object.keys(cached).length)
            return { status: true, data: cached };

        const campaignReference = campaignStore.editingCampaign.reference;
        const resp = campaignReference
            ? await services.apiServiceV4.getCountyBidPrices(stateLocationKey, countyLocationKey, campaignReference, propertyType).catch(e => e)
            : await services.apiServiceV4.getCountyFloorPrices(stateLocationKey, countyLocationKey, campaignStore.zipCodeCampaign).catch(e => e);
        if (resp.data?.data?.status) {
            transformAndStoreBidPrices(resp.data.data.prices);
            return { status: true, data: fetchBidPrices(propertyType, stateLocationKey, countyLocationKey) }
        }
        else
            return transformErrorResponse(resp);
    }

    const fetchBidPrices = (propertyType: PropertyType, stateLocationKey: string, countyLocationKey: string) => {
        const productName = useProperCase(services.apiServiceV4.getProductKey());
        return bidPrices[productName]?.[propertyType]?.[stateLocationKey]?.[countyLocationKey] ?? {};
    }

    const transformAndStoreBidPrices = (prices: PriceCollection) => {
        for (const productName in prices) {
            bidPrices[productName] = bidPrices[productName] ?? {};
            for (const propertyType in prices[productName]) {
                bidPrices[productName][propertyType] = bidPrices[productName][propertyType] ?? {};
                for (const stateKey in prices[productName][propertyType]) {
                    bidPrices[productName][propertyType][stateKey] = bidPrices[productName][propertyType][stateKey] ?? {};
                    const statePrices = prices[productName][propertyType][stateKey].state ?? bidPrices[productName][propertyType][stateKey].state;
                    for (const countyKey in prices[productName][propertyType][stateKey]) {
                        bidPrices[productName][propertyType][stateKey][countyKey] = bidPrices[productName][propertyType][stateKey][countyKey] ?? {};
                        for (const qualityTier in prices[productName][propertyType][stateKey][countyKey]) {
                            bidPrices[productName][propertyType][stateKey][countyKey][qualityTier] = bidPrices[productName][propertyType][stateKey][countyKey][qualityTier] ?? {};
                            for (const saleTypeKey in prices[productName][propertyType][stateKey][countyKey][qualityTier]) {
                                bidPrices[productName][propertyType][stateKey][countyKey][qualityTier][saleTypeKey] = prices[productName][propertyType][stateKey][countyKey][qualityTier][saleTypeKey] ?? {};

                                // Record the initial bid price to track changes from Bidding Table
                                // Also set a minimum bid for loaded counties - minimum bid is the highest number from
                                //  the county's floor price, and the parent state-level bid
                                for (const saleTypeKey in bidPrices[productName][propertyType][stateKey][countyKey][qualityTier]) {
                                    bidPrices[productName][propertyType][stateKey][countyKey][qualityTier][saleTypeKey].initial_bid_price = bidPrices[productName][propertyType][stateKey][countyKey][qualityTier][saleTypeKey].bid_price;
                                    if (statePrices && countyKey !== 'state') {
                                        bidPrices[productName][propertyType][stateKey][countyKey][qualityTier][saleTypeKey].minimum_price = statePrices[qualityTier][saleTypeKey].bid_price ?? 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    const getStateBid = (stateKey: string, propertyType: PropertyType, qualityTier: string, saleType: SaleTypeKey): number => {
        return bidPrices[campaignStore.getProductLabel(false)]?.[propertyType]?.[stateKey]?.state?.[qualityTier]?.[saleType]?.bid_price ?? 0;
    }

    const getPriceRangeForZipCodes = async (): Promise<DataResponse> => {
        const zipCodes = campaignStore.fetchModuleInputValue(CampaignSlide.Location, 'zip_codes');
        const propertyTypes = campaignStore.fetchModuleInputValue(ReservedComponent.Header, 'property_types');
        const zipCodeTargeted = campaignStore.zipCodeCampaign;

        const resp = await services.apiServiceV4.getPriceRangeForZipCodes(zipCodes, propertyTypes, zipCodeTargeted).catch(e=>e);
        if (resp.data?.data?.status)
            return { status: true, data: resp.data.data.prices ?? {} }
        else
            return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const $reset = () => {
        for (const key in bidPrices) delete bidPrices[key];
    }

    const transformErrorResponse = (response: ApiResponse|Error) => {
        return (response instanceof Error)
            ? ({ status: false, message: response.message || 'An unknown error occurred.' })
            : ({ status: false, message: response?.data?.data?.message || 'An unknown error occurred.' });
    }

    return {
        bidPrices,
        cachedPriceRanges,

        loadStateFloorPrices,
        loadCountyFloorPrices,
        fetchBidPrices,
        getStateBid,
        getPriceRangeForZipCodes,

        $reset,
    }
});