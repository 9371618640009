<template>
    <label class="relative inline-block"
        :class="[
           small ? 'w-[2.5rem] h-[1.25rem] rounded-[0.5rem]' : 'w-16 h-8 rounded-[1rem]',
        ]"
    >
        <input
            type="checkbox"
            class="opacity-0 w-0 h-0 bg-red-600 bg-green-600"
            :checked="!!modelValue"
            @change="updateModelValue"
        />
        <span
            class="transition-all duration-300 absolute cursor-pointer top-0 left-0 bottom-0 right-0
                 before:transition-all before:duration-300 before:absolute before:bg-slate-50"
            :class="[
                modelValue ? `bg-${color}-500` : `bg-slate-400`,
                small
                    ? `before:h-[0.9rem] before:w-[0.9rem] before:left-[0.175rem] before:bottom-[0.175rem] rounded-[0.625rem] before:rounded-[0.6255rem]`
                    : `before:h-[1.6rem] before:w-[1.6rem] before:left-[0.2rem] before:bottom-[0.2rem] rounded-[1rem] before:rounded-[1rem]`,
                modelValue
                    ? small
                        ? 'before:translate-x-5'
                        : 'before:translate-x-8'
                    : '',
            ]"
        />
    </label>
</template>

<script setup>

const props = defineProps({
    modelValue: {
        type: [Boolean, Number],
        default: false,
    },
    small: {
        type: Boolean,
        default: false,
    },
    color: {
        type: String,
        default: 'cyan',
        // If adding more colours, also add to the invisible input element so Tailwind loads the class
        validator: (value) => [ 'cyan', 'red', 'slate', 'green' ].includes(value) ? value : 'cyan',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits([ 'update:modelValue' ]);

const updateModelValue = ({ target }) => {
    emit('update:modelValue', target.checked);
}

</script>