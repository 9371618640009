import {defineStore} from "pinia";
import {reactive, ref, watch} from "vue";
import {useServicesStore} from "@/stores/services";
import {useCompanyStore} from "@/stores/company";
import { useReferenceDataStore } from "@/stores/reference-data";

export const useClaimLeadsStore = defineStore('claim-leads',
    () => {

        const productPriceTypes = [
            {
                value: '',
                label: 'All'
            },
            {
                value: 'exclusive',
                label: 'Exclusive',
            },
            {
                value: 'duo',
                label: 'Duo',
            },
            {
                value: 'trio',
                label: 'Trio',
            },
            {
                value: 'quad',
                label: 'Quad',
            }
        ];

        const searchOptions = reactive({
            price_from: '',
            price_to: '',
            zip_code: '',
            city: ''
        });

        const isPurchaseSuccessAlertVisible = ref(false);
        const loading = ref(false);
        const unsoldProducts = ref([]);
        const industryServiceOptions = ref([]);
        const paginationLimit = ref(0);
        const paginationTotal = ref(0);
        const paginationOffset = ref(0);
        const customerSelectedLeads = ref(JSON.parse((new URLSearchParams(window.location.search)).get('selectedProducts')) ?? []);
        const hasSelectedAllPageLeads = ref(false);
        const api = useServicesStore().apiService;
        const company = useCompanyStore();
        const errorMessage = ref(null);
        const defaultErrorMessage = ref('An error has occurred while loading products.');

        const getUnsoldProducts = async (page = 1) => {
            if (loading.value) {
                return;
            }
            errorMessage.value = null;
            loading.value = true;
            const { data, error, message } = await api
                .getUnsoldProducts(
                    {
                        page: page,
                        per_page: 20,
                        ...searchOptions
                    },
                    true,
                    customerSelectedLeads.value
                )
                .catch(error => {
                    const message = error?.response?.data?.message || defaultErrorMessage.value;
                    return {error, message};
                })
            if (error) {
                populateError(message || error.message);
            }
            else if (data?.data?.status) {
                const productData = data.data.products_paginated;
                if (productData !== undefined) {
                    let {data, ...pagination} = productData;
                    unsoldProducts.value = data ?? [];
                    paginationTotal.value = pagination.total;
                    paginationLimit.value = pagination.per_page;
                    paginationOffset.value = (pagination.current_page - 1) * pagination.per_page;
                    hasSelectedAllPageLeads.value = false;
                    errorMessage.value = null;
                }
                else {
                    populateError(data?.data?.message);
                }
            }
            else {
                populateError(data?.data?.message);
            }
            loading.value = false;
        }

        const clearSearchOptions = () => {
            Object.assign(searchOptions, {
                date_range: null,
                price_from: '',
                price_to: '',
                city: '',
                price_type: '',
                page: 1,
                zip_code: ''
            });
        }

        const checkProduct = (productId, checked) => {
            if (checked) {
                if(!customerSelectedLeads.value.includes(productId)) {
                    customerSelectedLeads.value.push(productId)
                }
            } else {
                customerSelectedLeads.value = customerSelectedLeads.value.filter((value) => productId !== value)
            }
        };

        const reserveUnsoldProducts = async (companyId) => {
            return await api.reserveUnsoldProducts({companyId: companyId, selectedLeads: customerSelectedLeads.value})
        }

        const populateError = (error) => {
            errorMessage.value = error || defaultErrorMessage.value;
            console.error(errorMessage.value)
        }

        const clearCustomerSelectedLeads = () => {
            customerSelectedLeads.value = []
        }

        return {
            loading,
            unsoldProducts,
            paginationLimit,
            paginationTotal,
            paginationOffset,
            getUnsoldProducts,
            productPriceTypes,
            searchOptions,
            clearSearchOptions,
            customerSelectedLeads,
            checkProduct,
            reserveUnsoldProducts,
            hasSelectedAllPageLeads,
            errorMessage,
            industryServiceOptions,
            isPurchaseSuccessAlertVisible,
            clearCustomerSelectedLeads
        }
    },
    {
        persist: false,
    }
)
