<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                </svg>

                <h5 class="text-md">Upload Media</h5>
            </div>
        </template>

        <p>
            Media files will be displayed on your company profile page on the Fixr website. However, all uploaded media
            will be reviewed by the Fixr team before being displayed.
        </p>

        <p class="mt-2 italic text-sm text-gray-500">
            Permitted file format: jpg, jpeg or png. Files must be less than 8MB.<br>
            Please note that media cannot contain contact details.
        </p>

        <div class="mt-4">
            <FileUpload
                accept=".jpg,.jpeg,.png"
                @file-uploaded="handleFileDrop"
                :multi="true"
                ref="fileUploadComponent"
            />
        </div>

        <div class="mt-4">
            <p class="text-cyan-500 text-sm">
                By uploading files into the Fixr dashboard, you warrant that your company is either the owner of the
                copyright of the files or has authorization from the owner of the copyright to use these files to
                display on the Fixr website. You agree to indemnify Fixr from any claims by third parties relating to
                the ownership, use or copyright of these files.
            </p>
            <p v-if="fileDropError || uploadError" class="text-red-900 text-center text-sm py-4">
                {{ fileDropError || uploadError }}
            </p>
            <div class="mt-4 flex justify-end">
                <SolidButton
                    :disabled="updating || fileDropError"
                    @click="uploadFile"
                >
                    Upload
                </SolidButton>
            </div>
        </div>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import FileUpload from "@/components/inputs/FileUpload.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { ref } from "vue";
import { useCompanyMediaStore } from "@/stores/company-media.js";
import { useErrorStore } from "@/stores/errors.js";

const companyMediaStore = useCompanyMediaStore();
const errors = useErrorStore();

const fileUploadComponent = ref(null);

const files = ref([]);
const updating = ref(false);

const fileDropError = ref(null);
const uploadError = ref(null);

const maxFileSize = 8000000;

const handleFileDrop = (data) => {
    files.value = data;
    fileDropError.value = null;
    for (const fileInfo in files.value) {
        if (files.value[fileInfo]?.size > maxFileSize) {
            fileDropError.value = `One of your images is larger than the maximum allowed 8MB size.`;
            break;
        }
    }
}

const uploadFile = async () => {
    if (updating.value) return;
    updating.value = true;
    fileDropError.value = null;
    uploadError.value = null;
    const { status, message } = await companyMediaStore.upload(files.value);
    if (!status) {
        fileDropError.value = null;
        uploadError.value = message;
        errors.report(message);
    }
    else {
        clearFileList();
    }
    updating.value = false;
}

const clearFileList = () => {
    fileUploadComponent.value.clearFileList();
    files.value = [];
}


</script>