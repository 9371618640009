<template>
    <div class="m-4">
        <BiddingControl :store-initialized="storesInitialized" />
    </div>
</template>

<script setup>
import BiddingControl from "@/components/leads/BiddingControl.vue";
import { useCampaignStore } from "@/stores/campaigns.js";
import { onBeforeMount, ref } from "vue";
import { useLocalityDataStore } from "@/stores/locality-data.js";
import Products from "@/Utilities/Products";

const campaignStore = useCampaignStore();
const localityDataStore = useLocalityDataStore();
const storesInitialized = ref(false);

onBeforeMount(async () => {
    await Promise.all([
        campaignStore.setProduct(Products.LEAD).then(()=> campaignStore.initialize()),
        localityDataStore.initialize(),
    ]);
    const defaultCampaign = campaignStore.campaigns[0];
    if (defaultCampaign && !campaignStore.editingCampaign.id) {
        await campaignStore.editCampaign(defaultCampaign.id);
    }
    storesInitialized.value = true;
});

</script>
