<template>
    <div class="py-1 px-2 rounded-md inline-flex font-bold text-xs"
         :class="[
                color === 'blue' ? (darkMode ? 'bg-dark-border text-blue-400' : 'bg-blue-100 text-blue-900') : '',
                color === 'cyan' ? (darkMode ? 'bg-dark-border text-cyan-400' : 'bg-cyan-200 text-cyan-900') : '',
                color === 'green' ? (darkMode ? 'bg-dark-border text-emerald-400' : 'bg-emerald-100 text-emerald-900') : '',
                color === 'red' ? (darkMode ? 'bg-dark-border text-rose-400' : 'bg-rose-100 text-rose-900') : '',
                color === 'orange' ? (darkMode ? 'bg-dark-border text-orange-400' : 'bg-orange-100 text-orange-900') : '',
                color === 'amber' ? (darkMode ? 'bg-dark-border text-amber-400' : 'bg-amber-100 text-amber-900') : '',
                color === 'purple' ? (darkMode ? 'bg-dark-border text-purple-400' : 'bg-purple-100 text-purple-900') : '',
                color === 'indigo' ? (darkMode ? 'bg-dark-border text-indigo-400' : 'bg-indigo-100 text-indigo-900') : '',
                color === 'gray' ? (darkMode ? 'bg-dark-border text-gray-400' : 'bg-gray-100 text-gray-900') : '',
                ]">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Badge",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'blue'
        }
    }
}
</script>

<style scoped>

</style>
