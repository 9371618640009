import {reactive, ref} from "vue";
import { defineStore } from "pinia";
import { useServicesStore } from "@/stores/services";

export const useCostumerReviewsStore = defineStore('costumer-reviews', () => {
    const servicesStore = useServicesStore();

    const reviews = ref([]);
    const reviewLink = ref('');
    const reviewsCount = ref('');
    const overallReviewsCount = ref(0);
    const countBreakdown = ref({});
    const loading = ref(false);
    const allReviews = ref([]);
    const paginationLimit = ref(0);
    const paginationTotal = ref(0);
    const paginationOffset = ref(0);
    const errorMessage = ref(null);
    const defaultErrorMessage = ref('An error has occurred while loading customer reviews.');

    const storeInitialized = ref(false);

    const searchOptions = reactive({
        sort_by: '',
        status: '',
        score: '',
        text: ''
    });
    async function initialize() {
        if (storeInitialized.value) return { status: true }
        let params = {
            limit: 2,
        }
        const resp = await servicesStore.apiService.getRecentReviews(params).catch(e=>e);
        if (resp.data?.data?.status) {
            reviews.value = resp.data.data.reviews;
            reviewLink.value = resp.data.data.review_link;
            storeInitialized.value = true;
            return { status: true }
        }
        else {
            return { status: false, message: resp.response?.data?.message || resp.err || `An unknown error occurred.`}
        }
    }

    async function getOverallRatings() {
        const resp = await servicesStore.apiService.getCompanyRating();
        if (resp) {
            overallReviewsCount.value = resp.data?.data?.rating?.average_score ?? 0;
            reviewsCount.value = resp.data?.data?.review_count ?? 0;
            countBreakdown.value = resp.data?.data?.rating?.data?.count_breakdown ?? {};
            countBreakdown.value = Object.fromEntries(Object.entries(countBreakdown.value).reverse());
        }
    }
    const getAllCostumerReviews = async (page = 1) => {
        if (loading.value) {
            return;
        }
        errorMessage.value = null;
        loading.value = true;

        const { data, error, message } = await servicesStore.apiService.getAllCustomerReviews(
            {
                page: page,
                per_page: 10,
                ...searchOptions
            },
            true
        ).catch(error => {
            const message = error?.response?.data?.message || defaultErrorMessage.value;
            return {error, message};
        })

        if (data?.data?.status) {
            const reviewsData = data.data.reviews_paginated;
            if (reviewsData !== undefined) {
                let {data, ...pagination} = reviewsData;
                allReviews.value = data ?? [];
                paginationTotal.value = pagination.total;
                paginationLimit.value = pagination.per_page;
                paginationOffset.value = (pagination.current_page - 1) * pagination.per_page;
                errorMessage.value = null;
            }
        }
        loading.value = false;
    }
    const clearSearchOptions = () => {
        Object.assign(searchOptions, {
            sort_by: '',
            status: '',
            score: '',
            text: ''
        });
    }
    async function postReviewReply(reply, reviewId) {
        const payload = {
            reply: reply,
        }
        const resp = await servicesStore.apiService.postReviewReply(reviewId, payload);
        return resp?.data?.data?.review_replies;
    }

    return {
        initialize,
        loading,
        reviews,
        reviewLink,
        reviewsCount,
        getOverallRatings,
        overallReviewsCount,
        getAllCostumerReviews,
        allReviews,
        paginationLimit,
        paginationTotal,
        paginationOffset,
        countBreakdown,
        searchOptions,
        clearSearchOptions,
        postReviewReply,
    }

});