import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "@/stores/auth";
import {useCompanyStore} from "@/stores/company";

import LoginView from '../views/LoginView.vue'
import LoginWithTokenView from '../views/LoginWithTokenView.vue'
import TermsAndConditions from "@/views/TermsAndConditions.vue";
import Home from "@/views/dashboard/Home.vue";
import Overview from "@/views/company/Overview.vue";
import LogoAndDescription from "@/views/company/LogoAndDescription.vue";
import UploadMedia from "@/views/company/UploadMedia.vue";
import Addresses from "@/views/company/Addresses.vue";
import Contacts from "@/views/company/Contacts.vue";
import Notifications from "@/views/company/Notifications.vue";
import BillingAndPayments from "@/views/billing/BillingAndPayments.vue";
import PaymentMethods from "@/views/billing/PaymentMethods.vue";
import LeadsOverview from '@/views/leads/LeadsOverview.vue';
import Campaigns from '@/views/leads/LeadsCampaigns.vue';
import Bidding from '@/views/leads/LeadsBidding.vue';
import MissedLeads from '@/views/leads/LeadsMissed.vue';
import CompanyProfitabilityView from "@/views/profitability/CompanyProfitabilityView.vue";
import LicensesCertifications from "@/views/company/LicensesCertifications.vue";
import NoServiceSelected from "@/views/NoServiceSelected.vue";
import AppointmentsOverview from '@/views/appointments/AppointmentsOverview.vue';
import AppointmentsSchedules from "@/views/appointments/AppointmentsSchedules.vue";
import CustomerReviews from "@/views/customer_reviews/CustomerReviews.vue";
import { useCampaignModulesStore } from "@/stores/v4/campaign-modules";
import { useAlertStore } from "@/stores/v4/alerts";
import { useNavigationStore } from "@/stores/v4/navigation";
import { useProductConfigurationStore } from "@/stores/v4/product-configuration";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import DirectLeadsOverview from "@/components/v4/direct-leads/DirectLeadsOverview.vue";
import DirectLeadsCampaigns from "@/components/v4/direct-leads/DirectLeadsCampaigns.vue";
import BiddingModule from "@/components/v4/campaigns/modules/BiddingModule.vue";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/login',
            name: 'login',
            component: LoginView,
            meta: {requiresAuth: false}
        },
        {
            path: '/login-with-token',
            name: 'login-with-token',
            component: LoginWithTokenView,
            meta: {shadowing: true}
        },
        {
            path: '/terms-and-conditions',
            name: 'terms-and-conditions',
            component: TermsAndConditions,
            meta: {requiresAuth: true}
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: ResetPasswordView,
            meta: {requiresAuth: false}
        },
        {
            path: '/',
            name: 'dashboard',
            component: Home,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/dashboard',
            name: 'dashboard-alt',
            component: Home,
            meta: {requiresAuth: true, requiresIndustryService:true}
        },
        {
            path: '/company-profitability',
            name: 'company-profitability',
            component: CompanyProfitabilityView,
            meta: {requiresAuth: true}
        },
        {
            path: '/no-service-selected',
            name: 'no-service-selected',
            component: NoServiceSelected,
            meta: {requiresAuth: true}
        },

        // Leads Routes
        {
            path: '/leads',
            name: 'leads',
            component: LeadsOverview,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/leads-campaigns',
            name: 'leads-campaigns',
            component: Campaigns,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/leads-bidding',
            name: 'leads-bidding',
            component: Bidding,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/leads-missed',
            name: 'leads-missed',
            component: MissedLeads,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/appointments',
            name: 'appointments',
            component: AppointmentsOverview,
            meta: {requiresAuth: true, requiresIndustryService: true, requiresAppointmentsActive: true}
        },
        {
            path: '/appointments-schedules',
            name: 'appointments-schedules',
            component: AppointmentsSchedules,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/direct-leads',
            name: 'direct-leads',
            component: DirectLeadsOverview,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/direct-leads-campaigns',
            name: 'direct-leads-campaigns',
            component: DirectLeadsCampaigns,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/direct-leads-bidding',
            name: 'direct-leads-bidding',
            component: BiddingModule,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },

        // Company Routes
        {
            path: '/company-profile',
            name: 'company-profile',
            component: Overview,
            meta: {requiresAuth: true}
        },
        {
            path: '/logo-and-description',
            name: 'logo-and-description',
            component: LogoAndDescription,
            meta: {requiresAuth: true}
        },
        {
            path: '/upload-media',
            name: 'upload-media',
            component: UploadMedia,
            meta: {requiresAuth: true}
        },
        {
            path: '/addresses',
            name: 'addresses',
            component: Addresses,
            meta: {requiresAuth: true}
        },
        {
            path: '/contacts',
            name: 'contacts',
            component: Contacts,
            meta: {requiresAuth: true}
        },
        {
            path: '/notification-settings',
            name: 'notification-settings',
            component: Notifications,
            meta: {requiresAuth: true}
        },
        {
            path: '/licenses-certifications',
            name: 'licenses-certifications',
            component: LicensesCertifications,
            meta: {requiresAuth: true}
        },

        // Billing & Payments
        {
            path: '/billing-and-payments',
            name: 'billing-and-payments',
            component: BillingAndPayments,
            meta: {requiresAuth: true}
        },
        {
            path: '/payment-methods',
            name: 'payment-methods',
            component: PaymentMethods,
            meta: {requiresAuth: true}
        },

        //Customer reviews
        {
            path: '/customer-reviews',
            name: 'customer-reviews',
            component: CustomerReviews,
            meta: {requiresAuth: true, requiresReviewsActive: true}
        }


    ]
});

export const updateRoutesForV4 = async () => {
    const company = useCompanyStore();
    const navigationStore = useNavigationStore();

    if (company.futureCampaignsActive) {
        const moduleStore = useCampaignModulesStore();
        const alertStore = useAlertStore();
        const productConfigStore = useProductConfigurationStore();
        const [ moduleStatus, configStatus ] = await Promise.all([
            moduleStore.initialize(),
            productConfigStore.initialize(),
        ]);

        if (!moduleStatus)
            alertStore.showDevError(moduleStatus.message ?? "Failed to initialize Module Store.");
        else if (!configStatus)
            alertStore.showDevError(configStatus.message ?? "Failed to initialize Product Configuration Store.");

        const futureComponents = moduleStore.moduleComponentNavigationMap;
        futureComponents.forEach((moduleComponent) => {
            if (navigationStore.updateNavigationItem(moduleComponent)) {
                router.removeRoute(moduleComponent.id);
                router.addRoute({
                    path: `/${moduleComponent.id}`,
                    name: moduleComponent.id,
                    component: moduleComponent.component,
                    meta: moduleComponent.meta ?? {},
                });
            }
            else {
                alertStore.showDevError(`Failed to update NavLink or route ${moduleComponent.route}`);
            }
        });

        return await new Promise(res => {
            setTimeout(() => {
                const currentName = router.currentRoute.value.name;
                if (currentName && futureComponents.map(item => item.id).includes(currentName)) {
                    router.push({ name: currentName });
                }
                res(true);
            }, 0);
        });
    }

    return true;
}

// Navigation Guard
router.beforeEach(async (to) => {
    if (to.meta.shadowing === true) return;

    const auth = useAuthStore();
    const authenticated = auth.isSessionValid();
    const company = useCompanyStore();

    if (to.meta.requiresIndustryService && !company.selectedService) {
        return company.autoSelectIndustryService()
            ? to
            : {name: 'no-service-selected'};
    }

    else if (to.meta.requiresAuth && !authenticated)
        return {name: 'login'};

    else if (authenticated && !company.contractAccepted && to.name !== 'terms-and-conditions')
        return {name: 'terms-and-conditions'}

    else if (!to.meta.requiresAuth && authenticated)
        return {name: 'leads'};

    else if (to.meta.requiresAppointmentsActive && !company.appointmentsActive)
        return {name: 'leads'};

    else if (to.meta.requiresReviewsActive && !company.reviewsEnabled)
        return {name: 'leads'};
});

export default router
