<template>
    <Modal :showConfirmButton="false"
            @clicked:cancel="closeEditUserModal"
            cancelLabel="Back"
    >
        <template v-slot:header>
            <h5 class="text-md">Review {{ review.id }}</h5>
        </template>
        <template v-slot:body>
            <div class="flex border-b">
                <h5 class="text-md">Details</h5>
            </div>
            <div class="flex border divide-x bg-cyan-50">
                <div class="p-6 flex-grow">
                    <p class="font-semibold text-xs mb-1 uppercase text-slate-500">
                        Overall Rating:
                    </p>
                    <div class="inline-flex items-center">
                        <Stars :score="review.score"></Stars>
                        <p class="font-semibold text-sm ml-2">
                            {{ review.review_data.overall_score }}/5
                        </p>
                    </div>
                </div>
                <div class="p-6 flex-grow">
                    <p class="font-semibold text-xs mb-1 uppercase text-slate-500">
                        Review By:
                    </p>
                    <p class="font-semibold text-sm">
                        {{ review.reviewer_name }}
                    </p>
                </div>
                <div class="p-6 flex-grow">
                    <p class="font-semibold text-xs mb-1 uppercase text-slate-500">
                        Review date:
                    </p>
                    <p> {{ formatDate(review.created) }}</p>
                </div>
                <div class="p-6 flex-grow">
                    <p class="font-semibold text-xs mb-1 uppercase text-slate-500">Property zipcode</p>
                    <p> {{ review.review_data['zip_code'] ?? '-' }} </p>
                </div>
                <div class="p-6 flex-grow">
                    <p class="font-semibold text-xs mb-1 uppercase text-slate-500">Location</p>
                    <p> {{ review.review_data['location'] ?? '-' }} </p>
                </div>
            </div>
            <div class="p-3"></div>
            <div class="grid grid-cols-2">
                <div class="border-r overflow-y-auto h-[20rem] p-6">
                    <div class="mb-4" :style="{ whiteSpace: 'pre-line' }">
                        <p class="font-semibold mb-2">
                                {{ review.title }}
                        </p>
                        <p> {{ review.comment }} </p>
                    </div>
                    <div v-for="reply in reviewReplies" class="border-l pl-4 mb-4 opacity-[80%] border-light-border">
                        <p class="font-semibold mb-1 text-sm">
                            {{reply.name}}
                        </p>
                        <p>{{reply.comments}}</p>
                    </div>
                    <div v-if="!replying" class="inline-flex items-center gap-3">
                        <p v-if="reviewReplies.length === 0" class="text-slate-500 text-sm">No replies yet</p>
                        <p v-else-if="reviewReplies.length === 1" class="text-slate-500 text-sm">1 Reply</p>
                        <p v-else class="text-slate-500 text-sm">{{reviewReplies.length}} Replies</p>
                        <SolidButton @click="toggleReplyBox">Reply</SolidButton>
                    </div>
                    <div v-else class="mt-6">
                        <p class="text-slate-500 text-sm mb-1">Replying to {{review.reviewer_name}}</p>
                        <textarea v-model="reply"
                                  placeholder="Start typing your message here..."
                                  class="w-full h-full p-4 focus:outline-none rounded-md border focus:ring-0 focus:border-primary-500  focus:shadow-lg focus:shadow-primary-500/10 bg-light-background border-light-border"></textarea>
                        <div class="inline-flex items-center mt-3 gap-3">
                            <SolidButton @click="postReply">Post Reply</SolidButton>
                            <SolidButton color="slate-inverse" @click="toggleReplyBox">Cancel</SolidButton>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-2">
                    <table class="w-full border divide-y text-sm">
                        <tr class="bg-cyan-50">
                            <th class="text-left p-4">System Details</th>
                            <th class="p-4"></th>
                        </tr>
                        <tr>
                            <td class="p-4">System Size (kW)</td>
                            <td class="p-4">-</td>
                        </tr>
                        <tr>
                            <td class="p-4">System Price</td>
                            <td class="p-4">-</td>
                        </tr>
                        <tr>
                            <td class="p-4">FTC or Incentives?</td>
                            <td class="p-4">-</td>
                        </tr>
                        <tr>
                            <td class="p-4">Year installed</td>
                            <td class="p-4">-</td>
                        </tr>
                        <tr>
                            <td class="p-4">Panel Brand</td>
                            <td class="p-4">-</td>
                        </tr>
                    </table>
                    <table class="border w-full divide-y text-sm">
                        <tr class="bg-cyan-50">
                            <th class="text-left p-4">Review Breakdown</th>
                            <th class="p-4">-/5</th>
                        </tr>
                        <tr>
                            <td class="p-4">Sales process</td>
                            <td class="p-4">-</td>
                        </tr>
                        <tr>
                            <td class="p-4">Price Charged as Quoted</td>
                            <td class="p-4">-</td>
                        </tr>
                        <tr>
                            <td class="p-4">On Schedule</td>
                            <td class="p-4">-</td>
                        </tr>
                        <tr>
                            <td class="p-4">Quality of Installation</td>
                            <td class="p-4">-</td>
                        </tr>
                        <tr>
                            <td class="p-4">After Sales Support</td>
                            <td class="p-4">-</td>
                        </tr>
                    </table>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import Stars from "@/components/shared/Stars.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import {formatDate} from "@/Utilities/Helpers.js";
import {ref} from "vue";
import {useUserStore} from "@/stores/user";
import {useCostumerReviewsStore} from "@/stores/customer-reviews";

const user = useUserStore();
const costumerReviewStore = useCostumerReviewsStore();

const props = defineProps({
    review: {
        type: Object,
        default: {}
    },
});

const replying = ref(false);
const reply = ref('');
const reviewReplies = ref(props.review.review_replies);

const emit = defineEmits([ 'hideCustomerReviewModal' ]);

const closeEditUserModal = () => {
    emit('hideCustomerReviewModal');
}

const toggleReplyBox = () => {
    replying.value = ! replying.value;
    reply.value = '';
};

const postReply = () => {
    costumerReviewStore.postReviewReply(reply.value, props.review.id).then(review_replies => {
        reviewReplies.value = review_replies;
    }).catch(e => console.error(e));
    toggleReplyBox();
}

</script>

<style scoped>

</style>