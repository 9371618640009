<template>
    <div class="flex flex-col items-center justify-center min-h-[25vh]">
        <p class="mb-10 w-full">
            Do you want to add the entire county in which these opportunities are, or just the specific zip codes of each opportunity? (We suggest you add counties)
        </p>
        <div class="flex flex-col gap-5 w-full md:w-2/3">
            <SolidButton @click="setServiceAreas('counties')">Counties</SolidButton>

            <SolidButton @click="setServiceAreas('zipCodes')">Zip Codes</SolidButton>
        </div>
    </div>
</template>

<script setup>
import SolidButton from "@/components/inputs/SolidButton.vue";
import {usePurchaseMissedProductsStore} from "@/stores/purchase-missed-products";

const store = usePurchaseMissedProductsStore();

const setServiceAreas = function (serviceAreas) {
    store.editingCampaign.service_areas = serviceAreas;
    store.nextSlide();
}

</script>