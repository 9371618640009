<template>
    <Module>
        <template v-if="!isBiddingSlide" v-slot:title>
            <div class="flex items-center justify-between py-2">
                <div class="flex items-center">
                    <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                    </svg>
                    <h2 class="text-lg font-semibold">Bidding</h2>
                </div>
            </div>
        </template>
        <template v-slot:default>
            <div class="relative min-h-[48rem]">
                <LoadingSpinner v-if="loading || saving" />
                <div v-if="!loading" class="gap-y-3 flex flex-col justify-center"
                    :class="saving ? 'pointer-events-none opacity-50' : ''"
                >
                    <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 gap-x-4 items-center ">
                        <CustomSelect
                            v-if="!isBiddingSlide"
                            label="Campaign"
                            :options="campaignOptions"
                            v-model="selectedCampaign"
                            @update:modelValue="loadCampaign"
                        />
                        <CustomSelect
                            label="Category"
                            :options="productCategoryOptions"
                            v-model="selectedPropertyType"
                        />
                        <CustomSelect
                            label="State"
                            :options="stateOptions"
                            v-model="selectedState"
                            @update:modelValue="getCountyOptions"
                        />
                        <CustomSelect
                            label="County"
                            :options="countyOptions"
                            v-model="selectedCounty"
                        />
                    </div>
                    <div v-if="errorMessage">
                        <p class="text-red-900 text-sm">{{ errorMessage }}</p>
                    </div>
                    <div v-if="loading">
                        <p class="text-gray-500 text-center">Loading...</p>
                    </div>
                    <div v-else class="justify-center gap-y-12">
                        <div v-if="!campaignStore.editingCampaign.id">
                            <p>No campaign selected.</p>
                        </div>
                        <div v-else class="relative">
                            <div
                                v-for="tier in productQualityTierOptions"
                                class="mb-8"
                                :key="tier.value"
                            >
                                <div v-if="qualityTierEnabled(tier.label)">
                                    <div class="flex items-center">
                                        <h4 class="font-semibold py-4 mr-2">{{ tier.label }}</h4>
                                        <Tooltip v-if="tooltips[productScope][tier.label]">{{ tooltips[productScope][tier.label] }}</Tooltip>
                                    </div>
                                    <div
                                        class="md:odd:bg-gray-50 text-gray-600 text-sm md:text-center items-center grid grid-cols-4 max-w-5xl overflow-x-auto divide-x border border-b-0">
                                        <div class="odd:bg-gray-50 border-gray-200 text-center items-center grid gap-x-3">
                                            <div class="bg-gray-200 py-2">
                                                <div class="flex items-center justify-center text-xs text-gray-700 uppercase font-bold">
                                                    <p>Type</p>
                                                </div>
                                            </div>
                                            <div
                                                class="py-3 border-b font-medium"
                                                v-for="productSalesType in getSaleTypeOptions(tier.label)"
                                                :key="productSalesType.id"
                                            >
                                                {{ productSalesType.name }}
                                            </div>
                                        </div>

                                        <div class="odd:bg-gray-50 border-gray-200 text-center items-center grid gap-x-3">
                                            <div class="bg-gray-200 py-2">
                                                <div class="flex items-center justify-center text-xs text-gray-700 uppercase font-bold">
                                                    <p>Available</p>
                                                    <Tooltip>{{ tooltips[productScope].Available}}</Tooltip>
                                                </div>
                                            </div>
                                            <!--                                        todo: statistics-->
                                            <!--                                        <div class="py-3 border-b" v-for="productSalesType in getSaleTypeOptions(tier.label)"-->
                                            <!--                                            :key="`${productSalesType.id}-available`"-->
                                            <!--                                        >-->
                                            <!--                                            {{ productStatistics[tier.label]?.[productSalesType.key]?.available ?? 0 }}-->
                                            <!--                                        </div>-->
                                            <div class="py-3 border-b" v-for="productSalesType in getSaleTypeOptions(tier.label)"
                                                 :key="`${productSalesType.id}-available`"
                                            >
                                                <p class="text-slate-500 text-xs italic inline">Statistics coming soon...</p>
                                            </div>
                                        </div>
                                        <!--   Purchased leads    -->
                                        <div class="odd:bg-gray-50 border-gray-200 text-center items-center grid gap-x-3">
                                            <div class="bg-gray-200 py-2 text-xs text-gray-700 uppercase font-bold">
                                                <p>Purchased</p>
                                            </div>
                                            <div class="py-3 border-b" v-for="productSalesType in getSaleTypeOptions(tier.label)"
                                                 :key="`${productSalesType.id}-purchased`"
                                            >
                                                <!--                                            todo: statistics -->
                                                <!--                                            <p class="inline">{{ productStatistics[tier.label]?.[productSalesType.key]?.purchased ?? 0 }}</p>-->
                                                <!--                                            <p class="inline text-slate-400 ml-2">[{{ getPercentagePurchased(productStatistics[tier.label]?.[productSalesType.key]) }}%]</p>-->
                                                <p class="text-slate-500 text-xs italic inline">Statistics coming soon...</p>
                                            </div>
                                        </div>
                                        <!--   Bid   -->
                                        <div class="odd:bg-gray-50 border-gray-200 text-center items-center grid gap-x-3">
                                            <div class="bg-cyan-50 py-2 text-xs text-gray-700 uppercase font-bold">
                                                <p>Bid</p>
                                            </div>
                                            <div class="py-3 border-b" v-for="productSalesType in getSaleTypeOptions(tier.label)"
                                                 :key="`${productSalesType.id}-bid`"
                                            >
                                                <div>
                                                    <NumberWithSpinners
                                                        :min="activePrices[tier.label]?.[productSalesType.key]?.floor_price ?? '-'"
                                                        :model-value="activePrices[tier.label]?.[productSalesType.key]?.bid_price ?? '-'"
                                                        :number-step="5"
                                                        @update:modelValue="(newBid) => updateBidPrice(newBid, tier.label, productSalesType.key)"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import { onMounted, ref, watch } from "vue";
import NumberWithSpinners from "@/components/inputs/NumberWithSpinners.vue";
import Tooltip from "@/components/Tooltip.vue";
import { useCampaignStore } from "@/stores/campaigns.js";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { useLocalityDataStore } from "@/stores/locality-data.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useProperCase } from "@/composables/useProperCase.js";
import { usePricingStore } from "@/stores/pricing.js";
import {AppointmentTypes} from "@/Utilities/Appointment";

const props = defineProps({
    storeInitialized: {
        type: Boolean,
        default: false,
    },
    isBiddingSlide: {
        type: Boolean,
        default: false
    },
    productScope: {
        type: String,
        default: 'appointments',
    }
});

const campaignStore = useCampaignStore();
const localityDataStore = useLocalityDataStore();
const pricingStore = usePricingStore();

const tooltips = {
    appointments: {
        ['In-Home']: `Face to face meetings with customer`,
        ['Online']: `Zoom / Video call with customer`,
        Available: `It’s possible for you to change your mix of lead types through bidding. If the customer selects they want bids from 2 companies, we will generally attempt to match them with 2 companies (Duo), but with bidding it’s possible to override this preference and begin winning the Duo leads as Exclusive if your bid is higher than the next 2 Duo bids.`,
    },
}

const saving = ref(false);
const loading = ref(false);

const selectedCampaign = ref();
const selectedPropertyType = ref(null);
const selectedState = ref('');
const selectedCounty = ref('');

const campaignOptions = ref([]);
const stateOptions = ref([]);
const countyOptions = ref([]);

const productCategoryOptions = ref([]);
const productQualityTierOptions = ref([]);

const productStatistics = ref({});
const activePrices = ref({});

const errorMessage = ref(null);

onMounted(async () => {
    loading.value = true;
    if (props.isBiddingSlide) {
        await Promise.all([
            campaignStore.setProduct(props.productScope).then(() => campaignStore.initialize()),
            localityDataStore.initialize()
        ]);
        loadAllOptions();
    }
    else {
        watch(() => props.storeInitialized, () => {
            loadAllOptions();
        });
    }
});

// Initial load
const loadAllOptions = async () => {
    getCampaignOptions();
    if (!campaignStore.editingCampaign.id && campaignStore.campaigns[props.productScope].length) {
        loading.value = true;
        await campaignStore.editCampaign(campaignStore.campaigns[props.productScope]?.[0].id);
    }
    initializePricing();
    getStateOptions();
    getCountyOptions();
    getProductOptions();
    await fetchPricingAndStatistics();

    watch(() => selectedState.value, () => {
        fetchPricingAndStatistics();
    });
    watch(() => selectedCounty.value, () => {
        fetchPricingAndStatistics();
    });
    watch(() => selectedPropertyType.value, () => {
        fetchPricingAndStatistics();
    });
}

// Reload options on Campaign change
const loadCampaign = async (uuid) => {
    loading.value = true;
    const { status, message } = await campaignStore.editCampaign(uuid);
    pricingStore.$reset();
    if (status) {
        getProductOptions();
        getStateOptions();
        getCountyOptions();
        await Promise.all([
            getLocationProductStatistics(),
            getLocationPricing(),
        ]);
    }
    else {
        errorMessage.value = message;
    }
    loading.value = false;
}

const initializePricing = () => {
    const output = {};
    campaignStore.campaignConfigData[`${props.productScope}_quality_tiers`].forEach(tier => {
        output[tier.label] = {};
        getSaleTypeOptions(tier.label).forEach(salesType => {
            output[tier.label][salesType.key] = {
                floor_price: 0,
                bid_price: 0,
            }
        });
    });

    activePrices.value = output;
}

const getCampaignOptions = () => {
    campaignOptions.value = campaignStore.campaigns[props.productScope].map(campaign => ({ value: campaign.id, label: campaign.name }) );
    selectedCampaign.value = campaignOptions.value?.[0]?.value ?? null;
}

const getSaleTypeOptions = (qualityTier) => {
    if (qualityTier === AppointmentTypes.IN_HOME) return campaignStore.campaignConfigData.appointments_sales_types.slice(0, -1);

    return campaignStore.campaignConfigData.appointments_sales_types;
};

const qualityTierEnabled = (tier) => {
    return !!campaignStore.editingCampaign.appointment_budgets.find((budget) => budget.quality_tier === tier)?.status;
}

const getProductOptions = () => {
    productQualityTierOptions.value = campaignStore.campaignConfigData[`${props.productScope}_quality_tiers`];
    if (props.productScope === 'leads') {
        productCategoryOptions.value = [];
        campaignStore.editingCampaign.property_types.forEach(categoryId => {
            const category = campaignStore.campaignConfigData[`${props.productScope}_property_types`].find(category => category.id === categoryId);
            if (category) {
                productCategoryOptions.value.push({ value: `${category.id.toString()}`, label: category.name });
            }
        });
    }
    else {
        productCategoryOptions.value = campaignStore.campaignConfigData.appointments_property_types.map(category => ({ label: category.name, value: category.id.toString() }));
    }
    selectedPropertyType.value = productCategoryOptions.value?.[0]?.value.toString() ?? null;
}

const getStateOptions = () => {
    const stateKeys = Object.values(campaignStore.editingCampaign.zip_codes).reduce((output, zipCode) => {
        if (!output.includes(zipCode.state_key)) output.push(zipCode.state_key);
        return output;
    }, []);
    stateOptions.value = stateKeys.map(key => {
        const targetState = localityDataStore.states.find(state => state.state_key === key);
        return ({ value: key, label: targetState.state_name });
    });
    selectedState.value = stateOptions.value[0]?.value ?? null;
}

const getCountyOptions = () => {
    if (selectedState.value) {
        const countyKeys = Object.values(campaignStore.editingCampaign.zip_codes).reduce((output, zipCode) => {
            if (zipCode.state_key === selectedState.value && !output.includes(zipCode.county_key)) output.push(zipCode.county_key);
            return output;
        }, []).sort((a,b) => a > b);
        countyOptions.value = [
            { value: '', label: 'All' },
            ...countyKeys.map(key => ({ value: key, label: useProperCase(key) })),
        ];
    }
    selectedCounty.value = countyOptions.value[0]?.value ?? null;
}

const getLocationProductStatistics = async () => {
    if (!selectedState.value) return;
    const { status, message } = await campaignStore.getBiddingLocationStatistics(selectedState.value, selectedCounty.value, selectedPropertyType.value);
    if (!status) {
        errorMessage.value = message;
    }
    else {
        const countyKey = selectedCounty.value || 'state';
        const propertyTypeKey = campaignStore.getPropertyTypeNameById(props.productScope, selectedPropertyType.value);
        productStatistics.value = campaignStore.productStatistics[props.productScope]?.[propertyTypeKey]?.[selectedState.value]?.[countyKey];
    }
}

const getLocationPricing = async () => {
    if (!selectedState.value) {
        return;
    }
    const { status, message } = await pricingStore.getPricingForLocation(props.productScope, campaignStore.editingCampaign.id, selectedState.value, selectedCounty.value, selectedPropertyType.value);

    if (!status) {
        errorMessage.value = message;
    }
    else {
        const categoryKey = campaignStore.getPropertyTypeNameById(props.productScope, selectedPropertyType.value);
        activePrices.value = pricingStore.prices[props.productScope][categoryKey][selectedState.value][selectedCounty.value || 'state'];
    }
}

const fetchPricingAndStatistics = async () => {
    loading.value = true;
    await Promise.all([
        getLocationProductStatistics(),
        getLocationPricing(),
    ]).catch(e => {
        errorMessage.value = e;
    });
    loading.value = false;
}

const getPercentagePurchased = (saleTypeStatistics) => {
    return (((saleTypeStatistics?.purchased || 0) / (saleTypeStatistics?.available || 0) * 100) || 0).toFixed(1);
}

const updateBidPrice = async (newBid, qualityTier, productSalesKey) => {
    saving.value = true;
    const { status, message } = await pricingStore.updateBidPrice(
        props.productScope,
        campaignStore.editingCampaign.id,
        selectedState.value,
        selectedCounty.value,
        qualityTier,
        selectedPropertyType.value,
        productSalesKey,
        newBid,
    );
    if (!status) {
        errorMessage.value = message;
    }
    else {
        const categoryKey = campaignStore.getPropertyTypeNameById(props.productScope, selectedPropertyType.value);
        activePrices.value = pricingStore.prices[props.productScope][categoryKey][selectedState.value][selectedCounty.value || 'state'];
    }
    saving.value = false;
}

const validateSlide = () => {
return { status: true };
}
defineExpose({ validateSlide });

</script>