<template>
    <Module
        :class="[saving ? 'pointer-events-none grayscale-[50%] opacity-50' : '']"
    >
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>

                <h5 class="text-md">Addresses</h5>
            </div>
        </template>

        <div class="flex justify-end">
            <SolidButton
                @click="toggleAddressModal(true)"
            >
                Add Address
            </SolidButton>
        </div>
        <div class="relative pt-8 pb-4 min-h-[16rem]">
            <div v-if="errorMessage" class="text-red-900 text-sm text-center">
                {{ errorMessage }}
            </div>
            <LoadingSpinner v-if="loading || saving || !addressesStoreInitialized" :small="true" />
            <div v-else class="grid grid-cols-1 gap-y-3 mt-4 border-t border-gray-200 pt-2">
                <div class="border-b border-gray-200 pb-3 flex" v-for="(location) in locationStore.locations">
                    <div>
                        <strong class="flex items-center">
                            {{ location.name }}
                            <span class="ml-1" v-if="location.is_primary">-</span>
                            <span class="ml-1 text-white bg-cyan-500 rounded-full px-2 text-sm" v-if="location.is_primary">Primary</span>
                        </strong>
                        <p>{{ location.address.address_1 }}</p>
                        <p>{{ location.address.address_2 }}</p>
                        <p>{{ location.address.city }}, {{ location.address.state }} {{ location.address.zip_code }}</p>
                    </div>

                    <div class="ml-auto relative">
                        <ButtonDropdown class="">
                            <template v-slot:trigger>
                                <div class="cursor-pointer flex justify-center items-center text-cyan-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                    </svg>
                                </div>
                            </template>

                            <div class="transform translate-y-7 bg-white border border-gray-300 shadow rounded absolute top-0 right-0 z-50 transform p-2 w-36">
                                <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                    @click="editLocation(location.id)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                    <p class="ml-2 pb-0 text-cyan-500">Edit</p>
                                </div>
                                <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                    @click="deleteConfirm(location.id)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                    <p class="ml-2 pb-0 text-cyan-500">Delete</p>
                                </div>
                                <div class="w-full py-1 cursor-pointer inline-flex items-center text-cyan-500"
                                    @click="makeLocationPrimary(location.id)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                    </svg>
                                    <p class="ml-2 pb-0 text-cyan-500">Make Primary</p>
                                </div>
                            </div>
                        </ButtonDropdown>
                    </div>
                </div>
            </div>

        </div>
<!--    Create/Edit Address Modal    -->
        <CreateUpdateAddressModal
            v-if="showAddressModal"
            @hideAddressModal="toggleAddressModal(false)"
        />
<!--    Delete confirmation Modal    -->
        <Modal
            v-if="showDeleteConfirmModal"
            @clicked:confirm="deleteLocation"
            @clicked:cancel="deleteCancel"
            confirm-label="Delete"
            :small="true"
        >
            <template v-slot:header>
                Delete this location?
            </template>
            <template v-slot:body>
                Are you sure you wish to delete location "{{ locationStore.getLocationNameById(deletingAddressId) }}"?
            </template>
        </Modal>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import ButtonDropdown from "@/components/ButtonDropdown.vue";
import { ref } from "vue";
import { useLocationsStore } from "@/stores/locations.js";
import CreateUpdateAddressModal from "@/components/company/modals/CreateUpdateAddressModal.vue";
import Modal from "@/components/Modal.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const props = defineProps({
    addressesStoreInitialized: {
        type: Boolean,
        default: false
    },
});

const showAddressModal = ref(false);
const showDeleteConfirmModal = ref(false);

const loading = ref(false);
const saving = ref(false);

const deletingAddressId = ref(null);

const errorMessage = ref(null);

const locationStore = useLocationsStore();

const editLocation = (locationId) => {
    locationStore.editLocation(locationId);
    toggleAddressModal(true);
}

const deleteConfirm = (locationId) => {
    deletingAddressId.value = locationId;
    showDeleteConfirmModal.value = true;
}
const deleteCancel = () => {
    deletingAddressId.value = null;
    showDeleteConfirmModal.value = false;
}

const deleteLocation = async () => {
    if (saving.value) return;
    saving.value = true;
    const { status, message } = await locationStore.deleteLocation(deletingAddressId.value);
    if (!status) {
        errorMessage.value = message;
    }
    saving.value = false;
    showDeleteConfirmModal.value = false;
}

const makeLocationPrimary = async (locationId) => {
    if (saving.value) return;
    saving.value = true;
    hideButtonDropdown();
    const { status, message } = await locationStore.makeLocationPrimary(locationId);
    if (!status) {
        errorMessage.value = message;
    }
    saving.value = false;
}

const toggleAddressModal = (showModal) => {
    showAddressModal.value = !!showModal;
}

const hideButtonDropdown = () => {
    document.body.click();
}

</script>