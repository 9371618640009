import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { useServicesStore } from "@/stores/services";
import { useCompanyStore } from "@/stores/company.js";

export const useProfitabilityAssumptionsStore = defineStore("profitabilityAssumptions", () => {
    const services = useServicesStore();
    const company = useCompanyStore();

    const scoped = reactive({
       average_lead_revenue: 0,
       labour_materials_cost: 0,
       average_job_cost: 0,
       percentage_leads_successful: 0,
    });

    const configurations = ref([]);

    const storeInitialized = ref(false);

    async function initialize() {
        if (storeInitialized.value) return;
        else await get();
        storeInitialized.value = true;
    }

    async function get() {
        const resp = await services.apiService.getGlobalProfitabilityAssumptions().catch(e => e);
        if(resp.data?.data?.status) {
            configurations.value = resp.data.data.profitability_configurations;
            setScopedConfiguration();
            return { status: true };
        } else return {status: false, message: resp.response?.data?.message || resp.err || `An unknown error occurred.`}
    }

    async function set(payload) {
        const resp = await services.apiService.updateProfitabilityAssumption({
            percentage_leads_successful: payload.percentageLeadsSuccessful ?? scoped.percentage_leads_successful,
            average_lead_revenue: payload.averageLeadRevenue ?? scoped.average_lead_revenue,
            average_job_cost: payload.averageJobCost ?? scoped.average_job_cost,
            labour_materials_cost: payload.labourMaterialsCost ?? scoped.labour_materials_cost
        }).catch(e => e);

        if(resp.data?.data?.status) {
            Object.assign(scoped, resp.data.data.profitability_configuration);
            const targetGlobalConfig = getGlobalConfigurationByServiceId(getScopedServiceId());
            if (targetGlobalConfig) Object.assign(targetGlobalConfig, resp.data.data.profitability_configuration);
            return { status: true };
        } else return { status: false, message: resp.response?.data?.message || resp.err || `An unknown error occurred.` }
    }

    function setScopedConfiguration() {
        const currentServiceId = getScopedServiceId();
        if (currentServiceId) {
           const targetConfig = configurations.value.find(config => config.industry_service_id === currentServiceId);
           Object.assign(scoped, targetConfig ?? {});
        }
    }

    function getGlobalConfigurationByServiceId(serviceId) {
        return configurations.value.find(config => config.industry_service_id === serviceId);
    }

    function getScopedServiceId() {
        return Object.values(company.services).reduce((serviceId, serviceArray) => {
            if (serviceId > 0) return serviceId;
            serviceArray.forEach(service => {
                if (serviceId > 0) return;
                if (service.slug === company.selectedService) serviceId = service.id;
            });
            return serviceId;
        }, 0);
    }

    /**
     * Subscribe to Company $patch changes
     * Set the scoped profitability when the Service Selector changes
     * Reload when a service is added so all profitabilities and leads can be re-acquired
     */
    company.$subscribe(async ({ payload }) => {
        if (payload?.selectedService) {
            setScopedConfiguration();
        }
        else if (payload?.services) {
            location.reload();
        }
    });

    function resetScoped() {
        Object.assign(scoped, {
            average_lead_revenue: 0,
            labour_materials_cost: 0,
            average_job_cost: 0,
            percentage_leads_successful: 0,
        });
    }

    function clearStore() {
        configurations.value = [];
    }

    function $reset() {
        resetScoped();
        clearStore();
    }

    return {
        scoped,
        configurations,
        initialize,
        set,
        $reset,
    }

});