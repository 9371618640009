/**
 * Convert a string to kebab case
 * Will convert camelCase, snake_case, normal whitespace, or a combination of
 * Existing dashes will be left in place
 * @param inputString
 * @returns {string}
 */
export const useKebabCase = (inputString) => {
    if (typeof(inputString) !== 'string') return '';
    return inputString.replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/_+/g, ' ')
        .replace(/[\s-]+/g, '-')
        .toLowerCase();
}