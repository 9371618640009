import {ref, computed} from 'vue';
import {defineStore} from 'pinia';
import {useServicesStore} from "@/stores/services";

export const useUserStore = defineStore('user', () => {
    const services = useServicesStore();

    const id = ref(null);
    const email = ref(null);
    const firstName = ref(null);
    const lastName = ref(null);

    const fullName = computed(() => firstName.value + " " + lastName.value);

    function set(payload) {
        id.value = payload.id;
        email.value = payload.email ?? null;
        firstName.value = payload.firstName;
        lastName.value = payload.lastName;
        services.apiService.setUserId(payload.id);
        services.apiServiceV4.setUserId(payload.id);
    }

    function $reset() {
        id.value = null;
        email.value = null;
        firstName.value = null;
        lastName.value = null;
    }

    return {
        id,
        email,
        firstName,
        lastName,

        fullName,

        set,
        $reset
    };
});