<template>
    <LeadsSearch :stores-initialized="storesInitialized" />
</template>

<script setup>
import LeadsSearch from "@/components/leads/LeadsSearch.vue";
import { useCampaignStore } from "@/stores/campaigns.js";
import { onBeforeMount, ref } from "vue";
import { useLeadsStore } from "@/stores/leads.js";
import Products from "@/Utilities/Products";

const props = defineProps({
    initialSearch: {
        type: Object,
        default: null,
    },
});

const campaignStore = useCampaignStore();
const leadStore = useLeadsStore();

const storesInitialized = ref(false);

onBeforeMount(async () => {
    await campaignStore.setProduct(Products.LEAD)
    await Promise.all([
        campaignStore.initialize(),
        leadStore.initialize(),
    ]);
    storesInitialized.value = true;
});

</script>