<template>
    <Module class="mt-4 mx-4 pb-4 relative min-h-[24rem]">
        <template v-slot:title>
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <svg class="w-6 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <h2 class="text-lg font-semibold">Missed Leads</h2>
                </div>
            </div>
        </template>
        <template v-slot:default>
            <div v-if="!purchaseProductsFlowStore.showCheckout" class="py-5 gap-y-3 flex flex-col justify-center relative min-h-[12rem]">
                <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3 mb-6">
                    <div>
                        <CustomInput
                            v-model="claimLeadsStore.searchOptions.city"
                            label="City"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="claimLeadsStore.searchOptions.zip_code"
                            label="Zip"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="claimLeadsStore.searchOptions.price_from"
                            label="Price from"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="claimLeadsStore.searchOptions.price_to"
                            label="Price to"
                        />
                    </div>
                    <div class="flex flex-col md:flex-row items-end gap-3">
                        <SolidButton @click="searchProducts" classes="w-full h-11 font-semibold">
                            Search
                        </SolidButton>
                        <OutlineButton @click="clearSearch" color="gray" classes="w-full h-11 font-semibold">
                            Reset
                        </OutlineButton>
                    </div>
                </div>
                <div v-if="claimLeadsStore.errorMessage">
                    <p class="text-red-900 text-sm text-center">{{ claimLeadsStore.errorMessage }}</p>
                </div>
                <LoadingSpinner v-if="claimLeadsStore.loading" :small="true" />
                <div class="min-h-[4rem]">
                    <div v-if="claimLeadsStore.unsoldProducts.length > 0">
                        <Pagination
                            :limit="claimLeadsStore.paginationLimit"
                            :total="claimLeadsStore.paginationTotal"
                            :offset="claimLeadsStore.paginationOffset"
                            @change-page="handlePaginationEvent"
                        >
                            <div class="md:grid-cols-9 hidden md:sticky md:top-20 md:grid text-xs text-gray-700 uppercase bg-cyan-50 text-center font-bold py-3 items-center border-b border-gray-200 z-[1]">
                                <div class="col-span-4 px-8 text-left">Lead information</div>
                                <div>Requested Service</div>
                                <div>Appointment Date Requested</div>
                                <div>Quotes Requested / Sold</div>
                                <div>Price</div>
                                <div>
                                    Select
                                    <!--                                    <span class="block">Claim All</span>-->
                                    <!--                                    <custom-checkbox-->
                                    <!--                                        :modelValue="claimLeadsStore.hasSelectedAllPageLeads"-->
                                    <!--                                        @update:modelValue="claimAllLeadsOnPage(claimLeadsStore.unsoldProducts, $event)"-->
                                    <!--                                    />-->
                                </div>
                            </div>
                            <div v-for="product in claimLeadsStore.unsoldProducts"
                                 :key="product.id"
                                 class="md:grid-cols-9 border-b odd:bg-gray-50 text-gray-600 text-sm items-start md:items-center grid grid-cols-2 gap-4 md:gap-0 px-4 py-6 md:py-4 md:px-0" >
                                <div class="px-8 flex col-span-4 gap-2">
                                    <div class="flex-1" v-if="product.consumer_data?.common?.length > 0">
                                        <ConsumerDataDisplayer :fields="product.consumer_data?.common ?? []" @click:field="handleFieldClick" :style-classes="consumerDataStyleClasses"/>
                                    </div>
                                    <div class="flex-1" v-if="product.consumer_data?.configurable?.length > 0">
                                        <ConsumerDataDisplayer :fields="product.consumer_data?.configurable ?? []" :style-classes="consumerDataStyleClasses"/>
                                    </div>
                                </div>
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">Requested Service</p>
                                    <p class="md:text-center">{{product.requested_service}}</p>
                                </div>
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">Appointment Date Requested</p>
                                    <p class="md:text-center">{{ product.date_requested }}</p>
                                </div>
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">Quotes Requested</p>
                                    <p class="md:text-center">{{ product.appointments_requested }} / {{ product.appointments_requested - product.appointments_remaining }}</p>
                                </div>
                                <div>
                                    <p class="font-bold text-sm md:hidden text-gray-900">Price</p>
                                    <p class="md:text-center">${{ product.price }}</p>
                                </div>
                                <div class="col-span-2 md:col-span-1 text-center">
                                    <p class="font-bold text-sm md:hidden text-gray-900 mb-2">Select</p>
                                    <custom-checkbox
                                        :model-value="claimLeadsStore.customerSelectedLeads.includes(product.id)"
                                        @update:modelValue="claimLeadsStore.checkProduct(product.id, $event)"
                                    />
                                </div>
                            </div>
                        </Pagination>

                    </div>
                    <div v-else class="text-center">
                        <p>All available {{company.selectedService}} leads have been purchased by other lead buyers. Try another service.</p>
                    </div>
                </div>

                <SolidButton classes="w-64 mx-auto" @click="purchaseLeads" :disabled="claimLeadsStore.customerSelectedLeads < 1">
                    Buy Selected Leads
                </SolidButton>
            </div>
            <PurchaseStatus :visible="claimLeadsStore.isPurchaseSuccessAlertVisible"
                            payment-status="success"
                            modelHeading="Placed Order"
                            title="Order Processing"
                            paragraph="Your selected payment method will be charged automatically and leads will appear shortly after successful payment."
                            @update:visible="dismissPaymentStatus"
            />
            <PurchaseMissedProductsFlow v-if="purchaseProductsFlowStore.showPurchaseFlow" />
            <Checkout
                v-if="purchaseProductsFlowStore.showCheckout"
                :missed-lead-data="purchaseProductsFlowStore.missedLeadData"
                @checkout-completed="completeCheckout"
                @checkout-cancelled="cancelCheckout"
            />
        </template>
    </Module>
    <Modal v-if="imageModel" :cancel-label="showTargetRoof ? 'Hide Target':'Show Target'" @clicked:cancel="changeTargetVisibility" confirm-label="Close" @clicked:confirm="closeImageModal" modal-classes="inline-block max-w-content">
        <template v-slot:body>
            <div class="flex justify-center relative">
                <img :src="roofImage" alt="lead roof image" />
                <div v-if="showTargetRoof" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 rounded-full bg-red-500 opacity-50"></div>
            </div>
        </template>
    </Modal>
</template>

<script setup>
import {onBeforeUnmount, onMounted, reactive, ref} from "vue";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import Module from "@/components/Module.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {useServicesStore} from "@/stores/services";
import {useCompanyStore} from "@/stores/company";
import {useClaimLeadsStore} from "@/stores/claim-leads.store";
import {useErrorStore} from "@/stores/errors";
import CustomInput from "@/components/inputs/CustomInput.vue";
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import PurchaseMissedProductsFlow from "@/components/checkout/PurchaseMissedProductsFlow.vue";
import {usePurchaseMissedProductsStore} from "@/stores/purchase-missed-products";
import Checkout from "@/components/checkout/Checkout.vue";
import PurchaseStatus from "@/components/billing/PurchaseStatus.vue";
import {useBannerStore} from "@/stores/banner";
import {useRouter} from "vue-router";
import Modal from "@/components/Modal.vue";
import ConsumerDataDisplayer from "@/components/ConsumerDataDisplayer.vue";

const router = useRouter();
const company = useCompanyStore();

// Immediately redirect the user if they don't have missed leads enabled.
if (!company.missedLeadsEnabled) {
    router.push('/leads');
}

const errorStore = useErrorStore();
const api = useServicesStore().apiService;
const bannerStore = useBannerStore();
const claimLeadsStore = useClaimLeadsStore();
const purchaseProductsFlowStore = usePurchaseMissedProductsStore();
const defaultErrorMessage = ref('An error has occurred while loading products.');
const errorMessage = ref(null);
const hasPaymentMethods = ref(false);
const imageModel = ref(false);
const roofImage = ref(null);
const showTargetRoof = ref(false);

const MISSED_LEADS_PAYMENT_METHOD_NEEDED_BANNER_ID = 'missed_lead.need_payment_method';

const missedLeadData = reactive({
    serviceArea: [],
    totalLeadsSelected: 0,
});

onMounted(() => {
    searchProducts();
    validatePaymentMethods();
});

onBeforeUnmount(() => {
    bannerStore.hideBanner(MISSED_LEADS_PAYMENT_METHOD_NEEDED_BANNER_ID)
})

const searchProducts = (page = 1) => {
    claimLeadsStore.getUnsoldProducts(page);
}

const clearSearch = () => {
    claimLeadsStore.clearSearchOptions();
    searchProducts();
}

const handlePaginationEvent = (payload) => {
    searchProducts(payload.page);
}

const openImageModal = (url) => {
    roofImage.value = url
    imageModel.value = true
}

const closeImageModal = () => {
    imageModel.value = false
}

const changeTargetVisibility = () => {
    showTargetRoof.value = !showTargetRoof.value;
}

const claimAllLeadsOnPage = (unsoldProducts, checked) => {
    claimLeadsStore.hasSelectedAllPageLeads = checked;
    for (const product of unsoldProducts) {
        claimLeadsStore.checkProduct(product.id, checked);
    }
}

const purchaseLeads = async () => {
    if (!hasPaymentMethods.value) {
        errorStore.report('At least 1 payment method must be added to your account. Please add one in the Billing & Payments tab.');
    } else {
        purchaseProductsFlowStore.showPurchaseFlow = true;
    }
}

const completeCheckout = () => {
    api.purchaseProducts({
        product_ids: claimLeadsStore.customerSelectedLeads,
        campaign_id: purchaseProductsFlowStore.legacyCampaign,
        deactivate_campaign: !purchaseProductsFlowStore.editingCampaign.active_after_payment,
    }).then(function(resp) {
        purchaseProductsFlowStore.resetCheckoutFlow();
        purchaseProductsFlowStore.showCheckout = false;
        claimLeadsStore.isPurchaseSuccessAlertVisible = true;
    });
}

const dismissPaymentStatus = function () {
    claimLeadsStore.isPurchaseSuccessAlertVisible = false;
    router.push('/leads');
}

const cancelCheckout = () => {
    purchaseProductsFlowStore.showCheckout = false;
    purchaseProductsFlowStore.resetCheckoutFlow();
}


const validatePaymentMethods = async () => {
    const paymentMethodsResp = await api.getPaymentMethods();
    hasPaymentMethods.value = !!paymentMethodsResp.data.data.methods.length;

    if (!hasPaymentMethods.value) {
        bannerStore.showBanner({
            id: MISSED_LEADS_PAYMENT_METHOD_NEEDED_BANNER_ID,
            title: 'You do not currently have a valid payment method on file',
            text: 'You need a valid payment method to purchase missed leads',
            button_text: 'Add payment method',
            callback: function () {
                window.location.href = "/payment-methods";
            }
        })
    }
}

const handleFieldClick = (field) => {
    if (field.key === 'roof_image_url') {
        openImageModal(field.value)
    }
}

const consumerDataStyleClasses = {
    roof_image_url: {
        value: ['cursor-pointer max-w-[6rem] mx-auto']
    },
    default: {
        wrapper: ['mb-2'],
        name: ['font-semibold']
    }
}

</script>

<style scoped>
.products-table thead {
    background: #EAECEE;
}

.products-table th {
    font-weight: 500;
    padding: 9px 0;
}

.products-table td {
    font-size: 14px;
}

.products-table .alternate-row {
    background-color: #F9F9F9;
}

.header-sub-text {
    color: #9AE5FF;
}

</style>