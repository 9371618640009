import { defineStore } from "pinia";
import { useServicesStore } from "@/stores/services";
import { reactive, ref, toRaw } from "vue";

export const useBillingStore = defineStore('billing', () => {
    const serviceStore = useServicesStore();

    const storeInitialized = ref(false);

    const billingConfigData = ref({
        // TODO: fetch from back end
        billing_status_options: [
            { name: 'Any Status', id: '' },
            { name: 'Cancelled', id: 'cancelled' },
            { name: 'Collection', id: 'collection' },
            { name: 'Initial', id: 'initial' },
            { name: 'Paid', id: 'paid' },
            { name: 'Processing', id: 'processing' },
            { name: 'Failed', id: 'failed' }
        ],
        company_summary: {},
    });

    const invoices = ref([]);

    const transactions = ref([]);

    const searchOptions = reactive({
        page: 1,
        date_range: [],
        date_start: null,
        date_end: null,
        status: null,
        invoice_id: null,
    });

    const paginationLimit = ref(0);
    const paginationTotal = ref(0);
    const paginationOffset = ref(0);

    const initialize = async () => {
        if (storeInitialized.value) return { status: true }
        const result = await search({}, true);
        storeInitialized.value = true;
        return result;
    }

    const search = async (page = 1, fetchConfig = false) => {
        searchOptions.page = page;
        if (searchOptions.date_range?.length === 2) {
            [ searchOptions.date_start, searchOptions.date_end ] = searchOptions.date_range;
        }
        const resp = await serviceStore.apiService.searchInvoices(toRaw(searchOptions), fetchConfig).catch(e=>e);
        if (resp.data?.data?.status) {
            invoices.value = resp.data.data.invoices;
            paginationTotal.value = resp.data.data.total;
            paginationLimit.value = resp.data.data.limit;
            paginationOffset.value = resp.data.data.offset;
            if (fetchConfig) {
                Object.assign(billingConfigData.value, resp.data.data.config_data ?? {});
            }
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    const downloadInvoice = async (invoiceId) => {
        const resp = await serviceStore.apiService.downloadInvoice(invoiceId).catch(e=>e);
        if (resp.data?.data?.status) {
            return { status: true, pdf: resp.data.data.pdf, filename: resp.data.data.filename }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    const payInvoiceNow = async (invoiceId) => {
        const resp = await serviceStore.apiService.payInvoiceNow(invoiceId);
        if (resp.data?.data?.status) {
            return {
                status: resp.data?.data?.status,
                paid: resp.data?.data?.paid,
                message: resp.data?.data?.message
            }
        }
        else {
            return { message: 'This payment has failed, please try again or contact your account manager.' }
        }
    }

    const getTransactionsForInvoice = async (invoiceId) => {
        const resp = await serviceStore.apiService.getTransactionsForInvoice(invoiceId).catch(e=>e);
        if (resp.data?.data?.status) {
            transactions.value = resp.data.data.transactions;
            return { status: true };
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    const resetSearch = () => {
        Object.assign(searchOptions, {
            page: 1,
            date_range: [],
            date_start: null,
            date_end: null,
            status: null,
            invoice_id: null,
        });
    }

    const clearStore = () => {
        invoices.value = [];
        transactions.value = [];
        storeInitialized.value = false;
    }

    const genericErrorMessage = (resp) => {
        return { status: false, message: resp.data?.data?.message || resp.err || `An unknown error occurred fetching data.` };
    }

    const $reset = () => {
        resetSearch();
        clearStore();
    }

    return {
        initialize,
        downloadInvoice,
        getTransactionsForInvoice,
        payInvoiceNow,
        billingConfigData,
        invoices,
        transactions,
        searchOptions,
        paginationLimit,
        paginationTotal,
        paginationOffset,
        search,
        $reset
    }

});