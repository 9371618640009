<template>
    <Module class="mt-4 mx-4 relative"
            :class="[ checkoutStore.loading ? 'grayscale-[60%] opacity-50 pointer-events-none' : '']"
    >
        <template v-slot:title>
            <div class="flex items-center justify-between py-2">
                <div class="flex items-center">
                    <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                    </svg>
                    <h2 class="text-lg font-semibold">
                        Checkout
                    </h2>
                </div>
            </div>
        </template>
        <template v-slot:default>
            <div>
                <h2 class="text-lg text-cyan-500 my-3">Purchasing Opportunities</h2>
                <p class="text-sm">Placing this order will automatically create and charge an invoice, viewable in the Billing & Payments screen.</p>
                <p class="text-sm">Shortly after, details will be delivered to your selected contacts and appear in the Leads Overview list.</p>
                <div class="gap-y-4 flex flex-col justify-center relative min-h-[12rem]">
                    <div v-if="!checkoutStore.lineItems.length" class="py-4">
                        No Items found.
                    </div>
                    <div v-else class="pt-8 relative">
                        <div class="relative hidden z-50 md:sticky md:top-20 md:grid md:grid-cols-7 bg-cyan-50 text-center font-bold py-2 items-center border-b border-gray-200 text-xs text-gray-700 uppercase">
                            <div>Item No.</div>
                            <div class="col-span-2">Description</div>
                            <div>
                                <div class="flex flex-col items-center gap-y-2">
                                    <Tooltip>The service name falling under a specific industry type.</Tooltip>
                                    <p>Industry <br/>Service</p>
                                </div>
                            </div>
                            <div>Quantity</div>
                            <div>Price</div>
                            <p>Total Price</p>
                        </div>
                        <div v-for="(lineItem, index) in checkoutStore.lineItems"
                             :key="index"
                             class="relative border-b odd:bg-gray-50 text-gray-600 text-sm md:text-center items-center grid md:grid-cols-7 gap-4 md:gap-0 p-4 md:py-4 md:px-0"
                        >
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">
                                    Item No.
                                </p>
                                <p>
                                    {{ index+1 }}
                                </p>
                            </div>
                            <div class="col-span-2">
                                <p class="font-bold text-sm md:hidden text-gray-900">
                                    Description
                                </p>
                                <p>
                                    {{ lineItem.description }}
                                </p>
                            </div>
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">
                                    Industry Service
                                </p>
                                <p>
                                    {{ lineItem.service }}
                                </p>
                                <p>
                                    ({{ lineItem.industry }})
                                </p>
                            </div>
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">
                                    Quantity
                                </p>
                                <p>
                                    {{ lineItem.quantity }}
                                </p>
                            </div>
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">
                                    Price
                                </p>
                                <p>
                                    ${{ lineItem.price }}
                                </p>
                            </div>
                            <div>
                                <p class="font-bold text-sm md:hidden text-gray-900">
                                    Total Price
                                </p>
                                <p>
                                    ${{ lineItem.totalPrice }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="sticky bottom-0 bg-white flex justify-end px-4 py-6 z-10">
                        <p class="font-bold text-lg mr-3 text-gray-900">
                            Total Amount:
                        </p>
                        <p class="text-lg mr-8 text-gray-900">
                            ${{ checkoutStore.lineItemsTotal ?? 0 }}
                        </p>
                    </div>
                </div>

                <hr class="my-10">

                <div>
                    <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-12">
                        <div v-if="checkoutStore.campaign.active_after_payment" class="text-sm space-y-2">
                            <h2 class="text-lg text-cyan-500 mb-3">Ongoing Campaign Configuration </h2>
                            <p><strong>Name:</strong> {{ checkoutStore.campaign.name }}</p>
                            <p><strong>Service Area Zips:</strong> {{ checkoutStore.campaign.zip_codes.length }}</p>
                            <p v-if="checkoutStore.campaign.daily_limit_type === 'spend'">
                                <strong>Max Daily Spend:</strong> {{ checkoutStore.campaign.max_daily_spend }}
                            </p>
                            <p v-if="checkoutStore.campaign.daily_limit_type === 'leads'">
                                <strong>Max Daily Leads:</strong> {{ checkoutStore.campaign.max_daily_leads }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="py-4 mt-5">
                    <h2 class="text-lg text-cyan-500 mb-3">Payment Method</h2>
                    <div class="hidden md:sticky md:top-20 md:grid md:grid-cols-4 text-xs text-gray-700 uppercase px-4 bg-cyan-50 font-bold py-3 items-center border-b border-gray-200" >
                        <div>Method</div>
                        <div>Brand</div>
                        <div>Name</div>
                        <div>Final digits</div>
                    </div>
                    <div v-if="paymentMethod" class="relative border-b odd:bg-gray-50 text-gray-600 text-sm items-start md:items-center grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-0 px-4 py-6 md:py-4 md:px-4" >
                        <div>
                            <p class="font-bold text-sm md:hidden text-gray-900">Method</p>
                            <p>{{ paymentMethod.method }}</p>
                        </div>
                        <div>
                            <p class="font-bold text-sm md:hidden text-gray-900">Brand</p>
                            <p>{{ paymentMethod.brand }}</p>
                        </div>
                        <div>
                            <p class="font-bold text-sm md:hidden text-gray-900">Name</p>
                            <p>{{ paymentMethod.name }}</p>
                        </div>
                        <div>
                            <p class="font-bold text-sm md:hidden text-gray-900">Final digits</p>
                            <p>{{ paymentMethod.last4 }}</p>
                        </div>
                    </div>
                </div>

                <div class="py-4 mt-5">
                    <h2 class="text-lg text-cyan-500 mb-3">Delivery Contacts</h2>
                    <p class="text-sm text-gray-500 pb-4">These contacts will receive opportunity details:</p>
                    <div class="hidden md:sticky md:top-20 md:grid md:grid-cols-3 text-xs text-gray-700 uppercase px-4 bg-cyan-50 font-bold py-3 items-center border-b border-gray-200" >
                        <div>Name</div>
                        <div>
                            Email
                            <span class="text-cyan-500" v-if="checkoutStore.campaign.delivery_methods.includes('email')">Selected Method</span>
                        </div>
                        <div>
                            SMS
                            <span class="text-cyan-500" v-if="checkoutStore.campaign.delivery_methods.includes('sms')">Selected Method</span>
                        </div>
                    </div>
                    <div v-for="contact in checkoutStore.campaign.contact_deliveries"
                         class="relative border-b odd:bg-gray-50 text-gray-600 text-sm items-start md:items-center grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-0 px-4 py-6 md:py-4 md:px-4" >
                        <div>
                            <p class="font-bold text-sm md:hidden text-gray-900">Name</p>
                            <p>{{ contact.name }}</p>
                        </div>
                        <div
                            :class="[ contact.email ? '' : 'pointer-events-none grayscale-[50%] opacity-50' ]"
                        >
                            <p class="font-bold text-sm md:hidden text-gray-900">Email</p>
                            <div class="flex gap-y-4 gap-x-2">
                                <p class="truncate" :class="[ contact.email_active ? 'text-slate-900 opacity-80' : 'text-slate-400' ]">{{ contact.email ?? "N/A" }}</p>
                            </div>
                        </div>
                        <div
                            :class="[ contact.cell_phone ? '' : 'pointer-events-none grayscale-[50%] opacity-50' ]"
                        >
                            <p class="font-bold text-sm md:hidden text-gray-900">Cell (SMS)</p>
                            <div class="flex gap-y-4 gap-x-2">
                                <p class="truncate" :class="[ contact.sms_active ? 'text-slate-900' : 'text-slate-400' ]">{{ contact.cell_phone ?? "N/A" }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!--    FOOTER    -->
                <div class="sticky bottom-0 bg-white border-t flex justify-between px-4 py-6 mt-6 z-10">
                    <SolidButton @click="confirmCancelCheckout"
                                 label="Cancel"
                                 color="gray"
                    />
                    <SolidButton @click="completeCheckout"
                                 label="Place Order"
                                 :disabled="!checkoutStore.lineItems.length"
                    />
                </div>
                <!--    MODAL   -->
                <Modal v-if="checkoutAlert"
                       @clicked:confirm="closeAlertModal"
                       :show-cancel-button="false"
                       :small="true"
                       confirm-label="Ok"
                >
                    <template v-slot:header>
                        Campaign Editor
                    </template>
                    <template v-slot:body>
                        <p class="text-center whitespace-pre">{{ checkoutAlert }}</p>
                    </template>
                </Modal>
                <Modal
                    v-if="showCancelConfirm"
                    confirm-label="Yes"
                    @clicked:confirm="cancelCheckout"
                    @clicked:cancel="showCancelConfirm = false"
                    :small="true"
                >
                    <template v-slot:header>
                        Cancel Checkout
                    </template>
                    <template v-slot:body>
                        <p class="text-center">Are you sure you wish to cancel? All changes will be lost.</p>
                    </template>
                </Modal>
            </div>
            <LoadingSpinner v-if="checkoutStore.loading" :small="true" />
        </template>
    </Module>
</template>

<script setup>
    import Module from "@/components/Module.vue";
    import { onMounted, ref } from "vue";
    import SolidButton from "@/components/inputs/SolidButton.vue";
    import { useCheckoutStore } from "@/stores/checkout";
    import { useLocationsStore } from "@/stores/locations";
    import Modal from "@/components/Modal.vue";
    import LoadingSpinner from "@/components/LoadingSpinner.vue";
    import Tooltip from "@/components/Tooltip.vue";
    import {useServicesStore} from "@/stores/services";
    import {useErrorStore} from "@/stores/errors";

    const props = defineProps({
        productScope: {
            type: String,
            default: 'leads',
        },
        missedLeadData: {
            type: Object,
            default: {}
        },
    });

    const errors = useErrorStore();
    const services = useServicesStore();
    const checkoutStore = useCheckoutStore();
    const locationsStore = useLocationsStore();

    const checkoutAlert = ref(null);
    const showCancelConfirm = ref(false);
    const paymentMethod = ref(null);

    const emit = defineEmits([
        'checkoutCancelled',
        'checkoutCompleted'
    ]);

    onMounted(async () => {
        checkoutStore.loading = true;
        await loadPaymentMethods()
        await locationsStore
            .initialize()
            .then(() => {
                fetchCompanyDetails();
                fetchCompanyContactDetails();
                fetchLineItems(props.productScope);
            });
        checkoutStore.loading = false;
    });

    const fetchLineItems = (productName = 'leads') => {
        if (productName === 'leads') {
            checkoutStore.getLineItemsPreparedForLead(props.missedLeadData);
        }
    }

    const fetchCompanyContactDetails = () => {
        checkoutStore.getCompanyContactDetails();
    }

    const fetchCompanyDetails = () => {
        checkoutStore.getCompanyDetails();
    }

    const confirmCancelCheckout = () => {
        showCancelConfirm.value = true;
    }

    const cancelCheckout = () => {
        emit('checkoutCancelled');
    }

    const completeCheckout = () => {
        emit('checkoutCompleted');
        checkoutStore.loading = true;
    }

    const closeAlertModal = () => {
        checkoutAlert.value = null;
    }

    const loadPaymentMethods = async () => {
        await services.apiService.getPaymentMethods().then(resp => {
            const [defaultPaymentMethod] = resp.data.data.methods

            paymentMethod.value = defaultPaymentMethod
        }).catch((e) => {
            errors.report(e, "Error fetching payment methods, please contact support.");
        })
    }

</script>

<style scoped>
    .slide-enter-active,
    .slide-leave-active {
      opacity: 1;
      transition: opacity 0.25s ease-in 0s;
    }

    .slide-enter-from,
    .slide-leave-to {
      opacity: 0;
      transition: opacity 0.25s 0s;
    }
</style>