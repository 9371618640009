<template>
    <div v-if="name"
         class="p-5 ml-4 flex items-center justify-center h-12 w-12 border border-cyan-500 rounded-full border-2"
    >
        <span class="text-cyan-500 uppercase font-bold">
            {{ prepareInitials(name) }}
        </span>
    </div>
</template>

<script setup>

    import { useInitials } from "../../composables/useInitials";

    const props = defineProps({
        name: {
            type: String,
            default: ''
        },
    });

    const prepareInitials = (name) => {
        return useInitials(name);
    }

</script>

<style scoped>

</style>