<template>
    <div class="m-4 pb-4">
        <ModuleBasic class="mb-3">
            <div class="py-5 px-3 md:px-6 gap-y-3 flex flex-row items-center justify-between">
                <h2 class="text-lg font-medium">Company Profitability Across {{ profitabilityFilterStore.servicesSelectedDisplay }} Services</h2>
            </div>
        </ModuleBasic>
        <ModuleBasic class="mb-3">
            <IndustryServiceFilters

            />
        </ModuleBasic>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <LeadProfitability :global="true" :services="selectedServices" :stores-initialized="storesLoaded" />
            <LeadTypesPurchased :global="true" :services="selectedServices" />

            <LeadVolume :global="true" :services="selectedServices" :stores-initialized="storesLoaded" />

            <div>
                <MoreLeads />
            </div>
        </div>
    </div>
</template>

<script setup>
import LeadProfitability from "@/components/dashboard/LeadProfitability.vue";
import LeadVolume from "@/components/dashboard/LeadVolume.vue";
import LeadTypesPurchased from "@/components/dashboard/LeadTypesPurchased.vue";
import MoreLeads from "@/components/dashboard/MoreLeads.vue";
import ModuleBasic from "@/components/ModuleBasic.vue";
import IndustryServiceFilters from "@/views/profitability/IndustryServiceFilters.vue";
import { useProfitabilityFilterStore } from "@/stores/profitability-filters.js";
import { onMounted, ref, watch } from "vue";
import { useProfitabilityAssumptionsStore } from "@/stores/profitability-assumptions.js";

const profitabilityFilterStore = useProfitabilityFilterStore();
const profitabilityAssumptionsStore = useProfitabilityAssumptionsStore();

const storesLoaded = ref(false);

const selectedServices = ref(null);

onMounted(async () => {
    await Promise.all([
        profitabilityFilterStore.initialize(),
        profitabilityAssumptionsStore.initialize()
    ]);
    selectedServices.value = profitabilityFilterStore.selectedProfitabilityServices;
    storesLoaded.value = true;
    watch(() => profitabilityFilterStore.selectedProfitabilityServices, (newValue) => {
        selectedServices.value = newValue;
    });
});

</script>