import {useAuthStore} from "@/stores/auth";
import {useCompanyStore} from "@/stores/company";
import {useUserStore} from "@/stores/user";
import {useAppointmentsStore} from "@/stores/appointments";
import {useBillingStore} from "@/stores/billing";
import {useCampaignStore} from "@/stores/campaigns";
import {useCompanyLicenseStore} from "@/stores/company-licenses";
import {useCompanyMediaStore} from "@/stores/company-media";
import {useCompanyUsersStore} from "@/stores/company-users";
import {useErrorStore} from "@/stores/errors";
import {useLeadsStore} from "@/stores/leads";
import {useLocalityDataStore} from "@/stores/locality-data";
import {useLocationsStore} from "@/stores/locations";
import {useNotificationSettingsStore} from "@/stores/notification-settings";
import {usePricingStore} from "@/stores/pricing";
import {useProfitabilityAssumptionsStore} from "@/stores/profitability-assumptions";
import {useProfitabilityFilterStore} from "@/stores/profitability-filters";
import {useReferenceDataStore} from "@/stores/reference-data";
import * as Sentry from "@/sentry";

export function logout () {
    const auth = useAuthStore();
    const company = useCompanyStore();
    const user = useUserStore();
    const appointments = useAppointmentsStore();
    const billing = useBillingStore();
    const campaigns = useCampaignStore();
    const companyLicenses = useCompanyLicenseStore();
    const companyMedia = useCompanyMediaStore();
    const companyUsers = useCompanyUsersStore();
    const errors = useErrorStore();
    const leads = useLeadsStore();
    const localityData = useLocalityDataStore();
    const locations = useLocationsStore();
    const notificationSettings = useNotificationSettingsStore();
    const pricing = usePricingStore();
    const profitabilityAssumptions = useProfitabilityAssumptionsStore();
    const profitabilityFilters = useProfitabilityFilterStore();
    const referenceData = useReferenceDataStore();

    auth.$reset();
    company.$reset();
    user.$reset();
    appointments.$reset();
    billing.$reset();
    campaigns.$reset();
    companyLicenses.$reset();
    companyMedia.$reset();
    companyUsers.$reset();
    errors.$reset();
    leads.$reset();
    localityData.$reset();
    locations.$reset();
    notificationSettings.$reset();
    pricing.$reset();
    profitabilityAssumptions.$reset();
    profitabilityFilters.$reset();
    referenceData.$reset();

    Sentry.removeUserFromContext();
}