<template>
    <div class="flex flex-col items-center justify-center min-h-[25vh]">
        <p class="mb-10 text-center w-full">How would you like to receive the details of the selected opportunities?</p>
        <div class="flex flex-col gap-5 w-full md:w-2/3">
            <SolidButton @click="setDeliveryMethod(['sms', 'email'])">Email & Cell</SolidButton>

            <SolidButton @click="setDeliveryMethod(['sms'])">Cell Only</SolidButton>

            <SolidButton @click="setDeliveryMethod(['email'])">Email Only</SolidButton>
        </div>

        <p @click="showCrmHelp = true" class="text-sm mt-10 text-center w-full text-blue-500 cursor-pointer">CRM Integration</p>
        <p v-if="showCrmHelp" class="text-sm mt-3 text-center w-full">You can configure CRM integration in the dashboard after buying your first batch of opportunities</p>
    </div>
</template>

<script setup>
import SolidButton from "@/components/inputs/SolidButton.vue";
import {usePurchaseMissedProductsStore} from "@/stores/purchase-missed-products";
import {ref} from "vue";

const store = usePurchaseMissedProductsStore();

const setDeliveryMethod = function (deliveryMethod) {
    store.editingCampaign.delivery_methods = deliveryMethod;
    store.nextSlide()
}

const showCrmHelp = ref(false)

</script>