import * as Sentry from '@sentry/vue'

export const ENVIRONMENT_ENUM = {
    PRODUCTION: 'production'
}

// Given vue app and router
// Configure sentry features only for production
export const setup = (app, router) => {
    try {
        const {
            VITE_ENVIRONMENT: env,
            VITE_SENTRY_DSN: dsn,
            VITE_SENTRY_TRACES_SAMPLE_RATE: tracesSampleRate,
            VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE: replaysSessionSampleRate,
            VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: replaysOnErrorSampleRate,
            VITE_APP_URL: appUrl,
            VITE_SAMPLE_RATE: sampleRate
        } = import.meta.env

        if (env === ENVIRONMENT_ENUM.PRODUCTION)
            Sentry.init({
                app,
                dsn,
                integrations: [
                    new Sentry.BrowserTracing({
                        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                        tracePropagationTargets: ['localhost', appUrl, /^\//],
                    }),
                    new Sentry.Replay()
                ],
                sampleRate: parseFloat(sampleRate),
                tracesSampleRate: parseFloat(tracesSampleRate),
                replaysSessionSampleRate: parseFloat(replaysSessionSampleRate),
                replaysOnErrorSampleRate: parseFloat(replaysOnErrorSampleRate)
            });
    } catch (error) {
        console.error(error)
    }
}


// Sets user to sentry's context
export const setUserToContext = ({email, company_user_id}) => {
    try {
        Sentry.setUser({email, id: company_user_id})
    } catch (error) {
        console.error(error)
    }
}


// Removes user from sentry's context
export const removeUserFromContext = () => {
    try {
        Sentry.setUser(null)
    } catch (error) {
        console.error(error)
    }
}