<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                </svg>

                <h5 class="text-md">Years in Business</h5>
            </div>
        </template>

        <div class="relative">
            <LoadingSpinner v-if="saving" :small="true" />
            <p class="text-cyan-500">Enter the year that your company started its business</p>

            <div class="flex items-center mt-4">
                <div class="w-1/4">
                    <p>Year</p>
                </div>

                <input class="ml-16 w-full pl-4 rounded border border-grey-400 py-2" type="text" placeholder="2002"
                    v-model="yearsInBusiness"
                />
            </div>

            <div class="flex items-center mt-4">
                <div class="w-1/4">
                    <p v-if="errorMessage" class="text-red-900 text-sm text-center">{{ errorMessage }}</p>
                </div>

                <SolidButton
                    class="ml-auto"
                    :disabled="saving"
                    @click="updateCompanyProfile"
                >Save</SolidButton>
            </div>
        </div>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { useCompanyStore } from "@/stores/company.js";
import { onMounted, ref } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";


const company = useCompanyStore();

const yearsInBusiness = ref(null);

const saving = ref(false);
const errorMessage = ref(null);

onMounted(() => {
    yearsInBusiness.value = company.yearsInBusiness;
});

const updateCompanyProfile = async () => {
    if (saving.value) return;
    errorMessage.value = null;
    saving.value = true;
    const { status, message } = await company.updateCompanyProfile({
        'year_started_business': yearsInBusiness.value,
    });
    if (!status) {
        errorMessage.value = message
    }
    saving.value = false;
}

</script>