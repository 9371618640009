<template>
    <div>
        <Modal
            @clicked:confirm="updateServices"
            @clicked:cancel="closeModal"
            confirm-label="Add Selected"
        >
            <template v-slot:header>
                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                    </svg>
                    <h5 class="text-md ml-2 font-medium">Add Industries and Services</h5>
                </div>
                <p class="text-sm font-light text-slate-500">Select the Industries and Services that your company offers</p>

            </template>
            <template v-slot:body>
                <div v-if="loading">
                    <p class="text-gray-500 text-center">Loading...</p>
                </div>
                <div v-else
                    :class="[saving ? 'grayscale-[50%] opacity-50 pointer-events-none' : '' ]"
                >
                    <div v-if="errorMessage" class="pb-6">
                        <p class="text-red-900 test-sm whitespace-pre">{{ errorMessage }}</p>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pb-4">
                        <div v-for="([ industryName, servicesGroup ]) in Object.entries(referenceStore.allServicesByIndustry)"
                             :key="industryName"
                        >
                            <p class="mb-1 text-slate-900 font-semibold">{{ industryName }}</p>
                            <div v-for="(service) in servicesGroup"
                                 :key="service.id"
                                 class="ml-2 mb-1"
                            >
                                <div class="flex"
                                     :class="[ findActiveServiceById(service.id) ? 'pointer-events-none grayscale-[50%] opacity-30' : '' ]"
                                >
                                    <div class="relative flex items-center justify-center">
                                        <CustomCheckbox
                                            v-model="selectedServices[service.id]"
                                        />
                                    </div>
                                    <span class="ml-2">{{ service.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import { onMounted, reactive, ref } from "vue";
import { useCompanyStore } from "@/stores/company.js";
import { useReferenceDataStore } from "@/stores/reference-data.js";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";

const companyStore = useCompanyStore();
const referenceStore = useReferenceDataStore();

const selectedServices = reactive({});
const selectedIndustries = reactive({});

const loading = ref(true);
const saving = ref(false);
const errorMessage = ref(null);

const emit = defineEmits(['hideIndustryServiceModal', 'updateServices']);

onMounted(async () => {
    referenceStore.getAllCompanyServices().then(() => {
        updateSelections();
        loading.value = false
    });
});

const updateSelections = () => {
    for (const industry in referenceStore.allServicesByIndustry) {
        const industryName = industry.toLowerCase();
        selectedIndustries[industryName] = companyStore.industries.includes(industryName);
        referenceStore.allServicesByIndustry[industry].forEach(service => {
            selectedServices[service.id] = findActiveServiceById(service.id);
        });
    }
}

const findActiveServiceById = (serviceId) => {
    for (const industry in companyStore.services) {
        for (let i=0; i<companyStore.services[industry].length; i++) {
            if (companyStore.services[industry][i].id === serviceId) return true;
        }
    }
    return false;
}

const updateServices = async () => {
    saving.value = true;
    const payload = Object.entries(selectedServices).reduce((output, [serviceId, selected]) => selected ? [...output, serviceId] : output, []);
    const { status, message } = await companyStore.updateCompanyIndustryServices(payload);
    if (!status) {
        errorMessage.value = message;
    }
    else {
        closeModal();
    }
    saving.value = false;
}

const closeModal = () => {
    emit('hideIndustryServiceModal')
}

</script>