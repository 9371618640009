import {defineStore} from "pinia";
import {ref, Ref} from "vue";
import { Environments } from "@/stores/services";

export enum AlertType {
    Log   = 'log',
    Info    = 'info',
    Error   = 'error',
}

type Alert = {
    message: string,
    type: AlertType,
    duration: number,
}

export const useAlertStore = defineStore('alerts', () => {

    const alertActive: Ref<boolean> = ref(false);
    const alertMessage: Ref<string | null> = ref(null);
    const alertType: Ref<AlertType> = ref(AlertType.Log);

    const alertQueue: Ref<Alert[]> = ref([]);
    const queueIdle: Ref<boolean> = ref(true);

    const alertWasClosed: Ref<boolean> = ref(false);

    const defaultAlertType: AlertType = AlertType.Log;
    const defaultAlertDuration: number = 5000;
    const maxAlertsQueued: number = 2; // Stop queueing alerts if too many are queued (does not include active alert)
    const animationDuration: number = 500; // should match the total animation time for GlobalAlerts.vue in style.css <style>

    /**
     * Provide clearQueue = true to immediately clear the queue and show the provided message
     * @param message
     * @param alertType
     * @param clearQueue
     * @param duration
     */
    const showAlert = (message: string, alertType?: AlertType, clearQueue = false,  duration?: number): void => {
        if (!message || alertQueue.value.length >= maxAlertsQueued) return;
        const newAlert = {
            message,
            type: alertType ?? defaultAlertType,
            duration: duration ?? defaultAlertDuration,
        };

        if (clearQueue) {
            clearAlerts();
        }

        alertQueue.value.push(newAlert);

        if (queueIdle.value) workQueue();
    }

    const showLog = (message: string, clearQueue = false, duration?: number): void => {
        showAlert(message, AlertType.Log, clearQueue, duration);
    }
    const showInfo = (message: string, clearQueue = false, duration?: number): void => {
        showAlert(message, AlertType.Info, clearQueue, duration);
    }
    const showError = (message: string, clearQueue = true, duration: number = 10000): void => {
        showAlert(message, AlertType.Error, clearQueue, duration);
    }

    const showDevError = (message: string, clearQueue = true, duration: number = 10000): void => {
        if (import.meta.env.VITE_ENVIRONMENT !== Environments.Production) {
            message = `DEV ERROR:\n${message}`;
            showAlert(message, AlertType.Error, clearQueue, duration);
        }
    }

    const closeAlert = () => {
        alertActive.value = false;
        alertWasClosed.value = true;
    }

    const clearAlerts = () => {
        alertQueue.value = [];
        closeAlert();
        alertWasClosed.value = false;
    }

    const workQueue = async () => {
        queueIdle.value = false;

        while (alertQueue.value.length > 0) {
            const currentAlert = alertQueue.value.shift();
            if (!currentAlert) continue;

            alertType.value = currentAlert.type;
            alertMessage.value = currentAlert.message;
            alertActive.value = true;

            await timeoutWithWatch(currentAlert.duration + animationDuration/2, animationDuration/2, () => alertWasClosed.value);

            if (alertWasClosed.value) {
                alertWasClosed.value = false;
            }
            else {
                alertActive.value = false;
            }

            await timeout(animationDuration/2);
        }

        queueIdle.value = true;
    }

    const timeoutWithWatch = async (durationMs: number, watchMs: number, predicate: () => boolean): Promise<boolean> => {
        return new Promise(res => {
            let timer = durationMs;
            const newInterval = setInterval(() => {
                const conditionMet = predicate();
                if (conditionMet || timer <= 0) {
                    clearInterval(newInterval);
                    res(conditionMet);
                }
                else {
                    timer -= watchMs;
                }
            }, watchMs);
        });
    }

    const timeout = async (ms: number): Promise<boolean> => {
        return new Promise(res => {
            setTimeout((): void => {
                res(true)
            }, ms);
        });
    }

    return {
        alertActive,
        alertMessage,
        alertType,

        showLog,
        showInfo,
        showError,
        showDevError,
        closeAlert,
        clearAlerts,
    }
});