<template>
    <div>
        <div>
            <h4 class="font-semibold pb-4">
                Campaign Budget
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 items-start gap-x-4 max-w-5xl">
                <CustomSelect
                    label="Verified Leads Budget"
                    :options="getBudgetLimitOptions()"
                    v-model="campaignStore.editingCampaign.daily_limit_type"
                />
                <CustomInput
                    v-if="campaignStore.editingCampaign.daily_limit_type === limitType.spend.value"
                    input-type="number"
                    prefix-text="$"
                    v-model="campaignStore.editingCampaign.max_daily_spend"
                    label="Spend Limit"
                    @change:modelValue="validateBudgetInput"
                />
                <CustomInput
                    v-if="campaignStore.editingCampaign.daily_limit_type === limitType.lead.value"
                    input-type="number"
                    v-model="campaignStore.editingCampaign.max_daily_leads"
                    label="Lead Limit"
                    @change:modelValue="validateBudgetInput"
                />
            </div>
            <div class="pb-6">
                <p v-if="budgetValidationErrorMessage"
                    class="text-red-900 text-sm"
                >
                    {{ budgetValidationErrorMessage }}
                </p>
            </div>
        </div>
        <div v-if="showOptionalLeads"
             class="max-w-5xl"
        >
            <h4 class="font-semibold pb-4">
                Optional Leads
            </h4>
            <div class="relative hidden z-50 md:sticky md:top-20 md:grid md:grid-cols-4 bg-cyan-50 text-center font-bold py-2 items-center border-b border-gray-200 text-xs text-gray-700 uppercase">
                <p>Active</p>
                <p>Name</p>
                <p>{{ leadDailyLimitOptions.find(limit => limit.value === campaignStore.editingCampaign.daily_limit_type)?.label ?? 'No Limit' }}</p>
                <p>Lead Price</p>
            </div>
            <div v-for="[ salesKey, salesType ] in Object.entries(optionalSalesTypes)"
                 :key="salesKey"
                 class="relative border-b odd:bg-gray-50 text-gray-600 text-sm md:text-center items-center grid md:grid-cols-4 gap-4 md:gap-0 p-4 md:py-4 md:px-0">
                <div>
                    <p class="font-bold text-sm md:hidden text-gray-900">Active</p>
                    <ToggleSwitch
                      v-model="salesType.status"
                      :small="true"
                      color="green"
                      @change="() => validateBudgetInput"
                    />
                </div>
                <div>
                    <p class="font-bold text-sm md:hidden text-gray-900">Name</p>
                    <div class="flex items-center md:justify-center">
                        <p class="inline">
                            {{ salesType.name }}
                        </p>
                        <Tooltip class="inline" v-if="tooltips[salesKey]">
                            {{ tooltips[salesKey] }}
                        </Tooltip>
                    </div>
                </div>
                <div>
                    <p class="font-bold text-sm md:hidden text-gray-900">{{ leadDailyLimitOptions.find(limit => limit.value === campaignStore.editingCampaign.daily_limit_type)?.label ?? 'No Limit' }}</p>
                    <div v-if="salesType.status"
                         class="flex md:justify-center items-center"
                    >
                        <CustomInput
                          v-if="campaignStore.editingCampaign.daily_limit_type === limitType.spend.value"
                          type="text"
                          prefix-text="$"
                          classes="max-w-[10rem]"
                          v-model="salesType.maxDailySpend"
                          @change:modelValue="validateBudgetInput"
                        />
                        <CustomInput
                          v-if="campaignStore.editingCampaign.daily_limit_type === limitType.lead.value"
                          type="text"
                          input-pattern="\d+"
                          classes="max-w-[10rem]"
                          v-model="salesType.maxDailyLeads"
                          @change:modelValue="validateBudgetInput"
                        />
                    </div>
                </div>
                <div>
                    <p v-if="prices[salesType.key]?.max && showPriceRange">
                        ${{ prices[salesType.key].min }} - ${{ prices[salesType.key].max }}
                    </p>
                    <p v-else-if="prices[salesType.key]?.min && !showPriceRange">
                        ${{ prices[salesType.key].min }}
                    </p>
                    <div v-else>
                        <p v-if="loading" class="text-slate-700">Searching prices...</p>
                        <p v-else class="text-slate-700">Could not fetch price.</p>
                    </div>
                </div>
            </div>

            <div class="col-start-1 my-10 mx-2 md:mx-0">
                <h4 class="font-semibold pb-2">
                    Other Options
                </h4>
                <div class="flex items-center gap-x-2">
                    <CustomCheckbox
                        v-model="campaignStore.editingCampaign.allow_non_budget_premium_leads"
                    />
                    <p class="text-sm md:text-base">Accept Qualified Premium Leads When Over Budget</p>
                </div>
            </div>
        </div>
        <div v-if="showRequiredLeads"
             class="justify-center flex flex-col max-w-5xl"
        >
            <div class="flex flex-row items-center pb-4">
                <h4 class="font-semibold">
                    Required Leads
                </h4>
                <Tooltip>{{ tooltips.requiredLeads }}</Tooltip>
            </div>
            <table class="w-full text-sm text-left text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-gray-100 text-center">
                <tr>
                    <th class="px-6 py-3">Name</th>
                    <th class="px-6 py-3">Lead Price</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="[ salesKey, salesType ] in Object.entries(requiredSalesTypes)"
                    :key="salesKey"
                    class="bg-white even:bg-gray-50 border-b border-gray-200 last:border-none text-center h-20"
                >
                    <td class="px-6 py-3">
                        <div class="flex items-center justify-center relative">
                            <p class="inline">
                                {{ salesType.name }}
                            </p>
                            <Tooltip class="" v-if="tooltips[salesKey]">
                                {{ tooltips[salesKey] }}
                            </Tooltip>
                        </div>
                    </td>
                    <td class="px-6 py-3">
                        <p v-if="prices[salesType.key]?.max && showPriceRange">
                            ${{ prices[salesType.key].min }} - ${{ prices[salesType.key].max }}
                        </p>
                        <p v-else-if="prices[salesType.key]?.min && !showPriceRange">
                            ${{ prices[salesType.key].min }}
                        </p>
                        <div v-else>
                            <p v-if="loading" class="text-slate-700">Searching prices...</p>
                            <p v-else class="text-slate-700">Could not fetch price.</p>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import CustomInput from "@/components/inputs/CustomInput.vue";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import Tooltip from "@/components/Tooltip.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { useCampaignStore } from "@/stores/campaigns.js";
import ToggleSwitch from "@/components/inputs/ToggleSwitch.vue";
import { usePricingStore } from "@/stores/pricing.js";

const props = defineProps({
    showOptionalLeads: {
        type: Boolean,
        default: true,
    },
    showRequiredLeads: {
        type: Boolean,
        default: true,
    },
    purchaseMissedLead: {
        type: Boolean,
        default: false,
    },
    minimumDailyLeads: {
        type: Number,
        default: 0,
    },
    minimumDailySpend: {
        type: Number,
        default: 0,
    },
});

const campaignStore = useCampaignStore();
const pricingStore = usePricingStore();

const loading = ref(false);
const saving = ref(false);

const prices = ref([]);

const optionalSalesTypes = reactive({});
const requiredSalesTypes = {};

const budgetInputValid = ref(false);
const budgetValidationErrorMessage = ref('');

const showPriceRange = ref(true);

const defaultDailyLeadsLimit = ref(1);
const defaultDailySpendLimit = ref(50);

const tooltips = {
    requiredLeads: 'Exclusive, Duo, Trio and Quad are required lead types and must be selected ',
    exclusive: 'Matched only to you',
    duo: 'Matched with 2 installers',
    trio: 'Matched with 3 installers',
    email_only: 'Communication by email only',
    unverified: 'Phone number not verified',
}

const limitType = {
    lead     : { value: 'leads'},
    spend    : { value: 'spend'},
    unlimited: { value: ''},
};

const leadDailyLimitOptions = [
    { label: 'No Limit', value: limitType.unlimited.value },
    { label: 'Average Daily Spend', value: limitType.spend.value },
    { label: 'Average Daily Leads', value: limitType.lead.value },
];

onMounted(() => {
    loading.value = true;
    if (campaignStore.editingCampaign.id) {
        pricingStore.getPriceRangeForCampaign(campaignStore.editingCampaign.id, limitType.lead.value).then(({ status }) => {
            if (status) {
                prices.value = pricingStore.priceRanges.leads[campaignStore.editingCampaign.id] ?? [];
            }
        }).catch(e => {
            console.error(e);
        }).finally(() => loading.value = false);
    } else {
        setDefaultBudgetForNewCampaign();

        const zipCodeIds = Object.keys(campaignStore.editingCampaign.zip_codes);
        pricingStore.getPriceRangeForZipCodes(zipCodeIds, limitType.lead.value).then(({ status }) => {
            if (status) {
                prices.value = pricingStore.priceRanges.leads.new ?? [];
            }
        }).catch(e => {
            console.error(e);
        }).finally(() => loading.value = false);
    }

    const leadSalesTypes = campaignStore.campaignConfigData.leads_sales_types;
    for (const salesType in leadSalesTypes) {
        if (!leadSalesTypes[salesType].default_type) {
            optionalSalesTypes[leadSalesTypes[salesType].key] = leadSalesTypes[salesType];
            optionalSalesTypes[leadSalesTypes[salesType].key].status = campaignStore.editingCampaign.optional_leads_types[leadSalesTypes[salesType].key]?.status ?? false;
            optionalSalesTypes[leadSalesTypes[salesType].key].maxDailySpend = campaignStore.editingCampaign.optional_leads_types[leadSalesTypes[salesType].key]?.max_daily_spend ?? 0;
            optionalSalesTypes[leadSalesTypes[salesType].key].maxDailyLeads = campaignStore.editingCampaign.optional_leads_types[leadSalesTypes[salesType].key]?.max_daily_leads ?? 0;
        }
        else {
            requiredSalesTypes[leadSalesTypes[salesType].key] = leadSalesTypes[salesType];
        }
    }
});

const validateBudgetInput = () => {
    let budgetErrors = [];
    const minimumDailySpend = !props.minimumDailySpend
        ? campaignStore.campaignConfigData.minimumDailySpend ?? defaultDailySpendLimit.value
        : props.minimumDailySpend;
    const minimumDailyLeads = !props.minimumDailyLeads
        ? campaignStore.campaignConfigData.minimumDailyLeads ?? defaultDailyLeadsLimit.value
        : props.minimumDailyLeads;
    if (campaignStore.editingCampaign.daily_limit_type === limitType.spend.value) {
        const maxDailySpend = campaignStore.editingCampaign.max_daily_spend;
        if (maxDailySpend < minimumDailySpend) budgetErrors.push(`The Verified leads spend must be at least $${minimumDailySpend}.`);
        for (const salesType in optionalSalesTypes) {
            if (optionalSalesTypes[salesType].status) {
                if (optionalSalesTypes[salesType].maxDailySpend < minimumDailySpend) {
                    budgetErrors.push(`The ${optionalSalesTypes[salesType].name} leads spend must be at least $${minimumDailySpend}.`);
                } else if (optionalSalesTypes[salesType].maxDailySpend > maxDailySpend) {
                    budgetErrors.push(`The ${optionalSalesTypes[salesType].name} leads spend cannot be more than the Verified daily spend.`);
                }
            }
        }
    }
    else if (campaignStore.editingCampaign.daily_limit_type === limitType.lead.value) {
        const maxDailyLeads = campaignStore.editingCampaign.max_daily_leads;
        if (maxDailyLeads < minimumDailyLeads) budgetErrors.push(`The Verified leads limit must be at least ${minimumDailyLeads}.`);
        for (const salesType in optionalSalesTypes) {
            if (optionalSalesTypes[salesType].status) {
                if (optionalSalesTypes[salesType].maxDailyLeads < minimumDailyLeads) {
                    budgetErrors.push(`The ${optionalSalesTypes[salesType].name} leads limit must be at least ${minimumDailyLeads}.`);
                } else if (optionalSalesTypes[salesType].maxDailyLeads > maxDailyLeads) {
                    budgetErrors.push(`The ${optionalSalesTypes[salesType].name} leads limit cannot be more than the Verified daily limit.`);
                }
            }
        }
    }
    if (budgetErrors.length) {
        budgetInputValid.value = false;
        budgetValidationErrorMessage.value = budgetErrors.join('\n');
    }
    else {
        budgetInputValid.value = true;
        budgetValidationErrorMessage.value = '';
        saveBudget();
    }
    return !budgetErrors.length;
}

const saveBudget = () => {
    campaignStore.editingCampaign.optional_leads_types = {};
    for (const salesType in optionalSalesTypes) {
        campaignStore.editingCampaign.optional_leads_types[optionalSalesTypes[salesType].key] = {
            id: optionalSalesTypes[salesType].id,
            status: optionalSalesTypes[salesType].status,
            max_daily_spend: optionalSalesTypes[salesType].maxDailySpend,
            max_daily_leads: optionalSalesTypes[salesType].maxDailyLeads,
        };
    }
}

const getBudgetLimitOptions = () => {
    return props.purchaseMissedLead
        ? leadDailyLimitOptions.filter((option) => option.value !== limitType.spend.value)
        : leadDailyLimitOptions;
}

const setDefaultBudgetForNewCampaign = () => {
    campaignStore.editingCampaign.daily_limit_type = props.purchaseMissedLead
        ? limitType.lead.value
        : limitType.unlimited.value;

    campaignStore.editingCampaign.max_daily_leads = props.purchaseMissedLead
        ? props.minimumDailyLeads
        : campaignStore.editingCampaign.max_daily_leads;
}

const validateSlide = () => {
    return validateBudgetInput()
        ? { status: true }
        : { status: false, message: budgetValidationErrorMessage.value };
}
defineExpose({ validateSlide });


</script>