import { createApp } from 'vue'
import { createPinia } from 'pinia'
import vClickOutside from 'click-outside-vue3'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import * as Sentry from './sentry'

import App from './App.vue'
import router from './router'

import './assets/main.css'

document.title = import.meta.env.VITE_APP_NAME ?? document.title;

const app = createApp(App)

Sentry.setup(app, router)

app.use(createPinia());
app.use(router)
app.use(vClickOutside)

app.component('DatePicker', VueDatePicker);

app.mount('#app')
