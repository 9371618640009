<template>
    <div class="relative flex items-center cursor-pointer pr-2" :class="titleClasses" @mouseenter="open" @mouseleave="hide">
        <div><slot name="title" /></div>
        <div class="cursor-pointer ml-2">
            <svg class="text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                :class="[ large ? 'w-5' : 'w-4']">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
            </svg>
        </div>

        <div v-if="showing" class="absolute pt-2 z-30 top-full w-128 flex justify-start" :class="{'left-0': alignLeft, 'right-0': !alignLeft}">
            <div class="shadow z-30 bg-white border border-gray-200 rounded p-4">
                <slot />
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";

const props = defineProps({
    delay: {
        type: Number,
        default: 400
    },
    titleClasses: {
        type: Array,
        default: []
    },
    alignLeft: {
        type: Boolean,
        default: true
    },
    large: {
        type: Boolean,
        default: false,
    },
});

const showing = ref(false);
const showTimeout = ref(null);

const open = () => showTimeout.value = setTimeout(() => showing.value = true, props.delay);

const hide = () => {
    if(showTimeout.value !== null)
        clearTimeout(showTimeout.value);

    showing.value = false;
}
</script>