<template>
    <ModuleBasic>
        <div class="py-5 px-3 md:px-6 gap-y-3 flex flex-row items-center">
            <h2 class="text-lg font-semibold">{{ company.name }}</h2>
        </div>
    </ModuleBasic>
</template>

<script setup>
import ModuleBasic from "@/components/ModuleBasic.vue";
import {useCompanyStore} from "@/stores/company";

const company = useCompanyStore();
</script>