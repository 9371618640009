import { KeyedZipCodeCollection } from "@/components/v4/wizard/slides/components/LocationCheckboxSelect.vue";
import { WizardTransformerService } from "@/services/v4/WizardTransformerService";
import { CampaignSlide } from "@/stores/v4/campaign-modules";

/**
 * Where the payload structure for A2 is not convenient for use in the Wizard slide, add a transformer here
 * These transforms are called when a slide is saved, and stored in futureCampaignStore
 * The wizardStore will retain the data structure used in the Slide
 */
export class CampaignTransformerService extends WizardTransformerService {

    protected transformers = {
        [CampaignSlide.Location]: this.locationTransformer,
    }

    protected locationTransformer(inputKey: string, payload: KeyedZipCodeCollection): GenericObject {
        if (inputKey === 'zip_codes') {
            return Object.values(payload).reduce((output: number[], zipCode): number[] => {
                return zipCode?.id ? [...output, zipCode.id] : output;
            }, []);
        }
        else {
            return payload;
        }
    }

}