import { ModuleInput } from "@/stores/v4/wizard";

type ValidatorCondition = {
    type: ValidTypeConditions,
    args: any[],
}

type Validator = {
    type: ValidTypes,
    nullable: boolean,
    conditions: ValidatorCondition[],
}

type TypeConditions = {
    [key in ValidTypes]: {
        [key in ValidTypeConditions]?: ValidTypes|null
    }
}

type TypeConditionValidators = {
    [key in ValidTypes]: {
        [key in ValidTypeConditions]?: (value: any, ...args: any[]) => boolean
    }
}

type ErrorMessageStore = {
    [key: string]: {
        [key: string]: string
    }
}

export interface ValidatorResults {
    valid: boolean,
    data?: GenericObject,
    message?: string,
    errorBag?: string[],
}

export enum ValidTypes {
    Array = 'array',
    Object = 'object',
    String = 'string',
    Numeric = 'numeric',
    Boolean = 'boolean',
    None = 'none',
}

enum ValidTypeConditions {
    Minimum = 'min',
    Maximum = 'max',
    HasKeys = 'hasKeys',
    Nullable = 'nullable',
}

/**
 * Basic frontend validator to mimic Laravel notation
 */
export class WizardValidationService {

    validTypeConditions: TypeConditions = {
        [ValidTypes.Array]: {
            [ValidTypeConditions.Minimum]: ValidTypes.Numeric,
            [ValidTypeConditions.Maximum]: ValidTypes.Numeric,
            [ValidTypeConditions.Nullable]: null,
        },
        [ValidTypes.Object]: {
            [ValidTypeConditions.Minimum]: ValidTypes.Numeric,
            [ValidTypeConditions.Maximum]:ValidTypes.Numeric,
            [ValidTypeConditions.Nullable]: null,
            [ValidTypeConditions.HasKeys]: ValidTypes.Array,
        },
        [ValidTypes.String]: {
            [ValidTypeConditions.Minimum]: ValidTypes.Numeric,
            [ValidTypeConditions.Maximum]: ValidTypes.Numeric,
            [ValidTypeConditions.Nullable]: null,
        },
        [ValidTypes.Numeric]: {
            [ValidTypeConditions.Minimum]: ValidTypes.Numeric,
            [ValidTypeConditions.Maximum]: ValidTypes.Numeric,
            [ValidTypeConditions.Nullable]: null,
        },
        [ValidTypes.Boolean]: {
            [ValidTypeConditions.Nullable]: null,
        },
        [ValidTypes.None]: {
            [ValidTypeConditions.Nullable]: null,
        }
    }
        
    public validate(input: ModuleInput, inputValue: any): ValidatorResults {
        if (!input.validation) return { valid: true }
        const { name, validation } = input;
        const errorBag = [];
        const validator = this.processValidator(validation);

        if (inputValue == null && !validator.nullable)
            errorBag.push(this.getErrorMessage('generic', 'nullable', name));
        else if (!this.typeCheck(this.cleanType(inputValue, validator.type), validator.type))
            errorBag.push(this.getErrorMessage('generic', 'badType', name, validator.type));
        else {
            for (const condition of validator.conditions) {
                const validationFunction = this.typeConditionValidators[validator.type][condition.type] ?? null;
                if (validationFunction && !validationFunction(inputValue, ...condition.args)) errorBag.push(this.getErrorMessage(validator.type, condition.type, name, condition.args));
            }
        }

        return errorBag.length
            ? { valid: false, errorBag, message: this.getErrorBagSummary(errorBag) }
            : { valid: true };
    }
    
    private typeConditionValidators: TypeConditionValidators = {
        [ValidTypes.Array]: {
            [ValidTypeConditions.Minimum]: (value: [], compareValue: number) => value.length >= compareValue,
            [ValidTypeConditions.Maximum]: (value: [], compareValue: number) => value.length <= compareValue,
        },
        [ValidTypes.Object]: {
            [ValidTypeConditions.Minimum]: (value: {}, compareValue: number) => Object.keys(value).length >= compareValue,
            [ValidTypeConditions.Maximum]: (value: {}, compareValue: number) => Object.keys(value).length <= compareValue,
            [ValidTypeConditions.HasKeys]: (value: {}, compareKeys: string[]) => {
                for (const key of compareKeys) {
                    if (!(key in value)) return false;
                }
                return true;
            }
        },
        [ValidTypes.String]: {
            [ValidTypeConditions.Minimum]: (value: string, compareValue: number) => value.length >= compareValue,
            [ValidTypeConditions.Maximum]: (value: string, compareValue: number) => value.length <= compareValue,
        },
        [ValidTypes.Numeric]: {
            [ValidTypeConditions.Minimum]: (value: number, compareValue: number) => value >= compareValue,
            [ValidTypeConditions.Maximum]: (value: number, compareValue: number) => value <= compareValue,
        },
        [ValidTypes.Boolean]: {},
        [ValidTypes.None]: {},
    }
    
    private errorMessages: ErrorMessageStore = {
        generic: {
            default: '%input% is not a valid value',
            nullable: 'Please supply a value for %input%',
            badType: '%input% must be of type "%arg0%"',
        },
        [ValidTypes.Array]: {
            [ValidTypeConditions.Minimum]: 'Please supply at least %arg0% values for %input%',
            [ValidTypeConditions.Maximum]: 'A maximum of %arg0% values can be supplied for %input%',
        },
        [ValidTypes.Object]: {
            [ValidTypeConditions.Minimum]: 'Please supply at least %arg0% values for %input%',
            [ValidTypeConditions.Maximum]: 'A maximum of %arg0% values can be supplied for %input%',
            [ValidTypeConditions.HasKeys]: '%input% requires the following keys: %arg0%',
        },
        [ValidTypes.String]: {
            [ValidTypeConditions.Minimum]: '%input% must contain at least %arg0% characters',
            [ValidTypeConditions.Maximum]: '%input% must contain %arg0% or less characters',
        },
        [ValidTypes.Numeric]: {
            [ValidTypeConditions.Minimum]: '%input% must be %arg0% or greater',
            [ValidTypeConditions.Maximum]: '%input% must be %arg0% or less',
        },
    }
    
    private getErrorMessage(validatorType: string, conditionType: ValidTypeConditions|string, inputName: string|undefined, ...args: any[]) {
        const errorMessage = this.errorMessages[validatorType]?.[conditionType] ?? this.errorMessages.default;
        inputName = inputName ?? 'the input';
    
        let baseMessage = errorMessage.replace(/%input%/g, inputName);
        for (let i = 0; i < 10; i++) {
            const rx = new RegExp(`%arg${i}%`, 'g');
            if (!rx.test(baseMessage) || !args[i]) break;
            else baseMessage = baseMessage.replace(rx, args[i]);
        }
    
        return baseMessage;
    }

    private processValidator(validatorString: string|string[]): Validator {
        const validatorOutput: Validator = {
            type: ValidTypes.None,
            nullable: false,
            conditions: [],
        };
    
        const parts: string[] = Array.isArray(validatorString)
            ? validatorString
            : validatorString.split(/\s*\|\s*/g);
    
        validatorOutput.type = (parts.find(part => Object.values<string>(ValidTypes).includes(part)) as ValidTypes) ?? ValidTypes.None;

        parts.forEach(part => {
            if (!Object.values<string>(ValidTypes).includes(part)) {
                const validCondition = this.processValidatorCondition(part, validatorOutput.type);
                if (validCondition) {
                    if (validCondition.type === ValidTypeConditions.Nullable)
                        validatorOutput.nullable = true;
                    else
                        validatorOutput.conditions.push(validCondition);
                }
            }
        });
    
        return validatorOutput;
    }
    
    private processValidatorCondition(conditionString: string, type: ValidTypes): ValidatorCondition|null {
        const conditionOutput: { type: ValidTypeConditions | null, args: any[] } = {
            type: null,
            args: [],
        };
    
        const parts: string[] = conditionString.split(/\s*:\s*/g);
        const condition: string = parts.shift() ?? '';

        if (Object.values<string>(ValidTypeConditions).includes(condition)) {
            const argumentType: ValidTypes|null = this.validTypeConditions[type][condition as ValidTypeConditions] ?? null;
            if (argumentType === null) {
                conditionOutput.type = condition as ValidTypeConditions;
            }
            else {
                const validInputs = this.cleanAndTypeCheckValues(parts, argumentType, true, true);
                if (validInputs?.length) {
                    conditionOutput.type = condition as ValidTypeConditions;
                    conditionOutput.args = validInputs;
                }
            }
        }

        if (conditionOutput.type && Object.values<string>(ValidTypeConditions).includes(conditionOutput.type)) {
            return conditionOutput as ValidatorCondition;
        }
        else {
            return null;
        }
    }
    
    private cleanAndTypeCheckValues(values: any[], type: ValidTypes, allValuesMustPass = true, conditionOperatorValues = false): any[]|null {
        const validValues: any[] = [];
        values.forEach(value => {
            const cleanValue = this.cleanType(value, type, conditionOperatorValues);
            if (this.typeCheck(cleanValue, type)) validValues.push(cleanValue);
        });
    
        return !allValuesMustPass || validValues.length === values.length
            ? validValues
            : null;
    }
    
    private cleanType(value: any, type: ValidTypes, isConditionOperator = false) {
        switch(type) {
            case ValidTypes.Numeric: {
                return !isNaN(Number(value))
                    ? Number(value)
                    : undefined;
            }
            case ValidTypes.String: {
                return typeof(value) === 'string'
                    ? value
                    : typeof(value) === 'number'
                        ? `${value}`
                        : undefined;
            }
            case ValidTypes.Boolean: {
                return (value === 'true' || value === 1 || value === true)
                    ? true
                    : (value === 'false' || value === 0 || value === false)
                        ? false
                        : undefined;
            }
            case ValidTypes.Array: {
                if (Array.isArray(value)) return value;
                else if (isConditionOperator && /\s*\[[^\]]+]\s*/.test(value)) {
                    return value.replace(/^\s*\[/, '')
                        .replace(/]\s*$/, '')
                        .split(/\s*,\s*/g);
                }
                else return null;
            }
            default: {
                return value;
            }
        }
    }
    
    private typeCheck(value: any, type: ValidTypes): boolean {
        switch(type) {
            case ValidTypes.Array: {
                return Array.isArray(value);
            }
            case ValidTypes.Object: {
                return value && typeof(value) === 'object' && !Array.isArray(value);
            }
            case ValidTypes.String: {
                return typeof(value) === 'string';
            }
            case ValidTypes.Numeric: {
                return typeof(value) === 'number';
            }
            case ValidTypes.Boolean: {
                return typeof(value) === 'boolean';
            }
            case ValidTypes.None:
            default: {
                return true;
            }
        }
    }
    
    public getErrorBagSummary(errorBag: string[]): string {
        return !errorBag?.length
            ? ''
            : errorBag.length > 1
                ? `${errorBag[0]} (and ${errorBag.length - 1} other errors).`
                : `${errorBag[0]}.`;
    }
    
    public getDefaultTypeValue(input: ModuleInput): any {
        if (!input.validation) return null;
        const validator = this.processValidator(input.validation);
        switch(validator.type) {
            case ValidTypes.Array: {
                return [];
            }
            case ValidTypes.Object: {
                return {};
            }
            case ValidTypes.String: {
                return '';
            }
            case ValidTypes.Numeric: {
                return 0;
            }
            case ValidTypes.Boolean: {
                return false;
            }
            case ValidTypes.None:
            default: {
                return null;
            }
        }
    }

    public getExpectedType(input: ModuleInput): ValidTypes {
        if (!input.validation) return ValidTypes.None;
        const parts = Array.isArray(input.validation)
            ? input.validation
            : input.validation.split(/\s*\|\s*/g);

        return (parts.find(part => Object.values<string>(ValidTypes).includes(part)) as ValidTypes) ?? ValidTypes.None;
    }
}