<template>
    <div class="fixed inset-x-0 z-[101px] lg:hidden bg-white py-2 border-b shadow-md rounded-b max-h-[36rem] overflow-y-auto">
        <ServiceSelector @launchIndustryServiceEditor="launchIndustryServiceEditor" />
        <NavigationItems></NavigationItems>
    </div>
</template>

<script setup>
import NavigationItems from "@/components/navigation/NavigationItems.vue";
import ServiceSelector from "@/components/navigation/ServiceSelector.vue";

const emit = defineEmits(['launchIndustryServiceEditor']);

const launchIndustryServiceEditor = () => {
    emit('launchIndustryServiceEditor');
}
</script>

<style scoped>

</style>