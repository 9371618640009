type Transformer = (inputKey: string, payload: GenericObject) => GenericObject;

type TransformerCollection = {
    [slideKey: string]: Transformer,
}

/**
 * Where the payload structure for A2 is not convenient for use in the Wizard slide, add a transformer here
 * These transforms are called when a slide is validated, and stored in futureCampaignStore
 * The wizardStore will retain the data structure used in the Slide
 */
export interface WizardTransformerServiceContract {
    transformPayload(slideKey: string, inputKey: string, payload: GenericObject): GenericObject;
}

export class WizardTransformerService implements  WizardTransformerServiceContract {

    protected transformers: TransformerCollection = {}

    protected getTransformer(slideKey: string): Transformer|null {
        return this.transformers[slideKey] ?? null;
    }

    public transformPayload(slideKey: string, inputKey: string, payload: GenericObject): GenericObject {
        const transformer: Transformer|null = this.getTransformer(slideKey);
        if (transformer) {
            return transformer(inputKey, payload);
        }
        else {
            return payload;
        }
    }
}