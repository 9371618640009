<template>
    <div>
        <Modal
            @clicked:confirm="addZipCodes"
            @clicked:cancel="closeModal"
            confirm-label="Add"
            :small="smallModal"
            :class="[saving && 'pointer-events-none grayscale-[80%]']"
        >
            <template v-slot:header>
                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                    </svg>
                    <h5 class="text-md ml-2">Upload Zip Code CSV</h5>
                </div>
            </template>
            <template v-slot:body>
                <div v-if="uploadResults?.length" class="w-[100%] max-h-[30rem] overflow-y-auto">
                    <div class="grid grid-cols-1 gap-5 p-3 mb-5 border rounded-md bg-gray-50">
                        <label class="inline-flex items-center">
                            <CustomCheckbox
                                :modelValue="uploadOptions.append"
                                @update:modelValue="(checked) => updateUploadOption('append', checked)"
                            />
                            <span class="ml-2">Append Locations</span>
                        </label>
                        <label class="inline-flex items-center">
                            <CustomCheckbox
                                :modelValue="uploadOptions.replace"
                                @update:modelValue="(checked) => updateUploadOption('replace', checked)"
                            />
                            <span class="ml-2">Replace Locations</span>
                        </label>
                    </div>
                    <div class="grid justify-center text-center grid-cols-3 text-sm text-gray-700 capitalize bg-cyan-25 font-bold py-2 px-6 items-center border-b border-gray-200">
                        <div class="flex items-center">
                            <CustomCheckbox
                                :model-value="true"
                                @update:modelValue="(checked) => selectAllZipCodes(checked)"
                            />
                            <p class="ml-4">Selected</p>
                        </div>
                        <p>Zip Code</p>
                        <p>City</p>
                    </div>
                    <div v-for="zipCode in uploadResults"
                         :key="zipCode.id"
                         class="text-center relative border-b odd:bg-gray-50 text-gray-600 items-center grid md:grid-cols-3 gap-4 md:gap-0 py-4 px-4 md:px-6"
                    >
                        <div class="flex text-sm justify-self-start">
                            <CustomCheckbox
                                v-model="selectedZipCodes[zipCode.id]"
                            />
                        </div>
                        <p class="text-sm">{{ zipCode.zip_code }}</p>
                        <p class="text-sm">{{ zipCode.city_name }}</p>
                    </div>
                </div>
                <div v-else
                     :class="[ loading ? 'pointer-events-none grayscale-[50%] opacity-50' : '' ]"
                >
                    <p class="pb-6 text-center">Add zip codes by comma-separated value files (CSV):</p>
                    <FileUpload
                        accept=".csv"
                        @file-uploaded="handleFileUpload"
                    />
                </div>
                <div v-if="errorMessage">
                    <p class="text-red-900 text-sm text-center py-4 whitespace-pre">{{ errorMessage }}</p>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import { ref } from "vue";
import FileUpload from "@/components/inputs/FileUpload.vue";
import { useLocalityDataStore } from "@/stores/locality-data.js";
import { useErrorStore } from "@/stores/errors.js";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";

const emit = defineEmits(['hideZipUploadModal', 'addZipCodesFromModal']);

const localityDataStore = useLocalityDataStore();
const errors = useErrorStore();

const smallModal = ref(false);
const loading = ref(false);

const errorMessage = ref(null);

const uploadResults = ref([]);
const selectedZipCodes = ref({});

const uploadOptions = ref({
    append: true,
    replace: false
});

const props = defineProps({
    saving: {
        type: Boolean,
        default: false,
    }
});

const handleFileUpload = async (fileList) => {
    errorMessage.value = null;
    const targetFile = fileList[0];
    loading.value = true;

    try {
        const textContent = await targetFile.text();
        const zipStringArray = textContent.trim().replace(/['"]+/g, '').split(/\s*,\s*/g);
        const { status, message, zip_codes } = await localityDataStore.getZipCodesByStringArray(zipStringArray);
        if (!status) {
            errors.report(message);
            errorMessage.value = message;
        }
        else {
            uploadResults.value = zip_codes;
            zip_codes.forEach(zip => selectedZipCodes.value[zip.id] = true);
        }
    } catch (e) {
        errorMessage.value = `There was an error uploading the file:\n${e.message}`;
        errors.report(e, errorMessage.value);
    }
    
    loading.value = false;
}

const selectAllZipCodes = (checked) => {
    for (const id in selectedZipCodes.value) {
        selectedZipCodes.value[id] = !!checked;
    }
}

const addZipCodes = () => {
    const payload = uploadResults.value.filter(zipCode => selectedZipCodes.value[zipCode.id]);
    emit('addZipCodesFromModal', payload, uploadOptions.value.append);
}

const closeModal = () => {
    emit('hideZipUploadModal');
}

const updateUploadOption = (option, checked) => {
    switch (option) {
        case 'append':
            uploadOptions.value.append = checked;
            uploadOptions.value.replace = !checked;
            break
        case 'replace':
            uploadOptions.value.replace = checked;
            uploadOptions.value.append = !checked;
            break;
    }
}

</script>