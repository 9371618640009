<template>
    <div class="inline-flex items-center relative cursor-pointer">
        <!-- TODO: Devs - Width controls Star rating, so apply data variable to width to change rating -->
        <div :style="styleWidth" class="absolute flex overflow-hidden">
            <div class="flex">
                <svg v-for="index in total" :key="index" fill="none" height="25" viewBox="0 0 28 25" width="25"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.8609 0L10.1822 9.07327L0 9.26723L7.92679 15.4095L4.94877 24.7198L13.5106 19.4181L21.8753 25L19.2476 15.5819L27.3715 9.69827L17.1893 9.18103L13.8609 0Z"
                        fill="#FFCB13"/>
                    <path
                        d="M17.8243 9.22412C16.4448 15.3233 11.8026 20.2586 5.69324 22.4138L4.94873 24.7198L13.5105 19.4396L21.8753 25L19.2476 15.6034L27.3715 9.71981L17.8243 9.22412Z"
                        fill="#FFE14D"/>
                </svg>
            </div>
        </div>
        <div class="flex">
            <svg v-for="index in total" :key="index" fill="none" height="25" viewBox="0 0 28 25" width="25"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.8609 0L10.1822 9.07327L0 9.26723L7.92679 15.4095L4.94877 24.7198L13.5106 19.4181L21.8753 25L19.2476 15.5819L27.3715 9.69827L17.1893 9.18103L13.8609 0Z"
                    fill="#EEEEEE"/>
                <path
                    d="M17.8243 9.22412C16.4448 15.3233 11.8026 20.2586 5.69324 22.4138L4.94873 24.7198L13.5105 19.4396L21.8753 25L19.2476 15.6034L27.3715 9.71981L17.8243 9.22412Z"
                    fill="#EEEEEE"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        score: {
            type: Number,
            default: 0
        },
        total: {
            type: Number,
            default: 5
        }
    },
    computed: {
        styleWidth: function () {
            return {
                width: Math.round(this.score / this.total * 100) + '%'
            };
        }
    }
}
</script>

<style scoped>

</style>