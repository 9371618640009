/**
 * Filter out industries and services which shouldn't be handled by Fixr Dashboard
 * Provide a disabled key to either function to override default disabled industries
 * Use filterCompany to filter Company store data
 * use filterServiceSelector to filter the data fetched by the Industry Service selector component
 *
 * This composable can be deleted once Solar is migrated
 */
export const useIndustryFilter = (() => {
    const defaultDisabledIndustries = ['solar', 'roofing'];

    const filterCompany = (industries = [], services = {}, industryConfigs = {}, config = {}) => {
        const disabledIndustries = config.disabled ?? defaultDisabledIndustries;

        if (industries.length) {
            industries = industries.filter(v => !disabledIndustries.includes(v.toLowerCase()));
        }

        if (Object.keys(services).length) {
            services = Object.entries(services).reduce((output, [key, val]) => {
                return disabledIndustries.includes(key)
                    ? output
                    : { ...output, [key]: val }
            }, {});
        }

        if(Object.keys(industryConfigs).length) {
            industryConfigs = Object.entries(industryConfigs).reduce((output, [key, val]) => {
                return disabledIndustries.includes(key.toLowerCase())
                    ? output
                    : { ...output, [key]: val }
            }, {});
        }

        return { filteredIndustries: industries, filteredServices: services, filteredIndustryConfigurations: industryConfigs }
    }

    const filterServiceSelector = (services = {}, config = {}) => {
        const disabledIndustries = config.disabled ?? defaultDisabledIndustries;
        if (Object.keys(services).length) {
            services = Object.entries(services).reduce((output, [key, val]) => {
                return disabledIndustries.includes(key.toLowerCase())
                    ? output
                    : { ...output, [key]: val }
            }, {});
        }
        return { filteredServices: services }
    }

    return {
        filterCompany,
        filterServiceSelector,
    }

})();