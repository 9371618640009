<template>
    <div class="flex flex-col items-center max-h-full">
        <p class="mb-5">Who would you like these leads/appointments sent to?</p>
        <div class="flex flex-col gap-5 w-full">
            <div class="relative min-h-[16rem]">
                <div class="overflow-x-hidden">
                    <div class="hidden md:grid md:grid-cols-7 text-xs text-gray-700 uppercase px-4 bg-cyan-50 font-bold py-3 items-center border-b border-gray-200" >
                        <div class="col-span-1">Select</div>
                        <div class="col-span-2">Name</div>
                        <div class="col-span-2">Email</div>
                        <div class="col-span-2">Cell Phone</div>
                    </div>
                    <div v-if="companyUserStore.isLoadingCompanyUsers" class="text-center mt-5">
                        <p>Loading...</p>
                    </div>
                    <div v-else-if="availableContacts.length === 0 && !companyUserStore.isLoadingCompanyUsers" class="text-center mt-5">
                        <p>No data found</p>
                    </div>
                    <div v-else
                         v-for="user in availableContacts"
                         :key="user.id"
                         class="hide-scrollbar relative border-b odd:bg-gray-50 text-gray-600 text-sm items-start md:items-center grid grid-cols-1 md:grid-cols-7 gap-4 md:gap-0 px-4 py-6 md:py-4 md:px-4" >
                        <div class="col-span-1">
                            <p class="font-bold text-sm md:hidden text-gray-900">Select</p>
                            <CustomCheckbox v-model="selectedContacts[user.legacy_id]" />
                        </div>
                        <div class="col-span-2">
                            <p class="font-bold text-sm md:hidden text-gray-900">Name</p>

                            <p class="overflow-scroll">{{ user.first_name }} {{user.last_name}}</p>
                        </div>
                        <div class="col-span-2">
                            <p class="font-bold text-sm md:hidden text-gray-900">Email</p>

                            <p class="overflow-scroll">{{ user.email }}</p>
                        </div>
                        <div class="col-span-2">
                            <p class="font-bold text-sm md:hidden text-gray-900">Cell Phone</p>

                            <p class="overflow-scroll">{{ user.cell_phone }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!companyUserStore.isLoadingCompanyUsers" class="bg-white w-full flex items-center justify-center sticky -bottom-5 py-5 gap-3 flex-wrap">
            <div>
                <OutlineButton @click="showAddContactModal = true" >
                    Create New Contact
                </OutlineButton>
            </div>
            <SolidButton :loading="store.isInitializingCheckout" :disabled="!canProgress" @click="checkout()">Proceed to checkout</SolidButton>
        </div>
        <!--    Add contact modal with the 'Contact' type pre-selected    -->
        <CreateUpdateCompanyUserModal
            :deliveries-slide="true"
            @hideEditUserModal="closeAddUserModal"
            v-if="showAddContactModal"
        />
    </div>
</template>

<script setup>
import SolidButton from "@/components/inputs/SolidButton.vue";
import {usePurchaseMissedProductsStore} from "@/stores/purchase-missed-products";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import { useCompanyUsersStore } from "@/stores/company-users.js";
import {ref, watch} from "vue";
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import CreateUpdateCompanyUserModal from "@/components/company/modals/CreateUpdateCompanyUserModal.vue";

const store = usePurchaseMissedProductsStore();
const companyUserStore = useCompanyUsersStore();
const availableContacts = ref([]);
const selectedContacts = ref({});
const canProgress = ref(false);
const showAddContactModal = ref(false);

companyUserStore.initialize().then(function() {
    updateContactList();
})

watch(selectedContacts.value, (newVal, oldVal) => {
    canProgress.value = Object.values(selectedContacts.value).some(val => val === true)
})

const updateContactList = function (refresh = false) {
    if(refresh) availableContacts.value = [];
    companyUserStore.editingUser.user_type = 'contact'
    companyUserStore.companyUsers.forEach(user => {
        if (user.is_contact
            && user.legacy_id
            && (user.email || user.cell_phone)
        ) {
            availableContacts.value.push(user);
            selectedContacts[user.legacy_id] = false;
        }
    });
}

const closeAddUserModal = async () => {
    showAddContactModal.value = false;
    companyUserStore.initialize(true).then(function() {
        updateContactList(true);
    })
}

const checkout = function () {
    Object.entries(selectedContacts.value).filter(([_, selected]) => selected).forEach(([legacyId]) => {
        const contact = companyUserStore.companyUsers.find(user => legacyId && parseInt(user.legacy_id) === parseInt(legacyId));
        if (contact) {
            store.editingCampaign.contact_deliveries.push({
                id: contact.id,
                legacy_id: contact.legacy_id,
                name: `${contact.first_name} ${contact.last_name}`,
                email: contact.email,
                cell_phone: contact.cell_phone,
                new: true,
            });
        }
    });

    store.initializeCheckout()
}


</script>

<style>
.hide-scrollbar .overflow-scroll::-webkit-scrollbar{
    display: none;
}
</style>