<template>
    <MainGrid>
        <ModuleBasic class="p-5">
            <div class="relative items-center gap-8 p-3 mx-auto">
                <h4 class="text-cyan-500 text-lg font-semibold pb-5 items-center inline-flex gap-2">
                    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3129 0.633644C10.7622 0.380691 11.2691 0.247803 11.7847 0.247803C12.3003 0.247803 12.8072 0.380691 13.2565 0.633644C13.7058 0.886598 14.0823 1.25108 14.3497 1.69192L14.3526 1.69667L22.8226 15.8367L22.8307 15.8505C23.0926 16.3041 23.2312 16.8184 23.2327 17.3423C23.2342 17.8661 23.0984 18.3812 22.839 18.8363C22.5796 19.2914 22.2056 19.6706 21.7541 19.9363C21.3026 20.2019 20.7895 20.3447 20.2657 20.3505L20.2547 20.3506L3.30369 20.3505C2.77989 20.3448 2.26673 20.2019 1.81526 19.9363C1.36378 19.6706 0.989737 19.2914 0.730334 18.8363C0.470931 18.3812 0.335211 17.8661 0.336678 17.3423C0.338145 16.8184 0.476747 16.3041 0.738695 15.8505L0.746818 15.8367L9.21967 1.69191C9.48708 1.25107 9.8636 0.886598 10.3129 0.633644ZM11.7847 2.2478C11.6128 2.2478 11.4439 2.2921 11.2941 2.37642C11.145 2.46035 11.02 2.58113 10.9309 2.72718L2.46737 16.8564C2.38219 17.0062 2.33715 17.1755 2.33667 17.3479C2.33618 17.5225 2.38142 17.6942 2.46789 17.8459C2.55436 17.9976 2.67904 18.124 2.82953 18.2125C2.9787 18.3003 3.14808 18.3479 3.32108 18.3505H20.2483C20.4213 18.3479 20.5907 18.3003 20.7398 18.2125C20.8903 18.124 21.015 17.9976 21.1015 17.8459C21.188 17.6942 21.2332 17.5225 21.2327 17.3479C21.2322 17.1755 21.1872 17.0062 21.102 16.8564L12.6397 2.72917C12.6393 2.72851 12.6389 2.72785 12.6385 2.72718C12.5494 2.58113 12.4244 2.46035 12.2753 2.37642C12.1255 2.2921 11.9566 2.2478 11.7847 2.2478Z" fill="currentColor"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7847 6.35059C12.337 6.35059 12.7847 6.7983 12.7847 7.35059V11.3506C12.7847 11.9029 12.337 12.3506 11.7847 12.3506C11.2324 12.3506 10.7847 11.9029 10.7847 11.3506V7.35059C10.7847 6.7983 11.2324 6.35059 11.7847 6.35059Z" fill="currentColor"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7847 15.3506C10.7847 14.7983 11.2324 14.3506 11.7847 14.3506H11.7947C12.347 14.3506 12.7947 14.7983 12.7947 15.3506C12.7947 15.9029 12.347 16.3506 11.7947 16.3506H11.7847C11.2324 16.3506 10.7847 15.9029 10.7847 15.3506Z" fill="currentColor"/>
                    </svg>
                    No service selected</h4>
                <p class="mb-1 text-gray-700">This section requires an Industry Service to be selected.</p>
                <p class="mb-1 text-gray-700">If none are available, at least one Industry Service must be added using the Service Management control.</p>
                <p class="mb-8 text-gray-700">Click the button below to Add a Service.</p>
                <SolidButton @click="launchIndustryServiceEditor">Add Service</SolidButton>
            </div>
        </ModuleBasic>
        <IndustryServiceModal
            key="industryServiceModal"
            @hideIndustryServiceModal="hideIndustryServiceEditor"
            v-if="showIndustryServiceEditor"
        />
    </MainGrid>
</template>

<script setup>
import ModuleBasic from "@/components/ModuleBasic.vue";
import MainGrid from "@/components/layouts/MainGrid.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import IndustryServiceModal from "@/components/navigation/Modals/IndustryServiceModal.vue";
import {ref} from "vue";

const showIndustryServiceEditor = ref(false);
const launchIndustryServiceEditor = () => { showIndustryServiceEditor.value = true; }
const hideIndustryServiceEditor = () => { showIndustryServiceEditor.value = false; }

</script>